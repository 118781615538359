(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('SavedFormValueModal', SavedFormValueModal);

    SavedFormValueModal.$inject = ['$mdDialog'];

    function SavedFormValueModal ($mdDialog) {
        var service = {
            create: create,
            edit: edit,
            search: search
        };

        return service;

        /**
         * Open the create dialog
         * @param  {String} value Optional. The value to pass to the dialog.
         * @param  {Event} ev     Optional. The event that triggered the modal.
         * @return {Promise}      A promise that will be resolved when the modal closes.
         */
        function create(value, ev) {
            return openDialog({
                id: null,
                name: null,
                value: value || {}
            }, ev);
        }

        /**
         * Open the edit dialog
         * @param  {Number} entityId The id of an existing entity.
         * @param  {Event} ev        Optional. The event that triggered the modal.
         * @return {Promise}         A promise that will be resolved when the modal closes.
         */
        function edit(entityId, ev) {
            return openDialog(entityId, ev);
        }

        /**
         * Open the create/edit dialog
         * @param  {Object|Number} entity Either an object representing the entity, or the id of
         *                                an existing entity.
         * @param  {Event} ev             Optional. The event that triggered the modal.
         * @return {Promise}              A promise that will be resolved when the modal closes.
         */
        function openDialog(entity, ev) {
            // Define common arguments
            var modalArgs = {
                targetEvent: ev,
                templateUrl: 'app/entities/saved-form-value/saved-form-value-dialog.html',
                controller: 'SavedFormValueDialogController',
                controllerAs: 'vm',
                clickOutsideToClose: true
            };

            // Resolve entity parameter
            if(angular.isObject(entity)) {
                modalArgs.locals = { entity: entity };
            } else {
                modalArgs.resolve = {
                    entity : ['SavedFormValue', function(SavedFormValue) {
                        return SavedFormValue.get({id : entity}).$promise;
                    }]
                };
            }

            // Return modal promise
            return $mdDialog.show(modalArgs);
        }

        function search(fieldPath, ev) {
            return $mdDialog.show({
                targetEvent: ev,
                templateUrl: 'app/entities/saved-form-value/saved-form-value-search-dialog.html',
                controller: 'SavedFormValueSearchDialogController',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                locals: {
                    fieldPath: fieldPath
                }
            });
        }
    }
})();
