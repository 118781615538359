(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('region', {
            parent: 'entity',
            url: '/region?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'valueconnectApp.region.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/region/regions.html',
                    controller: 'RegionController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'name,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('region');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('region-detail', {
            parent: 'entity',
            url: '/region/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'valueconnectApp.region.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/region/region-detail.html',
                    controller: 'RegionDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('region');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Region', function($stateParams, Region) {
                    return Region.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        .state('region.new', {
            parent: 'region',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/region/region-dialog.html',
                    controller: 'RegionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                id: null,
                                name: null,
                                disabled: false,
                                timeZoneId: null
                            };
                        }
                    }
                }).result.then(function(result) {
                    $state.go('region.pricing-matrix.clone', { id: result.id });
                }, function() {
                    $state.go('region');
                });
            }]
        })
        .state('region.edit', {
            parent: 'region',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/region/region-dialog.html',
                    controller: 'RegionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Region', function(Region) {
                            return Region.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('region', null, { reload: true });
                }, function(reason) {
                    if(reason !== 'stateChange') $state.go('^');
                });
            }]
        })
        .state('region.pricing-matrix', {
            abstract: true,
            parent: 'region',
            url: '/{id}/pricing-matrix',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'valueconnectApp.region.pricingMatrix.pageTitle'
            },
            resolve: {
                Enum: ['Enum', function(Enum) {
                    return Enum.$promise.then(function() { return Enum; });

                }],
                region: ['$stateParams', 'Region', function($stateParams, Region) {
                    return Region.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        .state('region.pricing-matrix.edit', {
            parent: 'region.pricing-matrix',
            url: '/edit',
            resolve: {
                pricingMatrix: ['$state', '$stateParams', 'PricingMatrix', 'AlertService', function($state, $stateParams, PricingMatrix, AlertService) {
                    // Retreive the pricing matrix; redirect to the clone state if uninitialized
                    return PricingMatrix.get({id : $stateParams.id}).$promise.then(null, function(response) {
                        var errorKey = response.headers('X-valueconnectApp-error');
                        if(errorKey === 'valueconnectApp.region.pricingMatrix.uninitialized') {
                            AlertService.error(errorKey);
                            $state.go('region.pricing-matrix.clone', { id : $stateParams.id });
                        }
                        return response;
                    });
                }]
            },
            onEnter: ['$state', '$mdDialog', 'pricingMatrix', 'region', function($state, $mdDialog, pricingMatrix, region) {
                $mdDialog.show({
                    templateUrl: 'app/entities/region/pricing-matrix/pricing-matrix-dialog.html',
                    controller: 'PricingMatrixDialogController',
                    controllerAs: 'vm',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('regionalProduct');
                            $translatePartialLoader.addPart('specialRequirement');
                            $translatePartialLoader.addPart('pricingParameter');
                            $translatePartialLoader.addPart('enum');
                            $translatePartialLoader.addPart('enumProductType');
                            return $translate.refresh();
                        }]
                    },
                    locals: {
                        pricingMatrix: pricingMatrix,
                        region: region
                    }
                });
            }]
        })
        .state('region.pricing-matrix.clone', {
            parent: 'region.pricing-matrix',
            url: '/clone',
            params: { error: null },
            onEnter: ['$state', '$stateParams', '$mdDialog', 'region', function($state, $stateParams, $mdDialog, region) {
                var returnStateName, returnStateParams;

                // Determine the return state based on the source state
                if(!$state.current || $state.current.abstract) {
                    returnStateName = 'region';
                    returnStateParams = null;
                } else if($state.current.name === 'region.new') {
                    returnStateName = 'region.edit',
                    returnStateParams = { id: $stateParams.id };
                } else {
                    returnStateName = $state.current.name;
                    returnStateParams = $state.params;
                }

                $mdDialog.show({
                    templateUrl: 'app/entities/region/pricing-matrix/pricing-matrix-clone-dialog.html',
                    controller: 'PricingMatrixCloneDialogController',
                    controllerAs: 'vm',
                    locals: {
                        region: region
                    }
                }).then(function() {
                    $state.go(returnStateName, returnStateParams, { reload: true });
                }, function() {
                    $state.go(returnStateName, returnStateParams);
                });
            }]
        })
        // TODO: Remove this state and re-use the region state
        // to get paging and sorting, etc to work correctly
        .state('region-ccr', {
            parent: 'region',
            url: '/list',
            data: {
                authorities: ['ROLE_CUSTOMER_CARE_REPRESENTATIVE']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/region/region-ccr.html',
                    controller: 'RegionCCRController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('region');
                    return $translate.refresh();
                }]
            }
        })

        .state('region.delete', {
            parent: 'region',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/region/region-delete-dialog.html',
                    controller: 'RegionDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Region', function(Region) {
                            return Region.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('region', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
