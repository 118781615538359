(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('ModalForm', ModalForm);

    ModalForm.$inject = ['$resource', 'DateUtils'];

    function ModalForm ($resource, DateUtils) {
        var resourceUrl =  'api/appraisal-orders/:id';

        return $resource(resourceUrl, {}, {
            'update': { method:'PUT' }
        });
    }
})();
