(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .factory('BrokerageSearch', BrokerageSearch);

    BrokerageSearch.$inject = ['$resource'];

    function BrokerageSearch($resource) {
        var resourceUrl =  'api/_search/brokerages';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
        });
    }
})();
