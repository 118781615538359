(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraisalOrderChangeRequestDialogController', AppraisalOrderChangeRequestDialogController);

    AppraisalOrderChangeRequestDialogController.$inject = ['$log', '$timeout', '$scope', '$state', '$stateParams', '$mdDialog', 'entity', 'AppraisalOrderChangeRequest', 'AppraisalOrder', 'User', 'AppraisalOrderModal', 'AlertService'];

    function AppraisalOrderChangeRequestDialogController ($log, $timeout, $scope, $state, $stateParams, $mdDialog, entity, AppraisalOrderChangeRequest, AppraisalOrder, User, AppraisalOrderModal, AlertService, showDeclineAndReassign) {
        var vm = this;

        AppraisalOrderChangeRequest.get({id: entity.appraisalOrderChangeRequest.id}, function(data) {
            vm.appraisalOrderChangeRequest = data;

            if (vm.appraisalOrderChangeRequest.proposedDueDate) {
                vm.appraisalOrderChangeRequest.proposedDueDate = new Date(vm.appraisalOrderChangeRequest.proposedDueDate);
                vm.appraisalOrderChangeRequest.acceptedDueDate = new Date(vm.appraisalOrderChangeRequest.proposedDueDate);
            } else if (vm.appraisalOrderChangeRequest.proposedFee) {
                vm.appraisalOrderChangeRequest.acceptedFee = vm.appraisalOrderChangeRequest.proposedFee;
            }

            vm.minDate = new Date() < vm.appraisalDueDate ? vm.appraisalDueDate : new Date();
            vm.readOnly = vm.appraisalOrderChangeRequest.respondedOn !== null;

            vm.loaded = true;
        });
        vm.changeDialog = entity;
        vm.clear = clear;
        vm.showDeclineAndReassign = showDeclineAndReassign;

        vm.save = save;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        vm.dueDateOpen = false;
        vm.conditionFinancingDateOpen = false;
        vm.appraisalDueDate = new Date(entity.appraisalOrder.activeReport.appraisalDueDate);

        vm.dateEnabled = function(date) {
            var day = date.getDay();
            return day !== 0 && day !== 6;
        };

        vm.activeReport = null;
        angular.element(entity.appraisalOrder.appraisalReports).each(function(i, rep) {
            if (entity.appraisalOrder.activeReport.id === rep.id) {
                vm.activeReport = rep;
                return;
            }
        });


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $mdDialog.cancel();
            console.log($mdDialog);
        }

        function save () {
            vm.isSaving = true;
            vm.appraisalOrderChangeRequest.appraisalOrderId = vm.changeDialog.appraisalOrder.id;

            var reassign = false;
            if (vm.appraisalOrderChangeRequest.action === "DECLINE_REASSIGN") {
                reassign = true;
                vm.appraisalOrderChangeRequest.action = "DECLINE";
            }
            AppraisalOrderChangeRequest.update(vm.appraisalOrderChangeRequest, function(data) {
                $mdDialog.hide(data);
                vm.isSaving = false;

                var alert = $mdDialog.alert()
                      .title('Change request accept/decline')
                      .textContent('Update complete.')
                      .targetEvent(null)
                      .ok('OK');
                $mdDialog.show(alert).then(function() {
                    if (reassign) {
                        $state.reload();
                        AppraisalOrderModal.assignAppraiser(entity.appraisalOrder, "ASSIGN_APPRAISER").then(function(result) {
                            vm.appraisalOrder = result;
                            AlertService.success("Appraiser updated successfully");
                        }, function(result) {
                            if(result && result.error) AlertService.error(result.error);
                        });
                    } else {
                        $state.reload();
                    }
                });

            }, function(data) {
                $log.error(data);
                vm.isSaving = false;
            });
        }

        vm.datePickerOpenStatus.proposedDueDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }


    }
})();
