(function () {
    'use strict';

    angular
        .module('valueconnectApp')
        .factory('Enum', Enum);

    Enum.$inject = ['$log','$resource', '$translate'];

    /**
     * This will make a request to get enum definitions when this service is first loaded
     * Subsequent uses of this service will reuse the existing data
     * Once data has been loaded, each enum type is aliased with a lower-cased version
     * I.E. 'PropertyType' will be aliased to 'propertyType'
     */
    function Enum($log, $resource, $translate) {
        var backgroundService = $resource('api/enums', {}, {
            query: {
                method: 'GET',
                isArray: false
            }
        });

        var service = backgroundService.query(function(data) {
            // Add an alias for each enum type
            // TODO: remove this, it is unecessary
            for(var enumType in data){
                if(!data.hasOwnProperty(enumType)) continue; // skip inherited properties
                var enumAlias = enumType.charAt(0).toLowerCase() + enumType.slice(1);
                service[enumAlias] = data[enumType];
            }

            // Add service methods to object
            service.$query = $query;
        }, function(response) {
            // ERROR :(
        });


        /**
         * Query the translated values for the specified enum
         * @param  {String} key                The enum to search
         * @param  {String} searchText         The text to search for
         * @param  {Object} translationParams  Parameters to pass to the translation service
         * @return {Promise}                   A promise that is resolved with an array of mathing translations
         */
        // TODO: support existing values to filter out of results
        function $query(key, searchText, translationParams) {
            // TODO: wait for service to resolve

            // Make sure the enum key is valid
            if(!service[key]) {
                $log.error("Enum does not exist:", key);
                return [];
            }

            // Map the enum keys to translation keys
            var translationKeys = service[key].map(function(enumValue) {
                return 'valueconnectApp.form.' + key + '.' + enumValue;
            });

            // Translate the values for the specified enum
            return $translate(translationKeys, translationParams).then(function (translations) {
                // Return all the translations if no search text was entered
                var translationValues = Object.keys(translations).map(function(k){return translations[k];});
                if(!angular.isString(searchText) || !searchText.trim()) return translationValues;

                // Create a regex for this search text
                searchText = searchText.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
                var searchRegex = new RegExp(searchText, 'i');

                // Filter the translations using the regex
                return translationValues.filter(function(option) {
                    return searchRegex.test(option);
                });
            }, function(translationIds) {
                $log.error("Failed to translate keys for enum: ", key, translationKeys);
                return [];
            });
        }

        return service;
    }
})();
