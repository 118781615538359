(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraisalReportThirdPartyReviewController', AppraisalReportThirdPartyReviewController);

    AppraisalReportThirdPartyReviewController.$inject = ['$scope', '$state', '$stateParams', '$sce', '$rootScope', '$mdDialog', 'report', 'order', 'account',
                                                'reportSubmittable', 'AppraisalReport', 'Principal', 'validationErrors', 'AppraiserUser',
                                                '$location', '$anchorScroll', 'LocalStorage', 'AppraisalState', 'reportErrors'];

    function AppraisalReportThirdPartyReviewController($scope, $state, $stateParams, $sce, $rootScope, $mdDialog, report, order, account,
                                                reportSubmittable, AppraisalReport, Principal, validationErrors, AppraiserUser,
                                                $location, $anchorScroll, LocalStorage, AppraisalState, reportErrors) {
        var vm = this;

        // Collapsible //
        var collapsibleStoreId = 'appraisal_report_review_open_errors_' + report.id + '_' + (account ? account.id : "");
        vm.isOpen = LocalStorage.HasModel(collapsibleStoreId);
        vm.toggleOpen = function() {
            vm.isOpen = !vm.isOpen;
            vm.isOpen ? LocalStorage.SaveModel(collapsibleStoreId, true) : LocalStorage.ClearModel(collapsibleStoreId);
        };
        vm.account = account;

        // Model //
        vm.appraisalReport = report;
        vm.appraisalOrder = order;

        vm.validationErrors = validationErrors;
        vm.hasValidationErrors = Object.keys(vm.validationErrors).filter(function(key) {
            return !key.startsWith('$') && vm.validationErrors[key].length > 0;
        }).length > 0;
        vm.reportErrors = reportErrors;
        vm.hasErrors = Object.keys(vm.reportErrors).some(function(key) { return vm.reportErrors[key]; });

        vm.version = null;

        vm.reportSubmittable = reportSubmittable;
        vm.canSubmit = !vm.hasErrors && (!AppraisalState.inAnyState(vm.appraisalOrder, AppraisalState.submitted) || vm.appraisalOrder.state === 'RESUBMISSION_REQUIRED');

        // Methods //
        vm.initReport = initReport;
        vm.hasReport = hasReport;
        vm.submitReport = submitReport;
        vm.acceptReport = acceptReport;
        vm.declineReport = declineReport;
        vm.goToActions = goToActions;
        vm.goToReport = goToReport;

        initReport();

        if (vm.isLender === false) {
            Principal.matchAnyAuthority(/^ROLE_ORIGINATOR.*/).then(function(hasAuth) {
                if (hasAuth) vm.acceptMessage = 'The report that you have reviewed meets your expectations?';
            });

            Principal.matchAnyAuthority(/^ROLE_VC.*/).then(function(hasAuth) {
                if (hasAuth) vm.acceptMessage = 'Would you like to release this report to the end users?';
            });
        }

        /**
         * Set the PDF URL and retrieve the HTML version from the server
         * @return {Promise} Promise that is resolved when the HTML report is done generating
         */
        function initReport() {
            // Set the generated pdf url
            var tempUrl = "/api/appraisal-reports/generate-pdf/" + vm.appraisalReport.id + "?version=" + (vm.version || "");
            if (vm.redacted) tempUrl += "&redacted=true"
            vm.appraisalReportPdfUrl = $sce.trustAsResourceUrl(tempUrl);
        }

        function hasReport() {
            var hasReport = !vm.validationErrors.thirdParty.some(function(err) { return err.fieldKey === 'thirdParty.report'; });

            if (!hasReport) vm.isOpen = true;

            return hasReport;
        }

        function acceptReport(ev) {
            var confirm = $mdDialog.confirm()
                .title('Accept Report')
                .textContent(vm.acceptMessage)
                .ariaLabel('Accept and Release Report')
                .targetEvent(ev)
                .ok('Accept')
                .cancel('Cancel');
            $mdDialog.show(confirm).then(function() {
                AppraisalReport.acceptReport({id:vm.appraisalReport.id}, {}, function(data){
                    $state.go("home");
                });
            });
        }

        function declineReport(ev) {
            $mdDialog.show({
                templateUrl: 'app/entities/appraisal-report/appraisal-report-decline-dialog.html',
                controller: 'AppraisalReportDeclineController',
                controllerAs: 'vm',
                fullscreen: true,
                locals: {
                    report: report,
                    order: order
                }
            }).then(function() {
                $state.go('appraisal-report-review-third-party', null, { reload: true });
            }, function() {
                $state.go('appraisal-report-review-third-party');
            });
        }

        function submitReport(ev) {
            // Appending dialog to document.body to cover sidenav in docs app
        	if(vm.appraisalOrder.lender && vm.appraisalOrder.lender.sendReportToCCR){
        		var confirm = $mdDialog.confirm(ev)
                	.title('Submit Report')
                	.htmlContent("By selecting 'OK' you are acknowledging your confidence in the report being submitted.<br><font color=\"red\"> ATTENTION: This lender does not receive reports automatically through Value Connect. Please send to lender directly and cc appraisals@valueconnect.ca</font>")
                	.ariaLabel('Submit Report')
                	.targetEvent(ev)
                	.ok('OK')
                	.cancel('Cancel');
        	}else{
        		var confirm = $mdDialog.confirm(ev)
            	.title('Submit Report')
            	.htmlContent("By selecting 'OK' you are acknowledging your confidence in the report being submitted.<br> Your report will be sent to the lender though the VC portal. No further action is required. Thank you!")
            	.ariaLabel('Submit Report')
            	.targetEvent(ev)
            	.ok('OK')
            	.cancel('Cancel');

        	}

            $mdDialog.show(confirm).then(function() {
                AppraisalReport.submitReport({id:vm.appraisalReport.id}, {}, function(data) {
                    $state.go('appraisal-order-detail', { 'id' : vm.appraisalOrder.id});
                });
            });
        }

        function goToActions() {
            $location.hash('actions');
            $anchorScroll();
        }

        function goToReport() {
            $location.hash('report');
            $anchorScroll();
        }

        var unsubscribe = $rootScope.$on('valueconnectApp:appraisalReportUpdate', function(event, result) {
            vm.appraisalReport = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
