(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .factory('errorHandlerInterceptor', errorHandlerInterceptor);

    errorHandlerInterceptor.$inject = ['$q', '$rootScope', '$injector'];

    function errorHandlerInterceptor ($q, $rootScope, $injector) {
        var service = {
            responseError: responseError
        };

        return service;

        function responseError (response) {
            // Ignore unauthorized requests; they are handled by the auth-expired interceptor
            if (!(response.status === 401 && (response.data === '' || (response.data.path && response.data.path.indexOf('/api/account') === 0 )))) {
                $rootScope.$emit('valueconnectApp.httpError', response);
            }

            // Show error alert
            var errorKey = response.headers('X-valueconnectApp-error');
            if (angular.isString(errorKey)) {
                var AlertService = $injector.get('AlertService');
                AlertService.error(errorKey, response.headers('X-valueconnectApp-params') ? response.headers('X-valueconnectApp-params') : {});
            }
            return $q.reject(response);
        }
    }
})();
