(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('PricingMatrixDialogController', PricingMatrixDialogController);

    PricingMatrixDialogController.$inject = ['$state', '$scope', '$mdDialog', 'region', 'pricingMatrix', 'PricingMatrix', 'TaxCode', 'Lender', 'AlertService', 'Enum'];

    function PricingMatrixDialogController ($state, $scope, $mdDialog, region, pricingMatrix, PricingMatrix, TaxCode, Lender, AlertService, Enum) {
        // Initalize pricing matrix
        pricingMatrix.products.PRICING_PARAMETER = pricingMatrix.products.PRICING_PARAMETER || [];
        Enum.PricingParameterType.forEach(function(parameterType) {
            pricingMatrix.parameters[parameterType] = pricingMatrix.parameters[parameterType] || [];
        });

        // Define bound variables
        var vm = this;
        vm.region = region;
        vm.pricingMatrix = pricingMatrix;
        vm.taxCodes = TaxCode.query({size: 9999, provinceId: region.provinceId});
        vm.lenders = Lender.query({size: 9999, isPrivate: false});
        vm.productCategories = Enum.ProductCategory.filter(function(value) {
            return !['PRICING_PARAMETER','UTILITY','SPECIAL_REQUIREMENT'].includes(value);
        });
        vm.parameterTypes = Enum.PricingParameterType.map(function(parameterType) {
            return {
                name: parameterType,
                isDiscrete: parameterType === 'MULTI_UNIT' || parameterType === 'ACREAGE',
                isCurrency: parameterType === 'PROPERTY_VALUE'
            };
        });
        vm.tierProducts = vm.pricingMatrix.products.PRICING_PARAMETER.reduce(function(object, product) {
            object[product.productType] = product;
            return object;
        }, {});

        vm.specialRequirementsTaxCodeId = vm.pricingMatrix.specialRequirements["PRIVATE_LENDER"][0].taxCodeId;
        vm.specialRequirementsTaxCodeChanged = specialRequirementsTaxCodeChanged;

        // Set initial selected category or parameterType
        vm.currentItem = vm.parameterTypes[0];

        vm.catList = [];

        // when a category is selected, the name is added to catList and the template will render that category
        // this is because it takes forever to render all of the categories upfront.
        $scope.$watch('vm.currentItem', function() {
            if (vm.productCategories.includes(vm.currentItem)) {
                if (!vm.catList.includes(vm.currentItem)) {
                    vm.catList.push(vm.currentItem);
                }
            }
        });

        // Define bound methods
        vm.cancel = cancel;
        vm.save = save;
        vm.addParameter = addParameter;
        vm.addSpecialRequirement = addSpecialRequirement;

        function cancel () {
            $mdDialog.cancel();
            $state.go('region', null);
        }

        function save () {
            vm.isSaving = true;
            PricingMatrix.update({id: vm.region.id}, vm.pricingMatrix).$promise.then(function(result) {
                $scope.$emit('valueconnectApp:pricingMatrixUpdate', result);
                AlertService.success("valueconnectApp.region.pricingMatrix.updated", { regionName: region.name});
                vm.pricingMatrix = result;
            }).finally(function() {
                vm.isSaving = false;
            });
        }

        /**
         * Add a new parameter to the end of the specified array
         * @param {Array} array The array to add the parameter to
         */
        function addParameter(array) {
            array.push({
                manualQuote: false,
                excludedProducts: []
            });
        }

        /**
         * Add a new SpecialRequirement to the end of the specified array
         * @param {Array} array The array to add the SpecialRequirement to
         */
        function addSpecialRequirement(array) {
            array.push({
                specialRequirementType: 'PUBLIC_LENDER',
                regionId: vm.region.id,
                taxCodeId: vm.specialRequirementsTaxCodeId,
                manualQuote: false,
                excludedProducts: []
            });
        }

        function specialRequirementsTaxCodeChanged() {
            angular.forEach(vm.pricingMatrix.specialRequirements, function(specialRequirementType, key) {
                angular.forEach(specialRequirementType, function(specialRequirement) {
                    specialRequirement.taxCodeId = vm.specialRequirementsTaxCodeId;
                });
            });
        }
    }
})();
