(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .directive('vcPasswordForm', ['$q', vcPasswordForm]);

    function vcPasswordForm ($q) {
        var directive = {
            restrict: 'E',
            link:  postLink,
            transclude: true,
            templateUrl: "app/account/password/vc-password-form.template.html",
            scope: {
                password: "=",
                confirmPassword: "=",
                oAuthUser: "="
            }
        };

        return directive;

        function postLink(scope, elm, attrs) {
            scope.showPassword = false;
            scope.isOAuth = scope.oAuthUser && scope.oAuthUser.email !== null;
            scope.showConfirmPassword = false;

            var numberRegex = new RegExp("[0-9]");
            var lowercaseRegex = new RegExp("[a-z]");
            var uppercaseRegex = new RegExp("[A-Z]");
            var specialCharsRegex = new RegExp("[^a-zA-Z0-9 ]");


            scope.$watch(function() { return scope.password; }, function() {
                if (!scope.password) scope.password = "";

                scope.passwordForm.password.$setValidity('minlength', scope.password.length >= 8);
                scope.passwordForm.password.$setValidity('maxlength', scope.password.length <= 50);
                scope.passwordForm.password.$setValidity('uppercase', uppercaseRegex.test(scope.password));
                scope.passwordForm.password.$setValidity('lowercase', lowercaseRegex.test(scope.password));
                scope.passwordForm.password.$setValidity('number', numberRegex.test(scope.password));
                scope.passwordForm.password.$setValidity('specialchars', specialCharsRegex.test(scope.password));

                scope.passwordForm.confirmPassword.$setValidity('nomatch', scope.password === scope.confirmPassword);
            });

            scope.$watch(function() { return scope.confirmPassword; }, function() {
                if (!scope.confirmPassword) scope.confirmPassword = "";

                scope.passwordForm.confirmPassword.$setValidity('nomatch', scope.password === scope.confirmPassword);
            });
        }
    }
})();
