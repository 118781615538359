(function() {
    'use strict';
        
    angular
        .module('valueconnectApp')
        .controller('BrokerageMergeController', BrokerageMergeController);
    
    BrokerageMergeController.$inject = ['$scope', '$mdDialog', '$state', 'brokerageToMergeInto', 'Brokerage', 'BrokerageLocation', 'BrokerageLocationSearch', 'BrokerageMerge', 'pagingParams', 'AlertService', 'paginationConstants', 'previousState'];
    
    function BrokerageMergeController($scope, $mdDialog, $state, brokerageToMergeInto, Brokerage, BrokerageLocation, BrokerageLocationSearch, BrokerageMerge, pagingParams, AlertService, paginationConstants, previousState) {
        var vm = this;

        var brokeragesToMerge = loadBrokeragesToMerge();
        
        vm.brokeragesList = [];
        vm.brokerageToMergeInto = brokerageToMergeInto;
        vm.currentSearchInput = pagingParams.search;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.brokerageSelectedChange = brokerageSelectedChange;
        vm.search = search;
        vm.transition = transition;
        vm.confirmChanges = confirmChanges;
        vm.cancel = cancel;

        loadBrokerageList();

        function loadBrokerageList() {
            if (pagingParams.search) {
                BrokerageLocationSearch.query({
                    query: pagingParams.search,
                    includeBrokerageNameInSearch: true,
                    onlyEnabledBrokerages: true,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                }, onBrokerageLoadSuccess, onBrokerageLoadError);
            } else {
                BrokerageLocation.query({
                    onlyEnabledBrokerages: true,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                }, onBrokerageLoadSuccess, onBrokerageLoadError);
            }
        }
        
        function selectPreviouslySelectedBrokerages() {
            var selectedBrokerageIds = Array.from(brokeragesToMerge.keys());
            vm.brokeragesList.forEach(function(brokerage, index) {
                if (selectedBrokerageIds.includes(brokerage.brokerageId)) {
                    vm.brokeragesList[index].selected = true;
                }
            })
        }
        
        function loadBrokeragesToMerge() {
            if (previousState.name !== 'brokerage.merge') {
                BrokerageMerge.brokeragesToMerge = new Map();
            }
            
            return BrokerageMerge.brokeragesToMerge;
        }
        
        function brokerageSelectedChange(brokerage) {
            if (brokeragesToMerge.has(brokerage.brokerageId)) {
                brokeragesToMerge.delete(brokerage.brokerageId);
            } else {
                brokeragesToMerge.set(brokerage.brokerageId, brokerage);
            }
        }
        
        function onBrokerageLoadSuccess(foundBrokerages, headers) {
            vm.brokeragesList = foundBrokerages;
            vm.queryCount = headers('X-Total-Count');
            vm.page = pagingParams.page;

            selectPreviouslySelectedBrokerages();
        }
        
        function onBrokerageLoadError(error) {
            AlertService.error(error.data.message);
        }
        
        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                search: vm.currentSearchInput,
                id: vm.brokerageToMergeInto.id
            });
        }
        
        function search() {
            $state.transitionTo($state.$current, {
                search: vm.currentSearchInput,
                id: vm.brokerageToMergeInto.id,
                page: 1
            });
        }
        
        function openConfirmDialog(brokeragesToMerge) {
            return $mdDialog.show({
                templateUrl: 'app/entities/brokerage/brokerage-merge-dialog.html',
                controller: 'BrokerageMergeDialogController',
                controllerAs: 'vm',
                locals: {
                    brokeragesToMerge: brokeragesToMerge,
                    brokerageToMergeInto: brokerageToMergeInto
                }
            })
        }

        function noBrokerageSelected() {
            AlertService.error('You need to select at least one brokerage');
        }
        
        function confirmChanges() {
            if (brokeragesToMerge.size > 0) {
                openConfirmDialog(brokeragesToMerge)
                    .then(mergeBrokerages)
                    .catch(cancel);
            } else {
                noBrokerageSelected();
            }
        }

        function mergeBrokerages() {
            Brokerage.mergeBrokerages(
                {brokerageToMergeIntoId: brokerageToMergeInto.id},
                Array.from(brokeragesToMerge.keys()),
                onMergeBrokeragesSuccess
            )
        }
        
        function onMergeBrokeragesSuccess() {
            $state.go($state.current, {}, {reload: true});
            BrokerageMerge.brokeragesToMerge = new Map();
            console.log('chagned brokerages to merge to be a new map!');
        }

        function cancel() {
            $mdDialog.cancel();
        }
    }
})();
