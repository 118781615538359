/*global Case */
(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('StandardResidentialAssignmentController',StandardResidentialAssignmentController);

    StandardResidentialAssignmentController.$inject = ['$scope', '$timeout', '$stateParams', '$filter', '$mdDialog', 'DynamicForm'];

    function StandardResidentialAssignmentController($scope, $timeout, $stateParams, $filter, $mdDialog, DynamicForm) {
        // Initialize model
        var vm = this;
        var sectionData = null;
        vm.reportvalueUpdated = reportvalueUpdated;
        vm.toggleForm = toggleForm;

        // Initialize model using form data
        init(null, $scope.vm.formData);
        $scope.$on('formDataReinitialized', init);
        function init(event, formData) {
            sectionData = formData.assignment;
            costApproachUpdated();
            var client = $scope.vm.appraisalOrder.lender ?
                $scope.vm.appraisalOrder.lender.lenderName : ($scope.vm.appraisalOrder.clientContact.firstName + ' ' + $scope.vm.appraisalOrder.clientContact.lastName);

            if (['FORECLOSURE', 'POWER_OF_SALE'].includes($scope.vm.appraisalOrder.legalProceedingType)) {
                client = $scope.vm.appraisalOrder.brokerageName;
            }

            // TODO: Figure out what the correct value for ltv should be
            // var ltv = $scope.vm.appraisalOrder.loanAmount / $scope.vm.appraisalOrder.lendersEstimatedPropertyValue;
            var ltv = 0.85;

            vm.translationParams = {
                client: client,
                ltv: ltv ? $filter('number')(ltv*100, 0) : 'XX'
            };

            var newIsPurchaseFinancingPurposes = ($scope.vm.appraisalOrder.financingPurposes && $scope.vm.appraisalOrder.financingPurposes === "PURCHASE") ? true : false;
            if (angular.isUndefined($scope.vm.formData.assignment.isPurchaseFinancingPurposes) || $scope.vm.formData.assignment.isPurchaseFinancingPurposes !== newIsPurchaseFinancingPurposes) {
                $scope.vm.formData.assignment.isPurchaseFinancingPurposes = newIsPurchaseFinancingPurposes;
                $scope.vm.setDirty("assignment.isPurchaseFinancingPurposes");
            }
        }

        function reportvalueUpdated() {
            $scope.vm.setDirty('assignment.reportValue');
            var value = sectionData.reportValue;
            if(value === "RETROSPECTIVE") {
                sectionData.hasAssumptionsAndConditions = true;
                $scope.vm.setDirty('assignment.hasAssumptionsAndConditions');
            } else if (value === "PROSPECTIVE") {
                sectionData.isProspectiveHypothetical = true;
                sectionData.hasHypotheticalConditions = true;
                $scope.vm.setDirty('assignment.isProspectiveHypothetical');
                $scope.vm.setDirty('assignment.hasHypotheticalConditions');
            }
        }

        /**
         * Mark assumptions and conditions as true if there is no cost approach
         */
        function costApproachUpdated() {
            if(!$scope.vm.hasForm('costApproach') && !sectionData.hasAssumptionsAndConditions) {
                sectionData.hasAssumptionsAndConditions = true;
                $timeout(function(){ // ensure dirty gets set
                    $scope.vm.setDirty('assignment.hasAssumptionsAndConditions');
                });
            }
        }

        /**
         * Add/Remove the form from the report depending on its existing state
         */
        // TODO: create a checkbox directive to do this
        function toggleForm(formName, $event) {
            var checkbox = angular.element($event.currentTarget);
            checkbox.attr('disabled', 'disabled');
            var togglePromise = $scope.vm.hasForm(formName) ?
                $scope.vm.removeForm(formName, $event) :
                $scope.vm.addForm(formName, $event);

            togglePromise.finally(function() {
                if(formName === 'costApproach') costApproachUpdated();
                checkbox.removeAttr('disabled');
            });
        }
    }
})();
