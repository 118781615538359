(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('TermsController', TermsController);

    TermsController.$inject = ['$state', '$rootScope' ,'$sessionStorage', '$translate', '$timeout', '$uibModal', 'Auth', 'Principal', 'User', 'AcceptTerms', "JhiLanguageService"];

    function TermsController ($state, $rootScope, $sessionStorage, $translate, $timeout, $uibModal, Auth, Principal, User, AcceptTerms, languageService) {
        var vm = this;

        languageService.getCurrent().then(
            function(language) {
                vm.language = language;
            });

        $rootScope.$on('$translateChangeSuccess', function(event, current, previous) {
            console.log(current.language);
            vm.language = current.language;
        });

        vm.error = null
        vm.getRedirectError = Auth.getRedirectError;
        vm.termsAccepted = false;
        vm.termsViewed = false;

        console.log($sessionStorage.previousState);

        // Navigate away from this page if the user has already accepted the terms of service
        Principal.identity().then(function(account) {
            if(account.isTermsAccepted) {
                $state.go('home');
            }
        });

        vm.showPrivacyPolicy = function() {
            $uibModal.open({
                animation: true,
                templateUrl: '/content/privacy-policy.html',
                controllerAs: 'vm',
                controller: ['$uibModalInstance', function($uibModalInstance) {
                    this.close = function() {
                        $uibModalInstance.close(false);
                    }
                }],
            });
        }

        vm.acceptTerms = function() {
            var request = {
                termsAccepted: new Date(),
                privacyPolicyAccepted: new Date()
            };

            AcceptTerms.save(request, onSaveSuccess, onSaveError)
        }

        function onSaveSuccess (result) {
            // Refresh the principal
            Principal.identity(true).then(function(account) {
                // Redirect if we have a previous page to go to
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();

                    if (previousState && previousState.name === "appraisal-order.new") {
                        Auth.resetPreviousState();
                        $state.go(previousState.name);
                    } else {
                        //Auth.resetPreviousState();
                        //$state.go(previousState.name, previousState.params);
                        //Make sure they go to their settings page
                        $state.go("settings");
                    }
                }
                else {
                    $state.go('home');
                }
            });
        }

        function onSaveError () {
            vm.error = 'ERROR';
        }
    }
})();
