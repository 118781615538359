(function () {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraisalOrderDialogAppraiserSelectionController', AppraisalOrderDialogAppraiserSelectionController);

    AppraisalOrderDialogAppraiserSelectionController.$inject = ['$scope', '$mdDialog', 'Contact', 'AppraisalOrderContact', 'AppraisalFirmSelection'];

    function AppraisalOrderDialogAppraiserSelectionController($scope, $mdDialog, Contact, AppraisalOrderContact, AppraisalFirmSelection) {
        // Initialize Entity
        var vm = this;
        vm.initOption = "AUTO_ASSIGN_APPRAISER_FIRM";
        vm.isFirmSelected = isFirmSelected;
        vm.assignmentChange = assignmentChange;
        vm.removeSelectedFirm = removeSelectedFirm;
        vm.appraisalFirmNameInput = "";
        vm.makeRequest = makeRequest();
        vm.lastRequest = {};
        vm.onSubmit = onSubmit;
        vm.showAppraisalFirmSelectionDialog = showAppraisalFirmSelectionDialog;
        vm.selectedAppraiserFirm = [];

        function init(data) {
            vm.data = [];
            JSON.parse(data).forEach(function(obj) {
                var lenderApproved;
                if(obj.lenderApproved){
                    lenderApproved = "<i class='material-icons'>check</i>";
                }else{
                    lenderApproved = "<i class='material-icons'>close</i>";
                }
                var baseFee;
                if(obj.baseFee > 0 ) {
                    baseFee = obj.baseFee + " + taxes"
                }else{
                    baseFee = "Quote Required"
                }
                vm.data.push(['', obj.appraisalFirmId, obj.appraisalFirmName, lenderApproved, baseFee]);
            });
            if (vm.initOption === "CHOOSE_AN_APPRAISER_FIRM") {
                    showAppraisalFirmSelectionDialog();
                }

        }

        $scope.hide = function () {
            $mdDialog.hide();
        };

        $scope.cancel = function () {
            $scope.templateVm.initOption = "AUTO_ASSIGN_APPRAISER_FIRM";
            $mdDialog.cancel();
        };

        function assignmentChange(){
            if (vm.initOption === "CHOOSE_AN_APPRAISER_FIRM") {
                if(vm.lastRequest.isResolved){
                    showAppraisalFirmSelectionDialog();
                }else{
                    makeRequest();
                }
            }else{
                var appraisalFirm = {
                                    'appraisalSelected': false,
                                    'appraisalFirmName': null,
                                }
                $scope.$parent.vm.appraisalOrder.appraisalFirmSelection = appraisalFirm;
            }
        }

        function makeRequest(){
            if(!vm.lastRequest || vm.lastRequest.isResolved){
                var postalCode = $scope.$parent.vm.appraisalOrder.address.postCode;
                                var lender_id = $scope.$parent.vm.appraisalOrder.lender.id;
                                AppraisalFirmSelection.getAppraisalFirmByRegionAndLender({postal: postalCode, lenderId: lender_id}).$promise.then(function(res) {
                                vm.lastRequest = {
                                                       isResolved : true,
                                                       postal : postalCode,
                                                       lenderId : lender_id
                                                   }
                                init(res.data);
                                 });
            }
        }

        function onSubmit(ev) {
            //validate
            vm.selectedAppraiserFirm = getSelectedRow();
            if (typeof vm.selectedAppraiserFirm !== 'undefined' && vm.selectedAppraiserFirm.length > 0) {
                var lineItem = vm.selectedAppraiserFirm[0];
                var appraisalFirm = {
                    'appraisalSelected': true,
                    'appraisalFirmName': lineItem[2]
                }
                $scope.$parent.vm.appraisalOrder.appraisalFirmSelection = appraisalFirm;
                $mdDialog.hide();

            } else if (vm.appraisalFirmNameInput){
                // Selection error
                var appraisalFirm = {
                    'appraisalSelected': false,
                    'appraisalFirmName': vm.appraisalFirmNameInput,
                }
                $scope.$parent.vm.appraisalOrder.appraisalFirmSelection = appraisalFirm;
                $mdDialog.hide();
            }else{
                //Show some error
            }
        };

        function showAppraisalFirmSelectionDialog() {
            $mdDialog.show({
                controller: AppraisalOrderDialogAppraiserSelectionController,
                scope: $scope,
                preserveScope: true,
                templateUrl: 'app/entities/appraisal-order/appraisal-order-dialog/appraisal-order-dialog.appraiser-firm-selection-dialog.html',
                clickOutsideToClose: false,
                fullscreen : true,
                onComplete: function () {
                    var table = jQuery('#dt-select').DataTable({
                        dom : 'tp',
                        data: vm.data,
                        pageLength : 7,
                        pagingType : 'full',
                        ordering : false,
                        order : [[1, 'asc']],
                        columns: [
                            {
                                sorting : false,
                                className: 'select-checkbox',
                            },
                            {
                                title: "Appraisal Firm Id",
                                visible : false,
                            },
                            {
                                title: "Appraisal Firm",
                                sorting : false,
                            },
                            {
                                title: "Lender Approved",
                                className: "dt-center",
                                sorting : false,
                                visible : false
                            },
                            {
                                title: "Base Fee",
                                className: "dt-center",
                                sorting : false,

                            }
                        ],
                        select: true
                    });

                    jQuery('.custom-search').on('keyup', function () {
                        table.search( this.value ).draw();
                    });

                    jQuery('.dataTable').on('click', 'tbody tr', function (e) {
                        // TODO Currently the Shift and click and select multiple options. Fix that issue.
                        table.rows().deselect();
                    });
                }
            })
                .then(function (answer) {

                });
        };


        $scope.showAppraisalFirmSelection = function () {
            if (vm.initOption === "CHOOSE_AN_APPRAISER_FIRM") {
                return true;
            } else if(vm.initOption === "AUTO_ASSIGN_APPRAISER_FIRM") {
                return false;
            }
        };

        function isFirmSelected() {
            // Method to check if Firm is selected or not.
            if ($scope.$parent.vm.appraisalOrder.appraisalFirmSelection != undefined && $scope.$parent.vm.appraisalOrder.appraisalFirmSelection.appraisalFirmName != null) {
                return true;
            } else {
                return false;
            }
        }

        function getSelectedRow() {
            // Returning the selected row from data table
            // There is a possibility of
            return jQuery('#dt-select').DataTable().rows({ selected: true }).data();
        }

        function getInputedAppraisalFirm (){
            return vm.appraisalFirmNameInput;
        }

        function removeSelectedFirm(){
            // TODO dialog which confirms deletion
            // Delete Selection
            $scope.$parent.vm.appraisalOrder.appraisalFirmSelection = undefined;
            // Select Auto Choose appraiser Firm
            vm.initOption = "AUTO_ASSIGN_APPRAISER_FIRM";
        }
    }
})();
