(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('Accounting', Accounting);

    Accounting.$inject = ['$resource'];

    function Accounting ($resource) {
        var resourceUrl =  'api/accounting';

        return $resource(resourceUrl, {}, {
            'getAccountsReceivableReport': { method: 'GET', url: 'api/accounting/receivable' },
            'getAccountsReceivableReportExport': {
                method: 'GET',
                url: 'api/accounting/receivable/export',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    if (data) return { file: new Blob([data], { type: 'application/vnd.ms-excel' }) };
                    else return data;
                }
            },
            'getAccountsReceivableQBReport': { method: 'GET', url: 'api/accounting/receivable/qb' },
            'getAccountsReceivableQBReportExport': {
                method: 'GET',
                url: 'api/accounting/receivable/qb/export',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    if (data) return { file: new Blob([data], { type: 'application/vnd.ms-excel' }) };
                    else return data;
                }
            },
            'getAccountsPayableReport': { method: 'GET', url: 'api/accounting/payable' },
            'getAccountsPayableReportExport': {
                method: 'GET',
                url: 'api/accounting/payable/export',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    if (data) return { file: new Blob([data], { type: 'application/vnd.ms-excel' }) };
                    else return data;
                }
            },
            'getAccountsPayableQBReport': { method: 'GET', url: 'api/accounting/payable/qb' },
            'getAccountsPayableQBReportExport': {
                method: 'GET',
                url: 'api/accounting/payable/qb/export',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    if (data) return { file: new Blob([data], { type: 'application/vnd.ms-excel' }) };
                    else return data;
                }
            }
        });
    }
})();
