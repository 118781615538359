(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('GeneralController', GeneralController);

    GeneralController.$inject = ['$scope'];

    /**
     * Placeholder controller for if future work is needed to view
     * the AVM data within the report builder
     * 
     * @param {*} $scope 
     */
    function GeneralController($scope) {
        // Initialize model
        var vm = this;

        init(null, $scope.vm.formData);
        $scope.$on('formDataReinitialized', init);

        function init(event, formData) {

        }
    }
})();
