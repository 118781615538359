(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .controller('AppraisalOrderAcceptAssignmentDialogController', AppraisalOrderAcceptAssignmentDialogController);

    AppraisalOrderAcceptAssignmentDialogController.$inject = ['$mdDialog', '$state', 'order', 'AppraisalOrder', 'AlertService'];

    function AppraisalOrderAcceptAssignmentDialogController ($mdDialog, $state, order, AppraisalOrder, AlertService) {
        var vm = this;
        vm.appraisalOrder = order;
        vm.save = save;
        vm.cancel = cancel;

        vm.showLenderWarning = vm.appraisalOrder.lender && vm.appraisalOrder.lender.id && !vm.appraisalOrder.privateLender;

        function save() {
            vm.isSaving = true;
            AppraisalOrder.accept({ id:vm.appraisalOrder.id }, {}, function(data) {
                if (data.success === true) {
                    $mdDialog.hide();
                    $state.reload();
                } else {
                    AlertService.error("Unfortunately something went wrong while accepting appraisal order.");
                }
                vm.isSaving = false;
            });
        }

        function cancel() {
            $mdDialog.cancel();
        }
    }
})();
