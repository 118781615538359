(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('ResidentialLandAppraisalGeneralController', ResidentialLandAppraisalGeneralController);

    ResidentialLandAppraisalGeneralController.$inject = ['$scope'];

    function ResidentialLandAppraisalGeneralController($scope) {
        // Initialize model
        var vm = this;

        init(null, $scope.vm.formData);
        $scope.$on('formDataReinitialized', init);

        function init(event, formData) {
            if (!$scope.vm.formData.general.outputFormat) {
                $scope.vm.formData.general.outputFormat = "AIC";
                $scope.vm.setDirty("general.outputFormat")
            }
        }
    }
})();
