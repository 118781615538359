(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('user-management', {
            parent: 'admin',
            url: '/user-management?page&sort&search',
            data: {
                authorities: ['USER_READ'],
                pageTitle: 'user-management.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/user-management/user-management.html',
                    controller: 'UserManagementController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: {
                    value: '',
                    squash: true
                }
            },
            resolve: {
                canEdit: ['authorize', 'Principal', function(authorize, Principal) {
                    return Principal.hasAuthority('USER_UPDATE');
                }],
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('user-management');
                    $translatePartialLoader.addPart('authority');
                    $translatePartialLoader.addPart('appraiserUser');
                    return $translate.refresh();
                }]

            }
        })
        // TODO: delete this state and re-use the 'user-management' state instead
        .state('user-management-originator', {
            parent: 'user-management',
            url: '/originators',
            data: {
                authorities: [/^ROLE_(ADMIN|CUSTOMER_CARE_REPRESENTATIVE|VC_.*)$/]
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/user-management/user-management-originator.html',
                    controller: 'UserManagementOriginatorController',
                    controllerAs: 'vm'
                }
            }
        })
        // TODO: delete this state and re-use the 'user-management' state instead
        .state('user-management-appraiser', {
            parent: 'user-management',
            url: '/appraisers',
            data: {
                authorities: [/^ROLE_(ADMIN|CUSTOMER_CARE_REPRESENTATIVE|VC.*)$/]
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/user-management/user-management-appraiser.html',
                    controller: 'UserManagementAppraiserController',
                    controllerAs: 'vm'
                }
            }
        })
        .state('user-management-appraiser.edit', {
            parent: 'user-management-appraiser',
            url: '/{login}/edit',
            data: {
                authorities: ['USER_UPDATE']
            },
            onEnter: ['$stateParams', '$state', '$mdDialog', function($stateParams, $state, $mdDialog) {
                $mdDialog.show({
                    templateUrl: 'app/admin/user-management/user-management-dialog.html',
                    controller: 'UserManagementDialogController',
                    controllerAs: 'vm',
                    fullscreen: true,
                    resolve: {
                        entity: ['User', function(User) {
                            return User.get({login : $stateParams.login}).$promise;
                        }]
                    }
                }).then(function() {
                    $state.go('user-management-appraiser', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('user-management-detail', {
            parent: 'admin',
            url: '/user/:login',
            data: {
                authorities: ['USER_READ'],
                pageTitle: 'user-management.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/user-management/user-management-detail.html',
                    controller: 'UserManagementDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('user-management');
                    $translatePartialLoader.addPart('authority');
                    return $translate.refresh();
                }]
            }
        })
        .state('user-management.new', {
            parent: 'user-management',
            url: '/new',
            data: {
                authorities: ['USER_CREATE']
            },
            onEnter: ['$stateParams', '$state', '$mdDialog', function($stateParams, $state, $mdDialog) {
                $mdDialog.show({
                    templateUrl: 'app/admin/user-management/user-management-dialog.html',
                    controller: 'UserManagementDialogController',
                    controllerAs: 'vm',
                    fullscreen: true,
                    locals: {
                        entity:  {
                            id: null, login: null, firstName: null, lastName: null, email: null,
                            activated: true, disabled: false, langKey: null, createdBy: null, createdDate: null,
                            lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                            resetKey: null, authorities: null, entityAuthorities: [], enableDirectMessageBoard: true
                        }
                    }
                }).then(function() {
                    $state.go('user-management', null, { reload: true });
                }, function() {
                    $state.go('user-management');
                });
            }]
        })
        .state('user-management.edit', {
            parent: 'user-management',
            url: '/{login}/edit',
            data: {
                authorities: ['USER_UPDATE']
            },
            onEnter: ['$stateParams', '$state', '$mdDialog', function($stateParams, $state, $mdDialog) {
                $mdDialog.show({
                    templateUrl: 'app/admin/user-management/user-management-dialog.html',
                    controller: 'UserManagementDialogController',
                    controllerAs: 'vm',
                    fullscreen: true,
                    resolve: {
                        entity: ['User', function(User) {
                            return User.get({login : $stateParams.login}).$promise;
                        }]
                    }
                }).then(function() {
                    $state.go('user-management', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('user-management.delete', {
            parent: 'user-management',
            url: '/{login}/delete',
            data: {
                authorities: ['USER_DESTROY']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/user-management/user-management-delete-dialog.html',
                    controller: 'UserManagementDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['User', function(User) {
                            return User.get({login : $stateParams.login});
                        }]
                    }
                }).result.then(function() {
                    $state.go('user-management', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }
})();
