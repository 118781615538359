(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('BrokerageDialogController', BrokerageDialogController);

    BrokerageDialogController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'entity', 'Principal', 'Region', 'Brokerage', 'NationalBrokerage', 'BrokerageLocation', 'User'];

    function BrokerageDialogController ($timeout, $scope, $state, $stateParams, entity, Principal, Region, Brokerage, NationalBrokerage, BrokerageLocation, User) {
        var vm = this;

        vm.brokerage = entity;
        vm.isNew = entity.id == null
        vm.save = save;
        vm.nationalbrokerages = NationalBrokerage.query({ size: 2000 });
        vm.brokeragelocations = BrokerageLocation.query({ brokerageId: entity.id, size: 2000 });
        vm.regions = Region.query({ size: 2000, sort: 'name' });
        vm.validate = validate;
        vm.decline = decline;
        vm.disable = disable;
        vm.enable = enable;
        vm.showReason = false;
        vm.processdisabling = processdisabling;

        Principal.matchAnyAuthority(/^ROLE_(ADMIN).*/i).then(function(result) {
            vm.isAdmin = result;
            vm.vcCcrs = User.getByAuthority({authority:'ROLE_CUSTOMER_CARE_REPRESENTATIVE'}, {});
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function save () {
            vm.isSaving = true;
            if (vm.brokerage.id !== null) {
               Brokerage.update(vm.brokerage, onSaveSuccess, onSaveError);
            } else {
                Brokerage.save(vm.brokerage, onSaveSuccess, onSaveError);
            }
        }

        function validate () {
        	vm.brokerage.activated = 1;
            if (vm.brokerage.id !== null) {
               Brokerage.update(vm.brokerage, onSaveSuccess, onSaveError);
            } else {
                Brokerage.save(vm.brokerage, onSaveSuccess, onSaveError);
            }
        }

        function decline () {
        	vm.brokerage.declined = 1;
            if (vm.brokerage.id !== null) {
               Brokerage.update(vm.brokerage, onSaveSuccess, onSaveError);
            } else {
                Brokerage.save(vm.brokerage, onSaveSuccess, onSaveError);
            }
        }

        function processdisabling() {
        	vm.showReason = true;
        }

        function disable () {
        	vm.brokerage.disabled = 1;
            if (vm.brokerage.id !== null) {
               Brokerage.update(vm.brokerage, onSaveSuccess, onSaveError);
            } else {
                Brokerage.save(vm.brokerage, onSaveSuccess, onSaveError);
            }

        }

        function enable () {
            vm.brokerage.disabled = 0;

        }


        function onSaveSuccess (result) {
            vm.isSaving = false;
            $scope.$emit('valueconnectApp:brokerageUpdate', result);

            if(vm.isNew) {
                $state.go('brokerage', null, { reload: true });
            }
            else {
                $state.go('.', {id: result.id}, {reload: true})
            }
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
