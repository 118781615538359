(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('AppraisalFirmSelection', AppraisalFirmSelection);

    AppraisalFirmSelection.$inject = ['$resource'];

    function AppraisalFirmSelection ($resource) {
        var resourceUrl =  'api/appraisal-firms/region-and-lender';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'getAppraisalFirmByRegionAndLender': {
                method: 'GET',
                url:'api/appraisal-firms/region-and-lender',
                transformResponse: function(data) { return { data : data }; },
                responseType: 'text'
            }
        });
    }
})();
