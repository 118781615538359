(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('BrokerageLocationDeleteController',BrokerageLocationDeleteController);

    BrokerageLocationDeleteController.$inject = ['$uibModalInstance', 'entity', 'BrokerageLocation'];

    function BrokerageLocationDeleteController($uibModalInstance, entity, BrokerageLocation) {
        var vm = this;

        vm.brokerageLocation = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            BrokerageLocation.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
