(function() {
    'use strict';

    // Create two directives that are almost the same
    createFormRowDirective('productFormRow', 'app/entities/region/pricing-matrix/product-form-row.template.html', {
        product: '=',
        taxCodes: '<'
    });
    createFormRowDirective('parameterFormRow', 'app/entities/region/pricing-matrix/parameter-form-row.template.html', {
        parameter: '=',
        parameterType: '<',
        taxCodes: '<',
        onDelete: '&'
    });
    createFormRowDirective('specialRequirementFormRow', 'app/entities/region/pricing-matrix/special-requirement-form-row.template.html', {
        specialRequirement: '=',
        taxCodes: '<',
        lenders: '<',
        rowCount: '<',
        isPublic: '<',
        onDelete: '&'
    });

    function createFormRowDirective(directiveName, templateUrl, scope) {
        angular
            .module('valueconnectApp')
            .directive(directiveName, function() {
                return {
                    restrict: 'A',
                    scope: scope,
                    require: {
                        editForm: "form"
                    },
                    templateUrl: templateUrl,
                    controller: FormRowDirectiveController,
                    controllerAs: 'vm',
                    bindToController: true
                };
            });

        FormRowDirectiveController.$inject = ['Enum', '$translate', '$filter'];

        function FormRowDirectiveController (Enum, $translate, $filter) {
            var vm = this;
            vm.enum = Enum;
            vm.getSelectedText = getSelectedText;
            vm.valueFilter = vm.parameterType && vm.parameterType.isCurrency ? $filter('currency') : angular.identity;
            vm.manualQuoteChanged = manualQuoteChanged;

            /**
             * Translate each value in the model and return as an HTML with line breaks between each value
             * @param  {Array} model              An array of values
             * @param  {String} translationPrefix The translation prefix for the values
             * @return {String}                   The generated HTML display string 
             */
            function getSelectedText(model, translationPrefix) {
                if(!model) return model;
                if (!translationPrefix) {
                    return model
                        .map(function(value) {
                            return (!vm.lenders.length) ? value : vm.lenders.find(function (lender) { return lender.id === value; }).lenderName;
                        })
                        .join('<br>');
                } else {
                    return model
                        .map(function(value) { return translationPrefix + value; })
                        .map($translate.instant)
                        .join('<br>');
                }
            }

            /**
             * Clear the price field when manual quote is checked or unchecked
             * @param  {Any} data   The model of the checkbox
             */
            function manualQuoteChanged (data) {
                data.vm.specialRequirement.price = 0;
            }
        }
    }
})();
