(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('TemplateEditController',TemplateEditController);

    TemplateEditController.$inject = ['$uibModalInstance', '$scope', 'entity', 'Template'];

    function TemplateEditController($uibModalInstance, $scope, entity, Template) {
        var vm = this;

        vm.template = entity;
        vm.templateName = vm.template.name;
        vm.templateShared = vm.template.shared;
        vm.clear = clear;
        vm.update = update;

        $scope.$watch('vm.templateName', function() {
            vm.template.name = vm.templateName;
        });

        $scope.$watch('vm.templateShared', function() {
            vm.template.shared = vm.templateShared;
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function update (id) {
          Template.update(vm.template, onSaveSuccess, onSaveError)
        }

        function onSaveSuccess (result) {
            $scope.$emit('valueconnectApp:templateUpdate', result);
            $uibModalInstance.close(true);
            vm.isSaving = false;
        }

        function onSaveError () {
            AlertService.error("Unable to edit template");
            vm.isSaving = false;
        }
    }
})();
