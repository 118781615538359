(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraisalOrderDialogPropertyAccessContactsController', AppraisalOrderDialogPropertyAccessContactsController);

    AppraisalOrderDialogPropertyAccessContactsController.$inject = ['$scope', 'Contact', 'AppraisalOrderContact'];

    function AppraisalOrderDialogPropertyAccessContactsController ($scope, Contact, AppraisalOrderContact) {

        // Initialize Entity
        var vm = this;

        // Init Property Access Contacts
        vm.contacts = Contact.query();

        vm.addAppraisalOrderContact = addAppraisalOrderContact;
        vm.removeAppraisalOrderContact = removeAppraisalOrderContact;
        vm.sameAsClient = sameAsClient;

        if ($scope.vm.appraisalOrder.$promise) {
            $scope.vm.appraisalOrder.$promise.then(function() { init(); });
        } else {
            init();
        }

        function init() {
            if ($scope.vm.appraisalOrder.id) {
                AppraisalOrderContact.getcontactbyappraisalorderid({id: $scope.vm.appraisalOrder.id}).$promise.then(function(data){
                    if(!data || !data.length){
                            $scope.vm.appraisalOrder.appraisalOrderContacts = [];
                            vm.addAppraisalOrderContact();
                    } else {
                        $scope.vm.appraisalOrder.appraisalOrderContacts = data;
                    }
                });
            } else {
                $scope.vm.appraisalOrder.appraisalOrderContacts = [];
                vm.addAppraisalOrderContact();
            }
        }

        function addAppraisalOrderContact() {
            $scope.vm.appraisalOrder.appraisalOrderContacts.push({
                id: null,
                appraisalReportSendToEmailAddress: null,
                disabled: false,
                appraisalOrder: null,
                contact: {
                    id: null,
                    officeAddress: {}
                }
            });
        }

        function removeAppraisalOrderContact(index) {
            $scope.vm.appraisalOrder.appraisalOrderContacts.splice(index, 1);
        }

        function sameAsClient(index) {
            $scope.vm.appraisalOrder.appraisalOrderContacts[index].contact = angular.copy($scope.vm.appraisalOrder.clientContact);
            $scope.vm.saveProgress();
        }
    }
})();
