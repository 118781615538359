(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraisalOrderDialogAdditionalFilesController', AppraisalOrderDialogAdditionalFilesController);

    AppraisalOrderDialogAdditionalFilesController.$inject = ['$scope', 'DataUtils', 'File', 'FileSaver'];

    function AppraisalOrderDialogAdditionalFilesController ($scope, DataUtils, File, FileSaver) {

        // Initialize Entity
        var vm = this;

        vm.supportingDocs = [];
        vm.agreementPurchase = [];
        vm.mlsPropertySheet = [];

        vm.downloadFile = downloadFile;

        // Update model when file pickers change
        $scope.$watch(function() { return vm.supportingDocs.length; }, function(newVal,oldVal){
            filePickerChanged(vm.supportingDocs, function(files) {
                $scope.vm.appraisalOrder.supportingDocumentationNew = files;
            });
        });
        $scope.$watch(function() { return vm.agreementPurchase.length; }, function(newVal,oldVal) {
            vm.dummyAgreementPurchase = vm.agreementPurchase.length > 0 ? "temp" : null;
            filePickerChanged(vm.agreementPurchase, function(files) {
                $scope.vm.appraisalOrder.agreementPurchaseNew = files;
            });
        });
        $scope.$watch(function() { return vm.mlsPropertySheet.length; }, function(newVal,oldVal) {
            vm.dummyMlsPropertySheet = vm.mlsPropertySheet.length > 0 ? "temp" : null;
            filePickerChanged(vm.mlsPropertySheet, function(files) {
                $scope.vm.appraisalOrder.mlsPropertySheetNew = files;
            });
        });

        function downloadFile(file) {
            File.download({id: file.id}, function(response) {
                var fileData = new Blob([response.data], { type: file.contentType});
                FileSaver.saveAs(fileData, file.fileName);
            });
        }

        function filePickerChanged(files, callback) {
            if(!files.length) {
                callback([]);
                return;
            }

            var encodedFiles = [];

            files.forEach(function(filePicker) {
                var file = filePicker.lfFile;

                // Convert file data and return array of promises
                DataUtils.toBase64(file, function(base64Data) {
                    $scope.$apply(function() {
                        encodedFiles.push({
                            contentType: file.type,
                            fileName: file.name,
                            fileData: base64Data
                        });

                        // Once all files have been encoded, excecute the callback
                        if(encodedFiles.length === files.length) {
                            callback(encodedFiles);
                        }
                    });
                });
            });
        }
    }
})();
