angular
    .module('valueconnectApp')
    .factory('LocalStorage', [ '$rootScope', '$localStorage', function( $rootScope, $localStorage ) {
        return {
            HasModel: function (key) {
                if ($localStorage[key]) return true;
                return false;
            },
            SaveModel: function (key, value) {
                $localStorage[key] = angular.toJson(value);
            },
            GetModel: function (key) {
                return angular.fromJson($localStorage[key]);
            },
            ClearModel: function (key) {
                delete $localStorage[key];
            }
        };
    }]);
