(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('CancelAppraisalOrderDialogController',CancelAppraisalOrderDialogController);

    CancelAppraisalOrderDialogController.$inject = ['$mdDialog', '$scope', '$timeout', '$state', 'entity', 'AppraisalOrder'];

    function CancelAppraisalOrderDialogController($mdDialog, $scope, $timeout, $state, entity, AppraisalOrder) {
        var vm = this;
        vm.cancelAppraisalOrder = entity;
        vm.clear = clear;
        vm.save = save;
        function save() {
            $scope.isSaving = true;

            AppraisalOrder.cancel(vm.cancelAppraisalOrder, function(data) {
                    $mdDialog.cancel('cancel');
                   $timeout(function(){
                        $state.go("home");
                    });
               });
        }


        function clear () {
            $mdDialog.cancel('cancel');
        }


    }
})();
