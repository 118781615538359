(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('Lender', Lender);

    Lender.$inject = ['$resource'];

    function Lender ($resource) {
        var resourceUrl =  'api/lenders/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            /*
               getLenderGrid -> Get lenders with only fields required to be displayed to the admin in grid
            */
            'getLendersGrid': {method: 'GET', url: 'api/lenders-grid', isArray: true},
            'querySimple': {method: 'GET', url: 'api/lenders/simple', isArray: true},
            'querySimplePublic': {method: 'GET', url: 'api/lenders/simple-public', isArray: true},
            'querySimpleAll': {method: 'GET', url: 'api/lenders/simple-all', isArray: true},
            'hasActiveLenderIntegrations': {
                method: 'GET',
                url: '/api/lender-has-active-lender-integrations/:id',
                isArray: false
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }
})();
