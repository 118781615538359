(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .controller('AppraisalOrderAssignCosignerDialogController', AppraisalOrderAssignCosignerDialogController);

    AppraisalOrderAssignCosignerDialogController.$inject = ['$mdDialog', 'order', 'suggestedAppraisers', 'AppraisalReport', 'AppraiserUser'];

    function AppraisalOrderAssignCosignerDialogController ($mdDialog, order, suggestedAppraisers, AppraisalReport, AppraiserUser) {
        var vm = this;
        vm.matchAppraisers = matchAppraisers;
        vm.appraisalOrder = order;
        vm.save = save;
        vm.cancel = cancel;

        function matchAppraisers(searchText) {
            var searchRegex = new RegExp(searchText, 'i');
            return suggestedAppraisers.filter(function(appraiser) {
                return searchRegex.test(appraiser.userFullName);
            });
        }

        function save() {
            AppraisalReport.assignCosigner({id: vm.appraisalOrder.activeReport.id, appraiserUserId: vm.selectedAppraiser.id}, null, function(response) {
                $mdDialog.hide(response);
            }, function(response) {
                $mdDialog.cancel({ error: "An error occurred while assigning the appraiser."});
            });
        }

        function cancel() {
            $mdDialog.cancel();
        }
    }
})();
