(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('StandardResidentialGeneralImprovementsController',StandardResidentialGeneralImprovementsController);

    StandardResidentialGeneralImprovementsController.$inject = ['$log', '$scope', '$filter', '$translate', 'moment'];

    function StandardResidentialGeneralImprovementsController($log, $scope, $filter, $translate, moment) {

        // Initialize model
        var vm = this;
        vm.capitalize = capitalize;
        var sectionData = null;

        // Initialize model using form data
        init(null, $scope.vm.formData);
        $scope.$on('formDataReinitialized', init);

        function init(event, formData) {
            sectionData = formData.improvements;

            // Initialize calculated fields
            sectionData.depreciation = function() {
                var remainingLife = sectionData.remainingLife || 0;
                var lifeSpan = remainingLife + (sectionData.effectiveAge || 0);
                return Number($filter('number')(lifeSpan !== 0 ?  (1 - remainingLife/lifeSpan) : 0, 4));
            };

            sectionData.ageInYears = function() {
                return sectionData.yearBuilt ? moment().year() - sectionData.yearBuilt : null;
            };
        }

        /**
         * @example
         * capitalize('javaSCrIPT');        // -> 'JavaSCrIPT
         * capitalize('javaSCrIPT', true);  // -> 'Javascript
         * @see Original post on {@link http://stackoverflow.com/questions/2332811/capitalize-words-in-string|Stack Overflow}
         */
        function capitalize(string, lower) {
            if(angular.isUndefined(lower)) lower = false;
            return (lower ? string.toLowerCase() : string).replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
        }
    }
})();
