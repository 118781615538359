(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .directive('formOnChange', ['$parse', formOnChange]);

    function formOnChange ($parse) {
        return {
            require: "form",
            link: function(scope, element, attrs){
                var cb = $parse(attrs.formOnChange);
                element.on("change", function(){
                    cb(scope);
                });
            }
        };
    }
})();
