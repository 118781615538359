(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('BlackoutPeriodDetailController', BlackoutPeriodDetailController);

    BlackoutPeriodDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'BlackoutPeriod', 'User'];

    function BlackoutPeriodDetailController($scope, $rootScope, $stateParams, entity, BlackoutPeriod, User) {
        var vm = this;

        vm.blackoutPeriod = entity;

        var unsubscribe = $rootScope.$on('valueconnectApp:blackoutPeriodUpdate', function(event, result) {
            vm.blackoutPeriod = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
