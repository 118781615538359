(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('template', {
            parent: 'entity',
            url: '/template?page&sort&search',
            data: {
                authorities: [/^ROLE_(APPRAISER|APPRAISAL|ADMIN).*/],
                pageTitle: 'valueconnectApp.template.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/template/templates.html',
                    controller: 'TemplateController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('template');
                    $translatePartialLoader.addPart('formStandardResidential');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('appraisalOrder');
                    $translatePartialLoader.addPart('appraisalReportForm');
                    $translatePartialLoader.addPart('formType');
                    $translatePartialLoader.addPart('file');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('address');
                    $translatePartialLoader.addPart('contact');
                    $translatePartialLoader.addPart('enum');
                    $translatePartialLoader.addPart('enumReportSource');
                    $translatePartialLoader.addPart('enumOccupiedBy');
                    $translatePartialLoader.addPart('reportValue');
                    $translatePartialLoader.addPart('valueApproach');
                    return $translate.refresh();
                }]
            }
        })
        .state('template.delete', {
            parent: 'template',
            url: '/{id}/delete',
            data: {
                authorities: [/^ROLE_(APPRAISER|APPRAISAL).*/]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/template/template-delete-dialog.html',
                    controller: 'TemplateDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Template', function(Template) {
                            return Template.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('template', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('template.edit', {
            parent: 'template',
            url: '/{id}/edit',
            data: {
                authorities: [/^ROLE_(APPRAISER|APPRAISAL).*/]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/template/template-edit-dialog.html',
                    controller: 'TemplateEditController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Template', function(Template) {
                            return Template.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('template', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
