(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('ResidentialLandAppraisalAppraisalScopeController',ResidentialLandAppraisalAppraisalScopeController);

    ResidentialLandAppraisalAppraisalScopeController.$inject = ['$scope', '$timeout', 'moment'];

    function ResidentialLandAppraisalAppraisalScopeController($scope, $timeout, moment) {
        // Initialize model
        var vm = this;
        var sectionData = null;

        vm.tinyMceOptions = $scope.vm.tinyMceOptions;
        vm.tinyMceOptions.plugins.push('noneditable');
        vm.tinyMceOptions.setup = function(editor) {
            $timeout(function(){  });
            editor.on("init", function() {
                $timeout(function() {
                    return init(null, $scope.vm.formData);
                });
            });
        };

        // Initialize model using form data
        init(null, $scope.vm.formData);
        $scope.$on('formDataReinitialized', init);
        $scope.$watch('vm.formData.appraisalScope.scope', function(newVal, oldVal) {
            if(newVal !== oldVal) init(null, $scope.vm.formData);
        });

        function init(event, formData) {
            sectionData = formData.appraisalScope;
            if (sectionData) {
                var $parsedScope = angular.element("<div>" + sectionData.scope + "</div>");
                var $dateElem = $parsedScope.find('.mceNonEditable');

                if($dateElem.length) {
                    var placeholder = $dateElem.data('placeholder') || "Enter date in certification section of form"

                    // Get the text to enter for the appraisal date
                    var appraisalDate;
                    if(formData.certification.appraiserInspected) appraisalDate = formData.certification.appraiserInspectedDate;
                    if(formData.certification.coSignerInspected) appraisalDate = appraisalDate || formData.certification.coSignerInspectedDate;
                    var appraisalDateText = appraisalDate ? moment(appraisalDate).format("MMMM DD, YYYY") : ("<"+placeholder+">");

                    // If the date has changed, update the text and mark the form as dirty
                    if($dateElem.text() !== appraisalDateText) {
                        $dateElem.text(appraisalDateText);
                        sectionData.scope = $parsedScope.html();
                        if($scope.editForm['appraisalScope.scope'])
                            $scope.editForm['appraisalScope.scope'].$setDirty();
                    }
                }
            }

        }

    }
})();
