(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('HolidayDetailController', HolidayDetailController);

    HolidayDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Holiday', 'Province', 'Country', 'moment'];

    function HolidayDetailController($scope, $rootScope, $stateParams, entity, Holiday, Province, Country, moment) {
        var vm = this;

        vm.holiday = entity;

        setDatesUTC();

        function setDatesUTC() {
            vm.holiday.startDate = vm.holiday.startDate ? new Date(moment.utc(vm.holiday.startDate).format('LL')) : null;
            vm.holiday.endDate = vm.holiday.endDate ? new Date(moment.utc(vm.holiday.endDate).format('LL')) : null;
        }

    }
})();
