(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('IncomeApproachController',IncomeApproachController);

    IncomeApproachController.$inject = ['$scope', '$translate'];

    function IncomeApproachController($scope, $translate) {
        // Initialize model
        var vm = this;
        var formData = null;

        vm.addFloor = addFloor;
        vm.addIncome = addIncome;
        vm.addExpense = addExpense;
        // TODO: use an array of key names instead (see approach taken in cost approach)
        vm.numStaticExpenses = 15;

        // Initialize model using form data
        init(null, $scope.vm.formData);
        $scope.$on('formDataReinitialized', init);

        function init(event, newData) {
            formData = newData;

            // Wait for promise to resolve before initializing
            if(formData.$promise) {
                formData.$promise.then(initCalculations);
            } else {
                initCalculations(formData);
            }

            // Initialize calculated fields
            // NOTE: existing calculated values will be overwritten
            function initCalculations(formData) {
                formData.rentIncome.map(initFloor);
                formData.otherIncome.map(initIncome);
                formData.expenses.map(initExpense);

                // Initialize expenses array to the length of the static cost items
                for(var i = formData.expenses.length; i < vm.numStaticExpenses; i++) {
                    addExpense();
                }

                formData.rentIncomeGross = function() {
                    return formData.rentIncome.map(function(floor) { return floor.annualTotal(); }).reduce(sum, 0);
                };

                formData.rentIncomeTotal = function() {
                    return formData.rentIncomeGross() * (1 - (formData.rentIncomeVacancy || 0));
                };

                formData.laundryGrossIncome = function() {
                    return (formData.numLaundryUnits || 0) * (formData.weeklyLaundryLoads || 0) *
                        ((formData.washerLoadCost || 0) + (formData.dryerLoadCost || 0)) * 52;
                };

                formData.laundryTotalIncome = function() {
                    return formData.laundryGrossIncome() * (1 - (formData.laundryVacancy || 0));
                };

                formData.grossIncome = function() {
                    return [formData.rentIncomeGross(), formData.laundryGrossIncome()]
                        .concat(formData.otherIncome.map(function(otherIncome) {
                            return otherIncome.totalIncomeManual || otherIncome.totalIncome();
                        })).reduce(sum, 0);
                };

                formData.tenantIncome = function() {
                    return [formData.rentIncomeTotal(), formData.laundryTotalIncome()]
                        .concat(formData.otherIncome.map(function(otherIncome) {
                            return otherIncome.totalIncomeManual || otherIncome.totalIncome();
                        })).reduce(sum, 0);
                };

                formData.interiorParkingOccupancy = function() {
                    return formData.interiorParkingSpaces ?
                        (formData.interiorParkingNumOccupied || 0) / (formData.interiorParkingSpaces) :
                        0;
                };

                formData.interiorParkingGross = function() {
                    return (formData.interiorParkingSpaces || 0) * (formData.interiorParkingMonthly || 0) * 12;
                };

                formData.interiorParkingNet = function() {
                    return (formData.interiorParkingNumOccupied || 0) * (formData.interiorParkingMonthly || 0) * 12 *
                        (1 - (formData.interiorParkingVacancy || 0));
                };

                formData.exteriorParkingOccupancy = function() {
                    return formData.exteriorParkingSpaces ?
                        (formData.exteriorParkingNumOccupied || 0) / (formData.exteriorParkingSpaces) :
                        0;
                };

                formData.exteriorParkingGross = function() {
                    return (formData.exteriorParkingSpaces || 0) * (formData.exteriorParkingMonthly || 0) * 12;
                };

                formData.exteriorParkingNet = function() {
                    return (formData.exteriorParkingNumOccupied || 0) * (formData.exteriorParkingMonthly || 0) * 12 *
                        (1 - (formData.exteriorParkingVacancy || 0));
                };

                formData.effectiveIncome = function() {
                    return formData.tenantIncome() + formData.interiorParkingNet() + formData.exteriorParkingNet();
                };

                formData.totalExpenses = function() {
                    return formData.expenses.map(function(expense) { return expense.total || 0; }).reduce(sum, 0);
                };

                formData.netIncome = function() {
                    return formData.effectiveIncome() - formData.totalExpenses();
                };

                formData.incomeApproachValue = function() {
                    return formData.capitalizationRate ? formData.netIncome() / formData.capitalizationRate : 0;
                };
            }
        }

        /**
         * Add a new floor to the income array, initializing the calculated fields
         */
        function addFloor() {
            formData.rentIncome.push(initFloor({}));
        }

        function addIncome() {
            formData.otherIncome.push(initIncome({numMonths:12}));
            $scope.vm.setDirty('otherIncome['+(formData.otherIncome.length-1)+'].numMonths');
        }

        function addExpense() {
            formData.expenses.push(initExpense({}, formData.expenses.length));
        }

        /**
         * Initialize calculated fields on the provided floor (rent income) object
         * NOTE: existing calculated values will be overwritten
         */
        function initFloor(floor) {
            floor.monthlyTotal = function() {
                var monthlyRent = floor.economicRent ? floor.monthlyRentEconomic : floor.monthlyRent;
                return (floor.numUnits || 0) * (monthlyRent || 0);
            };
            floor.annualTotal = function() {
                return floor.monthlyTotal() * 12;
            };
            return floor;
        }

        function initIncome(income) {
            if(angular.isUndefined(income.totalIncomeManual)) income.totalIncomeManual = null;
            income.totalIncome = function() {
                return (income.numUnits || 0) * (income.unitIncome || 0) * (income.numMonths || 0);
            };
            return income;
        }

        function initExpense(expense, index) {
            expense.perUnitTotal = function() {
                var numUnits = formData.rentIncome.map(function(floor) { return floor.numUnits || 0; }).reduce(sum, 0);
                return (expense.total || 0) / (numUnits || 1);
            };
            expense.percentOfIncome = function() {
                var grossIncome = formData.effectiveIncome();
                return grossIncome ? (expense.total || 0) / grossIncome : 0;
            };

            // Get a translated name for static rows
            if(index < vm.numStaticExpenses) {
                var translationKey = $scope.vm.translationPrefix + '.expenses.' + index;
                $translate(translationKey).then(function(name) {
                    expense.name = function() { return name; };
                });
            }

            return expense;
        }

        function sum(a,b) {
            return a + b;
        }
    }
})();
