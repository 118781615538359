/**
 * Directive to format text input as currency ($ only)
 * Attributes:
 *   - default: Must me a numerical value; defaut value is null
 *              Model value to use if no text is entered in the input
 *   - min: Model value will be modified to be >= this value
 *   - max: Model value will be modified to be <= this value
 */
(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .directive('currencyMask', ['$filter', currencyMask]);

    function currencyMask($filter) {
        var directive =  {
            require: 'ngModel',
            scope: {
                default: '&default',
                min: '&min',
                max: '&max'
            },
            link: link
        };

        return directive;

        function link(scope, element, attrs, ngModelController) {
            //convert data from view format to model format
            ngModelController.$parsers.push(function(data) {
                if(typeof data !== 'string') {
                    console.error('You must use a string model value with currency mask');
                    return null;
                }

                // Get scope values
                var min = scope.min();
                var max = scope.max();
                var defaultValue = scope.default();
                defaultValue = typeof defaultValue !== 'undefined' ? parseFloat(defaultValue) : null;

                // Remove all non-numerical characters
                data = data.replace(/[^0-9\.\-]/g,'').replace(/[.](?=.*[.])/g, "");

                // Attempt to convert to number; use default value on failure
                var numberValue = data.length === 0 || isNaN(data) ? defaultValue : parseFloat(data);

                // Limit value according to min and max values
                if(typeof(min) === 'number') numberValue = Math.max(min, numberValue);
                if(typeof(max) === 'number') numberValue = Math.min(max, numberValue);
                return numberValue;
            });

            //convert data from model format to view format
            ngModelController.$formatters.push(function(data) {
                return currencyFormatter(data);
            });

            // Format the field on blur
            element.on('blur', function () {
                ngModelController.$viewValue = currencyFormatter(ngModelController.$modelValue);
                ngModelController.$render();
            });

            function currencyFormatter(value) {
                return $filter('currency')(value, '$', 2);
            }
        }
    }
})();
