(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .factory('LenderIntegrationPayloadSearch', LenderIntegrationPayloadSearch);

    LenderIntegrationPayloadSearch.$inject = ['$resource'];

    function LenderIntegrationPayloadSearch($resource) {
        var resourceUrl =  'api/_search/lender-integration-payloads/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
