(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AddressDetailController', AddressDetailController);

    AddressDetailController.$inject = ['$scope', '$rootScope', '$timeout', 'entity', 'NgMap', 'GOOGLE_MAP_API_KEY'];

    function AddressDetailController($scope, $rootScope, $timeout, entity, NgMap, GOOGLE_MAP_API_KEY) {
        var vm = this;

        vm.apiKey = GOOGLE_MAP_API_KEY;

        vm.address = entity;
        var defaultZoom = 16;
        var cityName = vm.address.cityId ? vm.address.cityName : vm.address.customCity;
        vm.cityProvince = [cityName, vm.address.provinceName].filter(Boolean).join(', ');
        var addressString = [vm.address.address1, vm.cityProvince, vm.address.countryName].join(', ');
        vm.googleMapUrlParams = "z=" + defaultZoom + "&q=" + addressString;
        vm.mapInitialized = '';
        vm.hasLatLng = vm.address.lat && vm.address.lng;

        var unsubscribe = $rootScope.$on('valueconnectApp:addressUpdate', function(event, result) {
            vm.address = result;
        });
        $scope.$on('$destroy', unsubscribe);

        if(vm.hasLatLng) {
            vm.googleMapUrlParams += "&ll=" + [vm.address.lat,vm.address.lng].join(',');

            NgMap.getMap().then(function(map) {
                initMap(map);
                vm.mapInitialized = 'initialized';
            });
        }

        function initMap(map) {
            google.maps.event.trigger(map, "resize");
            map.setCenter(vm.address);
            map.setZoom(defaultZoom);

            var marker = map.markers[0];
            marker.setPosition(vm.address);
        }
    }
})();
