(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('OriginatorAppraiserDirectNote', OriginatorAppraiserDirectNote);

    OriginatorAppraiserDirectNote.$inject = ['$resource'];

    function OriginatorAppraiserDirectNote ($resource) {
        var resourceUrl =  'api/originator-appraiser-direct-notes/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },

            'getMessageBoardData' : {method: 'GET', url:'api/originator-appraiser-direct-notes-for-appraisal/:appraisalOrderId', isArray: false },

        });
    }
})();
