(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(firebaseConfig);

    firebaseConfig.$inject = ['FIREBASE_CONFIG'];

    function firebaseConfig(FIREBASE_CONFIG) {
        firebase.initializeApp(FIREBASE_CONFIG);
    }
})();
