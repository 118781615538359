(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('Payment', Payment);

    Payment.$inject = ['$resource', 'DateUtils'];

    function Payment ($resource, DateUtils) {
        var resourceUrl =  'api/payments/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'getHistory': { method: 'GET', url: 'api/payments/history' },
            'getHistoryExport': {
                method: 'GET',
                url: 'api/payments/history/export',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    if (data) return { file: new Blob([data], { type: 'application/vnd.ms-excel' }) };
                    else return data;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.transactionDate = DateUtils.convertDateTimeFromServer(data.transactionDate);
                    }
                    return data;
                }
            },
            'getByPaymentKey': { method: 'GET', url:'api/payments/by-payment-key/:paymentKey' },
            'update': { method:'PUT' }
        });
    }
})();
