(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraisalNoteDialogController', AppraisalNoteDialogController);

    AppraisalNoteDialogController.$inject = ['$scope', '$mdDialog', 'entity', 'AppraisalNote'];

    function AppraisalNoteDialogController ($scope, $mdDialog, entity, AppraisalNote) {
        var vm = this;

        vm.appraisalNote = entity;

        vm.cancel = cancel;
        vm.save = save;

        function cancel () {
            $mdDialog.cancel('cancel');
        }

        function save () {
            vm.isSaving = true;
            AppraisalNote.save(vm.appraisalNote, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $mdDialog.hide(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

    }
})();
