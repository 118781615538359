(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('DetrimentalConditionsController',DetrimentalConditionsController);

    DetrimentalConditionsController.$inject = ['$scope', '$translate'];

    function DetrimentalConditionsController($scope, $translate) {

        var vm = this;
        vm.observableEnums = ["OBSERVED", "NOT_OBSERVED", "NOT_OBSERVABLE"];

        $scope.$watch('vm.formData', function(newVal, oldVal) {
            var anyDetrimentalConditions = false;
            var sectionData = $scope.vm.formData;
            for (var property in sectionData) {
                if (!sectionData.hasOwnProperty(property) || property.startsWith('$')) continue;

                if (sectionData[property] === "OBSERVED") {
                    anyDetrimentalConditions = true;
                    break;
                }
            }

            if ($scope.vm.formData.anyDetrimentalConditions !== anyDetrimentalConditions) {
                $scope.vm.formData.anyDetrimentalConditions = anyDetrimentalConditions;
                $scope.vm.setDirty("anyDetrimentalConditions");
            }
        }, true);

    }
})();
