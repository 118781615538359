(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    var getPagingParams = [
        '$log', '$state', '$stateParams', 'PaginationUtil', 'paginationConstants',
        function ($log, $state, $stateParams, PaginationUtil, paginationConstants) {
            // Get sort from query parameter, and fall back to a default
            var sortString = $stateParams.sort || $state.next.data.sort;
            if(!sortString) {
                $log.error("Report state must define a default sort string", $state.next);
                return undefined;
            }
            return {
                page: PaginationUtil.parsePage($stateParams.page || 1),
                itemsPerPage: paginationConstants.itemsPerPage,
                sort: sortString,
                predicate: PaginationUtil.parsePredicate(sortString),
                ascending: PaginationUtil.parseAscending(sortString)
            };
        }
    ];

    function stateConfig($stateProvider) {
        $stateProvider
        // TODO: use this as the parent state for all reports
        .state('report', {
            parent: 'app',
            abstract: true,
            url: '/reports?page&sort',
            data: {
                authorities: [/^ROLE_(ADMIN|CUSTOMER_CARE_REPRESENTATIVE|VC.*)$/]
            },
            resolve: {
                isAdmin: ['Principal', 'authorize', function(Principal, authorize) {
                    return Principal.matchAnyAuthority(/^ROLE_(ADMIN|VC_DATA_ANALYST|VC_FINANCIAL_TEAM)$/);
                }],
                isCCR: ['Principal', 'authorize', function(Principal, authorize) {
                    return Principal.hasAnyRole(['CUSTOMER_CARE_REPRESENTATIVE']);
                }],
                isReviewer: ['Principal', 'authorize', function(Principal, authorize) {
                    return Principal.hasAnyRole(['VC_REVIEWER']);
                }],
                isPrincipal: ['Principal', 'authorize', function(Principal, authorize) {
                    return Principal.hasAnyRole(['ORIGINATOR_PRINCIPAL','ORIGINATOR_ADMINISTRATOR','APPRAISAL_FIRM_PRINCIPAL','APPRAISAL_FIRM_ADMINISTRATOR']);
                }],
                isFinancialTeam: ['Principal', 'authorize', function(Principal, authorize) {
                    return Principal.hasAnyRole(['VC_FINANCIAL_TEAM']);
                }],
                params: ['moment', function(moment) {
                    return {
                        minDate: moment().startOf('month').toDate(),
                        maxDate: moment().endOf('month').toDate()
                    };
                }],
                numCols: function() { return -1; },
                isCustomReport: function() { return false; },
                filterDataSources: ['AppraisalState', 'Lender', 'User', 'AppraiserUser', 'VCUser', 'Brokerage', 'AppraisalFirm', 'isAdmin', 'isCCR', 'Province', 'Region','LenderIntegrationBlacklist',
                    function(AppraisalState, Lender, User, AppraiserUser, VCUser, Brokerage, AppraisalFirm, isAdmin, isCCR, Province, Region,LenderIntegrationBlacklist) {
                        return {
                            states: AppraisalState.all,
                            allLenders:
                                function () {
                                    return Lender.querySimpleAll();
                                },
                            lenders:
                                function () {
                                    return Lender.querySimple();
                                },
                            // blackListLender: // No longer used.
                            //     function() {
                            //         return LenderIntegrationBlacklist.getMinimal({size:2000});
                            //     },
                            ccrs: isAdmin ?
                                function () {
                                    return VCUser.queryCCRs();
                                }
                                : undefined,
                            brokerages: (isAdmin || isCCR) ?
                                function () {
                                    return Brokerage.getDropDownBrokerages({size: 2000, sort: 'name,asc'});
                                }
                                : undefined,
                            appraisalFirms: (isAdmin || isCCR) ?
                                function() {
                                    return AppraisalFirm.getDropDownAppraisalFirms({size: 2000, sort: 'name,asc'});
                                }
                                : undefined,
                            originators: (isAdmin || isCCR) ?
                                function() {
                                    return User.getOriginators();
                                }
                                : undefined,
                            appraisers: (isAdmin || isCCR) ?
                                function() {
                                    return AppraiserUser.getMinimal({size:2000, sort:'user.lastName,asc'});
                                }
                                : undefined,
                            provinces:
                                function() {
                                    return Province.query({size:2000});
                                },
                            regions:
                                function() {
                                    return Region.query({size: 2000, sort: 'name,asc'});
                                }
                        };
                    }
                ],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('report');
                    $translatePartialLoader.addPart('appraisalOrder');
                    $translatePartialLoader.addPart('appraisalReport');
                    $translatePartialLoader.addPart('quote');
                    $translatePartialLoader.addPart('brokerage');
                    $translatePartialLoader.addPart('coupon');
                    $translatePartialLoader.addPart('address');
                    $translatePartialLoader.addPart('enum');
                    $translatePartialLoader.addPart('enumAppraisalState');
                    $translatePartialLoader.addPart('enumAppraisalFirmSelection');
                    $translatePartialLoader.addPart('enumAppraisalPurpose');
                    $translatePartialLoader.addPart('enumAppraisalType');
                    $translatePartialLoader.addPart('enumOccupiedBy');
                    $translatePartialLoader.addPart('appraisalContactRole');
                    return $translate.refresh();
                }]
            }
        })

        .state('report.fee-summary', {
            parent: 'report',
            url: '/fee-summary',
            reloadOnSearch: false,
            data: {
                authorities: [/^ROLE_(ADMIN|VC|APPRAISER|APPRAISAL|CUSTOMER_CARE).*/],
                sort: 'appraisalReport.startDate,desc'
            },
            views: {
                'content@': {
                    templateUrl: 'app/report/fee-summary.html',
                    controller: 'ReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                params: ['AppraisalState', "moment", function(AppraisalState, moment) {
                    return {
                        minDate: moment().startOf('month').toDate(),
                        maxDate: moment().endOf('month').toDate(),
                        states: AppraisalState.submitted
                    };
                }],
                pagingParams: getPagingParams,
                numCols: ['isAdmin', 'isCCR', 'isReviewer', 'isPrincipal', function(isAdmin, isCCR, isReviewer, isPrincipal) {
                    return (isAdmin || isCCR || isReviewer) ? 15 : (isPrincipal ? 13 : 12);
                }],
                queryFunction: ['Quote', function(Quote) { return Quote.feeSummary; }],
                exportFunction: ['Quote', function(Quote) { return Quote.feeSummaryExport; }],
                requiredApis: function() { return ['originators', 'lenders', 'appraisalFirms' , 'appraisers', 'states', 'ccrs', 'provinces', 'regions']; }
            }
        })

        .state('report.payment-history', {
            parent: 'report',
            url: '/payment-history',
            reloadOnSearch: false,
            data: {
                authorities: [/^ROLE_(ORIGINATOR|ADMIN|VC|CUSTOMER_CARE).*/],
                sort: 'transactionDate,desc'
            },
            views: {
                'content@': {
                    templateUrl: 'app/report/payment-history.html',
                    controller: 'ReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                params: ['moment', function(moment) {
                    return {
                        minDate: moment().startOf('month').toDate(),
                        maxDate: moment().endOf('month').toDate()
                    };
                }],
                pagingParams: getPagingParams,
                numCols: ['isAdmin', 'isCCR', 'isReviewer', 'isPrincipal', function(isAdmin, isCCR, isReviewer, isPrincipal) {
                    return (isAdmin || isCCR || isReviewer) ? 17 :
                        isPrincipal ? 16 : 15;
                }],
                queryFunction: ['Payment', function(Payment) { return Payment.getHistory; }],
                exportFunction: ['Payment', function(Payment) { return Payment.getHistoryExport; }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('payment');
                    $translatePartialLoader.addPart('enumProductType');
                    $translatePartialLoader.addPart('legalProceedingType');
                    $translatePartialLoader.addPart('financingPurpose');
                    $translatePartialLoader.addPart('enumPaymentMethod');
                    return $translate.refresh();
                }],
                requiredApis: function() { return ['brokerages', 'originators', 'lenders', 'appraisers', 'ccrs', 'provinces', 'regions']; }
            }
        })

        .state('report.appraisal-sales', {
            parent: 'report',
            url: '/accounting/appraisal-sales',
            reloadOnSearch: false,
            data: {
                authorities: [/^ROLE_(ADMIN|VC_FINANCIAL_TEAM).*/],
                sort: 'transactionDate,asc'
            },
            views: {
                'content@': {
                    templateUrl: 'app/report/appraisal-sales.html',
                    controller: 'ReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                params: ['moment', function (moment) {
                    return {
                        minDate: moment().subtract(1, 'months').startOf('month').toDate(),
                        maxDate: moment().subtract(1, 'months').endOf('month').toDate()
                    };
                }],
                pagingParams: getPagingParams,
                numCols: [function () {
                    return 11;
                }],
                queryFunction: ['Accounting', function (Accounting) {
                    return Accounting.getAccountsReceivableReport;
                }],
                exportFunction: ['Accounting', function (Accounting) {
                    return Accounting.getAccountsReceivableReportExport;
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('payment');
                    $translatePartialLoader.addPart('enumPaymentMethod');
                    $translatePartialLoader.addPart('quoteSplit');
                    return $translate.refresh();
                }],
                requiredApis: function () {
                    return ['brokerages'];
                }
            }
        })

        .state('report.appraisal-sales-qb', {
            parent: 'report',
            url: '/accounting/appraisal-sales-qb',
            reloadOnSearch: false,
            data: {
                authorities: [/^ROLE_(ADMIN|VC_FINANCIAL_TEAM).*/],
                sort: 'transactionDate,asc'
            },
            views: {
                'content@': {
                    templateUrl: 'app/report/appraisal-sales-qb.html',
                    controller: 'ReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                params: ['moment', function (moment) {
                    return {
                        minDate: moment().subtract(1, 'months').startOf('month').toDate(),
                        maxDate: moment().subtract(1, 'months').endOf('month').toDate()
                    };
                }],
                pagingParams: getPagingParams,
                numCols: [function () {
                    return 15;
                }],
                queryFunction: ['Accounting', function (Accounting) {
                    return Accounting.getAccountsReceivableQBReport;
                }],
                exportFunction: ['Accounting', function (Accounting) {
                    return Accounting.getAccountsReceivableQBReportExport;
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('payment');
                    $translatePartialLoader.addPart('enumPaymentMethod');
                    $translatePartialLoader.addPart('quoteSplit');
                    $translatePartialLoader.addPart('invoice');
                    return $translate.refresh();
                }],
                requiredApis: function () {
                    return ['brokerages'];
                }
            }
        })
        
        .state('report.accounts-payable', {
            parent: 'report',
            url: '/accounting/payable',
            reloadOnSearch: false,
            data: {
                authorities: [/^ROLE_(ADMIN|VC_FINANCIAL_TEAM).*/],
                sort: 'transactionDate,asc'
            },
            views: {
                'content@': {
                    templateUrl: 'app/report/accounts-payable.html',
                    controller: 'ReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                params: ['moment', function(moment) {
                    return {
                        minDate: moment().subtract(1, 'months').startOf('month').toDate(),
                        maxDate: moment().subtract(1, 'months').endOf('month').toDate()
                    };
                }],
                pagingParams: getPagingParams,
                numCols: [function() { return 13; }],
                queryFunction: ['Accounting', function(Accounting) { return Accounting.getAccountsPayableReport; }],
                exportFunction: ['Accounting', function(Accounting) { return Accounting.getAccountsPayableReportExport; }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('payment');
                    $translatePartialLoader.addPart('enumPaymentMethod');
                    $translatePartialLoader.addPart('quoteSplit');
                    return $translate.refresh();
                }],
                requiredApis: function() { return ['states']; }
            }
        })

        .state('report.accounts-payable-qb', {
            parent: 'report',
            url: '/accounting/payable-qb',
            reloadOnSearch: false,
            data: {
                authorities: [/^ROLE_(ADMIN|VC_FINANCIAL_TEAM).*/],
                sort: 'transactionDate,asc'
            },
            views: {
                'content@': {
                    templateUrl: 'app/report/accounts-payable-qb.html',
                    controller: 'ReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                params: ['moment', function(moment) {
                    return {
                        minDate: moment().subtract(1, 'months').startOf('month').toDate(),
                        maxDate: moment().subtract(1, 'months').endOf('month').toDate()
                    };
                }],
                pagingParams: getPagingParams,
                numCols: [function() { return 13; }],
                queryFunction: ['Accounting', function(Accounting) { return Accounting.getAccountsPayableQBReport; }],
                exportFunction: ['Accounting', function(Accounting) { return Accounting.getAccountsPayableQBReportExport; }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('payment');
                    $translatePartialLoader.addPart('enumPaymentMethod');
                    $translatePartialLoader.addPart('quoteSplit');
                    return $translate.refresh();
                }],
                requiredApis: function() { return ['states']; }
            }
        })

        .state('report.cancelled-orders', {
            parent: 'report',
            url: '/cancelled-orders',
            reloadOnSearch: false,
            data: { sort: 'lastModifiedDate,desc' },
            views: {
                'content@': {
                    templateUrl: 'app/report/cancelled-orders.html',
                    controller: 'ReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                pagingParams: getPagingParams,
                numCols: function () {
                    return 20;
                },
                queryFunction: ['AppraisalOrder', function (AppraisalOrder) {
                    return AppraisalOrder.getCancelled;
                }],
                exportFunction: ['AppraisalOrder', function (AppraisalOrder) {
                    return AppraisalOrder.getCancelledExport;
                }],
                requiredApis: function () {
                    return ['originators', 'allLenders', 'appraisers', 'ccrs', 'provinces', 'regions'];
                }
            }
        })

            .state('report.appraisal-report-history', {
                parent: 'report',
                url: '/appraisal-report-history',
                reloadOnSearch: false,
                data: {
                    sort: 'submissionDate,desc'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/report/appraisal-report-history.html',
                        controller: 'ReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                pagingParams: getPagingParams,
                numCols: function() { return 9; },
                queryFunction: ['AppraisalReport', function(AppraisalReport) { return AppraisalReport.queryHistory; }],
                exportFunction: ['AppraisalReport', function(AppraisalReport) { return AppraisalReport.queryHistoryExport; }],
                requiredApis: function() { return ['originators', 'lenders', 'appraisers', 'appraisalFirms', 'ccrs', 'provinces', 'regions']; }
            }
        })

        .state('report.outstanding-orders', {
            parent: 'report',
            url: '/outstanding-orders',
            reloadOnSearch: false,
            data: { sort: 'acceptedDate,desc' },
            views: {
                'content@': {
                    templateUrl: 'app/report/outstanding-orders.html',
                    controller: 'ReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                pagingParams: getPagingParams,
                numCols: function() { return 7; },
                queryFunction: ['AppraisalOrder', function(AppraisalOrder) { return AppraisalOrder.getOutstanding; }],
                exportFunction: ['AppraisalOrder', function(AppraisalOrder) { return AppraisalOrder.getOutstandingExport; }],
                requiredApis: function() { return ['originators', 'lenders', 'appraisers', 'ccrs', 'provinces', 'regions']; }
            }
        })

        .state('report.outstanding-payments', {
            parent: 'report',
            url: '/outstanding-payments',
            reloadOnSearch: false,
            data: { sort: 'id,desc' },
            views: {
                'content@': {
                    templateUrl: 'app/report/outstanding-payments.html',
                    controller: 'ReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                pagingParams: getPagingParams,
                numCols: function() { return 5; },
                queryFunction: ['AppraisalOrder', function(AppraisalOrder) { return AppraisalOrder.getOutstandingPayments; }],
                exportFunction: ['AppraisalOrder', function(AppraisalOrder) { return AppraisalOrder.getOutstandingPaymentsExport; }],
                requiredApis: function() { return ['originators']; }
            }
        })

        .state('report.order-history', {
            parent: 'report',
            url: '/order-history',
            reloadOnSearch: false,
            data: { sort: 'acceptedDate,desc' },
            views: {
                'content@': {
                    templateUrl: 'app/report/order-history.html',
                    controller: 'ReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                pagingParams: getPagingParams,
                numCols: function() { return 11; },
                queryFunction: ['AppraisalOrder', function(AppraisalOrder) { return AppraisalOrder.getHistory; }],
                exportFunction: ['AppraisalOrder', function(AppraisalOrder) { return AppraisalOrder.getHistoryExport; }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('enumProductType');
                    return $translate.refresh();
                }],
                requiredApis: function () { return ['originators', 'lenders', 'appraisers', 'ccrs', 'provinces', 'regions', 'brokerages']; }
            }
        })

        .state('report.risk-history', {
            parent: 'report',
            url: '/risk-history',
            reloadOnSearch: false,
            data: { sort: 'acceptedDate,desc' },
            views: {
                'content@': {
                    templateUrl: 'app/report/risk-history.html',
                    controller: 'ReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                pagingParams: getPagingParams,
                isCustomReport: function() { return true; },
                queryFunction: ['RiskRule', function(RiskRule) { return RiskRule.getRiskHistory; }],
                exportFunction: ['RiskRule', function(RiskRule) { return RiskRule.getRiskHistoryExport; }],
                requiredApis: function() { return ['lenders']; }
            }
        })

        .state('report.appraiser-coverage', {
            parent: 'report',
            url: '/appraiser-coverage',
            data: { sort: 'regionName,desc' },
            views: {
                'content@': {
                    templateUrl: 'app/report/appraiser-coverage.html',
                    controller: 'ReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                pagingParams: getPagingParams,
                isCustomReport: function() { return true; },
                queryFunction: ['AppraiserUser', function(AppraiserUser) { return AppraiserUser.getAppraiserCoverage; }],
                exportFunction: [function() { return null; }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('enumProductType');
                    return $translate.refresh();
                }],
                filterDataSources: ['Province',
                    function(Province) {
                        return {
                            provinces: Province.query({size:2000})
                        };
                    }
                ],
                requiredApis: function() { return ['provinces']; }
            }
        })

        .state('report.coupon-summary', {
            parent: 'report',
            url: '/coupon-summary',
            reloadOnSearch: false,
            data: {
                authorities: [/^ROLE_(ADMIN|VC|CUSTOMER_CARE).*/],
                sort: 'code,desc'
            },
            views: {
                'content@': {
                    templateUrl: 'app/report/coupon-summary.html',
                    controller: 'ReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                params: ['AppraisalState', 'moment', function(AppraisalState, moment) {
                    return {
                        states: AppraisalState.submitted,
                        minDate: moment().startOf('month').toDate(),
                        maxDate: moment().endOf('month').toDate()
                    };
                }],
                pagingParams: getPagingParams,
                numCols: ['isAdmin', 'isCCR', 'isReviewer', 'isPrincipal', function(isAdmin, isCCR, isReviewer, isPrincipal) {
                    return (isAdmin || isCCR || isReviewer) ? 13 :
                        isPrincipal ? 12 : 11;
                }],
                queryFunction: ['Quote', function(Quote) { return Quote.couponSummary; }],
                exportFunction: ['Quote', function(Quote) { return Quote.couponSummaryExport; }],
                requiredApis: function() { return ['brokerages']; }
            }
        })

        .state('report.blackout-period', {
            parent: 'report',
            url: '/blackout-periods/report',
            reloadOnSearch: false,
            data: {
                authorities: [/^ROLE_(ADMIN|VC|CUSTOMER_CARE).*/],
                sort: 'id,desc'
            },
            views: {
                'content@': {
                    templateUrl: 'app/report/blackout-period.html',
                    controller: 'ReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                pagingParams: getPagingParams,
                numCols: function() { return 11; },
                queryFunction: ['BlackoutPeriod', function(BlackoutPeriod) { return BlackoutPeriod.generateBlackoutReport; }],
                exportFunction: ['BlackoutPeriod', function(BlackoutPeriod) { return BlackoutPeriod.generateBlackoutReportExport; }],
                requiredApis: function() { return []; },
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                    $translatePartialLoader.addPart('blackoutPeriod');
                                    return $translate.refresh();
                }]
            }
        });

    }
})();
