(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('NewsDialogController', NewsDialogController);

    NewsDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'News'];

    function NewsDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, News) {
        var vm = this;

        vm.news = entity;
        vm.clear = clear;
        vm.save = save;
        vm.publish = publish;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.news.id !== null) {            	
                News.update(vm.news, onSaveSuccess, onSaveError);
            } else {
            	vm.news.postingDate = null;
            	vm.news.publish = false;
                News.save(vm.news, onSaveSuccess, onSaveError);
            }
        }
        
        function publish () {
            vm.isPublishing = true;            
            if (vm.news.id !== null) {
            	vm.news.publish = true;
                News.update(vm.news, onSaveSuccess, onSaveError);
            } else {
            	vm.news.publish = true;            
                News.save(vm.news, onSaveSuccess, onSaveError);
            }
        }


        function onSaveSuccess (result) {
            $scope.$emit('valueconnectApp:newsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }
        

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
