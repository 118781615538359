(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraisalOrderChangeRequestDetailController', AppraisalOrderChangeRequestDetailController);

    AppraisalOrderChangeRequestDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'AppraisalOrderChangeRequest', 'AppraisalOrder', 'User'];

    function AppraisalOrderChangeRequestDetailController($scope, $rootScope, $stateParams, entity, AppraisalOrderChangeRequest, AppraisalOrder, User) {
        var vm = this;

        vm.appraisalOrderChangeRequest = entity;

        var unsubscribe = $rootScope.$on('valueconnectApp:appraisalOrderChangeRequestUpdate', function(event, result) {
            vm.appraisalOrderChangeRequest = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
