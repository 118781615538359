(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('audits', {
            parent: 'admin',
            url: '/reports/system-log?page&sort',
            reloadOnSearch: false,
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'audits.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/audits/audits.html',
                    controller: 'AuditsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    var sortString = $stateParams.sort || 'auditEventDate,desc';
                    return {
                        page: PaginationUtil.parsePage($stateParams.page) || 1,
                        sort: sortString,
                        predicate: PaginationUtil.parsePredicate(sortString),
                        ascending: PaginationUtil.parseAscending(sortString),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('audits');
                    $translatePartialLoader.addPart('enum');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
