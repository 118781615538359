/**
 * Directive to create a checklist input from an enum field. Requires ng-model and name attributes.
 * @param enumKey {String} The enum to use. Defaults to the key used in the name attribute.
 * @param enumTranslationKey {String} The translation key to use for the enum values. Defaults to
 *                                    the key used in the name attribute. If an enumKey is provided,
 *                                    the path is prefixed to the enum transltion field instead of
 *                                    the form translation file.
 * @param otherValue {String} The value selected to display the 'other' input. Defaults to 'OTHER'
 * @param otherModel {String} If provided, the 'other' input will be rendered and be bound to this
 *                            value
 * @param otherName  {String} The field name to use for the 'other' input. Defaults to the name of
 *                            this checklist input, with 'Other' added as a suffix
 */
(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .directive('vcChecklist', ['$stateParams', '$log', '$timeout', 'Enum', 'DynamicForm', 'AppraisalReport', vcChecklist]);

    function vcChecklist ($stateParams, $log, $timeout, Enum, DynamicForm, AppraisalReport) {
        var directive = {
            restrict: 'E',
            link: {
                pre: preLink,
                post: postLink
            },
            require: 'ngModel',
            template: "\
                <label class='md-no-float' translate='valueconnectApp.form.{{fieldKey}}'>districtNature</label>\
                <div class='checklist-wrapper clearfix'>\
                    <div class='checklist-item'\
                            ng-class=\"{'other-container': opt === otherValue}\"\
                            ng-repeat='opt in checkListValues'>\
                        <span>\
                            <md-checkbox md-no-ink \
                                    ng-model='values[opt]'\
                                    ng-change='inputChanged(opt, $index)'>\
                                <span translate='{{enumTranslationKey}}.{{opt}}'>{{opt}}</span>\
                            </md-checkbox>\
                        </span>\
                        <md-input-container server-validation\
                                ng-if='hasOther && opt === otherValue'\
                                ng-show='values[otherValue] === true'>\
                            <input name='{{otherName}}' ng-model='otherModel'\
                                    class='other-input' ng-change='otherChanged(otherModel)'\
                                    aria-label=\"{{'valueconnectApp.form.other' | translate}}\" decode-fix />\
                        </md-input-container>\
                    <div>\
                </div>\
            ",
            scope: {
                enumKey: '@',
                enumTranslationKey: '@',
                otherValue: '@',
                otherModel: '=',
                otherName: '@',
                hideEntries: '='
            }
        };

        return directive;

        function preLink(scope, elm, attrs, inputCtrl) {
            scope.Enum = Enum;
            scope.hasOther = attrs.hasOwnProperty('otherModel');
            scope.otherValue = scope.otherValue || 'OTHER';
            scope.DynamicForm = DynamicForm;
            scope.AppraisalReport = AppraisalReport;
        }

        function postLink(scope, elm, attrs, inputCtrl) {
            // get the field name/path
            var formName = $stateParams.form;
            scope.fieldName = inputCtrl.$name;
            scope.fieldKey = formName + '.' + inputCtrl.$name;
            scope.otherName = angular.isDefined(attrs.otherName) ? scope.otherName : (scope.fieldName + 'Other');
            scope.enumKey = scope.enumKey || scope.fieldKey;
            scope.enumTranslationKey = scope.enumTranslationKey ||
                angular.isDefined(attrs.enumKey) ?
                    ('valueconnectApp.enum.' + scope.enumKey) :
                    ('valueconnectApp.form.' + scope.fieldKey);

            scope.AppraisalReport.get({
                id: $stateParams.id
            }).$promise.catch(function error(error) {
                console.error("Error fetching appraisal report", error);
            }).finally(function getDefinitionFieldValues() {
                scope.DynamicForm.getDefinitionFieldValues({
                    fieldKey: scope.fieldKey
                }).$promise.then(function response(response) {
                    scope.checkListValues = response;
                }).catch(function error(error) {
                    console.error("Error getting definition field values", error, scope.fieldKey);
                })
            });

            if (scope.hideEntries && scope.hideEntries.length > 0) {
                for (var i = scope.checkListValues.length-1; i >= 0; i--) {
                    for (var r = 0; r < scope.hideEntries.length; r++) {
                        if (scope.checkListValues[i] === scope.hideEntries[r]) {
                            scope.checkListValues.splice(i, 1);
                            break;
                        }
                    }
                }
            }

            // Account for linked fields
            if(scope.fieldKey.endsWith('$')) {
                scope.fieldKey = scope.fieldKey.substr(0, scope.fieldKey.lastIndexOf('.$'));
            }

            // Initialize the model and set up watch to reinitialize
            var unsubscribe = scope.$watch(function() { return inputCtrl.$modelValue; }, function() {
                init();
                unsubscribe();
            });
            scope.$on('formDataReinitialized', init);

            function init() {
                scope.values = (inputCtrl.$modelValue || []).reduce(function(object, key, index) {
                    object[key] = true;
                    return object;
                }, {});

                Enum.$promise.then(function() {
                    $timeout(function() {
                        elm.find('.checklist-item').each(function(index, element) {
                            element = angular.element(element);
                            element.toggleClass('checked', element.find('.md-checked').length === 1);
                        });
                    });
                });

                Object.keys(scope.values).forEach(function(key, i) {
                    if (scope.checkListValues.indexOf(key) === -1) {
                        //delete scope.values[key];
                        inputCtrl.$modelValue.splice(scope.checkListValues.indexOf(key), 1);
                        $timeout(function(){
                            inputCtrl.$setDirty();
                        });
                    }
                });
            }

            /**
             * Update model when an input is changed. Clear other value if it is unchecked
             * @param {String} key The enum key that was changed
             * @param {int} index The index of the item that was changed
             */
            scope.inputChanged = function(key, index) {
                // Update model
                inputCtrl.$setViewValue(Object.keys(scope.values).filter(function(key) {
                    return !!scope.values[key];
                }));

                // Toggle class on wrapper element
                elm.find('.checklist-item').eq(index)
                    .toggleClass('checked', scope.values[key]);

                // Clear other value if deselected
                if(key === scope.otherValue && !scope.values[scope.otherValue]) {
                    elm.find('input.other-input')
                        .controller('ngModel')
                        .$setViewValue('');
                }
            };

            // We have to update the other model manually for some reason
            scope.otherChanged = function(value) {
                scope.otherModel = value;
            };
        }
    }
})();
