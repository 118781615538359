(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('appraisal-firm', {
            parent: 'entity',
            url: '/appraisal-firm?page&sort&search',
            data: {
                authorities: [/^ROLE_(ADMIN|VC).*/],
                pageTitle: 'valueconnectApp.appraisalFirm.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/appraisal-firm/appraisal-firms.html',
                    controller: 'AppraisalFirmController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('appraisalFirm');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('appraisal-firm-detail', {
            parent: 'entity',
            url: '/appraisal-firm/{id}',
            data: {
                authorities: [/^ROLE_(ADMIN|VC|APPRAISER|APPRAISAL).*/],
                pageTitle: 'valueconnectApp.appraisalFirm.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/appraisal-firm/appraisal-firm-detail.html',
                    controller: 'AppraisalFirmDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('appraisalFirm');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'AppraisalFirm', function($stateParams, AppraisalFirm) {
                    return AppraisalFirm.get({id : $stateParams.id}).$promise;
                }]
            }
        })


        .state('appraisal-firm-add', {
            parent: 'appraisal-firm',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/appraisal-firm/appraisal-firm-add.html',
                    controller: 'AppraisalFirmAddController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: function () {
                    return {
                        id: null,
                        name: null,
                        preferredPaymentMethod: 'ETRANSFER',
                        disabled: false,
                        independent: false,
                        rostered: true,
                        regionsServed: [],
                        headOfficeAddress: {
                            province: null
                        }
                    };
                },
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('appraiserUser');
                    $translatePartialLoader.addPart('address');
                    $translatePartialLoader.addPart('user-management');
                    return $translate.refresh();
                }]
            }
        })

        .state('appraisal-firm.edit', {
            parent: 'appraisal-firm',
            url: '/{id}/edit',
            data: {
                authorities: [/^ROLE_(ADMIN|APPRAISAL_FIRM_PRINCIPAL|APPRAISAL_FIRM_ADMINISTRATOR).*/]
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/appraisal-firm/appraisal-firm-dialog.html',
                    controller: 'AppraisalFirmDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'AppraisalFirm', function ($stateParams, AppraisalFirm) {
                    return AppraisalFirm.get({id: $stateParams.id}).$promise;
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('enum');
                    return $translate.refresh();
                }]
            }
        })
        .state('appraisal-firm.delete', {
            parent: 'appraisal-firm',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/appraisal-firm/appraisal-firm-delete-dialog.html',
                    controller: 'AppraisalFirmDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['AppraisalFirm', function(AppraisalFirm) {
                            return AppraisalFirm.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('appraisal-firm', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
