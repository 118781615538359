(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('RegisterController', RegisterController)
        .directive('scrollDirective', function(){
            return {
                restrict: 'A',
                link: function (scope, element, attrs, RegisterController) {

                    element.bind('scroll', function () {
                    	if (($(element).scrollTop() + $(element).height() + 20) >= $(element)[0].scrollHeight) {
                            scope.$apply(function(){
                                scope.$parent.vm.termsViewed = true;

                            });
                    	}

                    });
                },
                scope: true
            };
        });

    RegisterController.$inject = ['$scope', '$rootScope', '$state', '$translate', '$timeout', '$q', '$uibModal', 'Auth', 'LoginService', 'vcRecaptchaService', 'NationalBrokerage', 'Region', 'AlertService', "JhiLanguageService"];

    function RegisterController ($scope, $rootScope, $state, $translate, $timeout, $q, $uibModal, Auth, LoginService, vcRecaptchaService, NationalBrokerage, Region, AlertService, languageService) {
        var vm = this;

       languageService.getCurrent().then(
            function(language) {
                vm.language = language;
        });

        $rootScope.$on('$translateChangeSuccess', function(event, current, previous) {
            vm.language = current.language;
        });

        vm.termsAccepted = false;
        vm.termsViewed = false;
        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.errorInvalidPassword = null;
        vm.errorInvalidRecaptcha = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.success = null;
        vm.nationalBrokerages = NationalBrokerage.query({size:2000});
        vm.regions = Region.query({ size:2000, sort: 'name' });

        $timeout(function (){angular.element('#login').focus();});

        /*vm.showPrivacyPolicy = function() {
            $uibModal.open({
                animation: true,
                templateUrl: '/content/privacy-policy.html',
                controllerAs: 'vm',
                controller: ['$uibModalInstance', function($uibModalInstance) {
                    this.close = function() {
                        $uibModalInstance.close(false);
                    }
                }],
            });
        }*/

        vm.showMore = function() {
            console.log('show more triggered');
        };
        vm.acceptTerms = function() {
            gtag('event', 'terms_accepted');

            vm.termsAccepted = true;
            vm.registerAccount.userForm.termsAccepted = new Date();
            vm.registerAccount.userForm.privacyPolicyAccepted = new Date();
        }

        function register () {
            if (vm.registerAccount.userForm.password !== vm.confirmPassword) {
                vm.doNotMatch = 'ERROR';
            } else {
                vm.registerAccount.recaptchaResponse = vcRecaptchaService.getResponse(vm.recaptchaWidgetId);
                vm.registerAccount.userForm.phoneNumber = vm.registerAccount.brokerageForm.phoneNumber;

                vm.doNotMatch = null;
                vm.error = null;
                vm.errorUserExists = null;
                vm.errorEmailExists = null;
                vm.errorInvalidRecaptcha = null;

                Auth.createAccount(vm.registerAccount).then(function () {
                    vm.success = 'OK';
                    AlertService.success('register.messages.success', {});
                    Auth.authorize(true).then(function() {
                        $state.go('home');
                    });
                }).catch(function (response) {
                    vm.success = null;
                    if (response.status === 409 && response.data === 'email address already in use') {
                        vm.errorEmailExists = 'ERROR';
                    } else if (response.status === 400 && response.data === 'Invalid password') {
                        vm.errorInvalidPassword = 'ERROR';
                    } else if (response.status === 400 && response.data === 'Invalid reCAPTCHA') {
                        vm.errorInvalidRecaptcha = 'ERROR';
                    }
                    else {
                        vm.error = 'ERROR';
                    }
                });
            }
            console.log(vm.error);
        }

        $scope.getSelectedRegionText = function() {
            var regions = [];
            if ( vm.registerAccount.regionsServiced == undefined) {
                return "";
            } else {
                for(var i = 0; i < vm.registerAccount.regionsServiced.length; i++) {
                    regions.push(vm.registerAccount.regionsServiced[i].name);
                }
                return regions.join(', ');
            }
        }
    }
})();
