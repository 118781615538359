(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('LenderIntegrationBlacklistDetailController', LenderIntegrationBlacklistDetailController);

    LenderIntegrationBlacklistDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'LenderIntegrationBlacklist', 'Lender', 'Integration'];

    function LenderIntegrationBlacklistDetailController($scope, $rootScope, $stateParams, entity, LenderIntegrationBlacklist, Lender, Integration) {
        var vm = this;

        vm.lenderIntegrationBlacklist = entity;

        var unsubscribe = $rootScope.$on('valueconnectApp:lenderIntegrationBlacklistUpdate', function(event, result) {
            vm.lenderIntegrationBlacklist = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
