(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('MarketRentConsultingCertificationController',MarketRentConsultingCertificationController);

    MarketRentConsultingCertificationController.$inject = ['$scope', '$filter'];

    function MarketRentConsultingCertificationController($scope, $filter) {
        var vm = this;
        var sectionData = null;
        console.log($scope.vm);
        init(null, $scope.vm.formData);
        $scope.$on('formDataReinitialized', init);
        function init(event, formData) {

            sectionData = formData.certification;
            //sectionData.$started = false;
            //$scope.vm.formData.certification.additionalCertifications=[];
            if (sectionData.$started == false && !$scope.vm.formData.certification.additionalCertifications.length) {
                for (var i = 0; i < 3; i++) {
                    var $translate = $filter('translate');
                    var obj = {};
                    obj.value = $translate("valueconnectApp.form.marketRentConsulting.certification.additionalCertificationDefaults." + i);
                    $scope.vm.formData.certification.additionalCertifications.push(obj);
                    $scope.vm.setDirty('certification.additionalCertifications['+i+'].value');
                }
            }
        }

        console.log($scope.vm.formData.certification.additionalCertifications);

        //console.log($scope.vm.formData.certification.additionalCertifications);

        vm.appraiser = $scope.vm.appraisalOrder.appraiser;
        vm.appraiserUser = $scope.vm.appraiserUser;

        vm.appraiserCertification = [
            vm.appraiserUser.designationCRA ? 'CRA P.App' : null,
            vm.appraiserUser.designationAACI ? 'AACI P.App' : null,
            vm.appraiserUser.credentials
        ].filter(Boolean).join(', ');
    }
})();
