(function() {
    'use strict';

    angular
    .module('valueconnectApp')
    .controller('OriginatorAppraiserDirectNoteMessageboardController', OriginatorAppraiserDirectNoteMessageboardController);

    OriginatorAppraiserDirectNoteMessageboardController.$inject = ['$state', 'order', 'dashboard', 'hasInsertRole', '$mdDialog', 'account', 'AppraisalState'];

    function OriginatorAppraiserDirectNoteMessageboardController ($state, order, dashboard, hasInsertRole, $mdDialog, account, AppraisalState) {

        var vm = this;

        // Entities
        vm.hasInsertPermission = hasInsertRole && account.enableDirectMessageBoard;
        vm.order = order;
        vm.enabled = dashboard.enabled;
        vm.notes = dashboard.notes;
        vm.account = account;
        
        vm.orderAddress = [vm.order.address.address1, vm.order.address.cityName, vm.order.address.provinceName].filter(Boolean).join(', ');

        // Methods
        vm.createNote = createNote;
        vm.hasAnyState = hasAnyState;

        function createNote() {
            $mdDialog.show({
                templateUrl: 'app/entities/originator-appraiser-direct-note/originator-appraiser-direct-note-dialog.html',
                controller: 'OriginatorAppraiserDirectNoteDialogController',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                resolve: {
                    entity: function () {
                        return {
                            note: null,
                            read: false,
                            appraisalOrderId: vm.order.id
                        };
                    }
                }
            })
            .then(
                function(data) {
                    $state.go('originator-appraiser-direct-note-messageboard', null, { reload: true });
                }, function() {
                    $state.go('originator-appraiser-direct-note-messageboard');
                }
            );
        }

        function hasAnyState() {
            var states = Array.prototype.slice.call(arguments);
            return AppraisalState.inAnyState(vm.order, states);
        }
    }
})();
