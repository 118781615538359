(function() {
    'use strict';

    angular
    .module('valueconnectApp')
    .controller('AppraisalNoteMessageboardController', AppraisalNoteMessageboardController);

    AppraisalNoteMessageboardController.$inject = ['$scope', 'Principal', '$state', 'order', 'messages', 'notes', '$mdDialog', 'report', 'account', 'reportEditable', 'reportSubmittable'];

    function AppraisalNoteMessageboardController ($scope, Principal, $state, order, messages, notes, $mdDialog, report, account, reportEditable, reportSubmittable) {

        var vm = this;

        // Entities
        vm.order = order;
        vm.messages = messages;
        vm.notes = notes;
        vm.account = account;
        vm.report = report;

        vm.orderAddress = [vm.order.address.address1, vm.order.address.cityName, vm.order.address.provinceName].filter(Boolean).join(', ');

        // Flags
        vm.reportEditable = reportEditable;
        vm.reportSubmittable = reportSubmittable;
        vm.isCosigner = vm.account.appraiserUser && vm.report.cosignerId === vm.account.appraiserUser.id;

        // Methods
        vm.createNote = createNote;

        function createNote(isMessage, replyId) {
            $mdDialog.show({
                templateUrl: 'app/entities/appraisal-note/appraisal-note-dialog.html',
                controller: 'AppraisalNoteDialogController',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                resolve: {
                    entity: function () {
                        return {
                            note: null,
                            appraisalId: vm.order.id,
                            user: isMessage ? { login: '' } : null,
                            parent: replyId ? { id: replyId } : null,
                            isMessage: isMessage
                        };
                    }
                }
            })
            .then(
                function(data) {
                    $state.go('appraisal-note-messageboard', null, { reload: true });
                }, function() {
                    $state.go('appraisal-note-messageboard');
                }
            );
        }

    }
})();
