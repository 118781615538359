(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('UserManagementDialogController', UserManagementDialogController);

    UserManagementDialogController.$inject = ['$scope', '$stateParams', '$filter', '$mdDialog', 'entity', 'User', 'JhiLanguageService', 'Country', 'Brokerage', 'BrokerageRegion', 'BrokerageLocation', 'AppraisalFirm', 'Region', 'VCUser', 'AppraisalFirmLocation', 'Principal', 'Lender'];

    function UserManagementDialogController ($scope, $stateParams, $filter, $mdDialog, entity, User, JhiLanguageService, Country,  Brokerage, BrokerageRegion, BrokerageLocation, AppraisalFirm, Region, VCUser, AppraisalFirmLocation, Principal, Lender) {
        var vm = this;
        vm.user = entity;
        vm.isNew = entity.id === null;
        vm.languages = null;
        vm.countries = Country.query();
        vm.save = save;
        vm.cancel = cancel;
        vm.addAuthority = addAuthority;
        vm.removeAuthority = removeAuthority;
        vm.authoritiesTouched = false;
        vm.hasCCR = false;
        vm.isAdmin = false;
        vm.userBrokerage = null;
        vm.userBrokerageRegion = null;
        vm.userBrokerageLocation = null;
        vm.userAppraisalFirm = null;
        vm.authorityLocationInfo = authorityLocationInfo;
        vm.matchVcCcrName = matchVcCcrName;
        vm.matchLocation = matchLocation;
        vm.matchLocationNameFromID = matchLocationNameFromID;
        vm.isUserOriginator = isUserOriginator;
        vm.isDesignationIdInvalid = false;

        var appraiserRegex = /^ROLE_(APPRAISER|APPRAISAL).*/;
        var originatorRegex = /^ROLE_(ORIGINATOR).*/;
        var lenderRegex = /^ROLE_(LENDER).*/;

        getLenders();

        function getLenders() {

            // Fetch the compact JSON from the efficient API endpoint and
            // apply custom sorting to the data when promise is resolved.
            Lender.querySimpleAll({size: 2000}, function (lenders) {
                //#1 separate private and public lenders using brokerageName
                var publicLenders = [];
                var privateLenders = [];

                lenders.forEach(function (lender) {
                    lender.brokerageName === null ? publicLenders.push(lender) : privateLenders.push(lender);
                });

                //#2 sort private lenders using brokerageName
                privateLenders.sort(customCompareByBrokerageName);

                //#3 sort public lenders using lenderName
                publicLenders.sort(customCompareByLenderName);

                //#4 concat public with private and assign to vm.lenders
                vm.lenders = publicLenders.concat(privateLenders);
            });
        }

        // custom comparison function to sort first by brokerageName ascending, then by lenderName ascending, then by id ascending
        function customCompareByBrokerageName(lenderLeft, lenderRight) {
            var left = lenderLeft.brokerageName.toLowerCase();
            var right = lenderRight.brokerageName.toLowerCase();
            var value = left.localeCompare(right);
            if (value > 0) {
                return 1;
            } else if (value < 0) {
                return -1;
            } else {
                return customCompareByLenderName(lenderLeft, lenderRight);
            }
        }

        // custom comparison function to sort first by lenderName ascending, then by id ascending
        function customCompareByLenderName(lenderLeft, lenderRight) {
            var left = lenderLeft.lenderName.toLowerCase();
            var right = lenderRight.lenderName.toLowerCase();
            var value = left.localeCompare(right);
            if (value > 0) {
                return 1;
            } else if (value < 0) {
                return -1;
            } else {
                return customCompareByLenderId(lenderLeft, lenderRight);
            }
        }

        //custom comparison function to sort by lenderId ascending
        function customCompareByLenderId(lenderLeft, lenderRight) {
            if (lenderLeft.id < lenderRight.id) {
                return -1;
            } else if (lenderLeft.id > lenderRight.id) {
                return 1;
            } else {
                return 0;
            }
        }

        function isUserOriginator(){
            return originatorRegex.test(vm.user.authorities) || isOriginator();
        }

        Principal.matchAnyAuthority(/^ROLE_(ADMIN|CUSTOMER_CARE).*/i).then(function(resp) {
            vm.isAdmin = resp;
        });

        vm.user.entityAuthorities = vm.user.entityAuthorities.filter(function(auth) { return auth.authority !== "ROLE_USER"; });
        cleanEntityAuthorities();

        // Load data from server
        vm.regions = Region.query({ size: 1000, sort: 'name' });
        vm.vcCcrs = User.getByAuthority({authority:'ROLE_CUSTOMER_CARE_REPRESENTATIVE'}, {});
        vm.vcReviewers = User.getByAuthority({authority:'ROLE_VC_REVIEWER'}, {});

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
            vm.language = vm.languages[0];
        });

        function cancel () {
            $mdDialog.cancel('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $mdDialog.hide(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function matchVcCcrName(searchText) {
        	var searchRegex = new RegExp(searchText, 'i');
        	var filterVal;
        	if(vm.vcCcrs.length > 0) {
                 	filterVal = vm.vcCcrs.filter(function(vcCcr){
        			return searchRegex.test(vcCcr.vcUser.userFullName);
                });
                // map is necessary because required ID is in vm.vcCcrs.[index].vcUser.id
                // therefore array is used for search results but vcUser for items and ID
        		return filterVal.map(function (ccr){
        			return ccr.vcUser;
        		});
            }
        	return [];
        }

        function matchLocation(searchText) {
            var searchRegex = new RegExp(searchText, 'i');
            var filterVal;
        	if(vm.appraisalFirmLocations.length > 0) {
                 	filterVal = vm.appraisalFirmLocations.filter(function(location){
        			return searchRegex.test(location.officeName);
                });
                return filterVal.map(function(location){
                	return location.id;
                });
            }
        	return [];
        }

        function matchLocationNameFromID(locationID) {
            if (locationID != null && vm.appraisalFirmLocations.length > 0) {
                return vm.appraisalFirmLocations.find(function(firm) {
                    return firm.id == locationID;
                }).officeName;
            }
        }

        function decorateAuthority(auth) {
            if (auth.appraisalFirmId) {
                vm.appraisalFirmLocations = auth.appraisalFirmLocations = AppraisalFirmLocation.findByAppraisalFirm({id: auth.appraisalFirmId}, {});
                vm.userAppraisalFirm = auth.userAppraisalFirm = AppraisalFirm.get({id: auth.appraisalFirmId});
            }

            if (auth.lenderId) {
                vm.userLendingFirm = auth.userLendingFirm = Lender.get({id: auth.lenderId});
            }

            if (auth.brokerageLocationId) {
                vm.userBrokerageLocation = auth.userBrokerageLocation = BrokerageLocation.get({id: auth.brokerageLocationId}, function (data) {
                    vm.userBrokerageRegion = auth.userBrokerageRegion = BrokerageRegion.get({id: data.brokerageRegionId}, function (data) {
                        vm.userBrokerage = auth.userBrokerage = Brokerage.get({id: data.brokerageId});
                    });
                });
            }

            if (auth.brokerageRegionId) {
                vm.userBrokerageRegion = auth.userBrokerageRegion = BrokerageRegion.get({id:auth.brokerageRegionId}, function(data) {
                    vm.userBrokerage = auth.userBrokerage = Brokerage.get({id: data.brokerageId});
                });
            }

            if (auth.brokerageId) {
                vm.userBrokerage = auth.userBrokerage = Brokerage.get({id:auth.brokerageId});
            }
        }

        function authorityLocationInfo(authority) {
            if (authority.appraisalFirmId && authority.userAppraisalFirm) {
                return " - (Appraisal Firm:" + authority.appraisalFirmId + ") " + authority.userAppraisalFirm.name;
            }
            if (authority.lenderId && authority.userLendingFirm) {
                return " - (Lender: " + authority.lenderId + ") " + authority.userLendingFirm.lenderName +
                    (authority.userLendingFirm.disabled
                        ? ' (disabled)'
                        : ' ') +
                    (authority.userLendingFirm.brokerageName
                        ? ' (brokerage = ' + authority.userLendingFirm.brokerageName + ')'
                        : ' (public lender)');
            }
            if (authority.brokerageLocationId && authority.userBrokerage) {
                return " -"
                    + " (Brokerage: " + authority.userBrokerage.id + ") " + authority.userBrokerage.name
                    + ", (Region: " + authority.userBrokerageRegion.id + ") " + authority.userBrokerageRegion.name
                    + ", (Location: " + authority.brokerageLocationId + ") " + authority.userBrokerageLocation.officeName;
            } else if (authority.brokerageRegionId && authority.userBrokerage) {
                return " -"
                    + " (Brokerage: " + vm.userBrokerage.id + ") " + vm.userBrokerage.name
                    + ", (Region: " + vm.userBrokerageRegion.id + ") " + vm.userBrokerageRegion.name;
            } else if (authority.brokerageId && authority.userBrokerage) {
                return " -"
                    + " (Brokerage: " + vm.userBrokerage.id + ") " + vm.userBrokerage.name;
            }
        }

        function save () {
            if (!vm.user.entityAuthorities.filter(function(auth) { return auth.authority !== "ROLE_USER"; }).length) return;

            vm.isSaving = true;
            if (vm.user.id !== null) {
                User.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }

        function addAuthority(authority) {
            if (isLenderAdmin(authority)) {
                // For "Lender Administrator": Avoid duplicate lender ids.
                if (!vm.user.entityAuthorities.some(function (elem) {
                    return isLenderAdmin(elem) && elem.lenderId === authority.lenderId
                })) {
                    vm.user.entityAuthorities.push(authority);
                }
            } else {
                // For roles other than "Lender Administrator": Allow duplicate rows.
                vm.user.entityAuthorities.push(authority);
            }
        }

        function isLenderAdmin(authority) {
            return authority.authorityType === 'LENDING_FIRM' && authority.authority === 'ROLE_LENDER_ADMINISTRATOR';
        }

        function removeAuthority(index) {
            vm.user.entityAuthorities.splice(index, 1);
        }

        function isAppraiser() {
            return vm.user.entityAuthorities.some(function (auth) {
                return appraiserRegex.test(auth.authority);
            });
        }

        function isOriginator() {
            return vm.user.entityAuthorities.some(function (auth) {
                return originatorRegex.test(auth.authority);
            });
        }

        function isLender() {
            return vm.user.entityAuthorities.some(function (auth) {
                return lenderRegex.test(auth.authority);
            });
        }

        function cleanEntityAuthorities() {
            vm.hasCCR = isOriginator() || isAppraiser() || isLender();

            if (isAppraiser()) {
                if (!vm.user.appraiserUser) {
                    vm.user.appraiserUser = {
                        independent: false,
                        designationCRA: false,
                        designationAACI: false,
                        aicActive: true,
                        regionOverride: false,
                        cities: [],
                        currentReviewCount: 0
                    };
                }
            } else {
                if (vm.user.appraiserUser && !vm.user.appraiserUser.id) {
                    vm.user.appraiserUser = null;
                }
            }

            angular.forEach(vm.user.entityAuthorities, function (auth) {
                decorateAuthority(auth);
            });
        }

        $scope.$watch('vm.user.entityAuthorities.length', function (newVal, oldVal) {
            if (newVal !== oldVal) vm.authoritiesTouched = true;
            cleanEntityAuthorities();
        });

        $scope.$watch('vm.user.appraiserUser.designationId', function (newVal, oldVal) {
            if (newVal !== oldVal) {
                if (newVal.match(/^[a-zA-Z0-9]*$/)) {
                    vm.isDesignationIdInvalid = false;
                } else {
                    vm.isDesignationIdInvalid = true;
                }
            }
        });

    }
})();
