(function() {
    'use strict';

    angular.
        module('valueconnectApp').
        component('vcOrderSearch', {
            templateUrl: 'app/components/search/order-search.template.html',
            controller: vcOrderSearchController,
            controllerAs: 'vm',
            bindings: {
                ngModel: '='
            }
        });

    vcOrderSearchController.$inject = ['AppraisalOrder'];

    function vcOrderSearchController (AppraisalOrder) {
        var vm = this;
        vm.querySearch = querySearch;

        /**
         * Send a request to the server to query appraisal orders.
         * return empty array if no query has been entered.
         * TODO: filter the current appraisal order from results
         */
        function querySearch (query) {
            return AppraisalOrder.query({query: query}).$promise;
        }
    }
})();
