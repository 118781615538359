(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('UserManagementOriginatorController', UserManagementOriginatorController);

    UserManagementOriginatorController.$inject = ['User', 'pagingParams', 'paginationConstants', '$state', 'JhiLanguageService'];

    function UserManagementOriginatorController(User, pagingParams, paginationConstants, $state, JhiLanguageService) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.page = 1;
        vm.totalItems = null;
        vm.links = null;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;
        vm.users = [];

        vm.loadAll();

        function loadAll () {
            User.getOriginatorsPageable({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
            }, onSuccess, onError);
        }

        function onSuccess (data, headers) {
            // Hide anonymous user from user management: it's a required user for Spring Security
            // TODO: do this server-side
            for (var i in data) {
                if (data[i]['login'] === 'anonymoususer') {
                    data.splice(i, 1);
                }
            }
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.users = data;
        }

        function onError (error) {
            AlertService.error(error.data.message);
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
            });
        }

        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }
    }

})();
