(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('VCNoteDialogController', VCNoteDialogController);

    VCNoteDialogController.$inject = ['$scope', '$mdDialog', 'entity', 'VCNote', 'DataUtils', 'File', 'FileSaver'];

    function VCNoteDialogController ($scope, $mdDialog, entity, VCNote, DataUtils, File, FileSaver) {
        var vm = this;

        vm.vcNote = entity;

        vm.files = [];

        vm.downloadFile = downloadFile;
        vm.cancel = cancel;
        vm.save = save;

        function filePickerMultipleChanged(files, callback) {
            if (!files.length) {
                callback([]);
                return;
            }

            var encodedFiles = [];

            files.forEach(function(filePicker) {
                var file = filePicker.lfFile;

                // Convert file data and return array of promises
                DataUtils.toBase64(file, function(base64Data) {
                    $scope.$apply(function() {
                        encodedFiles.push({
                            contentType: file.type,
                            fileName: file.name,
                            fileData: base64Data
                        });

                        // Once all files have been encoded, excecute the callback
                        if (encodedFiles.length === files.length) {
                            callback(encodedFiles);
                        }
                    });
                });
            });
        }

        function downloadFile(file) {
            File.download({id: file.id}, function(response) {
                var fileData = new Blob([response.data], { type: file.contentType});
                FileSaver.saveAs(fileData, file.fileName);
            });
        }

        $scope.$watch('vm.files.length',function(newVal,oldVal){
            filePickerMultipleChanged(vm.files, function(files) {
                vm.vcNote.filesNew = files;
            });
        });

        function save () {
            vm.isSaving = true;
            VCNote.save(vm.vcNote, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $mdDialog.hide(vm.vcNote);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function cancel() {
            $mdDialog.cancel();
        }

    }
})();
