(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraisalFirmAddController', AppraisalFirmAddController);

    AppraisalFirmAddController.$inject = ['$timeout', '$translate', '$state', 'entity', 'User', 'Region', 'AppraisalFirm', 'AppraisalFirmLocation', 'Lender'];

    function AppraisalFirmAddController ($timeout, $translate, $state, entity, User, Region, AppraisalFirm, AppraisalFirmLocation, Lender) {
        var vm = this;

        vm.registerAccount = entity;
        vm.isNew = entity.id === null;

        vm.regions = Region.query({ size: 2000, sort: 'name' });

        //commented because not used
        //vm.firmLocations = AppraisalFirmLocation.query({ size: 2000 });

        vm.vcCcrs = User.getByAuthority({authority:'ROLE_CUSTOMER_CARE_REPRESENTATIVE'}, {});
        vm.vcReviewers = User.getByAuthority({authority:'ROLE_VC_REVIEWER'}, {});

        vm.register = register;
        vm.getSelectedRegionText = getSelectedRegionText;
        vm.matchVcCcrName = matchVcCcrName;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        getLenders();

        function getLenders() {

            // Fetch the compact JSON from the efficient API endpoint and
            // apply custom sorting to the data when promise is resolved.
            Lender.querySimpleAll({size:2000},function(lenders) {

                //#1 separate private and public lenders using brokerageName
                var publicLenders = [];
                var privateLenders = [];

                lenders.forEach( function(lender) {
                    lender.brokerageName === null ? publicLenders.push(lender) : privateLenders.push(lender);
                });

                //#2 sort private lenders using brokerageName
                privateLenders.sort(customCompareByBrokerageName);

                //#3 sort public lenders using lenderName
                publicLenders.sort(customCompareByLenderName);

                //#4 concat public with private and assign to vm.lenders
                vm.lenders = publicLenders.concat(privateLenders);
            });
        }

        // custom comparison function to sort first by brokerageName ascending, then by lenderName ascending, then by id ascending
        function customCompareByBrokerageName(lenderLeft, lenderRight) {
            var left = lenderLeft.brokerageName.toLowerCase();
            var right = lenderRight.brokerageName.toLowerCase();
            var value = left.localeCompare(right);
            if (value > 0) {
                return 1;
            } else if (value < 0) {
                return -1;
            } else {
                return customCompareByLenderName(lenderLeft, lenderRight);
            }
        }

        // custom comparison function to sort first by lenderName ascending, then by id ascending
        function customCompareByLenderName(lenderLeft, lenderRight) {
            var left = lenderLeft.lenderName.toLowerCase();
            var right = lenderRight.lenderName.toLowerCase();
            var value = left.localeCompare(right);
            if (value > 0) {
                return 1;
            } else if (value < 0) {
                return -1;
            } else {
                return customCompareByLenderId(lenderLeft, lenderRight);
            }
        }

        //custom comparison function to sort by lenderId ascending
        function customCompareByLenderId(lenderLeft, lenderRight) {
            if (lenderLeft.id < lenderRight.id) {
                return -1;
            } else if (lenderLeft.id > lenderRight.id) {
                return 1;
            } else {
                return 0;
            }
        }

        function matchVcCcrName(searchText) {
        	var searchRegex = new RegExp(searchText, 'i');
        	var filterVal;
        	if(vm.vcCcrs.length > 0) {
        			filterVal = vm.vcCcrs.filter(function(vcCcr){
        			return searchRegex.test(vcCcr.vcUser.userFullName);
                });
                // map is necessary because required ID is in vm.vcCcrs.[index].vcUser.id
                // therefore arary is used for search results but vcUser for items and ID
        		return filterVal.map(function (ccr){
        			return ccr.vcUser;
        		});
            }
        	return [];
        }

        function register () {
            vm.registerAccount.langKey = $translate.use();
            vm.registerAccount.login = vm.registerAccount.email;

            //Make sure the booleans have a value
            vm.registerAccount.appraiserUser.regionOverride = false;
            vm.registerAccount.appraiserUser.designationCRA = vm.registerAccount.appraiserUser.designationCRA === true;
            vm.registerAccount.appraiserUser.designationAACI = vm.registerAccount.appraiserUser.designationAACI === true;

            AppraisalFirm.registerFirm(vm.registerAccount).$promise.then(function () {
                $state.go('appraisal-firm', null, { reload: true });
            });
        }

        function getSelectedRegionText() {
            return (vm.registerAccount.regionsServiced || []).map(function(region) {
                return region.name;
            }).join(',');
        }

    }
})();
