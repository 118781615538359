(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('TemplateDialogController', TemplateDialogController);

    TemplateDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$mdDialog', 'entity', 'Template', 'TemplateSearch', 'AlertService', 'formType', 'section'];

    function TemplateDialogController ($timeout, $scope, $stateParams, $mdDialog, entity, Template, TemplateSearch, AlertService, formType, section) {
        var vm = this;

        vm.template = null;
        vm.newTemplate = entity;
        vm.templateName = null;
        vm.templateShared = null;
        vm.close = close;
        vm.save = save;
        vm.formType = formType;
        vm.section = section;

        vm.searchTemplates = searchTemplates;

        function searchTemplates(searchText) {
            return TemplateSearch.query({ query: vm.searchQuery, formType: vm.formType, section: vm.section }).$promise;
        }

        function close() {
            $mdDialog.cancel();
        };

        $scope.$watch('vm.template', function() {
            if(vm.template) {
                vm.templateName = vm.template.name;
                vm.templateShared = vm.template.shared;
            }
        })

        function save () {
            vm.template = vm.template ? vm.template : vm.newTemplate;
            vm.template.data = vm.newTemplate.data;
            vm.template.name = vm.templateName ? vm.templateName : vm.template.name;
            if(vm.templateShared != null) {
                vm.template.shared = vm.templateShared;
            }
            vm.isSaving = true;
            if (vm.template.id) {
                var confirm = $mdDialog.confirm()
                  .title('Overwrite Existing Template')
                  .textContent('Are you sure you want to overwrite this template?')
                  .ok('Save')
                  .cancel('Discard Changes');

                  $mdDialog.show(confirm).then(function() {
                      Template.update(vm.template, onSaveSuccess, onSaveError);
                  });
            } else {
                Template.save(vm.template, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('valueconnectApp:templateUpdate', result);
            $mdDialog.hide(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            AlertService.error("Unable to save template");
            vm.isSaving = false;
        }
    }
})();
