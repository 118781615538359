(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('ProgressInspectionCertificationController', ProgressInspectionCertificationController);

    ProgressInspectionCertificationController.$inject = ['$scope'];

    function ProgressInspectionCertificationController($scope) {
        var vm = this;

        vm.appraiser = $scope.vm.appraisalOrder.appraiser;
        vm.appraiserUser = $scope.vm.appraiserUser;

        vm.appraiserCertification = [
            vm.appraiserUser.designationCRA ? 'CRA P.App' : null,
            vm.appraiserUser.designationAACI ? 'AACI P.App' : null,
            vm.appraiserUser.credentials
        ].filter(Boolean).join(', ');
    }
})();
