(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraisalOrderDialogController', AppraisalOrderDialogController);

    AppraisalOrderDialogController.$inject = ['$scope', '$state', '$stateParams' , '$q', 'entity', 'AppraisalOrder', 'Enum', '$filter', 'User', 'Principal', 'LocalStorage', 'AlertService', '$window', '$mdDialog', '$timeout', 'LenderIntegrationBlacklist', 'Opta', '$location'];

    function AppraisalOrderDialogController ($scope, $state, $stateParams , $q, entity, AppraisalOrder, Enum, $filter, User, Principal, LocalStorage, AlertService, $window, $mdDialog, $timeout, LenderIntegrationBlacklist, Opta, $location) {

        // Initialize Entity
        var vm = this;
        vm.appraisalOrder = entity;
        vm.isNewOrder = vm.appraisalOrder.id === null;
        vm.isLenderRefNumRequired = false;
        vm.enum = Enum;
        vm.users = User.query();
        vm.isOriginatorAccepted = vm.appraisalOrder.state
            && !['ORDER_DRAFTED', 'QUOTE_REQUIRED', 'PENDING_QUOTE'].includes(vm.appraisalOrder.state)
            && !Principal.hasAnyAuthority(["ROLE_CUSTOMER_CARE_REPRESENTATIVE", "ROLE_ADMIN"]);
        if (!vm.isNewOrder)
            vm.appraisalOrder.whatIstoBeAppraised = vm.appraisalOrder.housePlusLotSize ? 'HOUSE_PLUS_LAND' : 'HOUSE_ONLY';

        vm.avmTypes = ['AVM_BASIC', 'AVM', 'AVM_COMPARABLES'];

        // Navigation
        vm.visitedSections = [];
        setSection("client-info");
        vm.sections = sections;
        vm.setSection = setSection;
        vm.goNext = goNext;
        vm.goPrev = goPrev;
        vm.canGoNext = canGoNext;
        vm.canGoPrev = canGoPrev;
        vm.sectionInvalid = sectionInvalid;
        vm.isIntegrationOrder = null;
        vm.getNewOrderFrontendUrl = getNewOrderFrontendUrl;

        // Filters
        vm.needsLoanPurchaseFiles = needsLoanPurchaseFiles;
        vm.needsLenderInfo = needsLenderInfo;
        vm.needsAppraiserFirmSelection = needsAppraiserFirmSelection;
        vm.needsMortgageType = needsMortgageType;
        vm.needsAvmHighConfidence;
        vm.isAVM = isAVM;

        vm.canValidateAddress = canValidateAddress;

        resetAppraisalTypeOptions();
        vm.showMoreOptionsAppraisalType = ['PORT', 'ASSET_VALUE_VERIFICATION', 'DEFICIENCY_SALE_WORKOUT', 'RELOCATION', 'LEGAL_PROCEEDING', 'VALUE_ESTIMATE',
            'ESTIMATE_MARKET_RENT', 'HELOC', 'CONSTRUCTION', 'ETO', 'SWITCH_TRANSFER'].includes(vm.appraisalOrder.appraisalType);
        vm.showMoreOptionsAppraisalTypeOptions = vm.appraisalTypeOptions[1] && vm.appraisalTypeOptions[1].includes(vm.appraisalOrder.appraisalTypeOptions);
        vm.showMoreOptionsZoning = ['COMMERCIAL', 'AGRICULTURAL', 'LODGING_HOUSE', 'OTHER'].includes(vm.appraisalOrder.zoning);
        vm.showMoreOptionsOccupiedBy = ['OTHER'].includes(vm.appraisalOrder.occupiedBy);
        vm.appraisalTypeOptionsDisabled = [];

        vm.financingUse = ['VALUE_ESTIMATE', 'ESTIMATE_MARKET_RENT'].includes(vm.appraisalOrder.appraisalType) && vm.appraisalOrder.lender !== null;

        // Changes
        vm.appraisalTypeUpdated = appraisalTypeUpdated;
        vm.appraisalTypeOptionsUpdated = appraisalTypeOptionsUpdated;
        vm.financingUseUpdated = financingUseUpdated;
        vm.mortgageTypeUpdated = mortgageTypeUpdated;
        vm.legalProceedingTypeUpdated = legalProceedingTypeUpdated;
        vm.whatIstoBeAppraisedUpdated = whatIstoBeAppraisedUpdated;
        vm.addressUpdated = addressUpdated;
        vm.validateAddress = validateAddress;
        vm.addressUpdatedTimer = null;

        // Actions
        vm.findError = findError;
        vm.restoreProgress = restoreProgress;
        vm.saveProgress = saveProgress;
        vm.clearSection = clearSection;
        vm.canSave = canSave;
        vm.save = save;
        vm.isValidAddress = false;

        // Init
        if (vm.appraisalOrder.$promise) {
            vm.appraisalOrder.$promise.then(function () {
                init();
            });
        } else {
            init();
        }



        function init() {
            vm.appraisalOrder.optionFlags = {};
            vm.orderOptions = [];

            // If an originator with no account rep, send to the home view
            Principal.identity().then(function (identity) {
                vm.identity = identity;
                vm.canRestoreProgress = identity && LocalStorage.HasModel('appraisal_order_' + identity.id);
                Principal.matchAnyAuthority(/^ROLE_(ORIGINATOR).*/).then(function (result) {
                    if (result && identity.ccr === null) {
                        $state.go("home");
                    }
                });

                if (vm.appraisalOrder.lender != null && vm.appraisalOrder.lender.lenderName != null) {
                    User.getIntegrationId({id: identity.id}).$promise.then(function (value) {
                        Promise.resolve(value).then(function (resolved) {
                            vm.isIntegrationOrder = resolved.data;
                            LenderIntegrationBlacklist.isBlackListedByName({
                                integrationID: resolved.data,
                                lenderName: vm.appraisalOrder.lender.lenderName
                            }).$promise.then(function (value) {
                                Promise.resolve(value).then(function (resolved) {
                                    vm.displayLenderPrompt = resolved.data;
                                });
                            });
                        });
                    });
                }
            });

            // Check if current user is from VC
            Principal.matchAnyAuthority(/^ROLE_(CUSTOMER_CARE|ADMIN).*/).then(function (result) {
                vm.isVC = result;
                if (vm.isVC && vm.isNewOrder) vm.activeSection = "originating-user";
            });

            if (vm.appraisalOrder.id !== null) {
                vm.appraisalOrderManage = AppraisalOrder.getManage({id: vm.appraisalOrder.id}).$promise.then(function (result) {
                    vm.appraisalOrder.coAgentId = result.coAgentId;
                });
            }

            // Grab the list of Order Options for saving
            Enum.$promise.then(function (data) {
                vm.orderOptions = data.OrderOption;
                angular.forEach(vm.orderOptions, function (value) {
                    if (vm.appraisalOrder.options && $filter('filter')(vm.appraisalOrder.options, {name: value}, true).length) {
                        vm.appraisalOrder.optionFlags[value] = true;
                    }
                });
            });
        }

        $scope.$watch('vm.displayLenderPrompt', function (displayLenderPrompt) {

            if (displayLenderPrompt === "true") {
                //display modal
                $mdDialog.show(
                    $mdDialog.alert()
                        .clickOutsideToClose(true)
                        .title('Attention')
                        .textContent('Thank you for choosing Value Connect. Please be advised, due to the lender requirements Value Connect is unable to assist ')
                        .ok('Okay')
                ).then(function () {
                    $state.go('home');
                });
            }
        });

        // sets a flag to display an error if the lender selected for this order (through integration) is blacklisted for us
        $scope.$watch('vm.appraisalOrder.lender', function (lender) {
            if (vm.identity != null && vm.identity.id != null && typeof lender !== 'undefined' && lender != null) {
                User.getIntegrationId({id: vm.identity.id}).$promise.then(function (value) {
                    Promise.resolve(value).then(function (resolved) {
                        vm.isIntegrationOrder = resolved.data;
                        LenderIntegrationBlacklist.isBlackListedByName({
                            integrationID: resolved.data,
                            lenderName: lender.lenderName
                        }).$promise.then(function (value) {
                            Promise.resolve(value).then(function (resolved) {
                                vm.displayLenderPrompt = resolved.data;
                            });
                        });
                    });
                });
            }
        });

        // Reset the appraisl types when the address changes to ensure AVM is validated
        $scope.$watch('vm.appraisalOrder.address', function () {
            // Ensure there is a slight delay before resetting in case the user is still typing
            $timeout.cancel(vm.addressUpdatedTimer);
            vm.addressUpdatedTimer = $timeout(function () {
                addressUpdated();
            }, 1000);
        }, true);

        // Navigation
        function sections() {
            var sections = [];

            if (vm.isVC && vm.isNewOrder) sections.push("originating-user"); // VC
            sections.push("client-info", "appraisal-info", "property-info"); // BASE
            if (!vm.isAVM()) sections.push("property-access-contacts")
            if (vm.needsLenderInfo()) sections.push("lender-info"); // LENDER
            if (vm.needsAppraiserFirmSelection()) sections.push("appraiser-selection"); // LENDER
            if (!vm.isAVM()) sections.push("additional-files"); // FILES

//            Remove property-access-contacts section when Appraisal Type is AVM
            if (/^AVM.*/.test(vm.appraisalOrder.appraisalTypeOptions)) {
                sections = sections.filter(function (value, index, arr) {
                    return value != "property-access-contacts";
                });
            }

            return sections;
        };

        function setSection(section) {
            gtag("event", "active_section_changed", {
                prev_section: vm.activeSection,
                new_section: section});

            vm.activeSection = section;
            if (!vm.visitedSections.includes(vm.activeSection)) {
                vm.visitedSections.push(vm.activeSection);
            }
            $window.scrollTo(0, 0);
        }

        function goNext() {
            var sections = vm.sections();
            vm.setSection(sections[sections.indexOf(vm.activeSection) + 1]);
        }

        function goPrev() {
            var sections = vm.sections();
            vm.setSection(sections[sections.indexOf(vm.activeSection) - 1]);
        }

        function canGoNext() {
            var sections = vm.sections();
            return sections.indexOf(vm.activeSection) < sections.length - 1;
        }

        function canGoPrev() {
            var sections = vm.sections();
            return sections.indexOf(vm.activeSection) > 0;
        }

        function sectionInvalid(section) {
            return vm.visitedSections.includes(section) && $scope.appraisalForm[section].$invalid;
        }

        // Filters
        function needsLoanPurchaseFiles() {
            return ['PURCHASE', 'PURCHASE_AND_IMPROVEMENTS'].includes(vm.appraisalOrder.appraisalType);
        }

        function needsLenderInfo() {
            if (/^AVM.*/.test(vm.appraisalOrder.appraisalTypeOptions)) {
                return false;
            } else {
                return ['PURCHASE', 'RELOCATION', 'REFINANCE', 'PURCHASE_AND_IMPROVEMENTS', 'HELOC', 'REFINANCE_AND_IMPROVEMENTS',
                        'PORT', 'ASSET_VALUE_VERIFICATION', 'DEFICIENCY_SALE_WORKOUT', 'CONSTRUCTION', 'ETO', 'SWITCH_TRANSFER'].includes(vm.appraisalOrder.appraisalType)
                    || vm.financingUse;
            }
        }

        function needsAppraiserFirmSelection() {
            return !/^AVM.*/.test(vm.appraisalOrder.appraisalTypeOptions) && vm.appraisalOrder.address && vm.appraisalOrder.address.postCode && vm.appraisalOrder.lender && vm.appraisalOrder.lender.allowAppraisalFirmSelection && vm.identity.appraisal_firm_selection && ['ORDER_DRAFTED', 'QUOTE_REQUIRED', 'PENDING_QUOTE', 'PENDING_PAYMENT', undefined].includes(vm.appraisalOrder.state);
        }

        function needsMortgageType() {
            if (/^AVM.*/.test(vm.appraisalOrder.appraisalTypeOptions)) {
                return false;
            } else {
                return vm.appraisalOrder.appraisalType && !['RELOCATION', 'LEGAL_PROCEEDING', 'VALUE_ESTIMATE', 'ESTIMATE_MARKET_RENT', 'ETO', 'AVM'].includes(vm.appraisalOrder.appraisalType);
            }
        }

        function isAVM() {
            return /^AVM.*/.test(vm.appraisalOrder.appraisalTypeOptions);
        }

        function validateAddress() {
            var reqDto = {
                RequestHeader: {},
                RequestBody: {
                    Property: [{
                        Address: {
                            StreetAddress:
                                (vm.appraisalOrder.address.address2 ? vm.appraisalOrder.address.address2 + ' - ' : '')
                                + vm.appraisalOrder.address.number + ' '
                                + vm.appraisalOrder.address.name + ' '
                                + vm.appraisalOrder.address.type,
                            Municipality: vm.appraisalOrder.address.city.name,
                            Province: vm.appraisalOrder.address.province && vm.appraisalOrder.address.province[0] ? vm.appraisalOrder.address.province[0].name : '',
                            PostalCode: vm.appraisalOrder.address.postCode
                        }
                    }]
                }
            };

            Opta.getAutomatedValuationConfidenceRating(reqDto).$promise.then(function (res) {

                vm.isValidAddress = (res.data === 'HIGH');
                if(!vm.isValidAddress){
                    $mdDialog.show(
                        $mdDialog.alert()
                            .clickOutsideToClose(true)
                            .title('Please Note')
                            .textContent('AVM report is not available for this address. Please verify if the address is correct or select another appraisal type.')
                            .ariaLabel('AVM report not available for address')
                            .ok('Okay')
                    )
                }
            }, function () {
                vm.isValidAddress = false;
                if(vm.isValidAddress){
                    $mdDialog.show(
                        $mdDialog.alert()
                            .clickOutsideToClose(true)
                            .title('Please Note')
                            .textContent('We are sorry for the inconveninence, but we cannont validate the address at the moment, please try again later.')
                            .ariaLabel('AVM address validation is not working at the moment')
                            .ok('Okay')
                    )
                }
            });
        }

        function canValidateAddress() {
            return isAVM()
                && $scope.appraisalForm["property-info"].$valid
                && vm.activeSection === "property-info"
                && !vm.isValidAddress;
        }

        function needsAvmHighConfidence() {

            var address = vm.appraisalOrder.address;

            var result = $q.defer();

            // Ensure the address is complete
            if (address
                && address.number
                && address.name
                && address.type
                && address.postCode
                && address.provinceId
                && address.city) {

                var reqDto = {
                    RequestHeader: {},
                    RequestBody: {
                        Property: [{
                            Address: {
                                StreetAddress:
                                    (address.address2 ? address.address2 + ' - ' : '')
                                    + address.number + ' '
                                    + address.name + ' '
                                    + address.type,
                                Municipality: address.city.name,
                                Province: address.province && address.province[0] ? address.province[0].name : '',
                                PostalCode: address.postCode
                            }
                        }]
                    }
                };

                Opta.getAutomatedValuationConfidenceRating(reqDto).$promise.then(function (res) {
                    result.resolve(res.data === 'HIGH');
                }, function () {
                    result.resolve(false);
                });
            } else {
                result.resolve(false);
            }

            return result.promise;
        }

        function resetAppraisalTypeOptions() {
            vm.appraisalTypeOptionsDisabled = [];

            if (vm.appraisalOrder.appraisalType === 'RELOCATION') {
                vm.appraisalTypeOptions = [['RESIDENTIAL_NARRATIVE']];
            } else if (vm.appraisalOrder.appraisalType === 'ESTIMATE_MARKET_RENT') {
                vm.appraisalTypeOptions = [['STANDALONE_MARKET_RENT', 'MARKET_RENT_CONSULTING']];
            } else {
                vm.appraisalTypeOptions = [['RESIDENTIAL_FULL_REPORT', 'STANDALONE_MARKET_RENT', 'DRIVEBY', 'RESIDENTIAL_INSPECTION_REPORTS'],
                    ['RESIDENTIAL_LAND_APPRAISAL', 'RESIDENTIAL_MULTI_RESIDENT', 'AVM', 'AVM_COMPARABLES', 'DESKTOP', 'MARKET_RENT_CONSULTING', 'RESIDENTIAL_NARRATIVE']];
            }
        }

        // Changes that require more logic adjustments than simply saving progress
        function appraisalTypeUpdated() {
            // Reset conditional field values
            if (vm.appraisalOrder.appraisalType !== 'LEGAL_PROCEEDING') vm.appraisalOrder.legalProceedingType = null;

            // Don't reset financingPurpose if the current value is still valid
            if (!vm.needsMortgageType()) {
                vm.appraisalOrder.financingPurpose = null;
            }

            // Reset flags and options
            resetAppraisalTypeOptions();
            clearLegalFlags();
            clearRelocationFlags();

            // Reset financingUse flag if appraisalType not estimate
            if (!['VALUE_ESTIMATE', 'ESTIMATE_MARKET_RENT'].includes(vm.appraisalOrder.appraisalType)) {
                vm.financingUse = false;
            }

            // Clear lender info if not needed
            if (!vm.needsLenderInfo()) {
                clearLenderInfo();
            }

            // Reset Market Rents option flag if not needed
            if (vm.appraisalOrder.appraisalType === 'ESTIMATE_MARKET_RENT' || ['STANDALONE_MARKET_RENT', 'MARKET_RENT_CONSULTING'].includes(vm.appraisalOrder.appraisalTypeOptions)) {
                vm.appraisalOrder.optionFlags.MARKET_RENTS = undefined;
            }

            saveProgress();
        }

        function addressUpdated() {
            resetAppraisalTypeOptions();
        }

        function financingUseUpdated() {
            if (!vm.needsLenderInfo()) {
                clearLenderInfo();
            }

            saveProgress();
        }

        function appraisalTypeOptionsUpdated() {
            // Reset Market Rents option flag if not needed
            if (vm.appraisalOrder.appraisalType === 'ESTIMATE_MARKET_RENT' || ['STANDALONE_MARKET_RENT', 'MARKET_RENT_CONSULTING'].includes(vm.appraisalOrder.appraisalTypeOptions)) {
                vm.appraisalOrder.optionFlags.MARKET_RENTS = undefined;
            } else if (/^AVM.*/.test(vm.appraisalOrder.appraisalTypeOptions)) {
                vm.appraisalOrder.appraisalType = undefined;
                vm.appraisalOrder.appraisalOrderContacts = [];
                appraisalTypeUpdated();
                vm.sections = sections;
            }else if(!/^AVM.*/.test(vm.appraisalOrder.appraisalTypeOptions) && vm.appraisalOrder.appraisalType === 'AVM'){
                vm.appraisalOrder.appraisalType = undefined;
                appraisalTypeUpdated();
            }
            saveProgress();
        }

        function mortgageTypeUpdated() {
            if (!vm.needsLenderInfo()) {
                clearLenderInfo();
            }
            saveProgress();
        }

        function legalProceedingTypeUpdated() {
            clearLegalFlags();
            saveProgress();
        }

        function whatIstoBeAppraisedUpdated() {
            vm.appraisalOrder.housePlusLotSize = vm.appraisalOrder.whatIstoBeAppraised === 'HOUSE_ONLY' ? null : vm.appraisalOrder.housePlusLotSize;
            vm.appraisalOrder.lotSizeUnits = vm.appraisalOrder.whatIstoBeAppraised === 'HOUSE_ONLY' ? null : vm.appraisalOrder.lotSizeUnits;
            saveProgress();
        }

        function clearLegalFlags() {
            vm.appraisalOrder.optionFlags.LEGAL_SEPARATION_OCCUPATIONAL_RENTS = undefined;
            vm.appraisalOrder.optionFlags.LEGAL_SEPARATION_RETROSPECTIVE_MORE_3YR = undefined;
            vm.appraisalOrder.optionFlags.LEGAL_SEPARATION_RETROSPECTIVE_LESS_3YR = undefined;
            vm.appraisalOrder.optionFlags.LEGAL_SEPARATION_IMPROVEMENTS_SINCE = undefined;
            vm.appraisalOrder.optionFlags.LEGAL_SEPARATION_COURT_APPEARANCE_APPRAISER = undefined;
        }

        function clearRelocationFlags() {
            vm.appraisalOrder.optionFlags.RELOCATION_FLOOR_PLAN = undefined;
            vm.appraisalOrder.optionFlags.RELOCATION_DRIVE_BY_AND_PIC_COMPS = undefined;
            vm.appraisalOrder.optionFlags.RELOCATION_IMPROVEMENTS_HYPOTHETICAL = undefined;
            vm.appraisalOrder.optionFlags.RELOCATION_EXTERIOR_SKETCHES = undefined;
        }

        function clearLenderInfo() {
            vm.appraisalOrder.loanReferenceNumber = null;
            vm.appraisalOrder.loanAmount = null;
            vm.appraisalOrder.lender = null;
            vm.appraisalOrder.lenderUnderwriterContact = null;
        }

        // Actions
        function findError() {
            var errorKeys = Object.keys($scope.appraisalForm.$error);
            if (errorKeys.length) {
                var firstErrKey = errorKeys[0];
                if ($scope.appraisalForm.$error[firstErrKey].length) {
                    var invalidSection = $scope.appraisalForm.$error[firstErrKey][0]
                    vm.setSection(invalidSection.$name);
                    var invalidField = invalidSection.$error[firstErrKey][0];
                    if (invalidField.$name === 'addressForm') invalidField = invalidField.$error[firstErrKey][0];
                    var el = angular.element("[name='" + invalidField.$name + "']");
                    el.addClass('find-error');
                    el.focus();
                }
            }
        }

        function restoreProgress() {
            vm.appraisalOrder = JSON.parse(LocalStorage.GetModel('appraisal_order_' + vm.identity.id));
            vm.canRestoreProgress = false;
        }

        function updateLenderReferenceNumber() {
            var elem = document.getElementById("field_loanReferenceNumber");
            if (elem) {
                if (elem.required) {
                    if (elem.value.trim().length === 0) {
                        elem.classList.remove("ng-valid");
                        elem.classList.remove("ng-valid-required");
                        elem.classList.add("ng-invalid");
                        elem.classList.add("ng-invalid-required");
                        elem.setAttribute("aria-invalid", "true");
                        elem.setAttribute("isvalid", "false");
                    } else {
                        elem.classList.add("ng-valid");
                        elem.classList.add("ng-valid-required");
                        elem.classList.remove("ng-invalid");
                        elem.classList.remove("ng-invalid-required");
                        elem.setAttribute("aria-invalid", "false");
                        elem.setAttribute("isvalid", "true");
                    }
                } else {
                    elem.classList.add("ng-valid");
                    elem.classList.remove("ng-valid-required");
                    elem.classList.remove("ng-invalid");
                    elem.classList.remove("ng-invalid-required");
                    elem.setAttribute("aria-invalid", "false");
                    elem.setAttribute("isvalid", "true");
                }
            }
        }

        function saveProgress() {
            updateLenderReferenceNumber();
            // Save appraisal order to local storage if new order
            if (vm.isNewOrder && vm.identity) {
                var temp = angular.copy(vm.appraisalOrder);
                delete temp['agreementPurchaseNew'];
                delete temp['mlsPropertySheetNew'];
                delete temp['supportingDocumentationNew'];
                LocalStorage.SaveModel('appraisal_order_' + vm.identity.id, JSON.stringify(temp));
            }
        }

        function clearSection() {
            var model = $scope.appraisalForm[vm.activeSection];
            for (var prop in model) {
                if (model.hasOwnProperty(prop) && !prop.startsWith('$')) {
                    setAppraisalOrderProperty(prop, null);
                }
            }
            saveProgress();
        }

        function canSave() {
            var validAddress;
            var hasFiles = (
                (vm.appraisalOrder.agreementPurchase && vm.appraisalOrder.agreementPurchase.length) ||
                (vm.appraisalOrder.agreementPurchaseNew && vm.appraisalOrder.agreementPurchaseNew.length));
            if(isAVM()) {
                validAddress = vm.isValidAddress;
            } else {
                validAddress = true;
            }
            return validAddress && $scope.appraisalForm && !$scope.appraisalForm.$invalid &&
                (!vm.needsLoanPurchaseFiles() || hasFiles) &&
                (!vm.isLenderRefNumRequired || (vm.isLenderRefNumRequired && vm.appraisalOrder.loanReferenceNumber.trim().length > 0));
        }

        function save() {
            vm.isSaving = true;

            // set md-autocomplete values from objects to ids
            vm.appraisalOrder.submittingAgentId = vm.submittingAgent ? vm.submittingAgent.id : null;

            // Convert empty strings to null for enums
            vm.appraisalOrder.ownership = vm.appraisalOrder.ownership || null;
            vm.appraisalOrder.occupiedBy = vm.appraisalOrder.occupiedBy || null;
            vm.appraisalOrder.existingUse = vm.appraisalOrder.existingUse || null;
            vm.appraisalOrder.newConstruction = vm.appraisalOrder.newConstruction || null;
            vm.appraisalOrder.propertyType = vm.appraisalOrder.propertyType || null;
            vm.appraisalOrder.propertyStyle = vm.appraisalOrder.propertyStyle || null;
            vm.appraisalOrder.livingSpaceUnit = vm.appraisalOrder.livingSpaceUnit || null;
            vm.appraisalOrder.lotSizeUnits = vm.appraisalOrder.lotSizeUnits || null;
            vm.appraisalOrder.garageSize = vm.appraisalOrder.garageSize || null;
            vm.appraisalOrder.garageType = vm.appraisalOrder.garageType || null;

            // For AVM option in Appraisal Type options , Set the Appraisal Type as AVM
            if (vm.appraisalOrder.appraisalType !== 'AVM' && /^AVM.*/.test(vm.appraisalOrder.appraisalTypeOptions)){
                vm.appraisalOrder.appraisalType = 'AVM';
                // TODO: Currently the Zoning is hardcoded to RESIDENTIAL for all AVM - Appraisal Type Options
                vm.appraisalOrder.zoning = 'RESIDENTIAL';
                // TODO: Currently the Ownership is hardcoded to FREEHOLD for all AVM options selected so any other fees don't apply
                vm.appraisalOrder.ownership = 'FREEHOLD';
            }

            // Clear out the other text if not applicable
            vm.appraisalOrder.zoningOther = vm.appraisalOrder.zoning === 'OTHER' ? vm.appraisalOrder.zoningOther : null;
            vm.appraisalOrder.firstMortgageLoanAmount = ['SECOND_MORTGAGE', 'THIRD_MORTGAGE'].includes(vm.appraisalOrder.financingPurpose) ? vm.appraisalOrder.firstMortgageLoanAmount : null;

            // Update the options
            vm.appraisalOrder.options = [];
            angular.forEach(vm.orderOptions, function (value) {
                if (vm.appraisalOrder.optionFlags && vm.appraisalOrder.optionFlags[value] === true) {
                    vm.appraisalOrder.options.push({name: value});
                }
            });

            var serverRequest = vm.appraisalOrder.id !== null ? AppraisalOrder.update : AppraisalOrder.save;
            if (vm.appraisalOrder.id === null && !isAVM()) {
                $mdDialog.show(
                    $mdDialog.alert()
                        .clickOutsideToClose(true)
                        .title('Please Note')
                        .textContent('Industry best practice recommends you advise the client that access to the property will be required.')
                        .ariaLabel('Property Access Contact')
                        .ok('Okay')
                ).then(function () {
                    serverRequest(vm.appraisalOrder, onSaveSuccess, onSaveError);
                });
            } else {
                serverRequest(vm.appraisalOrder, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('valueconnectApp:appraisalOrderUpdate', result);
            vm.isSaving = false;

            if (vm.isNewOrder) {
                Principal.identity().then(function (identity) {
                    LocalStorage.ClearModel('appraisal_order_' + identity.id);
                });
            }
            $state.go('appraisal-order-detail', {id: result.id});
        }

        function onSaveError() {
            vm.isSaving = false;
            AlertService.error("valueconnectApp.appraisalOrder.edit.errorSaving");
        }

        function setAppraisalOrderProperty(path, value) {
            var schema = vm.appraisalOrder;
            var pList = path.split('.');
            var len = pList.length;
            for (var i = 0; i < len - 1; i++) {
                var elem = pList[i];
                if (!schema[elem]) schema[elem] = {}
                schema = schema[elem];
            }
            schema[pList[len - 1]] = value;
        }

        //automatically hits the findError function upon entry
        var findErrorBtn = "#findErrorBtn";
        $scope.$watch(function () {
            return angular.element(findErrorBtn).is(':visible')
        }, function () {
            //TODO: make a better check here for integration
            if ($stateParams.isSSO) {
                $timeout(function () {
                    findError();
                }, 100);
            }
        });

        function invalidatedAddressIfChanged(newValue, oldValue) {
            if(isAVM() && vm.isValidAddress && newValue !== oldValue){
                vm.isValidAddress = false;
            }
        }

        function getNewOrderFrontendUrl() {
            var currentUrl = $location.absUrl(); // Get the full current URL
            var newUrl = currentUrl.replace('/#/appraisal-order', '/v2/appraisal-order');
            return newUrl;
        }

        $scope.$watch('vm.appraisalOrder.address.address1', function(newValue, oldValue){
            invalidatedAddressIfChanged(newValue, oldValue);
        })

        $scope.$watch('vm.appraisalOrder.address.address2', function(newValue, oldValue){
            invalidatedAddressIfChanged(newValue, oldValue);
        })

        $scope.$watch('vm.appraisalOrder.address.postCode', function(newValue, oldValue){
            invalidatedAddressIfChanged(newValue, oldValue);
        })

        $scope.$watch('vm.appraisalOrder.address.provinceId', function(newValue, oldValue){
            invalidatedAddressIfChanged(newValue, oldValue);
        })
        $scope.$watch('vm.appraisalOrder.address.cityId', function(newValue, oldValue){
            invalidatedAddressIfChanged(newValue, oldValue);
        })

    }
})();
