(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('NewsNotificationController', NewsNotificationController);

    NewsNotificationController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'News', 'entity', 'Notification'];

    function NewsNotificationController ($timeout, $scope, $stateParams, $uibModalInstance, News, entity, Notification) {
        var vm = this;

        vm.clear = clear;
        vm.save = save;
        vm.news = entity;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;           
            Notification.acknowledgeNotification(vm.news, onSaveSuccess, onSaveError);
        }   
        
        function onSaveSuccess (result) {
            $scope.$emit('valueconnectApp:newsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }      
        
        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
