/**
 * Directive to display validation errors for the wrapped input.
 *
 * The validation update is triggered by the 'validationsUpdated' event, which should pass a map of
 * field validations.
 *
 * This directive assumes it is contained in a parent form, and the that each top-level section of
 * the form has its own ng-model field. In the case of the report builder, that field is the
 * section validation icon.
 *
 */
(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .directive('serverValidation', ['$stateParams', '$log', serverValidation]);

    function serverValidation ($stateParams, $log) {
        var directive = {
            restrict: 'A',
            link: link,
            transclude: true,
            // Include template inline so DOM can be accessed in the parent scope immediately after post-link
            template: "\
                <ng-transclude></ng-transclude>\
                <div ng-messages='input.$error' multiple md-auto-hide='true' class='vc-server-errors'>\
                    <div ng-message='vc-required' translate='entity.validation.required'></div>\
                    <div ng-message='vc-min' translate='entity.validation.min' translate-values='{ min: errors.min.params[0] }'></div>\
                    <div ng-message='vc-max' translate='entity.validation.max' translate-values='{ max: errors.max.params[0] }'></div>\
                </div>",
            scope: {}
        };

        return directive;

        function link(scope, elm, attrs) {
            // Locate the wrapped input control
            var inputSelector = "[ng-model]";
            var inputCtrl = elm.find(inputSelector).controller('ngModel');

            if(!inputCtrl) {
                $log.error("server-validation could not locate an input field using selector '"+inputSelector+"'", elm);
                return;
            } else {
                scope.input = inputCtrl;
            }

            // Get the ngModelController for the current section
            var formName = $stateParams.form;

            // Determine the field key for this input; account for linked fields
            var fieldKey = formName + '.' + inputCtrl.$name;
            if(fieldKey.endsWith('$')) {
                fieldKey = fieldKey.substr(0, fieldKey.lastIndexOf('.$'));
            }

            // Update validation status whenever new validations are recieved
            scope.$on("validationsUpdated", function(event, validations, sectionData) {

                // Do nothing if the current section has not been started
                if(!sectionData.$started) return;

                // Retrive the errors for this field
                var errors = validations[fieldKey] ? (validations[fieldKey].errors || []) : [];
                elm.toggleClass('vc-input-invalid', errors.length > 0);

                // Add the new error objects to the scope
                scope.errors = {};
                errors.forEach(function(error) {
                    scope.errors[error.errorType] = error;
                });

                // Remove existing validation errors with the 'vc-' prefix
                Object.keys(inputCtrl.$error).filter(function(errorType) {
                    return errorType.startsWith('vc-');
                }).forEach(function(errorType) {
                    inputCtrl.$setValidity(errorType, true);
                });

                // Add any new validation errors using a 'vc-' prefix
                Object.keys(scope.errors).forEach(function(errorType) {
                    inputCtrl.$setValidity('vc-' + errorType, false);
                    inputCtrl.$setTouched();
                });
            });
        }
    }
})();
