(function() {
    'use strict';

    angular.
        module('valueconnectApp.validationSummary', [])
        .component('validationSummary', {
            templateUrl: 'app/components/dynamic-form/validation-summary.template.html',
            bindings: {
                validationErrors: '=?',
                reportId: '<?',
                editLinks: '@?',
                hasErrorsText: '@',
                noErrorsText: '@'
            },
            controllerAs: 'vm',
            controller: ['$log', '$scope', '$stateParams', '$q', 'AppraisalReport', 'DynamicForm',
            function validationSummary($log, $scope, $stateParams, $q, AppraisalReport, DynamicForm) {
                var vm = this;
                vm.loading = true;
                vm.showEditLinks = angular.isDefined(vm.editLinks) ? true : false;

                // Wait for promise to reslove before initializing
                if(vm.validationErrors && vm.validationErrors.$promise) {
                    vm.validationErrors.$promise.then(init);
                } else if(vm.reportId) {
                    vm.validationErrors = AppraisalReport.validateForms({id: vm.reportId});
                    vm.validationErrors.$promise.then(init);
                } else {
                    $log.error("validation-summary requires either validationErrors or reportId to be set");
                }

                function init() {
                    // Check if there are validation errors
                    vm.hasValidationErrors = Object.keys(vm.validationErrors).filter(function(key) {
                        return !key.startsWith('$') && vm.validationErrors[key].length > 0;
                    }).length > 0;

                    // Map out error keys for collection validationErrors
                    var hasArray = /\[\d+\]/;
                    Object.keys(vm.validationErrors)
                        .filter(function(key) { return !key.startsWith('$'); })
                        .forEach(function(formName) {
                            vm.validationErrors[formName] = vm.validationErrors[formName].map(function(field) {
                                field.collectionFields = [];
                                var result;

                                // Remove array indices from field path, and populate an array of parent collections
                                while((result = hasArray.exec(field.fieldKey)) !== null) {
                                    var key = field.fieldKey;
                                    var collectionIndex = parseInt(result[0].replace('[', '').replace(']', ''));
                                    field.collectionFields.push({
                                        fieldKey: key.substring(0, result.index),
                                        index: angular.isNumber(collectionIndex) ? (collectionIndex+1) : ''
                                    });
                                    field.fieldKey = field.fieldKey.replace(result[0], '');
                                }

                                return field;
                            });
                        });

                    // Perform a manual check for depreciation match on costApproach and stdRes forms
                    // TODO: Use a built-in validation and remove this once supported
                    validateDepreciation().then(function(result) {
                        if(result) return;
                        vm.validationErrors.costApproach.push({
                            fieldKey: 'costApproach.depreciation',
                            errors: [{
                                errorType: 'match',
                                params: [
                                    vm.costValue ? ((vm.costValue*100) + '%') : null,
                                    vm.stdResValue ? ((vm.stdResValue*100).toString() + '%') : null
                                ]
                            }]
                        });
                    });

                    vm.loading = false;
                }

                // Perform a manual check for depreciation match on costApproach and stdRes forms
                // Returns a promise that is resolved with the result
                // TODO: Use a built-in validation and remove this once supported
                function validateDepreciation() {
                    var reportId = vm.reportId || $stateParams.id;
                    if(angular.isUndefined(vm.validationErrors.costApproach)) return $q.when(true);
                    if(angular.isUndefined(vm.validationErrors.standardResidential)) return $q.when(false);
                    return $q.all({
                        standardResidential: DynamicForm.get({ reportId: reportId, formName: 'standardResidential' }).$promise,
                        costApproach: DynamicForm.get({ reportId: reportId, formName: 'costApproach' }).$promise
                    }).then(function(reportData) {
                        var improvementData = reportData.standardResidential.improvements;

                        if(improvementData) {
                            var overrideStringValue = improvementData.depreciationOverride ? improvementData.depreciationOverride.replace(/[^\d.-]/g, '') : null;
                            var overrideValue = (parseFloat(overrideStringValue) || 0) / 100;
                            vm.stdResValue = overrideValue || improvementData.depreciation;
                        } else {
                            vm.stdResValue = undefined;
                        }

                        vm.costValue = reportData.costApproach.depreciation;
                        return vm.stdResValue === vm.costValue;
                    });
                }
            }]
        });
})();
