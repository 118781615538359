(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('BrokerageAddController', BrokerageAddController);

    BrokerageAddController.$inject = ['$timeout', '$scope', '$translate', '$state', '$stateParams', 'entity', 'Region', 'Brokerage', 'NationalBrokerage', 'BrokerageLocation', 'Auth', 'LoginService', 'User', 'Principal'];

    function BrokerageAddController ($timeout, $scope, $translate, $state, $stateParams, entity, Region, Brokerage, NationalBrokerage, BrokerageLocation, Auth, LoginService, User, Principal) {
        var vm = this;

        vm.brokerage = entity;
        vm.isNew = entity.id == null
        vm.save = save;

        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.errorInvalidPassword = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.registerAccount = {};
        vm.success = null;
        vm.nationalBrokerages = NationalBrokerage.query({size:2000});
        vm.regions = Region.query({ size: 2000, sort: 'name' });

        Principal.matchAnyAuthority(/^ROLE_(ADMIN).*/i).then(function(result) {
            vm.isAdmin = result;
            vm.vcCcrs = User.getByAuthority({authority:'ROLE_CUSTOMER_CARE_REPRESENTATIVE'}, {});
        });


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function register () {
        	 if (vm.registerAccount.password !== vm.confirmPassword) {
                vm.doNotMatch = 'ERROR';
            } else {
                vm.registerAccount.langKey = $translate.use();

                Brokerage.registerBrokerage(vm.registerAccount, function () {
                    vm.success = 'OK';
                    $state.go('brokerage', null, { reload: true });
                });
            }
            console.log(vm.error);
        }

        function save () {
        	alert("eytrey");
        	/*
            vm.isSaving = true;
            if (vm.brokerage.id !== null) {
               Brokerage.update(vm.brokerage, onSaveSuccess, onSaveError);
            } else {
                Brokerage.save(vm.brokerage, onSaveSuccess, onSaveError);
            }
            */
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $scope.$emit('valueconnectApp:brokerageUpdate', result);

            if(vm.isNew) {
                $state.go('brokerage', null, { reload: true });
            }
            else {
                $state.go('.', {id: result.id}, {reload: true})
            }
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        $scope.getSelectedRegionText = function() {
            var regions = [];
            if (vm.registerAccount.regionsServiced == undefined) {
                return "";
            } else {
                for(var i = 0; i < vm.registerAccount.regionsServiced.length; i++) {
                    regions.push(vm.registerAccount.regionsServiced[i].name);
                }
                return regions.join(', ');
            }
        }
    }
})();
