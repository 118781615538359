(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .directive('vcAutoSelect', ['$timeout', '$q', vcAutoSelect]);
    function vcAutoSelect ($timeout, $q) {
        var directive = {
            restrict: 'E',
            link:  postLink,
            template:
            '<md-autocomplete class="vc-auto-select"' +
            '   md-input-name="{{name}}"' +
            '   md-search-text="searchText"' +
            '   md-search-text-change="searchTextChangeWrapperFn()"' +
            '   md-selected-item="model"' +
            '   md-selected-item-change="itemChangeWrapperFn()"' +
            '   md-items="item in queryFn(searchText)"' +
            '   md-item-text="translationFn(item)"' +
            '   ng-blur="selectLastOnBlur()"' +
            '   md-match-case-insensitive="true"' +
            '   md-no-cache="true"' +
            '   md-min-length="0"' +
            '   md-require-match="required"' +
            '   ng-disabled="disabled"' +
            '   placeholder="{{placeholder | translate}}">' +
            '       <md-item-template><span md-highlight-text="searchText" md-highlight-flags="^i">{{translationFn(item)}}</span></md-item-template>' +
            '       <md-not-found>No matches found.</md-not-found>' +
            '   </md-autocomplete>',
            scope: {
                items               : '<',
                queryFn             : '<',
                model               : '=',
                itemChangeFn        : '<',
                searchTextChangeFn  : '<',
                translationFn       : '<',
                name                : '@',
                placeholder         : '@',
                required            : '<',
                disabled            : '<'
            }
        };

        return directive;

        function postLink(scope, elm, attrs) {

            // Wrap items as queryFn
            if (!scope.queryFn && scope.items) scope.queryFn = wrapItems;

            scope.itemChangeWrapperFn = itemChangeWrapperFn;
            scope.searchTextChangeWrapperFn = searchTextChangeWrapperFn;
            scope.selectLastOnBlur = selectLastOnBlur;

            function itemChangeWrapperFn() {
                if (scope.itemChangeFn) {
                    $timeout(function() {
                        scope.itemChangeFn(scope.model);
                    });
                }
            }

            function searchTextChangeWrapperFn() {
                if (scope.searchTextChangeFn) {
                    $timeout(function() {
                        scope.searchTextChangeFn(scope.searchText);
                    });
                }
            }

            function selectLastOnBlur() {
                if (!scope.searchText) return;

                scope.queryFn(scope.searchText).then(function(results) {
                    if (results.length === 1) {
                        scope.model = results[0];
                    } else if (results.length !== 0) {
                        scope.model = results.find(function (item) { return scope.translationFn(item).toLowerCase() === scope.searchText.toLowerCase(); })
                    }
                });
            }

            scope.$watch('items', function() {
                if (angular.isDefined(scope.items)) scope.queryFn = wrapItems;
            });

            function wrapItems(searchText) {
                var searchQuery = angular.isUndefined(searchText) ? '' : searchText;
                var searchRegex = new RegExp(searchQuery, 'i');

                return !scope.items ? $q.when([]) : $q.when(
                    scope.items.filter(function(item) {
                        return searchRegex.test(scope.translationFn(item));
                    })
                );
            }
        }
    }
})();
