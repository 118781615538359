(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('StandaloneMarketRentLocationMapController', StandaloneMarketRentLocationMapController);

    StandaloneMarketRentLocationMapController.$inject = ['$log', '$scope'];

    function StandaloneMarketRentLocationMapController($log, $scope) {
        var geocoder = new google.maps.Geocoder();

        var vm = this;

        var sectionData = null;
        vm.editMode = false;
        vm.attempts = 0;

        vm.initAddress = initAddress;
        vm.resetAddressPin = resetAddressPin;
        vm.resetPin = resetPin;
        vm.mapSaved = mapSaved;

        // Initialize model using form data
        $scope.vm.appraisalOrder.$promise.then(function() {
            init(null, $scope.vm.formData);
            $scope.$on('formDataReinitialized', init);
        });

        function init(event, formData) {
            sectionData = formData.locationMap;

            var comparables = (formData.comparableRentData.comparableSubjects || [])
                .filter(function(subject) { return subject.address; });

            // Populate the comprableLocations array with geocoded addresses
            vm.comparableLocations = [];
            comparables.forEach(function(subject, index) {
                initAddress(subject, index);
            });
        }

        function initAddress(subject, index) {
            // Generate a string for the geocode request
            var address = getAddress(subject);

            // Send geocode API request
            geocoder.geocode({
                address: address,
                region: "ca"
            }, function(results, status) {
                var position = results.length ? results[0].geometry.location : null;
                // Add this comperable as a pin on the map if it exists or can be geocoded
                var existingMarker = sectionData.comparableMarkers.find(function(marker) { return !marker.__deleted__ && marker.comparableId === subject.__id__; });
                if (existingMarker) {
                    position = new google.maps.LatLng(existingMarker.lat, existingMarker.lng);
                }
                vm.comparableLocations.push({
                    comparableId: subject.__id__,
                    label: (index + 1).toString(),
                    address: address,
                    position: position
                });
            });
        }

        /**
         * Delete existing markers and add a new set for the current map
         */
        function mapSaved() {
            // Delete existing markers
            // TODO: update an existing marker with the same comperableId
            sectionData.comparableMarkers = sectionData.comparableMarkers.filter(function(marker) {
                var isExisting = !!marker.__id__;
                if(isExisting) marker.__deleted__ = true;
                return isExisting;
            });

            // Save a new set of markers
            vm.comparableLocations.forEach(function(comparable) {
                sectionData.comparableMarkers.push({
                    comparableId: function() { return comparable.comparableId; },
                    lat: function() { return comparable.position.lat(); },
                    lng: function() { return comparable.position.lng(); },
                    label: function() { return comparable.label; },
                    distanceToSubject: function() {
                        return google.maps.geometry.spherical.computeDistanceBetween(comparable.position, new google.maps.LatLng($scope.vm.appraisalOrder.address.lat, $scope.vm.appraisalOrder.address.lng));
                    }
                });
            });

            // Set dirty for hidden fields updated by google-map-input
            var hiddenFieldNames = ['locationMap.centerLat', 'locationMap.centerLng', 'locationMap.zoom'];
            angular.forEach(hiddenFieldNames, function(fieldName) {
                var field = $scope.editForm[fieldName];
                if (!field) {
                    $log.error('Cannot update non-existent field:', fieldName);
                } else {
                    field.$setDirty();
                }
            });
        }

        function resetAddressPin() {
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode({
                address: $scope.vm.getAddress($scope.vm.formData.subject),
                region: "ca"
            }, function(results, status) {
                var position = results.length ? results[0].geometry.location : null;
                if (position) {
                    $scope.vm.appraisalOrder.address.lat = position.lat();
                    $scope.vm.appraisalOrder.address.lng = position.lng();
                    $scope.$apply();
                }
            });
        }

        function resetPin(index) {
            geocoder.geocode({
                address: vm.comparableLocations[index].address,
                region: "ca"
            }, function(results, status) {
                vm.comparableLocations[index].position = results.length ? results[0].geometry.location : null;
                $scope.$apply();
            });
        }

        function getAddress(subject) {
            var streetAddress = subject.address || ((subject.address2) ? subject.address2 + " - " + subject.address1 : subject.address1);
            return [
                streetAddress,
                subject.city || $scope.vm.appraisalOrder.address.cityName,
                $scope.vm.appraisalOrder.address.provinceName,
                subject.postCode
            ].filter(Boolean).join(', ');
        }
    }
})();
