(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('QuoteSplitDialogController', QuoteSplitDialogController);

    QuoteSplitDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'QuoteSplit', 'Quote', 'Principal'];

    function QuoteSplitDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, QuoteSplit, Quote, Principal) {
        var vm = this;

        vm.isVC = Principal.hasAnyAuthority([/^ROLE_(ADMIN|VC|CUSTOMER_CARE).*/]);
        vm.quoteSplit = entity;
        vm.originatorAmountInvalid = originatorAmountInvalid;
        vm.adjustmentReasonNotSelected = adjustmentReasonNotSelected;

        vm.adjustmentReasonEnums = [
            { label: 'Invoice', value: "INVOICE" },
            { label: 'EFT', value: "ELECTRONIC_FUNDS_TRANSFER" },
            { label: 'Paypal', value: "PAYPAL" },
            { label: 'Progress Order', value: "PROGRESS_ORDER" },
        ]

        if (vm.quoteSplit.adjustmentReason !== undefined) {
            vm.selectedTab = 3;
        } else if (vm.quoteSplit.originatorAmount === 0) {
            vm.selectedTab = 0;
        } else if (vm.quoteSplit.originatorAmount === vm.quoteSplit.totalAmount) {
            vm.selectedTab = 1;
        } else {
            vm.selectedTab = 2;
        }

        vm.clear = clear;
        vm.save = save;

        function originatorAmountInvalid() {
            return vm.quoteSplit.originatorAmount && (vm.quoteSplit.originatorAmount < 0 || vm.quoteSplit.originatorAmount > vm.quoteSplit.totalAmount);
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;

            var selectedTabTitle = "";

            switch (vm.selectedTab) {
                case 0: // Client to Pay
                    vm.quoteSplit.originatorAmount = 0;
                    selectedTabTitle = "Client to Pay";
                    break;
                case 1: // Brokerage to Pay
                    vm.quoteSplit.originatorAmount  = vm.quoteSplit.totalAmount;
                    selectedTabTitle = "Brokerage to Pay";
                    break;
                case 2: // Client And Brokerage to Pay
                    selectedTabTitle = "Client And Brokerage to Pay";
                    break;
                default: // Adjustment
                    selectedTabTitle = "Adjustment";
                    break;
            }

            gtag("event", "quote_split_saved", {split_selected: selectedTabTitle});

            QuoteSplit.update({
                quoteId: vm.quoteSplit.quoteId,
                originatorAmount: vm.quoteSplit.originatorAmount,
                clientContact: vm.quoteSplit.clientContact,
                adjustmentReason: vm.quoteSplit.adjustmentReason
            }, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('valueconnectApp:quoteSplitUpdate', result.originatorSplit);
            $uibModalInstance.close(result.originatorSplit);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function adjustmentReasonNotSelected() {
            return vm.selectedTab === 3 && vm.quoteSplit.adjustmentReason === undefined;
        }

    }
})();
