(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('sso', {
            parent: 'app',
            url: '/sso/{accessKey}?appraisalOrderId&edit',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', 'Integration', 'LenderIntegrationBlacklist', 'Auth', 'User', 'AlertService', 'Principal', function($stateParams, $state, Integration, LenderIntegrationBlacklist, Auth, User, AlertService, Principal) {
                Integration.verifySSO({ accessKey: $stateParams.accessKey }).$promise
                    .then(function() {
                        //this is where we get sent to the terms page from
                        return Auth.authorize(true);
                    }).then(function() {
                        Principal.identity().then(function(user){
                            if ($stateParams.appraisalOrderId) {
                                var target = $stateParams.edit === true || $stateParams.edit === 'true' ? 'appraisal-order.edit' : 'appraisal-order-detail';
                                return $state.go(target, { 'id' : $stateParams.appraisalOrderId, 'isSSO': true});
                            } else {
                                return $state.go('appraisal-order.new');
                            }

                        });
                    }).catch(function(err) {
                        AlertService.error("error.sso.invalid", {});
                        Auth.logout();
                        $state.go('login');
                    });
            }]
        })
        .state('authorize-grant-request', {
            parent: 'app',
            url: '/grant-request/{token}?integrationName',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', 'Integration', 'Auth', 'AlertService', 'Principal', function($stateParams, $state, Integration, Auth, AlertService, Principal) {

                Integration.authorizeGrantRequest({ token: $stateParams.token }).$promise
                    .then(function(resp) {
                        console.log(resp.translation);
                        AlertService.success(resp.translation, {});
                        return $state.go('login');
                    }).catch(function(err) {
                        AlertService.error("error.sso.invalid", {});
                        Auth.logout();
                        $state.go('login');
                    });
            }]
        });
    }
})();
