(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .factory('AuthServerProvider', AuthServerProvider);

    AuthServerProvider.$inject = ['$http'];

    function AuthServerProvider ($http) {
        var service = {
            login: login,
            logout: logout
        };

        return service;

        function login (credentials) {

            const auth = firebase.auth();

            return auth.signInWithEmailAndPassword(
                credentials.email,
                credentials.password
            );
        }

        function logout () {
            return $http.post('api/logout');
        }
    }
})();
