angular
    .module('valueconnectApp')
    .component('languageSwitcher', {
        templateUrl: 'app/components/languageSwitcher/language-switcher.html',
        controller: ['$scope', 'JhiLanguageService', 'Account', 'Principal', LanguageSwitcher]
    });

function LanguageSwitcher(scope, languageService, accountService, principal) {
    scope.changeLanguage = function(newLanguage) {
        principal.identity().then(
            function(account) {
                if(account != null) {
                    account.langKey = newLanguage;
                    accountService.save(account);
                }
            }
        );
        languageService.setLang(newLanguage);
    }
}
