(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('RiskRuleDetailController', RiskRuleDetailController);

    RiskRuleDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'RiskRule', 'Lender'];

    function RiskRuleDetailController($scope, $rootScope, $stateParams, previousState, entity, RiskRule, Lender) {
        var vm = this;

        vm.riskRule = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('valueconnectApp:riskRuleUpdate', function(event, result) {
            vm.riskRule = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
