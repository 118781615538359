(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('MarketRentConsultingLocationMapController', MarketRentConsultingLocationMapController);

    MarketRentConsultingLocationMapController.$inject = ['$log', '$scope'];

    function MarketRentConsultingLocationMapController($log, $scope) {
        var vm = this;

        vm.editMode = false;
        vm.mapSaved = mapSaved;
        vm.getAddress = getAddress;
        vm.resetAddressPin = resetAddressPin;

        function mapSaved() {
            // Set dirty for hidden fields updated by google-map-input
            var hiddenFieldNames = ['locationMap.centerLat', 'locationMap.centerLng', 'locationMap.zoom'];
            angular.forEach(hiddenFieldNames, function(fieldName) {
                var field = $scope.editForm[fieldName];
                if (!field) {
                    $log.error('Cannot update non-existent field:', fieldName);
                } else {
                    field.$setDirty();
                }
            });
        }

        function getAddress() {
            var streetAddress = (($scope.vm.appraisalOrder.address.address2) ? $scope.vm.appraisalOrder.address.address2 + " - " + $scope.vm.appraisalOrder.address.address1 : $scope.vm.appraisalOrder.address.address1);
            return [
                streetAddress,
                $scope.vm.appraisalOrder.address.cityName,
                $scope.vm.appraisalOrder.address.provinceName,
                $scope.vm.appraisalOrder.address.postCode
            ].filter(Boolean).join(', ');
        }

        function resetAddressPin() {
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode({
                address: getAddress(),
                region: "ca"
            }, function(results, status) {
                var position = results.length ? results[0].geometry.location : null;
                if (position) {
                    $scope.vm.appraisalOrder.address.lat = position.lat();
                    $scope.vm.appraisalOrder.address.lng = position.lng();
                    $scope.$apply();
                }
            });
        }
    }
})();
