(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('SettingsController', SettingsController);

    SettingsController.$inject = ['$scope', '$sessionStorage', '$state', 'Principal', 'Auth', 'JhiLanguageService', '$translate', 'Region', 'Lender', 'DataUtils'];

    function SettingsController ($scope, $sessionStorage, $state, Principal, Auth, JhiLanguageService, $translate, Region, Lender, DataUtils) {
        var vm = this;

        vm.error = null;
        vm.save = save;
        vm.settingsAccount = null;
        vm.success = null;
        vm.lenders = Lender.query({size:2000});

        // Create arrays to store form data
        vm.signatureFile = [];
        vm.profileFile = [];

        $scope.$watch('vm.signatureFile.length',function(newVal,oldVal) {
            filePickerChanged(vm.signatureFile, function(fileModel) {
                vm.settingsAccount.signatureFileNew = fileModel[0];
            });
        });

        $scope.$watch('vm.profileFile.length',function(newVal,oldVal) {
            filePickerChanged(vm.profileFile, function(fileModel) {
                vm.settingsAccount.profileFileNew = fileModel[0];
            });
        });

        /**
         * Store the "settings account" in a separate variable, and not in the shared "account" variable.
         */
        var copyAccount = function (account) {
            return {
                activated: account.activated,
                disabled: account.disabled,
                email: account.email,
                firstName: account.firstName,
                langKey: account.langKey,
                lastName: account.lastName,
                cellNumber: account.cellNumber,
                phoneNumber: account.phoneNumber,
                login: account.login,
                emailNotification: account.emailNotification,
                textNotification: account.textNotification,
                internalMessagingNotification: account.internalMessagingNotification,
                pushNotification: account.pushNotification,
                appraiserLenders: account.appraiserLenders,
                profileFile: account.profileFile,
                signatureFile: account.signatureFile,
                appraiserUser: account.appraiserUser
            };
        };

        Principal.identity().then(function(account) {
            vm.settingsAccount = copyAccount(account);
            vm.isAppraiserUser = vm.settingsAccount.appraiserUser;
            vm.settingsAccount.emailNotification = true; // make it clear that email notifications are always on
        });

        function filePickerChanged(files, callback) {
            if(!files.length) {
                callback([]);
                return;
            }

            // Get the newly added file
            var file = files[0].lfFile;

            // Convert file data and return promise
            DataUtils.toBase64(file, function(base64Data) {
                $scope.$apply(function() {
                    callback([{
                        contentType: file.type,
                        fileName: file.name,
                        fileData: base64Data
                    }]);
                });
            });
        }

        function save () {
            Auth.updateAccount(vm.settingsAccount).then(function() {
                vm.error = null;
                vm.success = 'OK';
                Principal.identity(true).then(function(account) {
                    vm.settingsAccount = copyAccount(account);
                });
                JhiLanguageService.getCurrent().then(function(current) {
                    if (vm.settingsAccount.langKey !== current) {
                        JhiLanguageService.setLang(vm.settingsAccount.langKey);
                    }
                });
                if($sessionStorage.previousState) {
                    $state.go($sessionStorage.previousState.name, $sessionStorage.previousState.params);
                }
            }).catch(function() {
                vm.success = null;
                vm.error = 'ERROR';
            });
        }
    }
})();
