angular.module("valueconnectApp")
	.directive("sandboxToggle", function() {
		return {
			restrict: 'A',
			link: link,
			scope: {
				trigger: '@'
			}
		}

		function link(scope, element, attrs) {
			var trigger = $(scope.trigger);

			element.addClass('panel-toggle');

			trigger.on("click", function() {
				element.toggleClass("sandbox-show", !element.hasClass("sandbox-show"));
			});
		}
	});
