(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('ConcernCCRController', ConcernCCRController);

    ConcernCCRController.$inject = ['$timeout', '$scope', '$stateParams', 'Principal', '$uibModalInstance', 'entity', 'Concern', '$uibModal' ];

    function ConcernCCRController ($timeout, $scope, $stateParams, Principal, $uibModalInstance, entity, Concern, $uibModal) {
    	
    	 var vm = this;    	 
    	 vm.concerns =  entity;   	
    	
    	 
    	 $scope.reply = function (id, name, email) {
    		
         var modalInstance = $uibModal.open({
             templateUrl: 'app/entities/concern/concern-dialog-reply.html',
             controller: 'ConcernDialogReplyController',
             controllerAs: 'vm',
             backdrop: 'static',
             size: 'lg',           
             resolve: {                   	
               entity: function () {
                   return {
                           concern :null,
                	       id: id,
                           name: name,
                           email: email                          
                       }
                   } 
               }                 
              
            }).result.then(function() {
                $state.go('home', null, { reload: true });
            }, function() {
             	$state.go('home');
            });  
             
         }; 
         

      }
})();
