(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AsIsController', AsIsController);

    AsIsController.$inject = ['$scope', '$translate', '$mdDialog', '$timeout'];

    function AsIsController($scope, $translate, $mdDialog, $timeout) {
        var adjustmentFields = ['dateOfSale','daysOnMarket','location','siteSize','buildingType','design','ageAndCondition','liveableFloorArea','parking','room','basement','bathroom'];

        // Initialize model
        var vm = this;
        var formData = null;

        vm.addOption = addOption;
        vm.removeOption = removeOption;
        vm.addSubject = addSubject;

        $scope.vm.reportData.standardResidential.$promise.then(function(reportData) {
            vm.reportData = reportData;
        });

        // Initialize model using form data
        init(null, $scope.vm.formData);
        $scope.$on('formDataReinitialized', init);

        function init(event, newData) {
            formData = newData;

            // Initalize each comparable
            formData.comparableSubjects.forEach(function(subject) {
                initSubject(subject, formData.customOptions.length);
            });
        }

        /**
         * Add a new custom option and mark the name as dirty to force it to persist
         */
        function addOption() {
            var newIndex = formData.customOptions.length;
            formData.customOptions.push({ name: '' });
            $scope.vm.setDirty('asIs.customOptions['+newIndex+'].name');

            formData.comparableSubjects.forEach(function(subject) {
                subject.customOptions.push({});
            });
        }

        function removeOption(index) {
            $scope.vm.removeFromArray(formData.customOptions, index).then(function() {
                formData.comparableSubjects.forEach(function(subject) {
                    $scope.vm.removeItem(subject.customOptions, index);
                });
            });
        }

        function addSubject() {
            var numOptions = formData.customOptions.length;
            formData.comparableSubjects.push(initSubject({}, numOptions));
        }

        /**
         * Initialize the provided comparable object
         * @param  {Object} subject    The comparable to initalize
         * @param  {Number} numOptions The number of custom options
         * @return {Object}            The initialized comparable
         */
        function initSubject(subject, numOptions) {
            // Make sure arrays are initialized
            if(!subject.customOptions) subject.customOptions = [];

            // Initalize custom options to match the numOptions length
            while(subject.customOptions.length < numOptions) {
                subject.customOptions.push({});
            }

            // Init calculated values
            subject.liveableFloorAreaAdjustment = function() {
                if(!$scope.vm.formData.liveableFloorArea || !subject.liveableFloorArea) return null;
                var adjustment = ($scope.vm.formData.liveableFloorArea - subject.liveableFloorArea) * (formData.pricePerArea || 0);
                return Math.round(adjustment/100)*100;
            };

            subject.dollarAdjustment = function() {
                return getAdjustments(subject).reduce(sum, 0);
            };
            subject.grossAdjustment = function() {
                return subject.salePrice ? getAdjustments(subject).map(Math.abs).reduce(sum, 0)/subject.salePrice : null;
            };
            subject.netAdjustment = function() {
                return subject.salePrice ? subject.dollarAdjustment()/subject.salePrice : null;
            };
            subject.adjustedValue = function() {
                return (subject.salePrice || 0) + subject.dollarAdjustment();
            };


            return subject;

            /**
             * Get a list of all adjustment values for the provided subject
             */
            function getAdjustments(subject) {
                return adjustmentFields.map(function(field) {
                    var fieldValue = subject[field + 'Adjustment'];
                    return angular.isFunction(fieldValue) ? fieldValue() : (fieldValue || 0);
                });
            }

            function sum(a,b) {
                return a + b;
            }
        }
    }
})();
