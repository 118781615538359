(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('OrderSearchDialogController', OrderSearchDialogController);

    OrderSearchDialogController.$inject = ['$mdDialog'];

    function OrderSearchDialogController ($mdDialog) {
        var vm = this;
        vm.selectedOrder = null;

        vm.cancel = function($event) {
          $mdDialog.cancel();
        };
        vm.finish = function($event) {
            $mdDialog.hide(vm.selectedOrder);
        };
    }
})();
