(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .constant('paginationConstants', {
            'itemsPerPage': 20,
            'templateUrl': 'app/layouts/pager/pager.template.html'
        });
})();
