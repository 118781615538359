(function() {
   'use strict';
    angular
        .module('valueconnectApp')
        .factory('Brokerage', Brokerage);

    Brokerage.$inject = ['$resource'];

    function Brokerage ($resource) {
        var resourceUrl =  'api/brokerages/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getBroker': {
                method: 'GET',
                url: 'api/get-broker/:id',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'registerBrokerage' : { method: 'POST', url: 'api/register-brokerage'},
            'pendingRegistrations' : { method:'GET', url: 'api/pending-brokerages', isArray: true },
            'getDropDownBrokerages' : {method : 'GET', url: 'api/brokerages-minimal', isArray:true},
            'mergeBrokerages' : { method: 'POST', url: 'api/brokerages/:brokerageToMergeIntoId/merge' }
        });
    }
})();

