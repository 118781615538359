(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('ConcernDialogController', ConcernDialogController);

    ConcernDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$location', 'Principal', '$uibModalInstance', 'entity', 'Concern'];

    function ConcernDialogController ($timeout, $scope, $stateParams, $location, Principal, $uibModalInstance, entity, Concern) {
        var vm = this;

        vm.concern = entity;
        vm.clear = clear;
        vm.save = save;

        Principal.identity().then(function(account) {
            vm.hasAccount = !!account;
            if(vm.hasAccount) {
                vm.concern.senderEmail = account.email;
                vm.concern.sender = [account.firstName, account.lastName].join(' ');
            }
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.concern.id !== null) {
                Concern.update(vm.concern, onSaveSuccess, onSaveError);
            } else {
                vm.concern.pageUrl = vm.concern.currentUrl;
                Concern.save(vm.concern, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('valueconnectApp:concernUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

    }
})();
