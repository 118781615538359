(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .directive('lineItemFormRow', function() {
            return {
                restrict: 'A',
                scope: {
                    lineItem: '=',
                    regionalProducts: '<',
                    onDelete: '&'
                },
                require: {
                    subForm: "form"
                },
                templateUrl: 'app/entities/quote/line-item-form-row.template.html',
                controller: LineItemFormRowDirectiveController,
                controllerAs: 'vm',
                bindToController: true
            };
        });

    LineItemFormRowDirectiveController.$inject = [];

    function LineItemFormRowDirectiveController () {
        var vm = this;
        vm.$onInit = function() {
            vm.regionalProducts.$promise.then(function(products) {
                vm.productIds = products.map(function(product) { return product.id; });

                // Check if the current product is a fallback
                if(vm.lineItem.regionalProduct && !vm.productIds.includes(vm.lineItem.regionalProduct.id)) {
                    // Try to find another (non-fallback) product with the same productType
                    var matchedProduct = products.find(function(product) {
                        return product.productType === vm.lineItem.regionalProduct.productType;
                    });

                    if(matchedProduct) {
                        // Update the fallback product to one from the current region with the same product type
                        vm.fallbackWarn = true;
                        vm.lineItem.regionalProduct = matchedProduct;
                    } else {
                        // No match found; add the fallback product to the list of products. This will
                        // allow the select control to show something, but will still fail the 'fallback' validation
                        vm.lineItem.regionalProduct.$isFallback = true;
                        vm.regionalProducts = products.concat([vm.lineItem.regionalProduct]);
                    }
                }
            });
        };

        // Add a validation to invalidate fallback products
        vm.$postLink = function() {
            vm.subForm.regionalProduct.$validators.fallback = function(modelValue, viewValue) {
                return !(modelValue && modelValue.$isFallback);
            };
        };

    }
})();
