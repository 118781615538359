(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(toastrConfig);

    toastrConfig.$inject = [];

    function toastrConfig() {
        angular.extend(toastrConfig, {
            target: '#content-main'
        });
    }
})();
