(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('NationalBrokerageDetailController', NationalBrokerageDetailController);

    NationalBrokerageDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'NationalBrokerage'];

    function NationalBrokerageDetailController($scope, $rootScope, $stateParams, entity, NationalBrokerage) {
        var vm = this;

        vm.nationalBrokerage = entity;

        var unsubscribe = $rootScope.$on('valueconnectApp:nationalBrokerageUpdate', function(event, result) {
            vm.nationalBrokerage = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
