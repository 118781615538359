(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('CostApproachControllerCuspap2024',CostApproachController);

    CostApproachController.$inject = ['$log', '$scope', '$filter', '$timeout', '$translate', '$q', 'DynamicForm'];

    function CostApproachController($log, $scope, $filter, $timeout, $translate, $q, DynamicForm) {
        // Initialize model
        var vm = this;

        vm.staticCosts = [
            'livingArea',
            'basement',
            'garages'
        ];
        var livingAreaIndex = vm.staticCosts.indexOf('livingArea');
        var formData = null;

        // Init methods exposed to view
        vm.getDepreciation = getDepreciation;
        vm.getLandValueCost = getLandValueCost;
        vm.addCost = addCost;
        vm.costNewUpdated = costNewUpdated;
        vm.costDepreciatedUpdated = costDepreciatedUpdated;

        // Initialize model using form data
        init(null, $scope.vm.formData);
        $scope.$on('formDataReinitialized', init);

        function init(event, newData) {
            formData = newData;
            vm.stdResData = $scope.vm.reportData.standardResidentialCuspap2024;

            // Initialize after promises resolve
            var stdResPromise = vm.stdResData.$promise || $q.when(vm.stdResData);
            stdResPromise.then(function(stdResData) {
                vm.modelReady = true;
                initCalculations(formData);
            });

            function initCalculations(formData) {
                // Initialize existing costs
                formData.costs.map(initCost);

                // Initialize costs array to the length of the static cost items
                for(var i = formData.costs.length; i < vm.staticCosts.length; i++) {
                    vm.addCost();
                }

                // Auto-populate living area if there is a compatible value from the room allocation section
                var improvementData = vm.stdResData.improvementsDetailed;

                if(!formData.costs[livingAreaIndex].value &&
                    // Room Allocation section has a value for area
                    improvementData &&
                    // Area unit of measure is compatible with the one set for improvementsDetailed
                    (!formData.unitOfMeasure || formData.unitOfMeasure === improvementData.roomAreaUnit)) {

                    var agAreaTotal = angular.isFunction(improvementData.agAreaTotal) ? improvementData.agAreaTotal() : improvementData.agAreaTotal;
                    formData.costs[livingAreaIndex].value = agAreaTotal;

                    $timeout(function(){ // required to ensure dirty gets set
                        $scope.vm.setDirty('costs['+livingAreaIndex+'].value');
                    });

                    //Not used, presetting this value caused confusion as it left a validation error
                    //formData.unitOfMeasure = improvementData.roomAreaUnit;
                    //$scope.vm.setDirty('unitOfMeasure');
                }

                formData.depreciation = function() {
                    return getDepreciation();
                };

                formData.landValueCost = function() {
                    return getLandValueCost();
                };

                formData.totalNew = function() {
                    return getCosts().map(function(cost) {
                        return cost.costNew || 0;
                    }).reduce(sum, 0);
                };

                formData.totalNewDepreciation = function() {
                    return getCosts().map(function(cost) {
                        return cost.costNew || 0;
                    }).reduce(sum, 0) * getDepreciation();
                };

                formData.totalNewDepreciated = function() {
                    return getCosts().map(function(cost) {
                        return cost.costNew || 0;
                    }).reduce(sum, 0) * (1 - getDepreciation());
                };

                formData.totalDepreciated = function() {
                    return getCosts().map(function(cost) {
                        return cost.costDepreciated || 0;
                    }).reduce(sum, 0) + formData.totalNewDepreciated();
                };

                formData.indicatedValue = function() {
                    return formData.totalDepreciated() +
                        (formData.landValueCost() || 0);
                };

                /**
                 * Get a list of costs to be used in calculations
                 */
                function getCosts() {
                    return formData.costs.map(getCalculationValues)
                        .concat(formData.extras.map(getCalculationValues));
                }

                /**
                 * Get an object with the cost values to use in calculations
                 */
                function getCalculationValues(cost) {
                    return {
                        costNew: cost.useDepreciated ? 0 : getCostNew(cost),
                        costDepreciated: cost.useDepreciated ? cost.costDepreciated : 0
                    };
                }

            }
        }

        /**
         * Retrieve depreciation from the standard residential improvements section, or return 0
         * @return {Number} The depreciation
         */
        function getDepreciation() {
            if(vm.stdResData && vm.stdResData.improvements) {
                var improvements = vm.stdResData.improvements;
                if(improvements.depreciationOverride) {

                    var depreciationString = improvements.depreciationOverride.replace(/[^\d.-]/g, '');
                    return (parseFloat(depreciationString) || 0) / 100;
                } else {
                    //Make sure the number used matches what will end up in the DB (i.e. 4 decimals)
                    return angular.isFunction(improvements.depreciation) ?
                        Number($filter('number')(improvements.depreciation(),4)) :
                        (improvements.depreciation || 0);
                }
            } else {
                return 0;
            }
        }

        function getLandValueCost() {
            if (vm.stdResData.bestUse && vm.stdResData.bestUse.vacantValue) {
                return vm.stdResData.bestUse.vacantValue;
            }
            return 0;
        }

        function addCost() {
            formData.costs.push(initCost({}, formData.costs.length));
        }

        /**
         * Initialize calculated fields on the provided cost object
         */
        function initCost(cost, index) {
            cost.costNew = function() {
                return (cost.value || 0) * (cost.rate || 0);
            };

            // Get basement name from improvements section
            if(index === vm.staticCosts.indexOf('basement') && vm.stdResData.improvements.basement) {
                $translate('valueconnectApp.form.costApproachCuspap2024.staticCost.basement').then(function (basementLabel) {
                    cost.name = function () {
                        return basementLabel + ": " + vm.stdResData.improvements.basement;
                    };
                });

                return cost;
            }

            // Get parking name from site section
            if(index === vm.staticCosts.indexOf('garages') && vm.stdResData.site.parking && vm.stdResData.site.parking.length) {
                // Retrieve parking and other values
                var parking = vm.stdResData.site.parking;
                var hasOther = parking.indexOf('OTHER') !== -1;
                var otherValue = hasOther ? vm.stdResData.site.parkingOther : null;
                parking = parking.filter(function(value) { return value !== 'OTHER'; });

                // Translate parking values
                var translationPromise = !parking.length ? $q.when([]) :
                    $q.all(parking.map(function(value) {
                        return $translate('valueconnectApp.form.standardResidentialCuspap2024.site.parking.' + value);
                    }));

                // Combine translated values into a single string
                translationPromise.then(function(translations) {
                    if(otherValue) translations.push(otherValue);
                    cost.name = function() {
                        return "Parking: " + translations.join(', ');
                    }
                }, function() {
                    $log.error("Failed to translate parking enumeration values", arguments);
                });
                return cost;
            }

            // Get a translated name for static rows
            if(index < vm.staticCosts.length) {
                var translationKey = $scope.vm.translationPrefix + '.staticCost.' + vm.staticCosts[index];
                $translate(translationKey).then(function(name) {
                    cost.name = function() { return name; };
                });
            }

            return cost;
        }

        /**
         * Return the new cost value to be used in calculations
         */
        function getCostNew(cost) {
            return (angular.isDefined(cost.costNewManual) ? cost.costNewManual :
                (angular.isFunction(cost.costNew) ? cost.costNew() : cost.costNew));
        }

        /**
         * Conditionally update the useDepreciated property of the provided cost object
         */
        function costNewUpdated(cost, fieldName) {
            if(cost.useDepreciated === false || getCostNew(cost) === 0 || cost.costDepreciated) return;
            cost.useDepreciated = false;
            $scope.vm.setDirty(fieldName + '.useDepreciated');
        }

        /**
         * Conditionally update the useDepreciated property of the provided cost object
         */
        function costDepreciatedUpdated(cost, fieldName) {
            var costNew = getCostNew(cost);
            if(cost.useDepreciated || cost.costDepreciated === 0 || costNew) return;
            cost.useDepreciated = true;
            $scope.vm.setDirty(fieldName + '.useDepreciated');
        }

        function sum(a,b) {
            return a + b;
        }
    }
})();
