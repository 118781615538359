(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('ResidentialLandAppraisalSiteController', ResidentialLandAppraisalSiteController);

    ResidentialLandAppraisalSiteController.$inject = ['$scope', '$timeout'];

    function ResidentialLandAppraisalSiteController($scope, $timeout) {
        var vm = this;
        vm.irregularChanged = irregularChanged;

        // Define a map of enum values to units supported by js-quantities
        var enumAreaUnits = {
            'SQUARE_FEET': 'foot^2',
            'SQUARE_METRES': 'meter^2',
            'ACRES': 'acre',
            'HECTARES': 'hectare'
        };

        init(null, $scope.vm.formData);
        $scope.$on('formDataReinitialized', init);

        // Initialize calculated values
        function init(event, formData) {
            irregularChanged();

            // Fix for change from zoning (text input) to zoningEnum w/ other
            if (formData.site.zoning && !formData.site.zoningEnum.length) {
                formData.site.zoningEnum.push("OTHER");
            }
        }

        /**
         * Called when the value of isIrregular changes.
         * Toggle between an input and calculated value for area depending on the value of isIrregular
         */
        function irregularChanged() {
            var sectionData = $scope.vm.formData.site;

            if(sectionData.isIrregular) {
                if(angular.isFunction(sectionData.propertyArea)) {
                    sectionData.propertyArea = sectionData.propertyArea();
                    $scope.vm.setDirty('site.propertyArea');
                }
            } else {
                sectionData.propertyArea = getArea;
            }

            /**
             * Calculate the area of the property using the width and depth dimensions and
             * converting units as appropriate. Returns zero if there is no dimension unit
             * specified. If the area unit is not selected, a default will be chosen.
             * @return {Number} The calculated area
             */
            function getArea() {
                if(!sectionData.dimensionUnit) return 0;

                // Chose a default area unit if none is selected
                if(!sectionData.areaUnit) {
                    sectionData.areaUnit = sectionData.dimensionUnit === 'FEET' ? 'SQUARE_FEET' : 'SQUARE_METRES';
                    $timeout(function(){ // ensure dirty gets set
                        $scope.vm.setDirty('site.areaUnit');
                    });
                }

                // Calculate the area and area unit
                var area = (sectionData.propertyWidth || 0) * (sectionData.propertyDepth || 0);
                var areaUnit = sectionData.dimensionUnit.toLowerCase() + '^2';

                // Converted to the appropriate unit and round
                return Qty(area, areaUnit)
                    .to(enumAreaUnits[sectionData.areaUnit])
                    .toPrec(sectionData.areaUnit.startsWith("SQUARE_") ? 1 : 0.01)
                    .scalar;
            }
        }
    }
})();
