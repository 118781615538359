(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(materialThemeConfig);

    materialThemeConfig.$inject = ['$mdThemingProvider'];

    function materialThemeConfig($mdThemingProvider) {
        var greenContrast = $mdThemingProvider.extendPalette('green', {
            '50': 'ffffff',
            '100': 'rgb(100,206,207)',
            '200': 'rgb(50,206,157)',
            '300': 'rgb(0,206,107)',
            '400': 'rgb(0,140,72)',
            '500': 'rgb(0,120,62)',
            '600': 'rgb(0,102,53)',
            '700': 'rgb(0,85,45)',
            '800': 'rgb(0,77,40)',
            '900': 'rgb(0,56,29)',
            'A100': 'rgb(0,140,72)',
            'A200': 'rgb(0,140,72)',
            'A400': 'rgb(0,206,107)',
            'A700': 'rgb(0,206,107)',
            'contrastDefaultColor': 'light'
        });
        $mdThemingProvider.definePalette('greenContrast', greenContrast);

        var vcGray = $mdThemingProvider.extendPalette('grey', {
            '50': 'ffffff',
            'A100': 'efefef',
            'contrastDefaultColor': 'light'
        });
        $mdThemingProvider.definePalette('vcGray', vcGray);

        $mdThemingProvider.theme('default')
            .primaryPalette('greenContrast', {
              'hue-1': '400'
            })
            .accentPalette('green')
            .backgroundPalette('vcGray');

        $mdThemingProvider.theme('darkTheme')
            .backgroundPalette('vcGray').dark();
    }
})();
