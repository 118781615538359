(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('home', {
            parent: 'app',
            url: '/',
            data: {
                authorities: [/^ROLE_.*/]
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/home.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('enum');
                    $translatePartialLoader.addPart('appraisalOrder');
                    $translatePartialLoader.addPart('enumOccupiedBy');
                    $translatePartialLoader.addPart('enumAppraisalState');
                    $translatePartialLoader.addPart('enumAppraisalPurpose');
                    $translatePartialLoader.addPart('enumAppraisalType');
                    $translatePartialLoader.addPart('notification');
                    return $translate.refresh();
                }],
                identity: ['$q', 'Principal', function($q, Principal) {
                    var deferred = $q.defer();
                    Principal.identity().then(function(_id){
                        deferred.resolve(_id);
                    });

                    return deferred.promise;
                }],
                newFeatures: ['NewFeature', function(NewFeature) {
                    return NewFeature.getForUser().$promise
                }]
            }
        })

        .state('home.openorders', {
            parent: 'app',
            url: '/',
            data: {
                authorities: [/^ROLE_.*/]
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/home.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            },
            params: {
                openorders: true
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('appraisalOrder');
                    $translatePartialLoader.addPart('enumOccupiedBy');
                    $translatePartialLoader.addPart('enumAppraisalState');
                    $translatePartialLoader.addPart('enumAppraisalPurpose');
                    $translatePartialLoader.addPart('enumAppraisalFirmSelection');
                    $translatePartialLoader.addPart('enumAppraisalType');
                    return $translate.refresh();
                }],
                identity: ['$q', '$stateParams', 'Principal', function($q, $stateParams, Principal) {
                    var deferred = $q.defer();
                    Principal.identity().then(function(_id){
                        deferred.resolve(_id);
                    });

                    return deferred.promise;
                }],
                newFeatures: ['NewFeature', function(NewFeature) {
                    return NewFeature.getForUser().$promise
                }]
            }
        })

        .state('home-search', {
            parent: 'app',
            url: '/search/{phrase}',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/search.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('appraisalOrder');
                    $translatePartialLoader.addPart('enumOccupiedBy');
                    $translatePartialLoader.addPart('enumAppraisalState');
                    $translatePartialLoader.addPart('enumAppraisalPurpose');
                    $translatePartialLoader.addPart('enumAppraisalFirmSelection');
                    $translatePartialLoader.addPart('enumAppraisalType');
                    return $translate.refresh();
                }],
                identity: ['$q', '$stateParams', 'Principal', function($q, $stateParams, Principal) {
                    var deferred = $q.defer();
                    Principal.identity().then(function(_id){
                        deferred.resolve(_id);
                    });

                    return deferred.promise;
                }],
                newFeatures: ['NewFeature', function(NewFeature) {
                    return null;
                }]
            }
        })

        .state('privacy-policy', {
            parent: 'app',
            url: '/privacy-policy',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/privacy-policy.html',
                    controller: 'HomePrivacyController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })

        .state('terms-of-use', {
            parent: 'app',
            url: '/terms-of-use',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/terms-of-use.html',
                    controller: 'HomePrivacyController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('terms');

                    return $translate.refresh();
                }]
            }
        });
    }
})();
