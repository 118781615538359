(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .directive('vcOriginatorForm', ['$q', 'User', 'BrokerageSearch', vcOriginatorForm]);

    function vcOriginatorForm ($q, User, BrokerageSearch) {
        var directive = {
            restrict: 'E',
            link:  postLink,
            transclude: true,
            templateUrl: "app/components/form/originator-form/vc-originator-form.template.html",
            scope: {
                brokerage: "=brokerageEntity",
                user: "=userEntity"
            }
        };

        return directive;

        function postLink(scope, elm, attrs) {
            scope.brokeragePromise = BrokerageSearch.query(buildBrokerageQuery()).$promise;
            scope.searchBrokerages = searchBrokerages;
            scope.matchBrokerages = matchBrokerages;

            scope.userPromise = $q.when([]);
            scope.searchUsers = searchUsers;
            scope.matchUsers = matchUsers;

            scope.checkSelected = checkSelected;

            /* BROKERAGE */

            /**
             * Brokerage autocomplete list query method
             */
            function searchBrokerages(brokerageSearchText) {
                scope.checkSelected();

                var query = buildBrokerageQuery(brokerageSearchText);
                scope.brokeragePromise = BrokerageSearch.query(query).$promise;
                return scope.brokeragePromise;
            }

            function buildBrokerageQuery(brokerageSearchText) {
                var query = { size: 25, sort: "name,asc" };
                if (brokerageSearchText) {
                    query['query'] = brokerageSearchText;
                }
                return query;
            }

            /**
             * Brokerage autocomplete filter method
             */
            function matchBrokerages(brokerageSearchText) {
                return scope.brokeragePromise.then(function(brokerages) {
                    var searchRegex = new RegExp(brokerageSearchText, 'i');
                    return brokerages.filter(function(brokerage) {
                        return searchRegex.test(brokerage.name);
                    });
                });
            }

            /* ORIGINATOR USER */

            /**
             * Originator user autocomplete list query method
             */
            function searchUsers() {
                scope.checkSelected();

                scope.userPromise = User.getOriginators({ brokerageId: scope.brokerage.id }).$promise;
                return scope.userPromise;
            }

            /**
             * Originating user autocomplete filter method
             */
            function matchUsers(userSearchText) {
                return scope.userPromise.then(function(users) {
                    var searchRegex = new RegExp(userSearchText, 'i');
                    return users.filter(function(user) {
                        return searchRegex.test(user.firstName + ' ' + user.lastName);
                    });
                });
            }

            /**
             * Validate that brokerage and originator user is chosen
             */
            function checkSelected() {
                if (scope.originatorForm.brokerage) {
                    if (scope.brokerage) {
                        scope.originatorForm.brokerage.$setValidity("nonechosen", true);
                    } else {
                        scope.originatorForm.brokerage.$setValidity("nonechosen", false);
                    }
                }

                if (scope.originatorForm.user) {
                    if (scope.user) {
                        scope.originatorForm.user.$setValidity("nonechosen", true);
                    } else {
                        scope.originatorForm.user.$setValidity("nonechosen", false);
                    }
                }
            }

            scope.$watch(function() { return scope.brokerage; }, function() {
                scope.user = null;

                if (scope.brokerage) {
                    scope.searchUsers();
                }
            });

            scope.$watch(function() { return scope.user; }, function() {
                scope.checkSelected();
            });

        }
    }
})();
