(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('BrokerageRegionDeleteController',BrokerageRegionDeleteController);

    BrokerageRegionDeleteController.$inject = ['$uibModalInstance', 'entity', 'BrokerageRegion'];

    function BrokerageRegionDeleteController($uibModalInstance, entity, BrokerageRegion) {
        var vm = this;

        vm.brokerageRegion = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            BrokerageRegion.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
