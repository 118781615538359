(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('Quote', Quote);

    Quote.$inject = ['$resource', '$log'];

    function Quote ($resource, $log) {
        var resourceUrl =  'api/quotes/:id';

        var dummyMethod = {
            url: 'api/does/not/exist',
            transformRequest: function(data) {
                $log.error('This method is not supported on the quote resource');
                return null;
            }
        };

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'feeSummary': { method: 'GET', url: 'api/quotes/fee-summary' },
            'feeSummaryExport': {
                method: 'GET',
                url: 'api/quotes/fee-summary/export',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    return data ? { file: new Blob([data], { type: 'application/vnd.ms-excel' }) } : data;
                }
            },
            'couponSummary': { method: 'GET', url: 'api/quotes/coupon-summary' },
            'couponSummaryExport': {
                method: 'GET',
                url: 'api/quotes/coupon-summary/export',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    if (data) return { file: new Blob([data], { type: 'application/vnd.ms-excel' }) };
                    else return data;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    return data ? angular.fromJson(data) : data;
                }
            },
            'getEditable': {
                method: 'GET',
                url: 'api/quotes/:id/editable',
                transformResponse: function (data) {
                    return data ? angular.fromJson(data) : data;
                }
            },
            'update': { method:'PUT' },
            'updatePaidAppraiser': {
                method: 'GET',
                url: 'api/quotes/:id/paid-appraiser'
            },
            'updateAccountsPayablePaidAppraiser': {
                method: 'GET',
                url: 'api/accounts-payable/:id/paid-appraiser'
            },
            'updatePaidAppraiserAmount': {
                method: 'POST',
                url: 'api/quotes/:id/paid-appraiser-amount'
            },
            // NOTE: Angular doesn't provide a way to remove these default methods so we'll just
            // use dummy methods here that will always fail and log an error
            'save': dummyMethod,
            'remove': dummyMethod,
            'delete': dummyMethod
        });
    }

})();
