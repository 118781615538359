(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .factory('AlertService', ['$translate', 'toastr', function($translate, toastr) {

            function getTranslatedMessage(msg, translationParams) {
                if (angular.isArray(msg)) {
                    var translations = $translate.instant(msg, translationParams);
                    return msg.map(function(key) {
                        return translations[key];
                    }).join(' ');
                } else if (translationParams || msg.startsWith("valueconnectApp")) {
                    return $translate.instant(msg, translationParams);
                } else {
                    return msg;
                }
            }

            // NOTE: Can be called with or without translations in the following ways
            // AlertService.method("Custom message"),
            // AlertService.method("path.to.translation.without.param", {})
            // AlertService.method("path.to.translation.with.param", {id: orderId}),
            // AlertService.method(["path.to.translation1.with.param1", "path.to.translation2.with.param2", ...], {id1: orderId1, id2: orderId2, ...}),

            return {
                success: function (msg, translationParams) {
                    toastr.success(getTranslatedMessage(msg, translationParams), '', {allowHtml: true});
                },
                error: function (msg, translationParams) {
                    toastr.error(getTranslatedMessage(msg, translationParams), '', {allowHtml: true});
                },
                warning: function (msg, translationParams) {
                    toastr.warning(getTranslatedMessage(msg, translationParams), '', {allowHtml: true});
                },
                info: function (msg, translationParams) {
                    toastr.info(getTranslatedMessage(msg, translationParams), '', {allowHtml: true});
                }
            };
        }]);
})();
