(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .directive('vcSetTheme', vcSetTheme);

    vcSetTheme.$inject = ['$state', '$rootScope', 'Principal'];

    function vcSetTheme($state, $rootScope, Principal) {
        var directive = {
            restrict: 'A',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs) {

            function refreshTheme() {
                if ($state.current.name === "login")
                    $(element).addClass('green');
                else
                    $(element).removeClass('green');
            }

            function refreshLoginTheme() {
                Principal.identity().then(function(id){
                    if (Principal.hasAnyAuthority([/^ROLE_(ADMIN|VC|CUSTOMER_CARE).*$/])) {
                        $(element).addClass('admin');
                    } else if (Principal.hasAnyAuthority([/^ROLE_(ORIGINATOR).*$/])) {
                        $(element).addClass('originator');
                    } else if (Principal.hasAnyAuthority([/^ROLE_(APPRAISER|APPRAISAL).*$/])) {
                        $(element).addClass('appraiser');
                    } else {
                        $(element).removeClass('admin originator appraiser');
                    }
                });
            }

            scope.$watch(function() {
                refreshTheme();
            }, function() {

            });

            $rootScope.$on('authenticationSuccess', function(data) {
                refreshLoginTheme();
            });
        }
    }
})();
