/**
 * Directive to display a file preview
 * @param ngModel {Object} A fileDTO object representing the file
 * @param showControls  If present, rotation controls will be shown for images
 */
(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .directive('vcFilePreview', ['$stateParams', 'File', vcFilePreview]);

    // Constants for identifying file tpyes from content type
    var imageRegex = new RegExp('^image/.*', 'i');
    var supportedVideoRegex = new RegExp('^video/(mp4|webm|ogg|quicktime)', 'i');
    var videoRegex = new RegExp('^video/.*', 'i');
    var iframeRegex = new RegExp('^application/.*', 'i');

    function vcFilePreview ($stateParams, File) {
        var directive = {
            restrict: 'AE',
            link: link,
            templateUrl: 'app/components/file/vc-file-preview.template.html',
            scope: {
                ngModel: '=',
                size: '@?'
            }
        };

        return directive;

        // TODO: this does not update when the model changes
        function link(scope, elm, attrs) {
            scope.showControls = angular.isDefined(attrs.showControls);

            scope.size = angular.isDefined(attrs.size) ? attrs.size : 'thumbnail';

            // Add functions to scope
            scope.previewType = previewType;
            scope.srcUrl = srcUrl;
            scope.contentType = contentType;
            scope.rotate = rotate;

            function previewType() {
                if(!scope.ngModel || !scope.ngModel.contentType) return null;
                var contentType = scope.ngModel.contentType.trim();

                if(imageRegex.test(contentType)) {
                    return 'image';
                } else if(supportedVideoRegex.test(contentType)) {
                    return 'video';
                } else if(videoRegex.test(contentType)) {
                    return 'unsupportedVideo';
                } else if(iframeRegex.test(contentType)) {
                    return 'iframe';
                } else {
                    return null;
                }
            }

            function srcUrl() {
                if (!scope.ngModel) return "";
                return "/api/files/" + scope.ngModel.id + "/download/" + window.encodeURIComponent(scope.ngModel.fileName)
                    + "?size=" + scope.size
                    + "&version=" + scope.ngModel.version;
            }

            function contentType() {
                if(!scope.ngModel) return "";
                if (/quicktime/.test(scope.ngModel.contentType)) {
                    return "video/mp4";
                } else {
                    return scope.ngModel.contentType;
                }
            }

            function rotate(direction) {
                scope.saving = true;
                var request = File.rotate({id: scope.ngModel.id, direction: direction}, null).$promise;
                request.then(function(result) { scope.ngModel = result; });
                request.finally(function() { scope.saving = false; });
            }
        }

    }
})();
