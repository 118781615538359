angular.module("valueconnectApp")
    .directive('upcomingBlackouts', function() {

        UpcomingBlackoutsController.$inject = ["$scope", "Principal", "BlackoutPeriod", "$filter", 'DateUtils'];
        function UpcomingBlackoutsController($scope, Principal, BlackoutPeriod, $filter, DateUtils) {

            $scope.upcomingblackouts = [];
            function getAccount() {
                Principal.identity().then(function(account) {
                    Principal.matchAnyAuthority(/^ROLE_(APPRAISER|APPRAISAL).*/).then(function(result) {
                        if(result){
                            BlackoutPeriod.query().$promise.then(function(data){
                                var today = new Date();
                                $scope.upcomingblackouts = data.map(function(blackout) {
                                    return DateUtils.convertDateTimeFromServer(blackout.startDate);
                                }).filter(function(blackout) {
                                    return blackout >= today;
                                })
                            });

                        }
                    });
                });

            }
            getAccount();
        };

        return {
            restrict: 'A',
            controller: UpcomingBlackoutsController,
            templateUrl: "app/components/upcomingblackouts/upcomingblackouts.template.html"
        }
    });
