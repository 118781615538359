(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('ReportSearchDialogController', ReportSearchDialogController);

    ReportSearchDialogController.$inject = ['$mdDialog', '$scope'];

    function ReportSearchDialogController ($mdDialog, $scope) {
        var vm = this;
        vm.selectedOrder = null;
        vm.selectedReport = null;

        vm.cancel = function($event) {
            $mdDialog.cancel();
        };
        vm.finish = function($event) {
            $mdDialog.hide(vm.selectedReport);
        };

        // Reset the selected report when the order changes
        $scope.$watch('vm.selectedOrder', function(order) {
            if(order && order.appraisalReports && order.appraisalReports.length === 1) {
                vm.selectedReport = order.appraisalReports[0];
            } else {
                vm.selectedReport = null;
            }
        });
    }
})();
