(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('ContactDetailController', ContactDetailController);

    ContactDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Contact', 'JobTitle', 'Address', 'AppraisalOrderContact'];

    function ContactDetailController($scope, $rootScope, $stateParams, entity, Contact, JobTitle, Address, AppraisalOrderContact) {
        var vm = this;

        vm.contact = entity;

        var unsubscribe = $rootScope.$on('valueconnectApp:contactUpdate', function(event, result) {
            vm.contact = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
