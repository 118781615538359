(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .factory('InvoiceUtils', InvoiceUtils);

    InvoiceUtils.$inject = ['Invoice', '$window'];

    function InvoiceUtils (Invoice, $window) {

        var service = {
            getInvoicePdf : getInvoicePdf
        };

        return service;

        function getInvoicePdf(id) {
            return Invoice.generatePdf({id:id}).$promise.then(function(response) {
                let file = new Blob([response.data], { type: 'application/pdf' });
                const dispositionHeader = response.headers['content-disposition'];
                if (dispositionHeader != null) {                    
                    let filename = dispositionHeader.split(/=(.+)/)[1];
                    if (filename.toLowerCase().startsWith("utf-8''")) {
                        filename = decodeURIComponent(filename.replace(/utf-8''/i, ''));
                    } else {
                        filename = filename.replace(/['"]/g, '');
                    }
                    file.name = filename;
                }
                var fileUrl = URL.createObjectURL(file);
                /*var a = document.createElement("a");
                a.setAttribute('target', '_blank');
                a.href = fileUrl;
                a.download = file.name;
                a.click();*/
                $window.open(fileUrl, '_blank');
            }); 
        }

    }

})();
