'use strict';

// Register `heroReference` component, along with its associated controller and template
angular.
    module('valueConnectPendingChangeRequests', []).
    component('valueConnectPendingChangeRequests', {
        templateUrl: 'app/components/pendingchangerequests/pendingchangerequests.template.html',
        controller: ['$scope', 'AppraisalOrder', 'AppraisalOrderChangeRequest', 'Principal',
            function ValueConnectPendingChangeRequestsController($scope, AppraisalOrder, AppraisalOrderChangeRequest, Principal) {

            $scope.sortType     = 'appraisalDueDate';
            $scope.sortReverse  = false;
            $scope.isOpen = false;
            $scope.collapsible = false;

            Principal.matchAnyAuthority(/^ROLE_CUSTOMER_CARE.*/g).then(function(result) {
                if (result) {
                    $scope.pendingChangeRequests = AppraisalOrderChangeRequest.pending();
                    console.log($scope.pendingChangeRequests.length);
                    if($scope.pendingChangeRequests.length > 0) {
                        $scope.collapsible = true;
                    };
                }
            });

            $scope.toggleView = function() {
                $scope.isOpen = !$scope.isOpen;
            }
        }]
    });
