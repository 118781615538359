(function() {
    'use strict';

    angular.
        module('valueconnectApp.reportErrorSummary', [])
        .component('reportErrorSummary', {
            templateUrl: 'app/components/dynamic-form/report-error-summary.template.html',
            bindings: {
                reportErrors: '<',
                appraisalOrder: '<',
                appraisalReport: '<',
                isCosigner: '<'
            },
            controllerAs: 'vm',
            controller: ['$log', '$scope', '$state', 'AppraisalOrderModal',
            function reportErrorSummary($log, $scope, $state, AppraisalOrderModal) {
                var vm = this;

                vm.hasErrors = Object.keys(vm.reportErrors).some(function(key) { return vm.reportErrors[key]; });

                vm.scheduleVisit = scheduleVisit;
                vm.completeVisit = completeVisit;
                vm.assignCosigner = assignCosigner;

                function scheduleVisit(ev) {
                    AppraisalOrderModal.scheduleVisit(vm.appraisalOrder).then(function(result) {
                        $state.reload();
                    });
                }

                function completeVisit(ev) {
                    AppraisalOrderModal.completeVisit(vm.appraisalOrder, ev).then(function(result) {
                        $state.reload();
                    });
                }

                function assignCosigner(ev) {
                    AppraisalOrderModal.assignCosigner(vm.appraisalOrder, null).then(function(result) {
                        $state.reload();
                    });
                }

            }]
        });
})();
