(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('PricingMatrixCloneDialogController', PricingMatrixCloneDialogController);

    PricingMatrixCloneDialogController.$inject = ['$mdDialog', 'region', 'TaxCode', 'Region', 'PricingMatrix', 'AlertService'];

    function PricingMatrixCloneDialogController ($mdDialog, region, TaxCode, Region, PricingMatrix, AlertService) {
        // Define bound variables
        var vm = this;
        vm.region = region;
        vm.regions = Region.query({sort: 'name', size: 9999});
        vm.taxCodes = TaxCode.query({size: 9999, provinceId: region.provinceId});

        vm.provincePromise = vm.regions.$promise.then(function(regions) {
            // Remove the current region from the list of regions
            vm.regions = regions.filter(function(region) {
                return region.id !== vm.region.id;
            });

            // Get a list of provinces using the list of regions
            vm.provinces = regions.reduce(function(accumulator, currentValue) {
                accumulator[currentValue.provinceId] = currentValue.provinceName;
                return accumulator;
            }, {});
        });

        // Auto-select the tax code if there is only one
        vm.taxCodes.$promise.then(function(codes) {
            if(codes.length === 1) {
                vm.taxCode = codes[0];
            }
        });

        // Define bound methods
        vm.cancel = cancel;
        vm.save = save;

        function save () {
            vm.isSaving = true;
            var sourceId = vm.sourceRegion ? vm.sourceRegion.id : null;
            var taxCodeId = !vm.sourceRegion || vm.sourceRegion.provinceId !== vm.region.provinceId ? vm.taxCode.id : null;

            PricingMatrix.clone({id: vm.region.id, sourceId: sourceId, taxCodeId: taxCodeId}, null).$promise.then(function(result) {
                var sourceName = vm.sourceRegion ? vm.sourceRegion.name : "system defaults";
                AlertService.success("valueconnectApp.region.pricingMatrix.clone.success", { regionName: region.name, sourceName: sourceName });
                $mdDialog.hide(result);
            }).finally(function() {
                vm.isSaving = false;
            });
        }

        function cancel () {
            $mdDialog.cancel();
        }
    }
})();
