(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .factory('AppraisalOrderSearch', AppraisalOrderSearch);

    AppraisalOrderSearch.$inject = ['$resource'];

    function AppraisalOrderSearch($resource) {
        var resourceUrl =  'api/_search/appraisal-orders/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
