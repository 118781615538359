(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('appraisal-report-list', {
            parent: 'entity',
            url: '/appraisal-report?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'valueconnectApp.appraisalReport.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/appraisal-report/appraisal-reports.html',
                    controller: 'AppraisalReportController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('appraisalReport');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('error');
                    return $translate.refresh();
                }]
            }
        })

        .state('appraisal-report', {
            abstract: true,
            parent: 'entity',
            url: '/appraisal-report/{id}',
            data: {
                authorities: [/^ROLE_(ADMIN|VC|APPRAISER|APPRAISAL|ORIGINATOR|CUSTOMER_CARE).*/]
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/appraisal-report/appraisal-report-header.html',
                    controller: 'AppraisalReportHeaderController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                account: ['Principal', function(Principal) {
                    return Principal.identity();
                }],
                report: ['$stateParams', 'AppraisalReport', function($stateParams, AppraisalReport) {
                    return AppraisalReport.get({id : $stateParams.id}).$promise;
                }],
                order: ['report', 'AppraisalOrder', function(report, AppraisalOrder) {
                    return AppraisalOrder.get({id: report.appraisalOrderId}).$promise;
                }],
                reportEditable: ['order', 'report', 'account', 'Principal', function(order, report, account, Principal) {
                    if (Principal.hasAnyAuthority([/^ROLE_(ADMIN|CUSTOMER_CARE).*/i])) {
                        return true;
                    }
                    var editStates = ['PENDING_REPORT_COMPLETION','PENDING_VISIT','PENDING_SCHEDULE_VISIT','PENDING_COSIGNER_ASSIGNMENT','PENDING_COSIGN','PENDING_SUBMISSION','RESUBMISSION_REQUIRED'];
                    return editStates.indexOf(order.state) !== -1 &&            // Make sure order is in the correct state
                        order.activeReport.id === report.id &&                  // Make sure this is the current active report
                        account.appraiserUser &&                                // Make sure the current account has appraiser information
                        (order.assignedAppraiserId === account.appraiserUser.id // Make sure this is either the assigned appraiser or the cosigner
                            || report.cosignerId === account.appraiserUser.id);
                }],
                // Determines if the user can access the report submit page
                reportSubmittable: ['order', 'report', 'account', 'Principal', function(order, report, account, Principal) {
                    if (Principal.hasAnyAuthority(['ROLE_CUSTOMER_CARE_REPRESENTATIVE'])) {
                        return false;
                    } else if(account.appraiserUser && report.cosignerId === account.appraiserUser.id) {
                        return ['PENDING_COSIGN','PENDING_SUBMISSION','RESUBMISSION_REQUIRED'].indexOf(order.state) !== -1;
                    } else if(['PENDING_SCHEDULE_VISIT','PENDING_VISIT','PENDING_COSIGNER_ASSIGNMENT','PENDING_COSIGN','PENDING_SUBMISSION','RESUBMISSION_REQUIRED'].indexOf(order.state) !== -1) {
                        return Principal.matchAnyAuthority(/^ROLE_(APPRAISER|APPRAISAL).*/);
                    } else if(order.state === 'REPORT_SUBMITTED') {
                        return Principal.matchAnyAuthority(/^ROLE_(ADMIN|VC_REVIEWER).*/);
                    } else if (order.state === 'REPORT_COMPLETE') {
                        return Principal.matchAnyAuthority(/^ROLE_(ORIGINATOR).*/);
                    } else {
                        return false;
                    }
                }],
                validationErrors: ['$stateParams', 'AppraisalReport', function($stateParams, AppraisalReport) {
                    return AppraisalReport.validateForms({id: $stateParams.id}).$promise;
                }],
                reportErrors: ['account', 'order', 'report', 'validationErrors', function(account, order, report, validationErrors) {
                    return account ? {
                        aicInactive: account.appraiserUser && account.appraiserUser.aicActive === false,
                        designationRequired: !report.manual && account.appraiserUser && report.cosignerId === null && account.appraiserUser.designationAACI === false && account.appraiserUser.designationCRA === false,
                        pendingScheduleVisit: order.state === 'PENDING_SCHEDULE_VISIT',
                        pendingVisit: order.state === 'PENDING_VISIT',
                        pendingCosignAssignment: !report.manual && order.state === 'PENDING_COSIGNER_ASSIGNMENT',
                        pendingCosign: !report.manual && order.state === 'PENDING_COSIGN',
                        missingSignature: !report.manual && account.signatureFile === null,
                        missingCustomForms: order.customForms.length > 0 && !report.manual && (!validationErrors['customForms'] || validationErrors['customForms'].length > 0)
                    } : {};
                }],
                translatePartialLoader: ['report', '$translate', '$translatePartialLoader', function (report, $translate, $translatePartialLoader) {
                    // Load translation files for each form on the report
                    report.forms.forEach(function(form) {
                        var formName = form.fieldName;
                        var formNameCapitalized = formName.charAt(0).toUpperCase() + formName.slice(1);
                        $translatePartialLoader.addPart('form' + formNameCapitalized);
                    });
                    $translatePartialLoader.addPart('formType');
                    $translatePartialLoader.addPart('enumAppraisalState');
                    $translatePartialLoader.addPart('enumAppraisalPurpose');
                    $translatePartialLoader.addPart('appraisalReport');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('appraisalOrder');
                    $translatePartialLoader.addPart('address');
                    $translatePartialLoader.addPart('revisionType');
                    return $translate.refresh();
                }]
            }
        })

        // Abstract state that validates editable state before allowing navigation
        .state('appraisal-report.edit-forms', {
            abstract: true,
            parent: 'appraisal-report',
            data: {
                authorities: [/^ROLE_(APPRAISER|APPRAISAL|ADMIN|CUSTOMER_CARE_REPRESENTATIVE).*/]
            },
            resolve: {
                isReadOnly: ['Principal', 'authorize', function(Principal, authorize) {
                    return !Principal.hasAnyRole(['ADMIN', 'APPRAISER', 'APPRAISER_CANDIDATE','APPRAISAL_FIRM_PRINCIPAL', 'APPRAISAL_FIRM_ADMINISTRATOR']);
                }]
            },
            onEnter: ['order', 'report', 'reportEditable', '$state', 'AlertService', function(order, report, reportEditable, $state, AlertService) {
                // Redirect to the home page if the report is not in an editable state
                if(reportEditable === false) {
                    AlertService.error("The report on appraisal order #" + order.id + " is not in an editable state.");
                    $state.go('home');
                    return false;
                }
            }]
        })

        // NOTE: This state is deprecated. Use form.builder instead
        .state('appraisal-report.edit', {
            parent: 'appraisal-report.edit-forms',
            url: '/edit',
            views: {
                'reportContent@appraisal-report': {
                    templateUrl: 'app/entities/appraisal-report/appraisal-report-dialog.html',
                    controller: 'AppraisalReportDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('appraisalReportForm');
                    return $translate.refresh();
                }]
            },
            onEnter: ['$log', '$state', '$stateParams', function($log, $state, $stateParams) {
                // Always Redirect to the report builder page
                $log.warn("The 'appraisal-report.edit' state is deprecated. Redirecting to 'form.builder' instead.");
                if($state.current.name === 'form.builder') {
                    $state.reload();
                } else {
                    $state.go('form.builder', $stateParams);
                }
            }]
        })

        .state('appraisal-report-review', {
            parent: 'appraisal-report',
            url: '/review',
            data: {
                authorities: [/^ROLE_(ADMIN|VC_CUSTOMER_CARE|VC_REVIEWER|ORIGINATOR|APPRAISER|APPRAISAL|CUSTOMER_CARE).*/],
                pageTitle: 'valueconnectApp.appraisalReport.review.title'
            },
            views: {
                'reportContent@appraisal-report': {
                    templateUrl: 'app/entities/appraisal-report/review/appraisal-report-review.html',
                    controller: 'AppraisalReportReviewController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                isReviewable: ['Principal', 'AlertService', 'order', 'report', '$state', isReviewable],
                validationErrors: ['$stateParams', 'AppraisalReport', validationErrors]
            },
            onEnter: ['report', '$state', '$stateParams', function(report, $state, $stateParams) {
                if (report && report.manual) $state.go('appraisal-report-review-third-party', {id: $stateParams.id });
            }]
        })

        .state('appraisal-report-review-third-party', {
            parent: 'appraisal-report',
            url: '/review-third-party',
            data: {
                authorities: [/^ROLE_(ADMIN|VC_CUSTOMER_CARE|VC_REVIEWER|ORIGINATOR|APPRAISER|APPRAISAL|CUSTOMER_CARE).*/],
                pageTitle: 'valueconnectApp.appraisalReport.review.title'
            },
            views: {
                'reportContent@appraisal-report': {
                    templateUrl: 'app/entities/appraisal-report/review/appraisal-report-review.third-party.html',
                    controller: 'AppraisalReportThirdPartyReviewController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                isReviewable: ['Principal', 'AlertService', 'order', 'report', '$state', isReviewable],
                validationErrors: ['$stateParams', 'AppraisalReport', validationErrors]
            },
            onEnter: ['report', '$state', '$stateParams', function(report, $state, $stateParams) {
                if (report && !report.manual) $state.go('appraisal-report-review', {id: $stateParams.id });
            }]
        })

        .state('appraisal-report-review-lender', {
            parent: 'entity',
            url: '/appraisal-report/lender-review/{accessKey}',
            data: {
                authorities: [],
                pageTitle: 'valueconnectApp.appraisalReport.review.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/appraisal-report/review/appraisal-report-review.lender.html',
                    controller: 'AppraisalReportLenderReviewController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                report: ['$stateParams', 'AppraisalReport', '$state', function($stateParams, AppraisalReport, $state) {
                    return AppraisalReport.getByAccessKey({accessKey : $stateParams.accessKey}).$promise
                        .catch(function(err) {
                            $state.go('home');
                        });
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('appraisalReport');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('error');
                    return $translate.refresh();
                }]
            }
        });
    }

    function isReviewable(Principal, AlertService, order, report, $state) {
        // Originators can only review report if in submitted state or is a previous report
        return Principal.matchAnyAuthority(/^ROLE_ORIGINATOR.*/).then(function(hasAuth) {
            if (hasAuth && ['REPORT_SUBMITTED', 'REPORT_COMPLETE', 'REPORT_ACCEPTED'].indexOf(order.state) === -1 && order.activeReport.id === report.id) {
                AlertService.error("The report on appraisal order #" + order.id + " cannot be reviewed yet.");
                $state.go('appraisal-order-detail', {id: order.id});
                return false;
            }
            return true;
        });
    }

    function validationErrors($stateParams, AppraisalReport) {
        return AppraisalReport.validateForms({id: $stateParams.id}).$promise;
    }

})();
