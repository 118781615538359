(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('FinalEstimateController', FinalEstimateController)

    FinalEstimateController.$inject = ['$scope']

    function FinalEstimateController($scope) {
        var vm = this;

        setDefaultValues();

        function setDefaultValues() {
            if ($scope.vm.formData.finalEstimate.completionDate == undefined) {
                $scope.vm.formData.finalEstimate.completionDate = new Date();
                $scope.vm.setDirty('finalEstimate.completionDate');
            }
        }

    }
})();
