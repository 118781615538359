(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .directive('sectionValidation', ['$stateParams', sectionValidation]);

    function sectionValidation ($stateParams) {
        var directive = {
            restrict: 'AE',
            link: link,
            transclude: true,
            require: 'ngModel',
            templateUrl: 'app/components/dynamic-form/section-validation.template.html',
            scope: {}
        };

        return directive;

        function link(scope, elm, attrs, modelCtrl) {
            var errorKeyPrefix = modelCtrl.$name + '.';

            // Mark this model as a section
            modelCtrl.$isSection = true;

            // Update validation status whenever new validations are recieved
            scope.$on("validationsUpdated", function(event, errors, currentSectionData) {
                scope.hasErrors = Object.keys(errors).some(function(errorKey) {
                    return errorKey === modelCtrl.$name || errorKey.startsWith(errorKeyPrefix);
                });
            });

            /**
             * Check if this section is valid
             * @return {Boolean} True if the form is valid, false if it is not valid, or null if validations should be hidden
             */
            scope.valid = function() {
                var sectionData = modelCtrl.$modelValue;
                var sectionStarted = sectionData ? sectionData.$started : false;
                return sectionStarted ? !scope.hasErrors : null;
            };
        }
    }
})();
