angular
    .module('valueconnectApp')
    .directive('collapsibleForm', function() {

        function link(scope, element, attrs) {
            // Toggle section open/closed
            scope.select = function(ev) {
                // Newly selected panel
                var targetFieldset = angular.element(ev.currentTarget);
                var targetSection = angular.element(targetFieldset).parent();
                var targetSectionId = targetSection.attr("id");

                // Currently open panel
                var currentSection = angular.element('ng-form.collapsible-form__section.active');
                var currentSectionId = currentSection.attr("id");

                if (targetSectionId === currentSectionId) {
                    // Close panel if currently open
                    currentSection.removeClass('active');
                    currentSection.addClass('inactive');
                } else {
                    // Open panel if otherwise
                    scope.setActiveSection(targetSection);
                }

                // Scroll to newly selected section
                angular.element('html, body').animate({
                    speed: 'fast',
                    scrollTop: targetSection.offset().top
                });

            };

            scope.setActiveSection = function(targetSection) {
                // Close all panels and open target
                angular.forEach(angular.element('ng-form.collapsible-form__section'), function(panel) {
                    var section = angular.element(panel);
                    if (section.hasClass('active')) {
                        section.removeClass('active');
                        section.addClass('inactive');
                    }
                });

                targetSection.addClass('active');
            };

            scope.setAllSectionsInactive = function() {
                angular.forEach(angular.element('ng-form.collapsible-form__section'), function(panel) {
                    angular.element(panel).addClass('inactive');
                });
            };

            // Listen for setActiveSection event
            scope.$on("setActiveSection", function(event, data) {
                scope.setActiveSection(angular.element(data[0]));
            });

            // Listen for setAllSectionsInactive event
            scope.$on("setAllSectionsInactive", function(event) {
                scope.setAllSectionsInactive();
            });

        }

        return {
            restrict: 'E',
            link: link,
            templateUrl: function(elem, attr) {
                return 'app/forms/' + attr.form + '.template.html';
            }
        };
    });
