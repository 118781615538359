(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('LenderIntegrationDialogController', LenderIntegrationDialogController);

    LenderIntegrationDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'LenderIntegration', 'Lender'];

    function LenderIntegrationDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, LenderIntegration, Lender) {
        var vm = this;

        vm.lenderIntegration = entity;
        vm.clear = clear;
        vm.save = save;
        vm.lenders = Lender.querySimpleAll();
        vm.toString = toString;
        vm.getHashKey = getHashKey;

        function toString(lender) {
            if (lender && lender.id && lender.lenderName) {
                var strA = (lender.brokerageName) ? lender.brokerageName : 'public';
                var strB = (lender.disabled) ? ' (disabled)' : '';
                return '' + lender.id + ': ' + lender.lenderName + ' (' + strA + ')' + strB;
            }
            return null;
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.lenderIntegration.id !== null) {
                LenderIntegration.update(vm.lenderIntegration, onSaveSuccess, onSaveError);
            } else {
                LenderIntegration.save(vm.lenderIntegration, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('valueconnectApp:lenderIntegrationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function getHashKey() {
            LenderIntegration.generateHashKey(function(data){
                vm.lenderIntegration.hashKey = data.hashKey;
            });
        }
    }
})();
