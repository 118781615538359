(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraisalReportReviewController', AppraisalReportReviewController);

    AppraisalReportReviewController.$inject = ['$scope', '$state', '$stateParams', '$timeout', '$sce', '$rootScope', '$mdDialog', 'report', 'order', 'account',
                                                'reportSubmittable', 'AppraisalReport', 'Principal', 'validationErrors', 'AppraiserUser', 'AppraisalOrderModal',
                                                '$location', '$anchorScroll', 'LocalStorage', 'AppraisalState', 'reportErrors','AlertService'];

    function AppraisalReportReviewController($scope, $state, $stateParams, $timeout, $sce, $rootScope, $mdDialog, report, order, account,
                                                reportSubmittable, AppraisalReport, Principal, validationErrors, AppraiserUser, AppraisalOrderModal,
                                                $location, $anchorScroll, LocalStorage, AppraisalState, reportErrors, AlertService) {
        var vm = this;

        // Collapsible //
        var collapsibleStoreId = 'appraisal_report_review_open_errors_' + report.id + '_' + (account ? account.id : "");
        vm.isOpen = LocalStorage.HasModel(collapsibleStoreId);
        vm.toggleOpen = function() {
            vm.isOpen = !vm.isOpen;
            vm.isOpen ? LocalStorage.SaveModel(collapsibleStoreId, true) : LocalStorage.ClearModel(collapsibleStoreId);
        };
        vm.account = account;

        // Model //
        vm.appraisalReport = report;
        vm.appraisalOrder = order;

        vm.validationErrors = validationErrors;
        vm.hasValidationErrors = Object.keys(vm.validationErrors).filter(function(key) {
            return !key.startsWith('$') && vm.validationErrors[key].length > 0;
        }).length > 0;
        vm.reportErrors = reportErrors;
        vm.hasErrors = Object.keys(vm.reportErrors).some(function(key) { return vm.reportErrors[key]; });

        vm.version = null;
        vm.appraisalReportHtml = $sce.trustAsHtml("<p style='text-align: center'>Loading Web Report... </p>");

        vm.isCosigner = account && account.appraiserUser && vm.appraisalReport.cosignerId === account.appraiserUser.id;
        vm.isCandidate = account && account.appraiserUser && !(account.appraiserUser.designationCRA || account.appraiserUser.designationAACI) && account.appraiserUser.aicActive;
        vm.canAssignCosigner = (vm.appraisalOrder.state === 'PENDING_SUBMISSION' || vm.appraisalOrder.state === 'RESUBMISSION_REQUIRED' || vm.appraisalOrder.state === 'PENDING_COSIGNER_ASSIGNMENT') && !vm.appraisalReport.cosignerId && !vm.isCosigner && vm.isCandidate;
        // Flags
        vm.reportSubmittable = reportSubmittable;
        vm.isLender = $stateParams.lender === true;

        vm.cosigners = vm.canAssignCosigner ? AppraiserUser.getCosigners({orderId: vm.appraisalOrder.id}) : null;

        vm.signatureFileId = vm.isCosigner ? vm.appraisalReport.cosignerSignatureFileId : vm.appraisalReport.signatureFileId;
        vm.canSign = !vm.reportErrors.missingSignature && !vm.signatureFileId && !vm.reportErrors.aicInactive && AppraisalState.inAnyState(vm.appraisalOrder, AppraisalState.canSign);
        vm.canReSign = account && (!AppraisalState.inAnyState(vm.appraisalOrder, AppraisalState.submitted) || vm.appraisalOrder.state === 'RESUBMISSION_REQUIRED')
                            && vm.signatureFileId && account.signatureFile && (vm.signatureFileId !== account.signatureFile.id);
        vm.canSubmit = !vm.hasErrors && vm.appraisalReport.signatureFileId !== null && (!AppraisalState.inAnyState(vm.appraisalOrder, AppraisalState.submitted) || vm.appraisalOrder.state === 'RESUBMISSION_REQUIRED');

        // Methods //
        vm.initReport = initReport;
        vm.assignCosigner = assignCosigner;
        vm.submitReport = submitReport;
        vm.signReport = signReport;
        vm.acceptReport = acceptReport;
        vm.declineReport = declineReport;
        vm.goToActions = goToActions;
        vm.goToReport = goToReport;

        initReport();

        if (vm.isLender === false) {
            Principal.matchAnyAuthority(/^ROLE_ORIGINATOR.*/).then(function(hasAuth) {
                if (hasAuth) vm.acceptMessage = 'The report that you have reviewed meets your expectations?';
            });

            Principal.matchAnyAuthority(/^ROLE_VC.*/).then(function(hasAuth) {
                if (hasAuth) vm.acceptMessage = 'Would you like to release this report to the end users?';
            });
        }

        /**
         * Set the PDF URL and retrieve the HTML version from the server
         * @return {Promise} Promise that is resolved when the HTML report is done generating
         */
        function initReport() {
            vm.updating = true;

            // Set the generated pdf url
            var tempUrl = "/api/appraisal-reports/generate-pdf/" + vm.appraisalReport.id + "?version=" + (vm.version || "");
            if (vm.redacted) tempUrl += "&redacted=true"
            vm.appraisalReportPdfUrl = $sce.trustAsResourceUrl(tempUrl);

            // Generate the report HTML
            return AppraisalReport.generateHtml({id:$stateParams.id, version: vm.version, redacted: vm.redacted}, {}).$promise.then(function(data) {
                vm.appraisalReportHtml = $sce.trustAsHtml(data.response);
                // TODO: Return and reset 'updating' after BOTH versions have finished loading
                vm.updating = false;
            });
        }

        function acceptReport(ev) {
            var confirm = $mdDialog.confirm()
                .title('Accept Report')
                .textContent(vm.acceptMessage)
                .ariaLabel('Accept and Release Report')
                .targetEvent(ev)
                .ok('Accept')
                .cancel('Cancel');
            $mdDialog.show(confirm).then(function() {
                AppraisalReport.acceptReport({id:vm.appraisalReport.id}, {}, function(data){
                    $state.go("home");
                });
            });
        }

        function declineReport(ev) {
            $mdDialog.show({
                templateUrl: 'app/entities/appraisal-report/appraisal-report-decline-dialog.html',
                controller: 'AppraisalReportDeclineController',
                controllerAs: 'vm',
                fullscreen: true,
                locals: {
                    report: report,
                    order: order
                }
            }).then(function() {
                $state.go('appraisal-report-review', null, { reload: true });
            }, function() {
                $state.go('appraisal-report-review');
            });
        }

        function assignCosigner(ev) {
            var cosigners = vm.cosigners ? vm.cosigners.$promise : null;
            AppraisalOrderModal.assignCosigner(order, cosigners).then(function(result) {
                $state.reload();
            });
        }

        function submitReport(ev) {
            // Appending dialog to document.body to cover sidenav in docs app
        	if(vm.appraisalOrder.lender && vm.appraisalOrder.lender.sendReportToCCR){
        		var confirm = $mdDialog.confirm(ev)
                	.title('Submit Report')
                	.htmlContent("By selecting 'OK' you are acknowledging your confidence in the report being submitted.<br><font color=\"red\"> ATTENTION: This lender does not receive reports automatically through Value Connect. Please send to lender directly and cc appraisals@valueconnect.ca</font>")
                	.ariaLabel('Submit Report')
                	.targetEvent(ev)
                	.ok('OK')
                	.cancel('Cancel');
        	}else{
        		var confirm = $mdDialog.confirm(ev)
            	.title('Submit Report')
            	.htmlContent("By selecting 'OK' you are acknowledging your confidence in the report being submitted.<br> Your report will be sent to the lender though the VC portal. No further action is required. Thank you!")
            	.ariaLabel('Submit Report')
            	.targetEvent(ev)
            	.ok('OK')
            	.cancel('Cancel');

        	}
            $mdDialog.show(confirm).then(function() {
                AppraisalReport.submitReport({id:vm.appraisalReport.id}, {}, function(data) {
                    $state.go('appraisal-order-detail', { 'id' : vm.appraisalOrder.id});
                });
            });
        }

        function signReport(ev) {
            // Appending dialog to document.body to cover sidenav in docs app
            var confirm = $mdDialog.confirm(ev)
                .title('Sign Report')
                .textContent("By selecting 'OK' the report will be signed with your digital signature, after signing you will be brought back to the review page.")
                .ariaLabel('Sign Report')
                .targetEvent(ev)
                .ok('OK')
                .cancel('Cancel');

            $mdDialog.show(confirm).then(function() {
                AppraisalReport.signReport({id:vm.appraisalReport.id}, {}, function(data) {
                    $state.go("appraisal-report-review", {id: vm.appraisalReport.id}, {reload: true});
                }, function(error){
                    if(error){
                        AlertService.error(error.data.description);
                    }
                });
            });
        }

        function goToActions() {
            $location.hash('actions');
            $anchorScroll();
        }

        function goToReport() {
            $location.hash('report');
            $anchorScroll();
        }

        var unsubscribe = $rootScope.$on('valueconnectApp:appraisalReportUpdate', function(event, result) {
            vm.appraisalReport = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
