(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('ProgressInspectionSubjectController', ProgressInspectionSubjectController);

    ProgressInspectionSubjectController.$inject = ['$timeout', '$scope'];

    function ProgressInspectionSubjectController($timeout, $scope) {
        // Initialize model
        var vm = this;
        var sectionData = null;

        // Initialize model using form data
        init(null, $scope.vm.formData);
        $scope.$on('formDataReinitialized', init);

        function init(event, formData) {
            sectionData = $scope.vm.formData.constructionProgress;

            var client = $scope.vm.appraisalOrder.lender ?
                $scope.vm.appraisalOrder.lender.lenderName : ($scope.vm.appraisalOrder.clientContact.firstName + ' ' + $scope.vm.appraisalOrder.clientContact.lastName);

            if (['FORECLOSURE', 'POWER_OF_SALE'].includes($scope.vm.appraisalOrder.legalProceedingType)) {
                client = $scope.vm.appraisalOrder.brokerageName;
            }

            vm.translationParams = { client: client };
        }
    }
})();
