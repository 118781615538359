(function() {
    'use strict';

    angular
    .module('valueconnectApp')
    .controller('OriginatorNoteMessageboardController', OriginatorNoteMessageboardController);

    OriginatorNoteMessageboardController.$inject = ['$scope', 'Principal', '$state', 'order', 'notes', '$mdDialog', 'account'];

    function OriginatorNoteMessageboardController ($scope, Principal, $state, order, notes, $mdDialog, account) {

        var vm = this;

        // Entities
        vm.order = order;
        vm.notes = notes;
        vm.account = account;

        vm.orderAddress = [vm.order.address.address1, vm.order.address.cityName, vm.order.address.provinceName].filter(Boolean).join(', ');

        // Methods
        vm.createNote = createNote;

        function createNote() {
            $mdDialog.show({
                templateUrl: 'app/entities/originator-note/originator-note-dialog.html',
                controller: 'OriginatorNoteDialogController',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                resolve: {
                    entity: function () {
                        return {
                            note: null,
                            read: false,
                            appraisalOrderId: vm.order.id
                        };
                    }
                }
            })
            .then(
                function(data) {
                    $state.go('originator-note-messageboard', null, { reload: true });
                }, function() {
                    $state.go('originator-note-messageboard');
                }
            );
        }

    }
})();
