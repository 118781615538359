(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('NewsPastController', NewsPastController);

    NewsPastController.$inject = ['$scope', '$state', 'News', 'NewsSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function NewsPastController ($scope, $state, News, NewsSearch, ParseLinks, AlertService, pagingParams, paginationConstants) {
        var vm = this;        
        
        getPastNewsForAppraisers();
        function getPastNewsForAppraisers() {
        	News.getPastNewsForAppraisers(function(data)
        	{
        		vm.pastNewsForAppraisers = data;
        	}, function()
        	{
        		//
        	});
        }
        
        getPastNewsForOriginators ();
        function getPastNewsForOriginators () {
        	News.getPastNewsForOriginators(function(data)
        	{
        		vm.pastNewsForOriginators = data;
        	}, function()
        	{
        		//
        	});
        }
      
    }
})();
