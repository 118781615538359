(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .factory('LenderIntegrationBlacklistSearch', LenderIntegrationBlacklistSearch);

    LenderIntegrationBlacklistSearch.$inject = ['$resource'];

    function LenderIntegrationBlacklistSearch($resource) {
        var resourceUrl =  'api/_search/lender-integration-blacklists/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
