(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraiserFeeDialogController', AppraiserFeeDialogController);

    AppraiserFeeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$mdDialog', '$q', 'order', 'quote', 'Quote', 'RegionalProduct'];

    function AppraiserFeeDialogController ($timeout, $scope, $stateParams, $mdDialog, $q, order, quote, Quote, RegionalProduct) {
        // Initialize model
        var vm = this;
        init(quote);

        // Define controller methods
        vm.save = save;
        vm.clear = close;
        vm.subTotal = subTotal;
        vm.taxTotal = taxTotal;
        vm.grandTotal = grandTotal;

        // Initialize the model with a new quote
        function init(quote) {
            vm.quote = quote;
            vm.quote.lineItems.map(initLineItem);

            vm.order = order;
            vm.regionalProducts = RegionalProduct.query({regionId: quote.regionId, size: 9999, sort: 'productType'});

            vm.regionalProducts.$promise.then(function(products) {
                var productIds = products.map(function(product) { return product.id; });

                // Check if the current product is a fallback
                angular.forEach(vm.quote.lineItems, function(lineItem) {
                    if (lineItem.regionalProduct && !productIds.includes(lineItem.regionalProduct.id)) {
                        // Try to find another (non-fallback) product with the same productType
                        var matchedProduct = products.find(function(product) {
                            return product.productType === lineItem.regionalProduct.productType;
                        });

                        if(matchedProduct) {
                            // Update the fallback product to one from the current region with the same product type
                            lineItem.regionalProduct = matchedProduct;
                        } else {
                            // No match found; add the fallback product to the list of products
                            vm.regionalProducts = products.concat([lineItem.regionalProduct]);
                        }
                    }
                });
            });
        }

        /**
         * Save the quote, and optionally finalize it. On success, reinitialize the form with the
         * updated quote.
         * @param  {boolean} finalize Optional. If true, the quote will be finalized.
         * @return {promise}          Promise that is resolved with the result of the service request
         */
        function save() {
            vm.isSaving = true;
            return Quote.update({overrideAppraiserPrice: true, comments: vm.comments}, vm.quote).$promise
                .then(function(result) {
                    vm.quoteUpdated = true;
                    init(result);
                    $scope.$emit('valueconnectApp:quoteUpdate', result);
                    $mdDialog.hide(result);
                    return result;
                }).finally(function() {
                    vm.isSaving = false;
                });
        }

        function initLineItem(lineItem) {
            lineItem.$appraiserTax = function() {
                if(!angular.isNumber(lineItem.price)) return null;
                if(!lineItem.regionalProduct) return null;
                return lineItem.appraiserPrice * (lineItem.regionalProduct.taxRate - 1);
            };

            lineItem.$appraiserTotal = function() {
                if(!angular.isNumber(lineItem.price)) return null;
                if(!lineItem.regionalProduct) return null;
                return lineItem.appraiserPrice + lineItem.$appraiserTax();
            };

            lineItem.$tax = function() {
                if(!angular.isNumber(lineItem.price)) return null;
                if(!lineItem.regionalProduct) return null;
                return lineItem.price * (lineItem.regionalProduct.taxRate - 1);
            };

            lineItem.$total = function() {
                if(!angular.isNumber(lineItem.price)) return null;
                if(!lineItem.regionalProduct) return null;
                return lineItem.price + lineItem.$tax();
            };

            return lineItem;
        }

        /**
         * If the quote has been updated, resolve the dialog with the updated quote,
         * otherwise cancel the dialog
         */
        function close() {
            if (vm.quoteUpdated) $mdDialog.hide(vm.quote);
            else $mdDialog.cancel();
        }

        function subTotal(forAppraiser) {
            return vm.quote.lineItems
                .map(function(item) { return (forAppraiser ? item.appraiserPrice : item.price); })
                .reduce(sum, 0);
        }

        function taxTotal(forAppraiser) {
            return vm.quote.lineItems
                .map(function(item) { return (forAppraiser ? item.$appraiserTax() : item.$tax()); })
                .reduce(sum, 0);
        }

        function grandTotal(forAppraiser) {
            return vm.quote.lineItems
                .map(function(item) { return (forAppraiser ? item.$appraiserTotal() : item.$total()); })
                .reduce(sum, 0);
        }

        function sum(a,b) {
            var aIsNum = angular.isNumber(a), bIsNum = angular.isNumber(b);
            if(!aIsNum && !bIsNum) return null;
            else if(!aIsNum) return b;
            else if(!bIsNum) return a;
            else return a + b;
        }
    }
})();
