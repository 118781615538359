(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('RiskRuleController', RiskRuleController);

    RiskRuleController.$inject = ['$state', 'RiskRule', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function RiskRuleController($state, RiskRule, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.loadAll = loadAll;

        vm.loadAll();

        function loadAll () {
            var queryObj = {
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            };

            if (vm.searchQuery) queryObj['query'] = vm.searchQuery;

            RiskRule.query(queryObj, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;

                vm.riskRules = data;

                // For each risk rule
                angular.forEach(vm.riskRules, function(riskRule) {
                    // Convert string to json object
                    riskRule.riskRuleObject = (riskRule.rule) ? angular.fromJson(riskRule.rule) : {};

                    // Set rule condition
                    if (riskRule.riskRuleObject["AND"]) riskRule.riskRuleCondition = "AND";
                    else if (riskRule.riskRuleObject["OR"]) riskRule.riskRuleCondition = "OR";
                    else riskRule.riskRuleCondition = "";

                    // Setup expression list from risk rule
                    riskRule.expressionList = [];
                    vm.specialOperations = ["ANY_COMP_SALE_DATE_TOO_OLD", "FEWER_THAN_BEDROOM_VARIANCE", "ANY_EXCEEDING_NET_GROSS_PERCENTAGE", "VALUE_GREATER_THAN_MAX_SALE_PRICE",
                                            "MONTHS_ON_MARKET", "FEWER_THAN_SALES_DATE_WITHIN", "AT_LEAST_COMPS_DISTANCE_FROM", "FEWER_THAN_DESIGN_SIMILAR"];
                    angular.forEach((riskRule.riskRuleCondition) ? riskRule.riskRuleObject[riskRule.riskRuleCondition] : [riskRule.riskRuleObject], function(expression) {
                        var operator = Object.keys(expression)[0];
                        if (operator && vm.specialOperations.indexOf(operator) === -1) {
                            var pathList = expression[operator][0].split('.');
                            var tempExpression = {
                                operator: operator,
                                selectedField0: pathList[0],
                                selectedField1: pathList[1],
                                value: expression[operator][1]
                            };

                            if (pathList.length === 3)
                                tempExpression.selectedField2 = pathList[2];

                            riskRule.expressionList.push(tempExpression);
                        }
                    });
                });

                // At least one of the rules has an AND/OR condition (show condition column)
                vm.hasCondition = vm.riskRules.some(function(riskRule) {
                    return riskRule.riskRuleCondition !== "";
                });

                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            });
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.transition();
        }
    }
})();
