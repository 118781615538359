(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraisalFirmLocationDetailController', AppraisalFirmLocationDetailController);

    AppraisalFirmLocationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'AppraisalFirmLocation', 'AppraisalFirmRegion', 'Address'];

    function AppraisalFirmLocationDetailController($scope, $rootScope, $stateParams, entity, AppraisalFirmLocation, AppraisalFirmRegion, Address) {
        var vm = this;

        vm.appraisalFirmLocation = entity;

        var unsubscribe = $rootScope.$on('valueconnectApp:appraisalFirmLocationUpdate', function(event, result) {
            vm.appraisalFirmLocation = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
