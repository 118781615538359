/**
 * @param address {Object} An address object to center the map on. Will be geocoded if it has not
 *                         been already.
 * @param otherPins {Array} An array of pins to display on the map. Each element should be an object
 *                          with position (LatLng) and label (String) properties.
 * @param mapType {String} The map type to start the map on
 */
(function () {
    'use strict';

    angular
        .module('valueconnectApp')
        .directive('googleMapInput', googleMapInput);

    googleMapInput.$inject = ['$log', '$timeout', 'NgMap', 'Address', 'GOOGLE_MAP_API_KEY'];

    function googleMapInput($log, $timeout, NgMap, Address, GOOGLE_MAP_API_KEY) {
        var defaultZoom = 16;
        var apiKey = GOOGLE_MAP_API_KEY;

        var directive = {
            restrict: 'E',
            link: postLink,
            transclude: true,
            require: 'ngModel',
            templateUrl: "app/components/form/google-map-input.template.html",
            scope: {
                // otherPinsUpdated: "=",
                mapId: '@',
                address: '=',
                otherPins: '=',
                centerLat: "=",
                centerLng: "=",
                zoom: "=",
                mapType: '@',
                editMode: "=",
                addressUpdatedType: '=',
                apiKey: '@'
            }
        };

        return directive;

        function postLink(scope, elm, attrs, ngModel) {
            scope.modelCtrl = ngModel;
            scope.editMode = false;
            scope.mapUrl;
            scope.apiKey = apiKey;
            scope.initMap = initMap;
            scope.edit = edit;
            scope.pinMoved = pinMoved;
            scope.cancel = cancel;
            scope.save = save;
            scope.attempts = 0;
            scope.$watch(function () {
                return scope.address;
            }, initAddress, true);
            scope.$watch(function () {
                return scope.otherPins;
            }, initOtherPins, true);
            // scope.$watch(function () {
            //     return scope.otherPinsUpdated;
            // }, initMap, true);
            scope.$on('otherPinsUpdated', initMap);

            /**
             * Ensure that the provided address has been geocoded (has lat/lng).
             * Once the address has been initialized, enter edit mode if there is no model.
             *
             * @param  {Object} address The address to initialize
             */
            function initAddress(address) {
                if (!address) return;

                // Ensure the lat/lng have been initialized
                if (!address.lat || !address.lng) {
                    if (scope.attempts < 2) {
                        scope.attempts++;
                        return Address.geocode(address, null, initAddress).$promise;

                    } else {
                        return {};
                    }
                }

                // Init the rest of the model if we have the lat/lng
                scope.address = address;

                // Go into edit mode if there is no model
                if (!ngModel.$modelValue) {
                    edit();
                }
            }

            function initOtherPins(otherPins) {
                NgMap.getMap(scope.mapId).then(function (map) {
                    angular.forEach(otherPins, function (pin) {
                        if (pin.newPin) {
                            pin.position = map.center;
                            pin.newPin = false;
                        }
                    });
                });
            }

            /**
             * Enter edit mode. On the first call to this method, the map will be initialized.
             */
            function edit() {
                scope.editMode = true;

                // Wait for DOM to update before configuring the map
                $timeout(function () {
                    NgMap.getMap(scope.mapId).then(function (map) {
                        google.maps.event.trigger(map, 'resize');

                        // Do some initialization the first time this is called
                        if (!scope.mapInitialized) {

                            // Set map type (hybrid, roadmap, ..)
                            if (scope.mapType) map.setMapTypeId(scope.mapType);

                            // Check if map center/zoom has already been saved
                            if (scope.centerLat && scope.centerLng && scope.zoom) {
                                var centerLatLng = new google.maps.LatLng(scope.centerLat, scope.centerLng);
                                map.setCenter(centerLatLng);
                                map.setZoom(scope.zoom);
                            } else {
                                // Otherwise use address as center and default zoom or create bounds from marker positions if any exist
                                var addressLatLng = new google.maps.LatLng(scope.address.lat, scope.address.lng);
                                if (scope.otherPins && scope.otherPins.length) {
                                    var bounds = new google.maps.LatLngBounds(null);
                                    scope.otherPins.forEach(function (x) {
                                        if (x.position) bounds.extend(x.position);
                                    });
                                    bounds.extend(addressLatLng);
                                    map.fitBounds(bounds);
                                } else {
                                    map.setCenter(addressLatLng);
                                    map.setZoom(defaultZoom);
                                }
                            }
                            scope.mapInitialized = 'initialized';
                        }
                    });
                }, 100);
            }

            // Copied this from edit because map is uninitialized upon page load. Might reset custom pins though?
            // Wait for DOM to update before configuring the map
            function initMap() {
                scope.editMode = true;

                // Wait for DOM to update before configuring the map
                $timeout(function () {
                    NgMap.getMap(scope.mapId).then(function (map) {
                        google.maps.event.trigger(map, 'resize');

                        // Do some initialization the first time this is called
                        if (!scope.mapInitialized) {

                            // Set map type (hybrid, roadmap, ..)
                            if (scope.mapType) map.setMapTypeId(scope.mapType);

                            // Check if map center/zoom has already been saved
                            if (scope.centerLat && scope.centerLng && scope.zoom) {
                                var centerLatLng = new google.maps.LatLng(scope.centerLat, scope.centerLng);
                                map.setCenter(centerLatLng);
                                map.setZoom(scope.zoom);
                            } else {
                                // Otherwise use address as center and default zoom or create bounds from marker positions if any exist
                                var addressLatLng = new google.maps.LatLng(scope.address.lat, scope.address.lng);
                                if (scope.otherPins && scope.otherPins.length) {
                                    var bounds = new google.maps.LatLngBounds(null);
                                    scope.otherPins.forEach(function (x) {
                                        if (x.position) bounds.extend(x.position);
                                    });
                                    bounds.extend(addressLatLng);
                                    map.fitBounds(bounds);
                                } else {
                                    map.setCenter(addressLatLng);
                                    map.setZoom(defaultZoom);
                                }
                            }
                            scope.mapInitialized = 'initialized';
                        }
                    });
                    save();
                }, 100);
            }

            function pinMoved(ev) {
                if (this.index === -1) {
                    scope.address.lat = ev.latLng.lat();
                    scope.address.lng = ev.latLng.lng();
                    scope.addressUpdatedType = 2;
                } else {
                    scope.otherPins[this.index].position.lat = ev.latLng.lat;
                    scope.otherPins[this.index].position.lng = ev.latLng.lng;
                }
            }

            /**
             * Leave edit mode, making no changes to the model
             */
            function cancel() {
                scope.editMode = false;
            }

            /**
             * leave edit mode, update the fileUrl and mark the model as dirty.
             */
            function save() {
                NgMap.getMap(scope.mapId).then(function (map) {
                    scope.editMode = false;
                    scope.mapUrl = generateUrl(map);
                    scope.zoom = map.getZoom();
                    scope.centerLat = map.getCenter().lat();
                    scope.centerLng = map.getCenter().lng();
                    ngModel.$setViewValue({
                        fileUrl: scope.mapUrl,
                        fileName: attrs.name + '.png',
                        contentType: 'image/png'
                    });
                });
            }

            /**
             * Generate an image URL based on the current map state
             */
            function generateUrl(map) {
                var zoom = map.getZoom();
                var center = map.getCenter();
                var mapType = map.getMapTypeId();
                var address = scope.address;

                var otherMarkers = angular.isDefined(scope.otherPins) ? scope.otherPins.sort(function (a, b) {
                    return a.label > b.label;
                }).map(function (pin, index) {
                    var label = pin.label || String.fromCharCode(65 + index);
                    return "markers=color:blue|label:" + label + "|" + pin.position.lat() + "," + pin.position.lng();
                }) : "";

                var urlParams = [
                    "key=" + scope.apiKey,
                    "scale=2",
                    "size=512x512",
                    "maptype=" + mapType,
                    "zoom=" + zoom,
                    "center=" + center.lat() + ',' + center.lng(),
                    "markers=" + address.lat + ',' + address.lng,
                    "markers=color:blue|"
                ].concat(otherMarkers);
                return "https://maps.googleapis.com/maps/api/staticmap?" + urlParams.join('&');
            }
        }

    }
})();
