(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('IdleEventsController', IdleEventsController);

    IdleEventsController.$inject = ['$scope', '$state', '$timeout', '$mdDialog', 'Idle', 'Principal', 'Auth', 'Account'];

    function IdleEventsController($scope, $state, $timeout, $mdDialog, Idle, Principal, Auth, Account) {
      $scope.events = [];

      var vm = {
        dialogOpen: false
      };

      $scope.$on('IdleStart', function() { 
          // the user appears to have gone idle

      });

      $scope.$on('IdleWarn', function(e, countdown) {
          // follows after the IdleStart event, but includes a countdown until the user is considered timed out
          // the countdown arg is the number of seconds remaining until then.
          // you can change the title or display a warning dialog from here.
          // you can let them resume their session by calling Idle.watch()
          $scope.countdown = countdown;

          if (vm.dialogOpen) {
            $scope.$apply();
          } else {
            vm.dialogOpen = true;
            $mdDialog.show({
              contentElement: '#countdownDialog',
              parent: angular.element(document.body)
            }).then(function(){
              vm.dialogOpen = false;
            });
          }
      });

      $scope.$on('IdleTimeout', function() {
          // the user has timed out (meaning idleDuration + timeout has passed without any activity)
          // this is where you'd log them
          console.log("IdleTimeout");
          $mdDialog.hide();
          vm.dialogOpen = false;
          $scope.$apply();
          Auth.logout();
          //authSuccessUnsubscribe = $scope.$on('authenticationSuccess', watchIdle);
          $state.go('login');
      });

      $scope.$on('IdleEnd', function() {
          // the user has come back from AFK and is doing stuff. if you are warning them, you can use this to hide the dialog
          $mdDialog.hide();
          vm.dialogOpen = false;
          $scope.$apply();
      });

      $scope.$on('Keepalive', function() {
        // do something to keep the user's session alive
        Account.get();
      });

      var authSuccessUnsubscribe = $scope.$on('authenticationSuccess', watchIdle);

      function watchIdle() {
          Idle.watch();
          //authSuccessUnsubscribe();
      }      

      Principal.identity().then(function(account) {
        console.log("CHECK IDLE: " + account + ", " + Idle.running());
          if(account != null && !Idle.running()) {
            watchIdle();
          }
      });

    }
})();

