(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('PaymentDialogController', PaymentDialogController);

    PaymentDialogController.$inject = ['$timeout', '$window', '$sce', '$stateParams', '$mdDialog', 'entity', 'QuoteSplit', 'Quote', 'AppraisalOrder', 'ProfileService'];

    function PaymentDialogController ($timeout, $window, $sce, $stateParams, $mdDialog, entity, QuoteSplit, Quote, AppraisalOrder, ProfileService) {
        var vm = this;

        vm.payment = entity;
        vm.clear = clear;
        vm.stripePaymentLinkGenerating = false;
        vm.generateStripePaymentLink = generateStripePaymentLink;

        vm.orderState = null;

        if (typeof(vm.payment.quoteSplitId) === "undefined") {
            alert("TODO: No Quote Split to pay");
        } else {
            vm.quoteSplit = QuoteSplit.get({id:vm.payment.quoteSplitId}, function(data){
                vm.payment.amount = data.splitAmount;
                if (data.paymentKey) {
                    vm.clientPaymentUrl = $window.location.href.replace(/[/][#].*/,"")
                        + "/#/payment/client/" + data.paymentKey;
                }
                vm.quote = Quote.get({id:vm.quoteSplit.quoteId}, function(data) {
                    vm.appraisalOrder = AppraisalOrder.get({id:vm.quote.appraisalOrderId}, function(resp) {
                        vm.orderState = resp.state;
                        vm.orderCancelled = ['ORDER_CANCELLED', 'REFUND_REQUIRED'].includes(vm.orderState);
                    });

                });

                ProfileService.getProfileInfo().then(function(data){
                    vm.profile = data;
                    vm.profile.paypalActionUrl = $sce.trustAsResourceUrl(vm.profile.paypalActionUrl);
                    vm.payPalReturnUrl = vm.profile.paypalReturnUrl + ($stateParams.token ? $stateParams.token : vm.quoteSplit.id);
                });
            });
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $mdDialog.cancel();
        }

        function generateStripePaymentLink () {
            vm.stripePaymentLinkGenerating = true;

            QuoteSplit.generateQuoteSplitPaymentLink({ id: vm.quoteSplit.id }, {},
                function(result) {
                    if(result.stripePaymentLink.length > 0) {
                        $window.open(result.stripePaymentLink,'_blank');

                        $translate('valueconnectApp.payment.paymentLinkGenerationSuccess').then(function(trans) {
                            AlertService.success(trans);
                        });
                        vm.quoteSplit.stripePaymentLink = result.stripePaymentLink;
                    } else {
                        $translate('valueconnectApp.payment.paymentLinkGenerationError').then(function(trans) {
                            AlertService.error(trans);
                        });
                    }

                    vm.stripePaymentLinkGenerating = false;
                },
                function(result) {
                    $translate('valueconnectApp.payment.paymentLinkGenerationError').then(function(trans) {
                        AlertService.error(trans);
                    });
                    vm.stripePaymentLinkGenerating = false;
                }
            );
        }
    }
})();
