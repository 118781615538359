(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('LenderIntegrationBlacklist', LenderIntegrationBlacklist);

    LenderIntegrationBlacklist.$inject = ['$resource'];

    function LenderIntegrationBlacklist ($resource) {
        var resourceUrl =  'api/lender-integration-blacklists/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'isBlackListed': { method: 'GET',
              url: 'api/lender-integration-blacklists/isBlacklisted/:integrationID/:lenderID',
              responseType:'boolean',
              transformResponse: function(data, headersGetter) { return { data : data }; }
            },
            'isBlackListedByName': { method: 'GET',
              url: 'api/lender-integration-blacklists/isBlacklistedByName/:integrationID/:lenderName',
              responseType:'boolean',
              transformResponse: function(data, headersGetter) { return { data : data }; }
            },
            'getMinimal': { method: 'GET', url:'api/lender-integration-blacklists-minimal', isArray: true}
        });
    }
})();
