(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('ProgressInspectionConstructionProgressController', ProgressInspectionConstructionProgressController);

    ProgressInspectionConstructionProgressController.$inject = ['$timeout', '$scope'];

    function ProgressInspectionConstructionProgressController($timeout, $scope) {
        // Initialize model
        var vm = this;
        var sectionData = null;

        vm.maxItems = 17;

        // vm.addStage = addStage;
        vm.addItem = addItem;
        vm.removeItem = removeItem;

        // Initialize model using form data
        init(null, $scope.vm.formData);
        $scope.$on('formDataReinitialized', init);

        function init(event, formData) {
            sectionData = $scope.vm.formData.constructionProgress;
            if (!sectionData.$started && !sectionData.stages.length) {
                $timeout(function() {
                    initializeDefaults();
                });
            } else {
                // Initalize each stage
                sectionData.stages.forEach(function(stage) {
                    initStage(stage);
                });

            }

            sectionData.totalProgress = function() {
                return sectionData.stages.map(function(stage) {
                    return $scope.vm.isNotDeleted(stage) ? stage.stageTotalPercentage() : 0.0;
                }).reduce(sum, 0);
            };
        }

        function initializeDefaults() {
            var initialItems = [[
                "Architect and Plans", "Permits, Surveyor, Home Warranty", "Service Connections", "Lot Clearing, Excavation, Backfill", "Footings, Foundation, Basement Floor",
                "Water Supply and Waste Disposal", "Framing", "Roof", "Windows", "Exterior Doors"
            ], [
                "Exterior Finish", "Soffit, Gutter and Facia", "Rough Plumbing", "Rough Electrical", "Heating and Air Conditioning", "Insulation", "Drywall", "Fireplace and Chimneys"
            ], [
                "Plumbing Fixtures", "Electrical Fixtures", "Flooring", "Cabinets and Vanities", "Finishing", "Painting", "Interior Doors", "Tile Work", "Built-ins",
                "Garage Doors and Openers", "Decks, Sidewalks and Patios", "Driveway, Landscaping"
            ]];

            var initialItemPercentages = [
                [0.007, 0.017, 0.016, 0.025, 0.06, 0.006, 0.018, 0.03, 0.031, 0.012],
                [0.082, 0.008, 0.026, 0.038, 0.045, 0.022, 0.062, 0.011],
                [0.037, 0.01, 0.045, 0.048, 0.055, 0.038, 0.016, 0.01, 0.021, 0.012, 0.015, 0.015]
            ];

            var initialStages = ["Stage 1 - Foundation and Rough Framing", "Stage 2 - Major Systems and Coverings", "Stage 3 - Finishing"];

            // Add stage with title, then add individual items
            angular.forEach(initialItems, function(items, stageIndex) {
                addStage();
                sectionData.stages[stageIndex].title = initialStages[stageIndex];
                $scope.vm.setDirty('constructionProgress.stages[' + stageIndex + '].title');
                angular.forEach(items, function(item, itemIndex) {
                    sectionData.stages[stageIndex].items.push({
                        name: item,
                        itemPercentage: initialItemPercentages[stageIndex][itemIndex],
                        completePercentage: 0.0,
                        totalPercentage: 0.0
                    });
                    angular.forEach(['name','itemPercentage','completePercentage','totalPercentage'], function(key) {
                        $scope.vm.setDirty('constructionProgress.stages[' + stageIndex + '].items[' + itemIndex + '].' + key);
                    });
                });
            });
        }

        function initStage(stage) {
            stage.stageItemPercentage = function() {
                return stage.items.map(function(item) { return $scope.vm.isNotDeleted(item) ? item.itemPercentage : 0.0; }).reduce(sum, 0);
            };

            stage.stageTotalPercentage = function() {
                return stage.items.map(function(item) { return $scope.vm.isNotDeleted(item) ? item.totalPercentage : 0.0; }).reduce(sum, 0);
            };

            return stage;
        }

        function addStage() {
            var stage = { title: "", items: [] };
            sectionData.stages.push(initStage(stage));
        }

        function addItem(stageIndex) {
            sectionData.stages[stageIndex].items.push({});
        }

        function removeItem(stageIndex, itemIndex) {
            $scope.vm.removeFromArray(sectionData.stages[stageIndex].items, itemIndex);
        }

        function sum(a,b) {
            return angular.isDefined(b) ? a + b : a;
        }
    }
})();
