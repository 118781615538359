(function () {
  'use strict';
  angular
    .module('valueconnectApp')
    .controller('AppraisalFirmSelectionDialogController', AppraisalFirmSelectionDialogController);

  AppraisalFirmSelectionDialogController.$inject = ['$scope', '$mdDialog', '$document'];

  function AppraisalFirmSelectionDialogController($scope, $mdDialog, $document) {
    var data = [[1, 2, 3], [10, 20, 30], [100, 200, 300]];
    var vm = this;
    vm.onSubmit = onSubmit;
    vm.showAdvanced = showAdvanced;
    vm.selectedAppraiserFirm = [];

    $scope.hide = function () {
      $mdDialog.hide();
    };

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    function onSubmit(ev) {
      //validate
      vm.selectedAppraiserFirm = getSelectedRow();
      if(typeof vm.selectedAppraiserFirm !== 'undefined' && vm.selectedAppraiserFirm.length > 0){
        var lineItem = vm.selectedAppraiserFirm[0];
        var appraisalFirm = {
            'isSelected' : true,
            'appraisalFirmName' : lineItem[0],
            'distanceInKm' : lineItem[1],
            'isLendersApproved' : lineItem[2],
        }
        $scope.$parent.status = appraisalFirm;
        $mdDialog.hide();
      }else{
            // Selection error
      }
    };

    function getSelectedRow(){
        return jQuery('#dt-select').DataTable().rows( { selected: true } ).data();
    }

    function showAdvanced(ev) {
      $mdDialog.show({
        controller: AppraisalFirmSelectionDialogController,
        controllerAs: 'vm',
        scope:$scope,
        preserveScope: true,
        templateUrl: 'app/entities/appraisal-order/appraisal-order-dialog/appraisal-order-dialog.appraiser-firm-selection-dialog.html',
        targetEvent: ev,
        clickOutsideToClose: false,
        onComplete: function () {
          var table = jQuery('#dt-select').DataTable({
            data: data,
            columns: [
              {
                title: "Appraisal Firm"
              },
              {
                title: "Distance(KM)"
              },
              {
                title: "Lenders Approved"
              }
            ],
            select: true
          });
          // Hiding some of the features
          jQuery('.dataTables_info').hide();
          jQuery('.dataTables_length').hide();

          // Onclick Event for selected item in datatable
          jQuery('.dataTable').on('click', 'tbody tr', function (e) {
            // TODO Currently the Shift and click and select multiple options. Fix that issue.
            table.rows().deselect();
          });
        }
      })
        .then(function (answer) {
          $scope.status = 'You said the information was "' + answer + '".';
        }, function () {
          $scope.status = 'You cancelled the dialog.';
        });
    };

  }

})();
