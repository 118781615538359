(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('PricingMatrix', PricingMatrix);

    PricingMatrix.$inject = ['$resource'];

    function PricingMatrix ($resource) {
        var resourceUrl =  'api/regions/:id/pricing-matrix';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    return data ? angular.fromJson(data) : data;
                }
            },
            'update': { method:'PUT' },
            'clone': { method: 'POST', url: 'api/regions/:id/pricing-matrix/clone' }
        });
    }
})();
