(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('JhiLanguageController', JhiLanguageController);

    JhiLanguageController.$inject = ['$translate', 'JhiLanguageService'];

    function JhiLanguageController ($translate, JhiLanguageService) {
        var vm = this;

        vm.languages = null;

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

    }
})();
