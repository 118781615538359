(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('appraisal-order-contact', {
            parent: 'entity',
            url: '/appraisal-order-contact',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'valueconnectApp.appraisalOrderContact.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/appraisal-order-contact/appraisal-order-contacts.html',
                    controller: 'AppraisalOrderContactController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('appraisalOrderContact');
                    $translatePartialLoader.addPart('appraisalContactRole');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('appraisal-order-contact-detail', {
            parent: 'entity',
            url: '/appraisal-order-contact/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'valueconnectApp.appraisalOrderContact.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/appraisal-order-contact/appraisal-order-contact-detail.html',
                    controller: 'AppraisalOrderContactDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('appraisalOrderContact');
                    $translatePartialLoader.addPart('appraisalContactRole');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'AppraisalOrderContact', function($stateParams, AppraisalOrderContact) {
                    return AppraisalOrderContact.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        .state('appraisal-order-contact.new', {
            parent: 'appraisal-order-contact',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/appraisal-order-contact/appraisal-order-contact-dialog.html',
                    controller: 'AppraisalOrderContactDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                id: null,
                                role: null,
                                contact: {
                                    officeAddress: {}
                                }
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('appraisal-order-contact', null, { reload: true });
                }, function() {
                    $state.go('appraisal-order-contact');
                });
            }]
        })
        .state('appraisal-order-contact.edit', {
            parent: 'appraisal-order-contact',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/appraisal-order-contact/appraisal-order-contact-dialog.html',
                    controller: 'AppraisalOrderContactDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['AppraisalOrderContact', function(AppraisalOrderContact) {
                            return AppraisalOrderContact.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('appraisal-order-contact', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('appraisal-order-contact.delete', {
            parent: 'appraisal-order-contact',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/appraisal-order-contact/appraisal-order-contact-delete-dialog.html',
                    controller: 'AppraisalOrderContactDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['AppraisalOrderContact', function(AppraisalOrderContact) {
                            return AppraisalOrderContact.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('appraisal-order-contact', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
