(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('TemplateSelectController', TemplateSelectController);

    TemplateSelectController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'formType', 'section', 'TemplateSearch'];

    function TemplateSelectController ($timeout, $scope, $uibModalInstance, formType, section, TemplateSearch) {
        var vm = this;

        vm.formType = formType;
        vm.section = section;

        vm.template = null;
        vm.searchQuery = "";
        vm.templatesPromise = searchTemplates();

        vm.searchTemplates = searchTemplates;
        vm.matchTemplates = matchTemplates;
        vm.clear = clear;
        vm.save = save;

        function searchTemplates() {
            vm.templatesPromise = TemplateSearch.query({ query: vm.searchQuery, formType: vm.formType, section: vm.section }).$promise;
            return vm.templatesPromise;
        }

        function matchTemplates(searchText) {
            return vm.templatesPromise.then(function(templates) {
                var searchRegex = new RegExp(searchText ? searchText : "", 'i');
                return templates.filter(function(template) {
                    return searchRegex.test(template.name);
                });
            });
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            $uibModalInstance.close(vm.template.id);
        }

        function onSaveSuccess (result) {
            $scope.$emit('valueconnectApp:templateUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            AlertService.error("Unable to load template");
            vm.isSaving = false;
        }
    }
})();
