(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .factory('NewFeatureSearch', NewFeatureSearch);

    NewFeatureSearch.$inject = ['$resource'];

    function NewFeatureSearch($resource) {
        var resourceUrl =  'api/_search/new-features/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
