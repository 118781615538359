(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('LenderController', LenderController);

    LenderController.$inject = ['$scope', '$state', 'identity', 'Lender', 'LenderSearch', 'Principal', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'AppraisalOrderModal'];

    function LenderController ($scope, $state, identity, Lender, LenderSearch, Principal, ParseLinks, AlertService, pagingParams, paginationConstants, AppraisalOrderModal) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.addContact = addContact;
        Principal.identity().then(function(id){
            vm.isBrokerage = Principal.hasAnyAuthority([/^ROLE_(ORIGINATOR).*$/]);
            loadAll();
        });

        function loadAll () {
            if (pagingParams.search) {
                Lender.getLendersGrid({ // getLenderGrid to get required fields for the grid that is shown to admin
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    isPrivate: vm.isBrokerage ? true : undefined
                }, onSuccess, onError);
            } else {
                Lender.getLendersGrid({// getLenderGrid to get required fields for the grid that is shown to admin
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    isPrivate: vm.isBrokerage ? true : undefined
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.lenders = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'lenderName';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function addContact(lenderId) {
            AppraisalOrderModal.openLenderContactDialog(lenderId);
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
    }
})();
