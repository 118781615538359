(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('BlackoutPeriod', BlackoutPeriod);

    BlackoutPeriod.$inject = ['$resource', 'DateUtils'];

    function BlackoutPeriod ($resource, DateUtils) {
        var resourceUrl =  'api/blackout-periods/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'generateBlackoutReport': { method: 'GET', url:'api/blackout-periods/report', isArray: true},
            'generateBlackoutReportExport': {
                method: 'GET',
                url: 'api/blackout-periods/report/export',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    if (data) return { file: new Blob([data], { type: 'application/vnd.ms-excel' }) };
                    else return data;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.startDate = DateUtils.convertDateTimeFromServer(data.startDate);
                        data.endDate = DateUtils.convertDateTimeFromServer(data.endDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
