(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('NavbarController', NavbarController)
        .constant('LOGO_REDIRECT_URLS', {
            loginHash:'#/login',
            prodRedirectUrl:'https://www.valueconnect.ca',
            nonProdRedirectUrl:'https://yahoo.ca',
            dashboardHash:'#/'
        });

    NavbarController.$inject = ['$rootScope', '$scope', '$state', '$window', 'Auth', 'Principal', 'ProfileService', 'LoginService','LOGO_REDIRECT_URLS'];

    function NavbarController ($rootScope, $scope, $state, $window,  Auth, Principal, ProfileService, LoginService, LOGO_REDIRECT_URLS) {
        var vm = this;
        console.log($window);

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;



        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerDisabled = response.swaggerDisabled;
        });

        vm.login = login;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.redirect = redirect;
        vm.navigateToPlaceOrder = navigateToPlaceOrder;
        vm.$state = $state;

        setIdentity();
        setBrokerage();
        setAppraisalFirm();
        $rootScope.$on('authenticationSuccess', function() {
            setIdentity();
            setBrokerage();
            setAppraisalFirm();
        });

        function setIdentity() {
            Principal.identity().then(function(_id) {
                vm.identity = _id;
            });
        }

        function setBrokerage() {
            Principal.getBrokerageId().then(function(brokerageId) {
                vm.brokerageId = brokerageId;
            });
        }

        function setAppraisalFirm() {
            Principal.getAppraisalFirmId().then(function(appraisalFirmId) {
                vm.appraisalFirmId = appraisalFirmId;
            });
        }

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function redirect(){
            if(vm.inProduction && $window.location.hash === LOGO_REDIRECT_URLS.loginHash){
                $window.location.href = LOGO_REDIRECT_URLS.prodRedirectUrl;
            }
            else if($window.location.hash === LOGO_REDIRECT_URLS.loginHash){
                $window.location.href = LOGO_REDIRECT_URLS.nonProdRedirectUrl;
            }else{
                $window.location.hash = LOGO_REDIRECT_URLS.dashboardHash;
            }
        }

        function navigateToPlaceOrder() {
            $window.location.href = $window.location.protocol + "//" + $window.location.host + "/v2/appraisal-order/new"
        }
    }
})();
