(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraiserUserDialogController', AppraiserUserDialogController);

    AppraiserUserDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'AppraiserUser', 'Region', 'User', 'VCUser'];

    function AppraiserUserDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, AppraiserUser, Region, User, VCUser) {
        var vm = this;

        vm.appraiserUser = entity;
        vm.clear = clear;
        vm.save = save;
        vm.regions = Region.query({ size: 1000, sort: 'name' });
        vm.users = User.queryManaged();
        vm.vcusers = VCUser.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.appraiserUser.id !== null) {
                AppraiserUser.update(vm.appraiserUser, onSaveSuccess, onSaveError);
            } else {
                AppraiserUser.save(vm.appraiserUser, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('valueconnectApp:appraiserUserUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
