(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('BrokerageRegionDetailController', BrokerageRegionDetailController);

    BrokerageRegionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'BrokerageRegion', 'Brokerage', 'BrokerageLocation'];

    function BrokerageRegionDetailController($scope, $rootScope, $stateParams, entity, BrokerageRegion, Brokerage, BrokerageLocation) {
        var vm = this;

        vm.brokerageRegion = entity;

        var unsubscribe = $rootScope.$on('valueconnectApp:brokerageRegionUpdate', function(event, result) {
            vm.brokerageRegion = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
