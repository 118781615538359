(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .directive('decodeFix', decodeFix);

    decodeFix.$inject = [];

    function decodeFix () {
        var directive = {
            restrict: 'A',
            require: '?ngModel',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs, ngModel){
            if (!ngModel) return;

            scope.unregister = registerWatch(scope, element, attrs, ngModel);
        }

        function registerWatch(scope, element, attrs, ngModel) {
            var unregister = scope.$watch(attrs.ngModel, function (v) {
                unregister();
                var txt = document.createElement("textarea");
                txt.innerHTML = v || "";
                var newValue = txt.value;
                txt = null;

                //Cannot use ngModel.$setViewValue(newValue); as it marks the field and
                //form as dirty causing a new version
                ngModel.$viewValue = newValue;
                ngModel.$render();

                setTimeout(function(){
                    unregister = registerWatch(scope, element, attrs, ngModel);
                }, 50);
            });
        }
    }
})();
