angular.module('passwordCheck', []).
	directive('passwordCheck', function() {
		return {
			require: 'ngModel',
			link: function(scope, elem, attrs, ctrl) {
				var firstPassword = '#' + attrs.passwordCheck;
				console.log(firstPassword);
				elem.add(firstPassword).on('blur', function() {
					scope.$apply(function() {

						var v = elem.val() === $(firstPassword).val();
						
						ctrl.$setValidity('pwmatch', v);
					});
				});
			}
		}
		
	});