(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('News', News);

    News.$inject = ['$resource', 'DateUtils'];

    function News ($resource, DateUtils) {
        var resourceUrl =  'api/news/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        //data.postingDate = DateUtils.convertLocalDateFromServer(data.postingDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    //data.postingDate = DateUtils.convertLocalDateToServer(data.postingDate);
                    return angular.toJson(data);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    //data.postingDate = DateUtils.convertLocalDateToServer(data.postingDate);
                    return angular.toJson(data);
                }
            },            
                 
            'getRecentNewsForAppraisers': {method: 'GET', url:'api/appraisers-news-recent/', isArray: true },
            'getRecentNewsForOriginators': {method: 'GET', url:'api/originators-news-recent/', isArray: true },            
            'getPastNewsForAppraisers': {method: 'GET', url:'api/appraisers-news-past/', isArray: true },
            'getPastNewsForOriginators': {method: 'GET', url:'api/originators-news-past/', isArray: true },           
            'getUrgentNotifications' : {method: 'GET', url:'api/user-notification', isArray: true }  
         });
    }
})();
