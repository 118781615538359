(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .directive('scrollControl', ['$log', scrollControl]);

    function scrollControl ($log) {
        var directive = {
            restrict: 'E',
            link: {
                pre: preLink,
                post: link
            },
            templateUrl: 'app/components/scroll/scroll-control.template.html',
            scope: {
                'scrollTarget': '@',
                'scrollIncrement': '='
            }
        };

        return directive;

        function preLink(scope, elm, attrs) {
            // Validate the the scroll increment
            if(angular.isDefined(scope.scrollIncrement) && !angular.isNumber(scope.scrollIncrement) || scope.scrollIncrement === 0) {
                $log.error("Invalid scroll increment specified:", scope.scrollDirection);
                return;
            }
            elm.addClass(scope.scrollIncrement > 0 ? 'right' : 'left');

            // Set up scope
            scope.windowHeight = angular.element(window).height();
        }

        function link(scope, elm, attrs) {
            // Get the scroll target element
            var scrollTarget = angular.element(scope.scrollTarget);
            if(!scrollTarget.length) {
                $log.error("Scroll target does not exist: " + scope.scrollTarget);
                return;
            }

            // Set up scope
            scope.scroll = scroll;

            /**
             * Scroll the target element.
             * The scroll increment is set dynamically based on the width of the scroll children
             * This method assumes the children all share the same width
             */
            var scrolling = false;
            function scroll() {
                // Do nothing if we are already scrolling
                if(scrolling) return;
                else scrolling = true;

                // Collect calculation variables
                var childWidth = scrollTarget.children().first().outerWidth();
                var currentPos = scrollTarget.scrollLeft();
                var gridOffset = currentPos % childWidth;
                var direction = scope.scrollIncrement > 0 ? 1 : -1;

                // Calculate increment
                var increment = childWidth*direction;
                if(gridOffset !== 0) increment += direction === 1 ? -gridOffset : (childWidth - gridOffset);

                // Perform animation
                scrollTarget.animate({scrollLeft: currentPos + increment + 'px'}, function() {
                    scrolling = false;
                });
            }
        }
    }
})();
