(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('StandardResidentialCertificationController2024',StandardResidentialCertificationController);

    StandardResidentialCertificationController.$inject = ['$scope'];

    function StandardResidentialCertificationController($scope) {
        var vm = this;

        vm.appraiser = $scope.vm.appraisalOrder.appraiser;
        vm.appraiserUser = $scope.vm.appraiserUser;

        vm.appraiserCertification = [
            vm.appraiserUser.designationCRA ? 'CRA P.App' : null,
            vm.appraiserUser.designationAACI ? 'AACI P.App' : null,
            vm.appraiserUser.credentials
        ].filter(Boolean).join(', ');

        setDefaultValues();

        function setDefaultValues() {
            if ($scope.vm.formData.certification.appraiserInspectedDate == undefined) {
                $scope.vm.formData.certification.appraiserInspectedDate = new Date();
                $scope.vm.setDirty('certification.appraiserInspectedDate');
            }
        }
    }
})();
