(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('StandaloneMarketRentCertificationController',StandaloneMarketRentCertificationController);

    StandaloneMarketRentCertificationController.$inject = ['$scope'];

    function StandaloneMarketRentCertificationController($scope) {
        var vm = this;
        vm.appraiser = $scope.vm.appraisalOrder.appraiser;
        vm.appraiserUser = $scope.vm.appraiserUser;

        vm.appraiserCertification = [
            vm.appraiserUser.designationCRA ? 'CRA P.App' : null,
            vm.appraiserUser.designationAACI ? 'AACI P.App' : null,
            vm.appraiserUser.credentials
        ].filter(Boolean).join(', ');
    }
})();
