(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .filter('tel', tel);

    function tel() {
        return telFilter;

        function telFilter (s, output) {
            if (s == null || s.length < 10) {
                return s;
            }

            var phone = "";

            // Remove all non-numeric characters
            s = s.replace(/[^0-9]+/g, "");

            if (s.startsWith("1") && s.length >= 11) {
                // Format with country code
                phone = "1 (" + s.substring(1, 4) + ") " + s.substring(4, 7) + "-" + s.substring(7, 11);
            } else if (s.length >= 10) {
                // Format without country code
                phone = "(" + s.substring(0, 3) + ") " + s.substring(3, 6) + "-" + s.substring(6, 10);
            } else {
                // Invalid phone number length, do not format
                phone = s;
            }

            //clear out the extra formatting since it is for a href
            if (output === "href") {
                phone = phone.replace(/[^0-9]+/g, "");
            }

            // Format extension
            if (s.startsWith("1") && s.length > 11) {
                phone = phone + (output === "href" ? "," : " x") + s.substring(11);
            } else if (s.length > 10) {
                phone = phone + (output === "href" ? "," : " x") + s.substring(10);
            }

            return phone;
        }
    }
})();
