(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .factory('AppraiserUserSearch', AppraiserUserSearch);

    AppraiserUserSearch.$inject = ['$resource'];

    function AppraiserUserSearch($resource) {
        var resourceUrl =  'api/_search/appraiser-users/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
