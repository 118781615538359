angular.module('toggleForm', []).
	directive('toggleForm', function($scope) {
		$scope.$parent.activepane = 1;
		function link(scope, element, attrs) {
			$scope.$parent.activepane = 1;
		}

		return {
			restrict: 'A',
			link: link
		}
	});