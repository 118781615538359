(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('RegionCCRController', RegionCCRController);

    RegionCCRController.$inject = ['$scope', '$state', '$rootScope', '$mdDialog', 'Region'];

    function RegionCCRController($scope, $state, $rootScope, $mdDialog, Region) {
        var vm = this;

        // TODO: Remove this controller and use the RegionController instead for proper
        // paging and sorting support
        vm.myregions = Region.queryForCCR({
            size: 1000,
            sort: 'name'
        });
    }

})();
