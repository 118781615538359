(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .directive('appraiserUserRegionForm', ['$q', 'CitySearch', appraiserUserRegionForm]);

    function appraiserUserRegionForm ($q, CitySearch) {
        var directive = {
            restrict: 'E',
            link:  postLink,
            templateUrl: "app/components/form/appraiser-user-region-form/appraiser-user-region-form.template.html",
            scope: {
                appraiserUser: "=appraiserUser"
            }
        };

        return directive;

        function postLink(scope, elm, attrs) {

            // City Resources
            scope.cities = {};

            // City table row vars/methods
            scope.citiesPerRow = 4;
            scope.cityRows = cityRows;

            // City selection methods
            scope.citySelected = citySelected;
            scope.toggleCity = toggleCity;
            scope.selectAll = selectAll;
            scope.selectNone = selectNone;

            // Load initial city resource list
            refreshCities();

            // Boolean - city checkbox toggled
            function citySelected(city) {
                var index = scope.appraiserUser.cities.map(function (testCity) {
                    return testCity.id;
                }).indexOf(city.id);
                return index > -1;
            }

            // Adds/Removes cities on appraiserUser
            function toggleCity(city) {
                var index = scope.appraiserUser.cities.map(function (testCity) {
                    return testCity.id;
                }).indexOf(city.id);

                if (index > -1) {
                    scope.appraiserUser.cities.splice(index,1);
                } else {
                    scope.appraiserUser.cities.push(city);
                }
            }

            // Refresh city resource list
            function refreshCities() {
                if (scope.appraiserUser != null && scope.appraiserUser.regions != null) {
                    angular.forEach(scope.appraiserUser.regions, function(region) {
                        var query = {
                            regionId: region.id,
                            size: 2000,
                            sort: "name,asc"
                        };
                        CitySearch.query(query).$promise.then(function(cities) {
                            scope.cities[region.id] = cities;
                        });
                    });
                }
            }

            // Integer arrays for city table
            function cityRows(regionId) {
                if (scope.cities[regionId]) {
                    return new Array(Math.ceil(scope.cities[regionId].length / scope.citiesPerRow));
                }
                return [];
            }

            // Select all cities for region
            function selectAll(regionId) {
                angular.forEach(scope.cities[regionId], function(city) {
                    var index = scope.appraiserUser.cities.map(function (testCity) {
                        return testCity.id;
                    }).indexOf(city.id);

                    if (index === -1) {
                        scope.appraiserUser.cities.push(city);
                    }
                });
            }

            // Deselect all cities for region
            function selectNone(regionId) {
                angular.forEach(scope.cities[regionId], function(city) {
                    var index = scope.appraiserUser.cities.map(function (testCity) {
                        return testCity.id;
                    }).indexOf(city.id);

                    if (index > -1) {
                        scope.appraiserUser.cities.splice(index,1);
                    }
                });
            }

            // Refresh city lists when region list changes
            scope.$watch(function() { return scope.appraiserUser ? scope.appraiserUser.regions : null; }, function(newVal, oldVal) {
                refreshCities();
            });

            // Set all regions selected if region override toggled on with no existing selections
            scope.$watch(function() { return scope.appraiserUser ? scope.appraiserUser.regionOverride : null; }, function(newVal, oldVal) {
                if (newVal && scope.appraiserUser.cities.length === 0) {
                    angular.forEach(scope.appraiserUser.regions, function(region) {
                        selectAll(region.id);
                    });
                }
            });
        }
    }
})();
