(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .controller('AppraisalOrderChangeDueDateDialogController', AppraisalOrderChangeDueDateDialogController);

    AppraisalOrderChangeDueDateDialogController.$inject = ['$scope', '$mdDialog', 'appraisalOrder', 'moment', 'AppraisalOrder', 'AppraisalOrderContact'];

    function AppraisalOrderChangeDueDateDialogController ($scope, $mdDialog, appraisalOrder, moment, AppraisalOrder, AppraisalOrderContact) {
        var vm = this;
        vm.appraisalOrder = appraisalOrder;
        vm.newDueDate = new Date();

        vm.save = save;
        vm.cancel = cancel;

        vm.minDate = new Date();

        vm.dateEnabled = function(date) {
            var day = date.getDay();
            return day !== 0 && day !== 6;
        };


        function save() {
            //Set the date and time
            vm.newDueDate = vm.newDueDateDate;
            vm.newDueDate.setHours(vm.newDueDateTime.getHours());
            vm.newDueDate.setMinutes(vm.newDueDateTime.getMinutes());

            AppraisalOrder.changeDueDate({
                id: vm.appraisalOrder.id,
                newDueDate: vm.newDueDate.toISOString(),
                reason: vm.reason
            }, function(response) {
                console.log(response);
                $mdDialog.hide(response);
            }, function(response) {
                $mdDialog.cancel({ error: "An error occurred while changing the due date."});
            });
        }

        function cancel() {
            $mdDialog.cancel();
        }
    }
})();
