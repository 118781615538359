/*global tinyMCE */
(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .directive('savedValueLibrary', ['$stateParams', '$log', '$translate', '$translatePartialLoader', 'SavedFormValueModal', savedValueLibrary]);

    function savedValueLibrary ($stateParams, $log, $translate, $translatePartialLoader, SavedFormValueModal) {
        var directive = {
            restrict: 'E',
            link: {
                pre: preLink,
                post: postLink
            },
            templateUrl: 'app/components/dynamic-form/saved-value-library.template.html',
            scope: {
                inputSelector: '@'
            }
        };

        return directive;

        function preLink() {
            // Load necessary translation section
            $translatePartialLoader.addPart('savedFormValue');
            $translate.refresh();
        }

        function postLink(scope, elm, attrs) {
            // Retrieve the input element to link to this control
            var inputElm = scope.inputSelector ?
                angular.element(scope.inputSelector) :
                elm.closest('md-input-container, .vc-input-container').find('[ng-model]');

            if(!inputElm.length) {
                $log.error("Failed to find an input element to link to value library.", inputElm);
                return;
            }

            // Get the model controller for the linked input
            var inputModelCtrl = inputElm.controller('ngModel');
            if(!inputModelCtrl) {
                $log.error("Input linked to value library does not have an associated model");
                return;
            }

            var isTinyMce = !!inputElm.attr('ui-tinymce');

            // Define scope
            scope.save = save;
            scope.loadValue = loadValue;
            scope.fieldName = inputModelCtrl.$name.split('.').slice(-1)[0];
            // TODO: allow configuring formName
            // TODO: support array paths
            scope.fieldPath = $stateParams.form + '.' + inputModelCtrl.$name;

            /**
             * Open the modal to create a new saved value
             * @return {Promise} Promise thast is resolved when the modal is closed
             */
            function save(ev) {
                var stringValue = inputModelCtrl.$modelValue;

                // Insert placeholder text as necessary
                // TODO: move placeholder text replacement into a directive and replace this
                if(isTinyMce && stringValue && stringValue.includes('mceNonEditable')) {
                    var $parsedValue = angular.element("<div>" + stringValue + "</div>");
                    var $fields = $parsedValue.find('.mceNonEditable');
                    $fields.each(function(index, element) {
                        element = angular.element(element);
                        element.text("<" + (element.data('placeholder') || 'placeholder') + ">");
                    });
                    stringValue = $parsedValue.html();
                }

                var value = {
                    fieldName: scope.fieldName,
                    fieldPath: scope.fieldPath,
                    stringValue: stringValue
                };

                return SavedFormValueModal.create(value, ev);
            }

            /**
             * Update the model with an existing saved value from the libary
             * @return {Promise}    Promise that is resolved with the selected value
             */
            function loadValue(ev) {
                return SavedFormValueModal.search(scope.fieldPath, ev).then(function(result) {
                    if(isTinyMce) {
                        // Replace newlines in string with a space because the tinyMCE model parser doesn't handle them well
                        result.value.stringValue = result.value.stringValue.replace(/(\r\n|\n|\r)/gm," ");

                        // Manually update TinyMce controls because they don't update automatically
                        tinyMCE.get(inputElm.prop('id')).setContent(result.value.stringValue);
                    }

                    // TODO: support other data types
                    inputModelCtrl.$setViewValue(result.value.stringValue);
                    return result;
                });
            }
        }

    }
})();
