'use strict';

// Register `heroReference` component, along with its associated controller and template
angular.
    module('valueConnectAppraisalOrderGrid', []).
    component('valueConnectAppraisalOrderGrid', {
        templateUrl: 'app/components/appraisalordergrid/appraisalordergrid.template.html',
        controller: ['$scope', '$state', '$timeout', 'AlertService', 'ParseLinks', 'AppraisalOrder', 'Principal', 'PaginationUtil', 'moment', 'AppraisalOrderSearch',

        function ValueConnectAppraisalOrderGridController($scope, $state, $timeout, AlertService, ParseLinks, AppraisalOrder, Principal, PaginationUtil, moment, AppraisalOrderSearch) {
            var vm = this;
            $scope.isOpen = angular.isDefined(vm.open) ? vm.open : false;
            $scope.collapsible = false;
            $scope.sortType = angular.isDefined(vm.sortField) ? vm.sortField : 'id';
            $scope.sortReverse = angular.isDefined(vm.sortReverse) ? vm.sortReverse : false;
            $scope.searchPhrase = angular.isDefined(vm.searchPhrase) ? vm.searchPhrase : '';
            $scope.heading = angular.isDefined(vm.heading) ? vm.heading : '';
            $scope.atduedaterisk = angular.isDefined(vm.atduedaterisk) ? vm.atduedaterisk : '';

            $scope.showAll = angular.isDefined(vm.showAll) ? vm.showAll : false;

            Principal.matchAnyAuthority(/^ROLE_(APPRAISER|APPRAISAL).*/).then(function(result) {
                vm.role = result ? "APPRAISER" : "ORIGINATOR";
                $scope.role = vm.role;
            });

            vm.loadPage = loadPage;
            vm.transition = transition;
            vm.clear = clear;
            vm.search = search;
            vm.loadAll = loadAll;
            // Flag for differentiating table for search result or regular grid, default is false
            $scope.isSearch = false;

            $scope.totalItems = 0;
            $scope.page = 1;
            $scope.itemsPerPage = angular.isDefined(vm.itemsPerPage) ? vm.itemsPerPage : 10;
            $scope.transition = vm.transition;
            $scope.sortParams = {
                reverse: $scope.sortReverse,
                predicate: $scope.sortType
            };
            $scope.withinFourHours = withinFourHours;
            $scope.isAheadOfDateTime = isAheadOfDateTime;

            if (vm.states === null) {
                vm.states = [];
            }

            loadAll();

            function loadAll () {
                loadPage(1);
            }

            $scope.getHours = function (dt) {
                if(dt) {
                    var currentTime = new Date();
                    var date1_ms = currentTime.getTime();
                    var date2_ms = Date.parse(dt);
                    // yields time in milliseconds
                    var difference = date1_ms - date2_ms;
                    return moment.duration(difference).humanize();
                } else {
                    return "N/A";
                }
            };

            $scope.toggleView = function() {
                $scope.isOpen = !$scope.isOpen;
            };

            function sort() {
                var result = [$scope.sortType + ',' + ($scope.sortReverse ? 'asc' : 'desc')];
                return result;
            }

            function onSuccess(data, headers) {
                $scope.links = ParseLinks.parseCommas(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
                vm.appraisalOrders = data;

                $scope.collapsible = (vm.appraisalOrders.length > 0);
                $scope.isOpen = $scope.collapsible ? $scope.isOpen : false;

                if (vm.appraisalOrders.length === 1 && vm.navigateIfOneResult) {
                    $state.go("appraisal-order-detail", {id:data[0].id});
                }else if(vm.atrisk){
                    data.forEach(function(element, i){
                        if(element.states === "PENDING_VISIT"){
                            if(isAheadOfDateTime(element.visitScheduleDate)){
                                // remove item
                                data.splice(i, 1);
                            }
                        }
                    });
                }
            }

            function onError(error) {
                if (error && error.data) {
                    AlertService.error(error.data.message, {});
                }
            }

            function loadPage (page) {
                $scope.page = page;
                vm.transition();
            }

            function transition () {
                $scope.sortParams.reverse = $scope.sortReverse;
                $scope.sortParams.predicate = $scope.sortType;

                if ($scope.searchPhrase !== "") {
                    $scope.isSearch = true;
                    $scope.pendingAppraisals = AppraisalOrderSearch.query({
                        query:vm.searchPhrase,
                        page: $scope.page - 1,
                        size: $scope.itemsPerPage,
                        sort: sort()
                    }, onSuccess, onError);
                } else if (vm.states && vm.states.length !== 0) {
                    $scope.isSearch = false;
                    $scope.pendingAppraisals = AppraisalOrder.getByStatePaged({
                        states: vm.states,
                        atrisk: vm.atrisk,
                        atduedaterisk : vm.atduedaterisk,
                        page: $scope.page - 1,
                        size: $scope.itemsPerPage,
                        sort: sort(),
                        searchPhrase: vm.searchPhrase,
                        showAll: $scope.showAll
                    }, onSuccess, onError);
                }

                // Optionally filter out cosigned orders; do not show orders that the current
                // user has already cosigned
                // TODO: Do this filtering server-side
                $scope.pendingAppraisals.$promise.then(function() {
                    Principal.identity().then(function(account) {
                        if(angular.isUndefined(vm.filterCosigned) || !account || !account.appraiserUser) return;
                        var userId = account.appraiserUser.id;

                        var isFirm = account.authorities.some(function(element, index, array) {
                            return element.startsWith("ROLE_APPRAISAL");
                        });

                        if (!isFirm) {
                            $scope.pendingAppraisals = $scope.pendingAppraisals.filter(function(order) {
                                return !(order.cosignerId === userId && order.state !== 'PENDING_COSIGN');
                            });
                        }
                    });
                });
            }

            function withinFourHours(date) {
                var d = new Date();
                d.setHours(d.getHours() + 4);
                return d.getTime() > new Date(date).getTime();
            }

            function isAheadOfDateTime(dateTime){
                var d = new Date();
                return d.getTime() > new Date(dateTime).getTime();
            }

            function search (searchQuery) {
                if (!searchQuery){
                    return vm.clear();
                }
                vm.links = null;
                vm.page = 1;
                vm.reverse = false;
                vm.currentSearch = searchQuery;
                vm.transition();
            }

            function clear () {
                vm.links = null;
                vm.page = 1;
                vm.reverse = true;
                vm.currentSearch = null;
                vm.transition();
            }

            this.$onChanges = function (changesObj) {
                if (changesObj.showAll && !changesObj.showAll.isFirstChange() && changesObj.showAll.currentValue !== changesObj.showAll.previousValue) {
                    $scope.showAll = changesObj.showAll.currentValue;
                    vm.transition();
                }
            };

            $scope.formatAddress = function(order) {
                var result = order.address1 + ", " + order.cityName;
                if (order.address2) result = order.address2 + " - " + result;
                return result;
            };

        }],
        bindings: {
            states: '<?',
            showAll: '<?',
            searchPhrase: '=?',
            sortField: '=?',
            sortReverse: '=?',
            itemsPerPage: '=?',
            heading: '<?',
            atrisk: '<?',
            atduedaterisk: '<?',
            filterCosigned: '@',
            navigateIfOneResult: '<?',
            open: '<?'
        }
    });
