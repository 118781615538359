(function() {
    'use strict';

    angular.module('valueconnectApp')
        .controller('NewFeaturesModalController', NewFeaturesModalController);

    NewFeaturesModalController.$inject = ['$uibModalInstance', 'features', 'NewFeature', 'AlertService', '$sce'];

    function NewFeaturesModalController ($uibModalInstance, features, NewFeature, AlertService, $sce) {
        var vm = this;

        vm.cancel = closeModal;
        vm.features = features;
        vm.curIndex = 0;
        vm.close = close;
        vm.next = next;
        vm.prev = prev;
        vm.videoUrl = videoUrl;

        function videoUrl() {
            console.log(vm.features[vm.curIndex].videoUrl)
            return $sce.trustAsResourceUrl(vm.features[vm.curIndex].videoUrl);
            return vm.features[vm.curIndex].videoUrl
        }

        function next() {
            console.log(vm.features[vm.curIndex].id)
            NewFeature.markViewed({ id: vm.features[vm.curIndex].id }, null, function() {}, onMarkViewedError)
            vm.curIndex++;
        }

        function prev() {
            vm.curIndex--;
        }

        function close() {
            NewFeature.markViewed({ id: vm.features[vm.curIndex].id }, null, null, onMarkViewedError)
            closeModal()
        }

        function closeModal() {
            $uibModalInstance.dismiss('cancel');
        }

        function onMarkViewedError() {
            AlertService.error("valueconnectApp.new-feature.modal.error");
        }
    }
})();
