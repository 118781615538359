(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('CouponDialogController', CouponDialogController);

    CouponDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$mdDialog', 'entity', 'Coupon', 'Brokerage'];

    function CouponDialogController ($timeout, $scope, $stateParams, $mdDialog, entity, Coupon, Brokerage) {
        var vm = this;

        vm.coupon = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.brokerages = Brokerage.query({size:2000});
        vm.checkDates = checkDates;

        vm.isPercentage = vm.coupon.discountAmount ? false : true;
        vm.isPercentageChanged = isPercentageChanged;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function checkDates() {
            // Make sure the to date is not less than the from date
            if (vm.coupon.validFromDate && vm.coupon.validToDate) {
                return new Date(vm.coupon.validFromDate).getTime()
                    <= new Date(vm.coupon.validToDate).getTime();
            }

            return true;
        }

        function clear () {
            $mdDialog.cancel('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.coupon.id !== null) {
                Coupon.update(vm.coupon, onSaveSuccess, onSaveError);
            } else {
                Coupon.save(vm.coupon, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('valueconnectApp:couponUpdate', result);
            $mdDialog.hide(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.validFromDate = false;
        vm.datePickerOpenStatus.validToDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function isPercentageChanged() {
            if (vm.isPercentage)
                vm.coupon.discountAmount = null;
            else
                vm.coupon.discountPercent = null;
        }
    }
})();
