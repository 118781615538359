(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('ConcernDetailController', ConcernDetailController);

    ConcernDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Concern'];

    function ConcernDetailController($scope, $rootScope, $stateParams, entity, Concern) {
        var vm = this;

        vm.concern = entity;

        var unsubscribe = $rootScope.$on('valueconnectApp:concernUpdate', function(event, result) {
            vm.concern = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
