(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraisalReportSendBackDialogController',AppraisalReportSendBackDialogController);

    AppraisalReportSendBackDialogController.$inject = ['$state', '$scope', '$rootScope', '$mdDialog', 'appraisalReport', 'AppraisalReport'];

    function AppraisalReportSendBackDialogController($state, $scope, $rootScope, $mdDialog, appraisalReport, AppraisalReport) {
        var vm = this;

        vm.appraisalReport = appraisalReport;
        vm.clear = clear;
        vm.save = save;
        
        function clear () {
            $mdDialog.cancel();
        }

        function save () {
            AppraisalReport.sendReportBack({id: vm.appraisalReport.id, message: vm.message},
                function () {
                    $mdDialog.cancel();
                    $state.go("home");
                });
        }
    }
})();
