(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('IntegrationDetailController', IntegrationDetailController);

    IntegrationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Integration'];

    function IntegrationDetailController($scope, $rootScope, $stateParams, previousState, entity, Integration) {
        var vm = this;

        vm.integration = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('valueconnectApp:integrationUpdate', function(event, result) {
            vm.integration = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
