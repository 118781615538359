(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(stateConfig);

    stateConfig.$inject = ['$provide'];

    function stateConfig($provide) {
        $provide.decorator('$state', ['$delegate', '$rootScope', function($delegate, $rootScope) {
            // Listen to the $stateChangeStart event and add the target event to the $state object
            $rootScope.$on('$stateChangeStart', function(event, state, params) {
                $delegate.next = state;
                $delegate.toParams = params;
            });
            return $delegate;
        }]);
    }

})();
