(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('lender-contact', {
            parent: 'entity',
            url: '/lender-contact?page&sort&search',
            data: {
                authorities: [/^ROLE_(ADMIN|ORIGINATOR|CUSTOMER_CARE).*/],
                pageTitle: 'valueconnectApp.lenderContact.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/lender-contact/lender-contacts.html',
                    controller: 'LenderContactController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'contact.firstName,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                canEdit: ['authorize', 'Principal', function(authorize, Principal) {
                    return Principal.hasAuthority('USER_UPDATE');
                }],
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('lenderContact');
                    $translatePartialLoader.addPart('contact');
                    $translatePartialLoader.addPart('address');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('error');
                    return $translate.refresh();
                }]
            }
        })
        .state('lender-contact-detail', {
            parent: 'entity',
            url: '/lender-contact/{id}',
            data: {
                authorities: [/^ROLE_(ADMIN|ORIGINATOR|CUSTOMER_CARE).*/],
                pageTitle: 'valueconnectApp.lenderContact.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/lender-contact/lender-contact-detail.html',
                    controller: 'LenderContactDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('lenderContact');
                    $translatePartialLoader.addPart('contact');
                    $translatePartialLoader.addPart('address');
                    $translatePartialLoader.addPart('error');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'LenderContact', function($stateParams, LenderContact) {
                //queryMinimal -> fetches fields which are required by the user rather than getting everything from the table
                    return LenderContact.queryMinimal({id : $stateParams.id}).$promise;
                }]
            }
        })
        .state('lender-contact.new', {
            parent: 'lender-contact',
            url: '/new',
            data: {
                authorities: [/^ROLE_(ADMIN|ORIGINATOR|CUSTOMER_CARE).*/]
            },
            onEnter: ['$stateParams', '$state', '$mdDialog', function($stateParams, $state, $mdDialog) {
                $mdDialog.show({
                    templateUrl: 'app/entities/lender-contact/lender-contact-dialog.html',
                    controller: 'LenderContactDialogController',
                    controllerAs: 'vm',
                    animation: true,
                    backdrop: 'static',
                    size: 'lg',
                    clickOutsideToClose: true,
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('address');
                            $translatePartialLoader.addPart('appraisalOrderContact');
                            $translatePartialLoader.addPart('appraisalContactRole');
                            $translatePartialLoader.addPart('contact');
                            $translatePartialLoader.addPart('file');
                            $translatePartialLoader.addPart('lenderContact');
                            return $translate.refresh();
                        }],
                        entity: function () {
                            return {
                                id: null,
                                appraisalReportSendToEmailAddress: null,
                                disabled: false,
                                lenderId: null,
                                contact: {}
                            };
                        }
                    },
                    locals: {
                        showLender: true,
                        operation: "create"
                    }
                }).then(function() {
                    $state.go('lender-contact', null, { reload: true });
                }).catch(function() {
                    $state.go('lender-contact');
                });
            }]
        })
        .state('lender-contact.edit', {
            parent: 'lender-contact',
            url: '/{id}/edit',
            data: {
                authorities: [/^ROLE_(ADMIN|ORIGINATOR|CUSTOMER_CARE).*/]
            },
            onEnter: ['$stateParams', '$state', '$mdDialog', function($stateParams, $state, $mdDialog) {
                $mdDialog.show({
                    templateUrl: 'app/entities/lender-contact/lender-contact-dialog.html',
                    controller: 'LenderContactDialogController',
                    controllerAs: 'vm',
                    animation: true,
                    backdrop: 'static',
                    size: 'lg',
                    clickOutsideToClose: true,
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('address');
                            $translatePartialLoader.addPart('appraisalOrderContact');
                            $translatePartialLoader.addPart('appraisalContactRole');
                            $translatePartialLoader.addPart('contact');
                            $translatePartialLoader.addPart('file');
                            $translatePartialLoader.addPart('lenderContact');
                            return $translate.refresh();
                        }],
                        entity: ['LenderContact', function(LenderContact) {
                            return LenderContact.get({id : $stateParams.id}).$promise;
                        }]
                    },
                    locals: {
                        showLender: false,
                        operation: "edit"
                    }
                }).then(function() {
                    $state.go('lender-contact', null, { reload: true });
                }).catch(function() {
                    $state.go('^');
                });
            }]
        })
        .state('lender-contact.delete', {
            parent: 'lender-contact',
            url: '/{id}/delete',
            data: {
                authorities: [/^ROLE_(ADMIN|ORIGINATOR|CUSTOMER_CARE).*/]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/lender-contact/lender-contact-delete-dialog.html',
                    controller: 'LenderContactDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['LenderContact', function(LenderContact) {
                            return LenderContact.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('lender-contact', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
