(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('AppraisalOrder', AppraisalOrder);

    AppraisalOrder.$inject = ['$resource', 'DateUtils'];

    function AppraisalOrder ($resource, DateUtils) {
        var resourceUrl =  'api/appraisal-orders/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'getCancelled': {method: 'GET', url: 'api/appraisal-orders/cancelled', isArray: true},
            'getCancelledExport': {
                method: 'GET',
                url: 'api/appraisal-orders/cancelled/export',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    if (data) return {file: new Blob([data], {type: 'application/vnd.ms-excel'})};
                    else return data;
                }
            },
            'getOutstanding': {method: 'GET', url: 'api/appraisal-orders/outstanding', isArray: true},
            'getOutstandingExport': {
                method: 'GET',
                url: 'api/appraisal-orders/outstanding/export',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    if (data) return {file: new Blob([data], {type: 'application/vnd.ms-excel'})};
                    else return data;
                }
            },
            'getOutstandingPayments': { method: 'GET', url:'api/appraisal-orders/outstanding-payments', isArray: true},
            'getOutstandingPaymentsExport': {
                method: 'GET',
                url: 'api/appraisal-orders/outstanding-payments/export',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    if (data) return { file: new Blob([data], { type: 'application/vnd.ms-excel' }) };
                    else return data;
                }
            },
            'getHistory': { method: 'GET', url:'api/appraisal-orders/history'},
            'getHistoryExport': {
                method: 'GET',
                url: 'api/appraisal-orders/history/export',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    if (data) return { file: new Blob([data], { type: 'application/vnd.ms-excel' }) };
                    else return data;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.appraisalDueDate = DateUtils.convertDateTimeFromServer(data.appraisalDueDate);
                        data.closingDate = DateUtils.convertDateTimeFromServer(data.closingDate);
                        if (angular.isDefined(data.newConstruction) && data.newConstruction !== null)
                            data.newConstruction = data.newConstruction.toString();
                    }
                    return data;
                }
            },
            'getMinimal': {method: 'GET', url: 'api/appraisal-orders/:id/minimal'},
            'getManage': {method: 'GET', url: 'api/appraisal-orders/:id/manage'},
            'getDetailed': {method: 'GET', url: 'api/appraisal-orders/:id/detailed'},
            'getByPaymentKey': {method: 'GET', url: 'api/appraisal-orders/by-payment-key/:paymentKey'},
            'getByState': {method: 'GET', url: 'api/appraisal-orders/by-state/:states', isArray: true},
            'getByStatePaged': {
                method: 'GET',
                url: 'api/dashboard/appraisal-orders/by-state-pageable/:states',
                isArray: true
            },
            'getByFilter': {method: 'POST', url: 'api/appraisal-orders/by-filter', isArray: true},
            'update': {method: 'PUT'},
            'requestQuote': {method: 'POST', url: 'api/appraisal-orders/:id/request-quote'},
            'reviewQuote': {method: 'POST', url: 'api/appraisal-orders/:id/review-quote'},
            'accept': {method: 'POST', url: 'api/appraisal-orders/accept/:id'},
            'cancel': {method: 'POST', url: 'api/appraisal-orders/cancel/:id'},
            'restoreCancelledOrder': {method: 'POST', url: 'api/appraisal-orders/:id/restore-cancelled-order'},
            'redirectOrder': {method: 'POST', url: 'api/appraisal-orders/redirect-order'},
            'changeDueDate': {method: 'POST', url: 'api/appraisal-orders/change-due-date'},
            'missingConsentNotified': {method: 'POST', url: 'api/appraisal-orders/missing-consent-notified/:id'},
            'getNumberOfAppraiserOrders': {
                method: 'GET',
                url: 'api/get-orders-count/',
                responseType: 'int',
                transformResponse: function (data, headersGetter) {
                    return {data: data};
                }
            },
            'getNumberOfOutstandingOrders': {
                method: 'GET',
                url:'api/get-outstanding-orders-count/',
                responseType:'int',
                transformResponse: function(data, headersGetter) { return { data : data }; }
            },
            'setInternalId': { method:'PUT', url: 'api/appraisal-orders/set-internal-id'},
            'assignAppraiser': { method: 'POST', url: 'api/appraisal-orders/:id/assign-appraiser/:appraiserId' },
            'reassignAppraiser': { method: 'POST', url: 'api/appraisal-orders/:id/reassign-appraiser/:appraiserId' },
            'preassignAppraiser': { method: 'POST', url: 'api/appraisal-orders/:id/preassign-appraiser/:appraiserId' },
            'getNumberOfOpenOrders' : {method: 'GET',
                url:'api/get-open-orders-count/:states',
                responseType:'int',
                transformResponse: function(data, headersGetter) { return { data : data }; }
            },
            'getAppraisalReportAccessKeyURL': {
                method: 'GET',
                url:'api/appraisal-orders/:id/report-access-key-url',
                transformResponse: function(data) { return { url : data }; },
                responseType: 'text'
            },
            'setAddressPosition': { method:'PUT', url: 'api/appraisal-orders/:id/set-address-position'},
            'updateState': { method: 'PUT', url: 'api/appraisal-orders/:id/update-state/:newState' }
        });
    }
})();
