(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('HolidayDialogController', HolidayDialogController);

    HolidayDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Holiday', 'Province', 'Country', 'moment'];

    function HolidayDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Holiday, Province, Country, moment) {
        var vm = this;

        vm.holiday = entity;

        setDatesUTC();

        vm.provinces = Province.query();
        vm.countries = Country.query();

        vm.provinceIds = [];
        angular.forEach(vm.holiday.provinces, function(prov) {
            vm.provinceIds.push(prov.id);
        });
        vm.provincesUpdated = provincesUpdated;

        vm.clear = clear;
        vm.save = save;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            setDatesUTC();

            vm.isSaving = true;
            if (vm.holiday.id !== null) {
                Holiday.update(vm.holiday, onSaveSuccess, onSaveError);
            } else {
                Holiday.save(vm.holiday, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function setDatesUTC() {
            vm.holiday.startDate = vm.holiday.startDate ? new Date(moment.utc(vm.holiday.startDate).format('LL')) : null;
            vm.holiday.endDate = vm.holiday.endDate ? new Date(moment.utc(vm.holiday.endDate).format('LL')) : null;
        }

        function provincesUpdated() {
            vm.holiday.provinces = [];
            angular.forEach(vm.provinceIds, function(provinceId) {
                var tempProvince = vm.provinces.find(function(prov) { return prov.id == provinceId; });
                vm.holiday.provinces.push(tempProvince);
            });
        }

    }
})();
