(function () {

function DialogCtrl($scope, $mdDialog, $location){
	$scope.status = '  ';
  $scope.customFullscreen = false;

  $scope.showAlert = function(ev) {
    // Appending dialog to document.body to cover sidenav in docs app
    // Modal dialogs should fully cover application
    // to prevent interaction outside of dialog
    $mdDialog.show(
      $mdDialog.alert()
        .parent(angular.element(document.querySelector('#popupContainer')))
        .clickOutsideToClose(true)
        .title('This is an alert title')
        .textContent('You can specify some description text in here.')
        .ariaLabel('Alert Dialog Demo')
        .ok('Got it!')
        .targetEvent(ev)
    );
  };

  $scope.checkLoanAmountNOTINUSE = function(loanAmount, lendersEstimatedPropertyValue, ev, vm) {
	  if ( loanAmount != null && lendersEstimatedPropertyValue != null ) {

		  lendersEstimatedPropertyValue = parseFloat(lendersEstimatedPropertyValue.replace(/[^0-9.]/g, ""));
		  loanAmount = parseFloat(loanAmount.replace(/[^0-9.]/g, ""));

		  if ( loanAmount > lendersEstimatedPropertyValue ) {
			  $mdDialog.show(
				      $mdDialog.alert()
				        .parent(angular.element(document.querySelector('#popupContainer')))
				        .clickOutsideToClose(true)
				        .title('Please check loan amount')
				        .textContent('Loan amount can not be greater than the property value.')
				        .ariaLabel('Alert Dialog Demo')
				        .ok('Got it!')
				        .targetEvent(ev)
				    );
			  vm.appraisalOrder.loanAmount = "";
		  }
	  }
  };


  $scope.showPrivacy = function(ev) {
          $mdDialog.show({
              controller: DialogController,
              templateUrl: 'app/components/dialog/privacypolicy/privacypolicy.template.html',
              parent: angular.element(document.body),
              targetEvent: ev,
              clickOutsideToClose:false,
              fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
          });
  }

  $scope.showConfirm = function(ev) {
    // Appending dialog to document.body to cover sidenav in docs app
    var confirm = $mdDialog.confirm()
          .title('Would you like to delete your debt?')
          .textContent('All of the banks have agreed to forgive you your debts.')
          .ariaLabel('Lucky day')
          .targetEvent(ev)
          .ok('Please do it!')
          .cancel('Sounds like a scam');
    $mdDialog.show(confirm).then(function() {
      $scope.status = 'You decided to get rid of your debt.';
    }, function() {
      $scope.status = 'You decided to keep your debt.';
    });
  };

  $scope.confirmBrokerageToPay = function(ev, vm) {
    // Appending dialog to document.body to cover sidenav in docs app
    var confirm = $mdDialog.confirm()
          .title('Brokerage to pay')
          .textContent('Are you sure you want to accept this appraisal order quote?')
          .ariaLabel('Brokerage to pay')
          .targetEvent(ev)
          .ok('ACCEPT')
          .cancel('CANCEL');
    $mdDialog.show(confirm).then(function() {
    	vm.setupSplit(vm.quote.total);
    }, function() {
    });
  };

  $scope.appraiserConfirm = function(decision, ev, vm) {
	    if ( decision == 1) {
	          // ACCEPTED
	    	  var confirm = $mdDialog.confirm()
			          .title('Accept assignment')
			          .textContent('Are you sure you want to ACCEPT this assignment?')
			          .ariaLabel('Accept assignment')
			          .targetEvent(ev)
			          .ok('ACCEPT ASSIGNMENT')
			          .cancel('CANCEL');
			    $mdDialog.show(confirm).then(function() {
			    	vm.appraiserAcceptance(decision);
			    }, function() {
			    });
	      } else if ( decision == 0) {
	          // DECLINED
	    	  var confirm = $mdDialog.confirm()
			          .title('Decline assignment')
			          .textContent('Are you sure you want to DECLINE this assignment?')
			          .ariaLabel('Decline assignment')
			          .targetEvent(ev)
			          .ok('DECLINE ASSIGNMENT')
			          .cancel('CANCEL');
			    $mdDialog.show(confirm).then(function() {
			    	vm.appraiserAcceptance(decision);
			    }, function() {
			    });
	      } else if ( decision == 2) {
	          // REQUEST CLARIFICATION
	    	  var confirm = $mdDialog.confirm()
			          .title('Request clarification')
			          .textContent('Redirecting to CLARIFICATION page.')
			          .ariaLabel('Request clarification')
			          .targetEvent(ev)
			          .ok('REQUEST CLARIFICATION')
			          .cancel('CANCEL');
			    $mdDialog.show(confirm).then(function() {
			    	vm.appraiserAcceptance(decision);
			    }, function() {
			    });
	      }
  };


  $scope.showPrompt = function(ev) {
    // Appending dialog to document.body to cover sidenav in docs app
    var confirm = $mdDialog.prompt()
      .title('What would you name your dog?')
      .textContent('Bowser is a common name.')
      .placeholder('Dog name')
      .ariaLabel('Dog name')
      .initialValue('Buddy')
      .targetEvent(ev)
      .ok('Okay!')
      .cancel('I\'m a cat person');
    $mdDialog.show(confirm).then(function(result) {
      $scope.status = 'You decided to name your dog ' + result + '.';
    }, function() {
      $scope.status = 'You didn\'t name your dog.';
    });
  };

  $scope.showReportAConcern = function(ev) {
    $mdDialog.show({
      controller: DialogController,
      templateUrl: 'app/components/dialog/reportaconcern/reportaconcern.template.html',
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose:false,
      fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
    })
    .then(function(answer) {
      console.log(answer);
      vm.isSaving = true;
    }, function() {
      $scope.status = 'You cancelled the dialog.';
    });
  };


  $scope.showTabDialog = function(ev) {
    $mdDialog.show({
      controller: DialogController,
      templateUrl: 'tabDialog.tmpl.html',
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose:true
    })
        .then(function(answer) {
          $scope.status = 'You said the information was "' + answer + '".';
        }, function() {
          $scope.status = 'You cancelled the dialog.';
        });
  };


  $scope.showPrerenderedDialog = function(ev) {
    $mdDialog.show({
      controller: DialogController,
      contentElement: '#myDialog',
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: true
    });
  };


  function DialogController($scope, $mdDialog, $location, languageService) {
      languageService.getCurrent().then(
          function(language) {
              $scope.language = language;
              console.log("language is" + language);
          });
      $scope.user = {};
      $scope.user.currentUrl = $location.absUrl();
      $scope.currentUrl = $location.absUrl();
      $scope.hide = function() {
          $mdDialog.hide();
      };
      $scope.cancel = function() {
          $mdDialog.cancel();
      };
      $scope.answer = function(answer) {
          $mdDialog.hide(answer);
      };
      $scope.submitConcern = function(user) {
          $mdDialog.hide(user);
      }
  }
  DialogController.$inject = ['$scope', '$mdDialog', '$location', 'JhiLanguageService'];

}
DialogCtrl.$inject = ['$scope', '$mdDialog', '$location'];

angular.module('vcDialog', ['ngMaterial'])
.controller('DialogCtrl', DialogCtrl);


})();
