(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('OriginatorNoteDialogController', OriginatorNoteDialogController);

    OriginatorNoteDialogController.$inject = ['$scope', '$mdDialog', 'entity', 'OriginatorNote'];

    function OriginatorNoteDialogController ($scope, $mdDialog, entity, OriginatorNote) {
        var vm = this;

        vm.note = entity;

        vm.cancel = cancel;
        vm.save = save;

        function cancel () {
            $mdDialog.cancel('cancel');
        }

        function save () {
            vm.isSaving = true;
            OriginatorNote.save(vm.note, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $mdDialog.hide(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

    }
})();
