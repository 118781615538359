(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('OriginatorNote', OriginatorNote);

    OriginatorNote.$inject = ['$resource'];

    function OriginatorNote ($resource) {
        var resourceUrl =  'api/originator-notes/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },

            'getNotesForAppraisal' : {method: 'GET', url:'api/originator-notes-for-appraisal/:appraisalOrderId', isArray: true },

            'getNotesForCCR': { method:'GET', url: 'api/originator-notes-for-ccr', isArray: true },

            'markAsRead': { method:'POST', url: 'api/originator-notes/:id/read', params:{ id:'@id' } }

        });
    }
})();
