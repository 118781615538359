(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('LenderContactDialogController', LenderContactDialogController);

    LenderContactDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$mdDialog', 'entity', 'LenderContact', 'Lender', 'Contact', 'showLender', 'operation' ,'JobTitle', 'Province', 'Country', 'Principal'];

    function LenderContactDialogController ($timeout, $scope, $stateParams, $mdDialog, entity, LenderContact, Lender, Contact, showLender, operation, JobTitle, Province, Country, Principal) {
        var vm = this;

        vm.lenderContact = entity;
        vm.clear = clear;
        vm.save = save;
        vm.lenders = Lender.querySimpleAll({size:2000});
        vm.jobtitles = JobTitle.query({size:2000});
        vm.provinces = Province.query({size:2000});
        vm.countries = Country.query({size:2000});
        vm.showLender = showLender;
        vm.operation = operation;
        vm.matchLenderName = matchLenderName;
        vm.matchLenderNameFromID = matchLenderNameFromID;
        vm.matchProvinceName = matchProvinceName;
        vm.matchProvinceNameFromID = matchProvinceNameFromID;
        vm.matchCountryName = matchCountryName;
        vm.matchCountryNameFromID = matchCountryNameFromID;

        Principal.hasRole("ADMIN").then(function(result) {
            vm.detailed = result;
        });

        function matchLenderName(searchText) {
            var searchRegex = new RegExp(searchText, 'i');
            var filterVal;
            if(searchText != null && vm.lenders.length > 0) {
                filterVal = vm.lenders.filter(function(lender){
                    return searchRegex.test(lender.lenderName);
                });
                return filterVal.map(function(lender){
                    return lender.id;
                });
            } else {
                filterVal = vm.lenders;
                return filterVal.map(function(lender) {
                    return lender.id;
                });

            }
        }

        function matchLenderNameFromID(lenderID) {
            if (lenderID != null && vm.lenders.length > 0) {
                return vm.lenders.find(function(lender) {
                    return lender.id == lenderID;
                }).lenderName;
            }
        }

        function matchProvinceName(searchText) {
        	var searchRegex = new RegExp(searchText, 'i');
        	var filterVal;
        	if(searchText != null && vm.provinces.length > 0) {
        			filterVal = vm.provinces.filter(function(province){
        			return searchRegex.test(province.name);
                });
                return filterVal.map(function(province){
                	return provice.id;
                });
            }
        	return [];
        }

        function matchProvinceNameFromID(provinceID) {
            if (provinceID != null && vm.provinces.length > 0) {
                return vm.provinces.find(function(province) {
                    return province.id == provinceID;
                }).name;
            }
        }

        function matchCountryName(searchText) {
        	var searchRegex = new RegExp(searchText, 'i');
        	var filterVal;
        	if(searchText != null && vm.countries.length > 0) {
        			filterVal = vm.countries.filter(function(country){
        			return searchRegex.test(country.name);
                });
                return filterVal.map(function(country){
                	return country.id;
                });
            }
        	return [];
        }

        function matchCountryNameFromID(countryID) {
            if (countryID != null && vm.countries.length > 0) {
                return vm.countries.find(function(country) {
                    return country.id == countryID;
                }).name;
            }
        }

        function clear () {
            $mdDialog.cancel();
        }


        function save () {
            vm.isSaving = true;

            if(!vm.lenderContact.appraisalReportSendToEmailAddress) {
                vm.lenderContact.appraisalReportSendToEmailAddress = vm.lenderContact.contact.emailAddress;
            }

            if (vm.lenderContact.id !== null) {
                LenderContact.update(vm.lenderContact, onSaveSuccess, onSaveError);
            } else {
                LenderContact.save(vm.lenderContact, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $mdDialog.hide(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

    }
})();
