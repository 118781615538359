(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('PaymentClientController', PaymentClientController);

    PaymentClientController.$inject = ['$window', '$scope', '$state', '$rootScope', '$stateParams', '$sce', '$translate', 'entity', 'Payment', 'Principal', 'QuoteSplit', 'AppraisalOrder', 'Country', 'Province', 'AlertService', 'ProfileService'];

    function PaymentClientController($window, $scope, $state, $rootScope, $stateParams, $sce, $translate, entity, Payment, Principal, QuoteSplit, AppraisalOrder,   Country,   Province,   AlertService, ProfileService) {
        var vm = this;

        vm.isAuthenticated = Principal.isAuthenticated;
        vm.appraisalOrder = null;
        vm.payment = entity;
        vm.stripePaymentLinkGenerating = false;
        vm.generateStripePaymentLink = generateStripePaymentLink;
        vm.isPaid = false;

        ProfileService.getProfileInfo().then(function(data){
            vm.profile = data;
            vm.profile.paypalActionUrl = $sce.trustAsResourceUrl(vm.profile.paypalActionUrl);
            //vm.profile.payPalReturnUrl =  $sce.trustAsResourceUrl(vm.profile.paypalReturnUrl + ($stateParams.token ? $stateParams.token : vm.quoteSplit.id));
        });

        if ($stateParams.token) {
        	//Get the necessary objects by the token
        	vm.quoteSplit = QuoteSplit.getByPaymentKey({paymentKey:$stateParams.token}, function(data) {
                AppraisalOrder.getByPaymentKey({paymentKey:$stateParams.token}).$promise.then(function (appraisalOrder) {
                    vm.appraisalOrder = appraisalOrder;
                    vm.orderCancelled = ['ORDER_CANCELLED', 'REFUND_REQUIRED'].includes(vm.appraisalOrder.state);
                });

        		vm.payment.amount = vm.quoteSplit.splitAmount;
        		vm.payment.quoteSplitId = vm.quoteSplit.id;

                //Make sure it has not already been paid
                if (data.paymentSplits && data.paymentSplits.length > 0) {
                    vm.isPaid = data.paymentSplits.map(function(ps) { return ps.amount; })
                        .reduce(function (a,b) { return a + b; }) == vm.payment.amount;
                }
        	});
        }

        function generateStripePaymentLink () {
            vm.stripePaymentLinkGenerating = true;

            QuoteSplit.generateQuoteSplitPaymentLink({ id: vm.quoteSplit.id }, {},
                function(result) {
                    if(result.stripePaymentLink.length > 0) {
                        $window.open(result.stripePaymentLink,'_blank');

                        $translate('valueconnectApp.payment.paymentLinkGenerationSuccess').then(function(trans) {
                            AlertService.success(trans);
                        });
                        vm.quoteSplit.stripePaymentLink = result.stripePaymentLink;
                    } else {
                        $translate('valueconnectApp.payment.paymentLinkGenerationError').then(function(trans) {
                            AlertService.error(trans);
                        });
                    }

                    vm.stripePaymentLinkGenerating = false;
                },
                function(result) {
                    $translate('valueconnectApp.payment.paymentLinkGenerationError').then(function(trans) {
                        AlertService.error(trans);
                    });
                    vm.stripePaymentLinkGenerating = false;
                }
            );
        }

        var unsubscribe = $rootScope.$on('valueconnectApp:paymentUpdate', function(event, result) {
            vm.payment = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
