(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('quote', {
            parent: 'entity',
            url: '/quote?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'valueconnectApp.quote.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/quote/quotes.html',
                    controller: 'QuoteController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('quote');
                    $translatePartialLoader.addPart('enumQuoteState');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('quote-edit', {
            parent: 'appraisal-order-detail',
            url: '/edit-quote',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_CUSTOMER_CARE_REPRESENTATIVE'],
                pageTitle: 'valueconnectApp.quote.update.title'
            },
            resolve: {
                orderState: ['$q', '$state', 'entity', 'AlertService', function($q, $state, order, AlertService) {
                    if (['PENDING_QUOTE', 'FINALIZING_QUOTE'].includes(order.state)) return $q.when(order.state);
                    AlertService.error('valueconnectApp.quote.update.wrongState');
                    $state.go('appraisal-order-detail', { id: order.id });
                    return $q.reject(order.state);
                }]
            },
            onEnter: ['$state', '$mdDialog', 'entity', 'AppraisalOrder', function($state, $mdDialog, order, AppraisalOrder) {
                AppraisalOrder.reviewQuote({id: order.id}, null).$promise
                .then(function(result) {
                    return $mdDialog.show({
                        templateUrl: 'app/entities/quote/quote-dialog.html',
                        controller: 'QuoteDialogController',
                        controllerAs: 'vm',
                        resolve: {
                            quote: ['Quote', function(Quote) {
                                var activeQuoteId = order.appraisalReports.find(function(report) {
                                    return report.id === order.activeReport.id;
                                }).quote.id;
                                return Quote.getEditable({id : activeQuoteId}).$promise;
                            }]
                        },
                        locals: {
                            order: order
                        }
                    }).then(function() {
                        $state.go('appraisal-order-detail', { id: order.id }, { reload: true });
                    }, function() {
                        $state.go('appraisal-order-detail', { id: order.id }, { reload: true });
                    });
                });
            }]
        })
        .state('appraiser-fee-edit', {
            parent: 'appraisal-order-detail',
            url: '/appraiser-fee',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_CUSTOMER_CARE_REPRESENTATIVE'],
                pageTitle: 'valueconnectApp.quote.appraiserFee.title'
            },
            resolve: {
                orderState: ['$q', '$state', 'entity', 'AlertService', function($q, $state, order, AlertService) {
                    if(!['ORDER_DRAFTED', 'QUOTE_REQUIRED', 'PENDING_QUOTE'].includes(order.state)) return $q.when(order.state);
                    AlertService.error('valueconnectApp.quote.appraiserFee.wrongState');
                    $state.go('appraisal-order-detail', { id: order.id });
                    return $q.reject(order.state);
                }]
            },
            onEnter: ['$state', '$mdDialog', 'entity', function($state, $mdDialog, order) {
                $mdDialog.show({
                    templateUrl: 'app/entities/quote/appraiser-fee-dialog.html',
                    controller: 'AppraiserFeeDialogController',
                    controllerAs: 'vm',
                    resolve: {
                        quote: ['Quote', function(Quote) {
                            var activeQuoteId = order.appraisalReports.find(function(report) {
                                return report.id === order.activeReport.id;
                            }).quote.id;
                            return Quote.getEditable({id : activeQuoteId}).$promise;
                        }]
                    },
                    locals: {
                        order: order
                    }
                }).then(function() {
                    $state.go('appraisal-order-detail', { id: order.id }, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }
})();
