(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraisalReportDeclineController',AppraisalReportDeclineController);

    AppraisalReportDeclineController.$inject = ['$state', '$scope', '$rootScope', '$mdDialog', 'report', 'AppraisalReport'];

    function AppraisalReportDeclineController($state, $scope, $rootScope, $mdDialog, report, AppraisalReport) {
        var vm = this;

        vm.appraisalReport = report;
        vm.clear = clear;
        vm.save = save;
        
        function clear () {
            $mdDialog.cancel();
        }

        function save () {
            AppraisalReport.declineReport({id: vm.appraisalReport.id, reason: vm.reason},
                function () {
                    $mdDialog.cancel();
                    $state.go("home");
                });
        }
    }
})();
