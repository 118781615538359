(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .directive('matchAnyAuthority', matchAnyAuthority);

    matchAnyAuthority.$inject = ['Principal'];

    function matchAnyAuthority(Principal) {
        var directive = {
            restrict: 'A',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs) {
            var regex = attrs.matchAnyAuthority.replace(/\s+/g, '');
            var flags = "";
            if (regex.indexOf('/') == 0) {
                regex = regex.substring(1);
                var lastSlash = regex.lastIndexOf('/');
                flags = regex.substring(lastSlash + 1, regex.length);
                regex = regex.substring(0, lastSlash);
            }

            regex = new RegExp(regex, flags);
            var setVisible = function () { element.removeClass('hidden'); }
            var setHidden = function () { element.addClass('hidden'); },

            defineVisibility = function (reset) {
                if (reset) {
                    setVisible();
                }

                Principal.matchAnyAuthority(regex).then(function (result) {
                    if (result) {
                        setVisible();

                    } else {
                        setHidden();
                    }
                });
            };

            defineVisibility(true);

            scope.$watch(function() {
                return Principal.isAuthenticated();
            }, function() {
                defineVisibility(true);
            });
        }
    }
})();
