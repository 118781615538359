(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(googleConfig);

    googleConfig.$inject = ['GOOGLE_MAP_API_KEY'];

    function googleConfig(GOOGLE_MAP_API_KEY) {
        var script = document.createElement('script');
        script.src = "https://maps.googleapis.com/maps/api/js?key=" + GOOGLE_MAP_API_KEY + "&callback=initMap&libraries=geometry";
        script.id = "google-map-script";
        script.async = true;
        document.getElementsByTagName('body')[0].appendChild(script);
    }
})();
