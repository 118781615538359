(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('DrivebyDirectComparisonController',DrivebyDirectComparisonController);

    DrivebyDirectComparisonController.$inject = ['$timeout', '$scope', 'moment'];

    function DrivebyDirectComparisonController($timeout, $scope, moment) {
        // Initialize model
        var vm = this;
        var sectionData = null;
        vm.locationMapReminder = false;

        vm.addSubject = addSubject;
        vm.addOption = addOption;

        vm.removeOption = removeOption;
        vm.removeSubject = removeSubject;

        vm.compsLength = compsLength;
        vm.moveComparable = moveComparable;

        // Initialize model using form data
        init(null, $scope.vm.formData);
        $scope.$on('formDataReinitialized', init);

        function init(event, formData) {
            sectionData = formData.directComparison;

            // Migrate custom options to new data format
            // TODO: create a database migration for this
            sectionData.customOptions = sectionData.customOptions.map(function(option, index) {
                if(angular.isString(option)) {
                    $scope.vm.setDirty('directComparison.customOptions['+index+'].name');
                    return { name: option };
                } else {
                    return option;
                }
            });

            // Initialize custom options
            if (sectionData.$started) {
                // Find the total number of custom options
                // NOTE: since we are forcing custom options to persist when created, we don't really
                // need some of this logic; leaving it here anyway just to make this less brittle
                var numOptions = sectionData.comparableSubjects
                    .map(function(subject) { return subject.customOptions.length; })
                    .concat([sectionData.customOptions.length])
                    .reduce(function(a,b) { return Math.max(a,b); }, 0);

                // Initialize the custom options to match the numOptions length
                while(sectionData.customOptions.length < numOptions) {
                    sectionData.customOptions.push({});
                }
            }

            // Initalize each comparable
            sectionData.comparableSubjects.forEach(function(subject) {
                initSubject(subject, numOptions);
            });
        }

        /**
         * Add a new custom option and mark the name as dirty to force it to persist
         */
        function addOption() {
            var newIndex = sectionData.customOptions.length;
            sectionData.customOptions.push({ name: '' });
            $scope.vm.setDirty('directComparison.customOptions['+newIndex+'].name');

            sectionData.comparableSubjects.forEach(function(subject) {
                subject.customOptions.push({});
            });
        }

        function removeOption(index) {
            $scope.vm.removeFromArray(sectionData.customOptions, index).then(function() {
                sectionData.comparableSubjects.forEach(function(subject) {
                    $scope.vm.removeItem(subject.customOptions, index);
                });
            });
        }

        function addSubject() {
            var numOptions = sectionData.customOptions.length;
            sectionData.comparableSubjects.push(initSubject({}, numOptions));
        }

        function removeSubject(index) {
            $scope.vm.removeFromArray($scope.vm.formData.directComparison.comparableSubjects, index);
        }

        function compsLength() {
            return $scope.vm.formData.directComparison.comparableSubjects.filter($scope.vm.isNotDeleted).length;
        }

        function moveComparable(from, to) {
            $timeout(function() {
                $scope.vm.formData.directComparison.comparableSubjects.splice(to, 0, $scope.vm.formData.directComparison.comparableSubjects.splice(from, 1)[0]);
            }, 300)
        };

        /**
         * Initialize the provided comparable object
         * @param  {Object} subject    The comparable to initalize
         * @param  {Number} numOptions The number of custom options
         * @return {Object}            The initialized comparable
         */
        function initSubject(subject, numOptions) {
            // Make sure arrays are initialized
            if(!subject.customOptions) subject.customOptions = [];
            if(!subject.images || subject.images.length === 0) subject.images = [{}];

            // Initalize custom options to match the numOptions length
            while(subject.customOptions.length < numOptions) {
                subject.customOptions.push({});
            }

            return subject;
        }
    }
})();
