(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('NewFeatureDialogController', NewFeatureDialogController);

    NewFeatureDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'NewFeature', 'File'];

    function NewFeatureDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, NewFeature, File) {
        var vm = this;

        vm.feature = entity;
        
        if(vm.feature.image == null) {
            vm.feature.image = {};
        }

        vm.checkDates = checkDates;
        vm.clear = clear;
        vm.save = save;
        vm.removeImage = false;
        vm.newImages = [];
        vm.oldFileId = null;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;

            var saveFeature = function() {

                if (vm.feature.id !== null) {
                    NewFeature.update(vm.feature, onSaveSuccess, onSaveError);
                } else {
                    NewFeature.save(vm.feature, onSaveSuccess, onSaveError);
                }
            }

            if(vm.newImages.length > 0) {
                saveFile(saveFeature, onSaveError)
            } else {
                if(vm.removeImage) {
                    delete vm.imageId
                }

                saveFeature();
            }
        }

        function saveFile(onSaveSuccess, onSaveError) {
            var multipartData = new FormData();

            console.log(vm.newImages[0].lfFile)
            multipartData.append("file", vm.newImages[0].lfFile); 

            File.upload(multipartData).$promise.then(function(file) {
                vm.oldFileId = vm.feature.imageId
                vm.feature.imageId = file.id

                onSaveSuccess();
            })
            .catch(function() {
                console.error("Error saving file");
                onSaveError();
            });
        }

        function onSaveSuccess (result) {
            $scope.$emit('valueconnectApp:newFeatureUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;

            if(vm.oldFileId != null) {
                File.delete(vm.feature.imageId)
                vm.feature.imageId = vm.oldFileId
            }
        }

        function checkDates() {
            // Make sure the to date is not less than the from date
            if (vm.feature.startDate && vm.feature.endDate) {
                return new Date(vm.feature.startDate).getTime()
                    <= new Date(vm.feature.endDate).getTime();
            }

            return true;
        }

        vm.setFileData = function ($file, file) {
            if (!$file) {
                return
            }

            console.log($file)

            $scope.$apply(function() {
                file.contentType = $file.type;
                file.fileName = $file.name;
            });
        };
    }
})();
