(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('VCNote', VCNote);

    VCNote.$inject = ['$resource'];

    function VCNote ($resource) {
        var resourceUrl =  'api/vc-notes/:id';

        return $resource(resourceUrl, {}, {
            'getVCNotesForAppraisalOrder' : {method: 'GET', url:'api/vc-notes/appraisal-order/:id', isArray: true }
        });
    }
})();
