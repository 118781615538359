(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('DrivebyScopeController', DrivebyScopeController);

    DrivebyScopeController.$inject = ['$scope'];

    function DrivebyScopeController($scope) {
        init(null, $scope.vm.formData);
        $scope.$on('formDataReinitialized', init);

        // Initialize default values
        function init(event, formData) {
            var sectionData = formData.scope;

            if(typeof sectionData.useScopeOfAppraisal === 'undefined') {
                sectionData.useScopeOfAppraisal = true;
            }
        }
    }
})();
