(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app', {
            abstract: true,
            views: {
                'browser-warning@': {
                    templateUrl: 'app/layouts/browser-warning/browser-warning.html',
                    controller: 'BrowserWarningController',
                    controllerAs: 'vm'
                },
                'navbar@': {
                    templateUrl: 'app/layouts/navbar/navbar.html',
                    controller: 'NavbarController',
                    controllerAs: 'vm'
                },
                'searchbar@': {
                    templateUrl: 'app/layouts/searchbar/searchbar.template.html',
                    controller: 'SearchbarController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                authorize: ['Auth', function (Auth) {
                    return Auth.authorize();
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global').addPart('login').addPart('error');

                }]
            }
        });
    }
})();
