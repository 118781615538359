(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraisalReportDeleteController',AppraisalReportDeleteController);

    AppraisalReportDeleteController.$inject = ['$uibModalInstance', 'entity', 'AppraisalReport'];

    function AppraisalReportDeleteController($uibModalInstance, entity, AppraisalReport) {
        var vm = this;

        vm.appraisalReport = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            AppraisalReport.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
