(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraisalOrderDialogLenderInfoController', AppraisalOrderDialogLenderInfoController);

    AppraisalOrderDialogLenderInfoController.$inject = ['$scope', 'Lender', 'LenderContact'];

    function AppraisalOrderDialogLenderInfoController($scope, Lender, LenderContact) {

        // Initialize Entity
        var vm = this;
        vm.lenderContacts = [];

        vm.changeLender = changeLender;
        vm.changeLenderContact = changeLenderContact;
        vm.removeLenderContact = removeLenderContact;
        //querySimple -> Fetches only enabled lenders using compact JSON
        vm.lenders = Lender.querySimple({size: 2000}).$promise.then(function (lenders) {
            vm.lenders = lenders;
        })
        // vm.matchLenderContact = matchLenderContact;
        vm.matchLender = matchLender;
        // Init Lenders
        refreshLenderContacts();

        function makeLenderRefNumRequired() {
            var elem = document.getElementById("field_loanReferenceNumber");
            if (elem) {
                elem.required = true;
                elem.parentElement.classList.add("required-large");
            }
        }

        function makeLenderRefNumOptional() {
            var elem = document.getElementById("field_loanReferenceNumber");
            if (elem) {
                elem.required = false;
                elem.parentElement.classList.remove("required-large");
            }
        }

        function changeLender() {
            if ($scope.vm.appraisalOrder.lender) {
                $scope.vm.appraisalOrder.lender = vm.lenders.find(function (l) {
                    return l.id == $scope.vm.appraisalOrder.lender.id;
                });
                Lender.hasActiveLenderIntegrations({id: $scope.vm.appraisalOrder.lender.id}).$promise.then(function (response) {
                    if (response.hasActiveLenderIntegrations === true) {
                        $scope.vm.isLenderRefNumRequired = true;
                        makeLenderRefNumRequired();
                    } else {
                        $scope.vm.isLenderRefNumRequired = false;
                        makeLenderRefNumOptional();
                    }
                });
            } else {
                // The user cleared the "Lender" field.
                $scope.vm.isLenderRefNumRequired = false;
                makeLenderRefNumOptional();
            }

            removeLenderContact();
            refreshLenderContacts();
            $scope.vm.saveProgress();
        }

        function changeLenderContact() {
            if ($scope.vm.appraisalOrder.lenderUnderwriterContact) {
                $scope.vm.appraisalOrder.lenderUnderwriterContact = vm.lenderContacts.find(function (lc) {
                    return lc.id == $scope.vm.appraisalOrder.lenderUnderwriterContact.id;
                });
            }
            $scope.vm.saveProgress();
        }

        function removeLenderContact() {
            $scope.vm.appraisalOrder.lenderUnderwriterContact = null;
            $scope.vm.saveProgress();
        }

        function refreshLenderContacts() {
            if ($scope.vm.appraisalOrder.lender && $scope.vm.appraisalOrder.lender.id) {
                LenderContact.getEnabledByLender({id: $scope.vm.appraisalOrder.lender.id}).$promise.then(function (lenderContacts) {
                    vm.lenderContacts = lenderContacts;
                });
            }
        }

        function matchLender(searchText) {
            var searchRegex = new RegExp(searchText, 'i');
            return vm.lenders.filter(function (lenders) {
                return searchRegex.test(lenders.lenderName);
            });
        }
    }
})();
