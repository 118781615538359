(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraisalOrderContactDialogController', AppraisalOrderContactDialogController);

    AppraisalOrderContactDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$mdDialog', 'entity', 'AppraisalOrderContact', 'AppraisalOrder', 'Contact', 'Province', 'Country', 'GoogleVC'];

    function AppraisalOrderContactDialogController ($timeout, $scope, $stateParams, $mdDialog, entity, AppraisalOrderContact, AppraisalOrder, Contact, Province, Country, GoogleVC) {
        var vm = this;

        vm.appraisalOrderContact = entity;
        vm.clear = clear;
        vm.save = save;

        vm.lookupAddress = lookupAddress;
        vm.matchContacts = matchContacts;
        vm.changeContact = changeContact;

        vm.contacts = Contact.query();
        vm.provinces = Province.query();
        vm.countries = Country.query();
        vm.searchText = "Find a contact";
        vm.searchText = "";
        vm.selectedContact = null;

        vm.sameAsClient = sameAsClient;

        // If this is a new contact, default the country to Canada
        if(vm.appraisalOrderContact.id === null && !vm.appraisalOrderContact.contact.officeAddress.country) {
            vm.countries.$promise.then(function(countries) {
                vm.appraisalOrderContact.contact.officeAddress.country = countries.find(function(country) {
                    return country.abbrev === 'CA';
                });
            });
        }

        function lookupAddress() {
            GoogleVC.lookupAddress(vm.appraisalOrderContact.contact.officeAddress, "CA").then( function(googleLookupResults) {
                GoogleVC.setAddress(vm.appraisalOrderContact.contact.officeAddress, googleLookupResults, vm.provinces, vm.cities);
            });
        }

        function matchContacts(searchText) {
            var searchRegex = new RegExp(searchText, 'gi');
            return vm.contacts.filter(function(contact) {
                var name = contact.firstName + ' ' + contact.lastName;
                return searchRegex.test(name);
            });
        }

        function clear () {
            $mdDialog.cancel();
        }

        function save () {
            $scope.$emit('valueconnectApp:appraisalOrderContactAdd', vm.appraisalOrderContact);
            // TODO: save the entity by default
            $mdDialog.hide(vm.appraisalOrderContact);
        }

        function changeContact() {
            var contactId = vm.selectedContact ? vm.selectedContact.id : null;
            if (!vm.appraisalOrderContact.contact) vm.appraisalOrderContact.contact = {};
            vm.appraisalOrderContact.contact.id = contactId;

            if (contactId > 0) {
                // TODO: this entire block is unnecessary...
                Contact.getcontactbyid({id: contactId}, function(contact){
                    // Hack the contact DTO object to match the address domain object
                    // TODO: use a DTO for appraisalOrderContact so we don't need to do this
                    if (contact.officeAddress) {
                        contact.officeAddress.customCity = contact.officeAddress.city;
                        contact.officeAddress.city = undefined;
                    }
                    vm.appraisalOrderContact.contact = contact;
                });
            } else {
                vm.appraisalOrderContact.contact.id = null;
            }
        }

        function sameAsClient() {
            if (vm.appraisalOrderContact.client && vm.appraisalOrderContact.client.id) vm.appraisalOrderContact.client.id = null;
            vm.appraisalOrderContact.contact = vm.appraisalOrderContact.client;
        }
    }
})();
