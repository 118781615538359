(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('StandaloneMarketRentAerialMapController', StandaloneMarketRentAerialMapController);

    StandaloneMarketRentAerialMapController.$inject = ['$log', '$scope'];

    function StandaloneMarketRentAerialMapController($log, $scope) {
        var vm = this;

        vm.editMode = false;
        vm.mapSaved = mapSaved;
        vm.resetAddressPin = resetAddressPin;

        function mapSaved() {
            // Set dirty for hidden fields updated by google-map-input
            var hiddenFieldNames = ['aerialMap.centerLat', 'aerialMap.centerLng', 'aerialMap.zoom'];
            angular.forEach(hiddenFieldNames, function(fieldName) {
                var field = $scope.editForm[fieldName];
                if (!field) {
                    $log.error('Cannot update non-existent field:', fieldName);
                } else {
                    field.$setDirty();
                }
            });
        }

        function resetAddressPin() {
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode({
                address: $scope.vm.getAddress($scope.vm.formData.subject),
                region: "ca"
            }, function(results, status) {
                var position = results.length ? results[0].geometry.location : null;
                if (position) {
                    $scope.vm.appraisalOrder.address.lat = position.lat();
                    $scope.vm.appraisalOrder.address.lng = position.lng();
                    $scope.$apply();
                }
            });
        }
    }
})();
