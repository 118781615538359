(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('LenderContactController', LenderContactController);

    LenderContactController.$inject = ['$scope', '$state', 'LenderContact', 'LenderContactSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants','canEdit'];

    function LenderContactController ($scope, $state, LenderContact, LenderContactSearch, ParseLinks, AlertService, pagingParams, paginationConstants,canEdit) {
        var vm = this;

        vm.canEdit = canEdit;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.setDisable = setDisable;

        loadAll();
        function setDisable (lender, isDisabled) {
            lender.disabled = isDisabled;
            LenderContact.update(lender, function () {
                vm.loadAll();
                vm.clear();
            });
        }

        function loadAll () {
            if (pagingParams.search) {
                LenderContact.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                LenderContact.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'contact.firstName') {
                    result.push('contact.firstName' + ',' + 'asc');
                }
                //second level sorting using lastName
                if(vm.predicate !== 'contact.lastName') {
                    result.push('contact.lastName' + ',' + 'asc');
                }
                //third level sorting using appraisalReportSendToEmailAddress
                if(vm.predicate !== 'appraisalReportSendToEmailAddress') {
                    result.push('appraisalReportSendToEmailAddress' + ',' + 'asc');
                }

                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.lenderContacts = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'contact.firstName';
            vm.reverse = true;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'contact.firstName';
            vm.reverse = true;
            vm.currentSearch = null;
//            vm.transition();
        }
    }
})();
