(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('LenderIntegration', LenderIntegration);

    LenderIntegration.$inject = ['$resource'];

    function LenderIntegration ($resource) {
        var resourceUrl =  'api/lender-integrations/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'save': { method: 'POST' },
            'generateHashKey': { method: 'GET', url: 'api/generate-hash-key', isArray: false}
        });
    }
})();
