(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(ga4Config);

    ga4Config.$inject = ['GA4_MEASUREMENT_ID'];

    function ga4Config(GA4_MEASUREMENT_ID) {
        var script = document.createElement('script');
        script.src = "https://www.googletagmanager.com/gtag/js?id=" + GA4_MEASUREMENT_ID;
        script.id = "ga4-script";
        script.async = true;
        document.getElementsByTagName('body')[0].appendChild(script);

        var configScript = document.createElement('script');
        configScript.innerHTML =
            "window.dataLayer = window.dataLayer || [];\n" +
            "function gtag(){dataLayer.push(arguments);}\n" +
            "gtag('js', new Date());\n" +
            "gtag('config', '" + GA4_MEASUREMENT_ID + "');\n";
        document.getElementsByTagName('body')[0].appendChild(configScript);
    }
})();
