(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('saved-form-value', {
            parent: 'entity',
            url: '/saved-value-library?page&sort&search',
            data: {
                // authorities: ['ROLE_ADMIN', /^ROLE_(APPRAISER|APPRAISAL).*/],
                authorities: ['ROLE_DOESNT_EXIST'],
                pageTitle: 'valueconnectApp.savedFormValue.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/saved-form-value/saved-form-values.html',
                    controller: 'SavedFormValueController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('savedFormValue');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('formStandardResidential');
                    return $translate.refresh();
                }]
            }
        })
        .state('saved-form-value-detail', {
            parent: 'entity',
            url: '/saved-value-library/{id}',
            data: {
                // authorities: ['ROLE_ADMIN', /^ROLE_(APPRAISER|APPRAISAL).*/],
                authorities: ['ROLE_DOESNT_EXIST'],
                pageTitle: 'valueconnectApp.savedFormValue.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/saved-form-value/saved-form-value-detail.html',
                    controller: 'SavedFormValueDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('savedFormValue');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'SavedFormValue', function($stateParams, SavedFormValue) {
                    return SavedFormValue.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        .state('saved-form-value.new', {
            parent: 'saved-form-value',
            url: '/new',
            data: {
                // authorities: ['ROLE_ADMIN', /^ROLE_(APPRAISER|APPRAISAL).*/]
                authorities: ['ROLE_DOESNT_EXIST']
            },
            onEnter: ['$stateParams', '$state', 'SavedFormValueModal', function($stateParams, $state, SavedFormValueModal) {
                SavedFormValueModal.create().then(function() {
                    $state.go('saved-form-value', null, { reload: true });
                }, function() {
                    $state.go('saved-form-value');
                });
            }]
        })
        .state('saved-form-value.edit', {
            parent: 'saved-form-value',
            url: '/{id}/edit',
            data: {
                // authorities: ['ROLE_ADMIN', /^ROLE_(APPRAISER|APPRAISAL).*/]
                authorities: ['ROLE_DOESNT_EXIST']
            },
            onEnter: ['$stateParams', '$state', 'SavedFormValueModal', function($stateParams, $state, SavedFormValueModal) {
                SavedFormValueModal.edit($stateParams.id).then(function() {
                    $state.go('saved-form-value', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('saved-form-value.delete', {
            parent: 'saved-form-value',
            url: '/{id}/delete',
            data: {
                // authorities: ['ROLE_ADMIN', /^ROLE_(APPRAISER|APPRAISAL).*/]
                authorities: ['ROLE_DOESNT_EXIST']
            },
            onEnter: ['$stateParams', '$state', '$mdDialog', function($stateParams, $state, $mdDialog) {
                $mdDialog.show({
                    templateUrl: 'app/entities/saved-form-value/saved-form-value-delete-dialog.html',
                    controller: 'SavedFormValueDeleteController',
                    controllerAs: 'vm',
                    resolve: {
                        entity: ['SavedFormValue', function(SavedFormValue) {
                            return SavedFormValue.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).then(function() {
                    $state.go('saved-form-value', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
