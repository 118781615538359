(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('lender', {
            parent: 'entity',
            url: '/lender?page&sort&search',
            data: {
                authorities: [/^ROLE_(ADMIN|ORIGINATOR|CUSTOMER_CARE).*/],
                pageTitle: 'valueconnectApp.lender.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/lender/lenders.html',
                    controller: 'LenderController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('lender');
                    $translatePartialLoader.addPart('address');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                identity: ['Principal', function(Principal) {
                    return Principal.identity();
                }]
            }
        })
        .state('lender-detail', {
            parent: 'entity',
            url: '/lender/{id}',
            data: {
                authorities: [/^ROLE_(ADMIN|ORIGINATOR|CUSTOMER_CARE).*/],
                pageTitle: 'valueconnectApp.lender.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/lender/lender-detail.html',
                    controller: 'LenderDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('lender');
                    $translatePartialLoader.addPart('address');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Lender', function($stateParams, Lender) {
                    return Lender.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        .state('lender.new', {
            parent: 'lender',
            url: '/new',
            data: {
                authorities: [/^ROLE_(ADMIN|ORIGINATOR|CUSTOMER_CARE).*/]
            },
            onEnter: ['$stateParams', '$state', '$mdDialog', function($stateParams, $state, $mdDialog) {
                $mdDialog.show({
                    templateUrl: 'app/entities/lender/lender-dialog.html',
                    controller: 'LenderDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                lenderName: null,
                                headOfficePhoneNumber: null,
                                emailAddress: null,
                                emailAddressForPayments: null,
                                emailAddressToReceiveAppraisalReports: null,
                                website: null,
                                disabled: false,
                                sendReportToCCR: true,
                                allowAppraisalFirmSelection: true,
                                allowTriggeredRiskRules: true,
                                id: null
                            };
                        }
                    },
                    locals:{
                        operation: "create"
                    }
                }).then(function() {
                    $state.go('lender', null, { reload: true });
                }, function() {
                    $state.go('lender');
                });
            }]
        })
        .state('lender.edit', {
            parent: 'lender',
            url: '/{id}/edit',
            data: {
                authorities: [/^ROLE_(ADMIN|ORIGINATOR|CUSTOMER_CARE).*/]
            },
            onEnter: ['$stateParams', '$state', '$mdDialog', function($stateParams, $state, $mdDialog) {
                $mdDialog.show({
                    templateUrl: 'app/entities/lender/lender-dialog.html',
                    controller: 'LenderDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Lender', function(Lender) {
                            return Lender.get({id : $stateParams.id}).$promise;
                        }]
                    },
                    locals: {
                        operation: "edit"
                    }
                }).then(function() {
                    $state.go('lender', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('lender.delete', {
            parent: 'lender',
            url: '/{id}/delete',
            data: {
                authorities: [/^ROLE_(ADMIN|ORIGINATOR|CUSTOMER_CARE).*/]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/lender/lender-delete-dialog.html',
                    controller: 'LenderDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Lender', function(Lender) {
                            return Lender.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('lender', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
