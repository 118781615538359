(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('StandaloneMarketRentComparableRentDataController',StandaloneMarketRentComparableRentDataController);

    StandaloneMarketRentComparableRentDataController.$inject = ['$timeout', '$scope', 'moment'];

    function StandaloneMarketRentComparableRentDataController($timeout, $scope, moment) {
        var adjustmentFields = ['rentRate','location','design','liveableFloorArea'];

        // Initialize model
        var vm = this;
        var sectionData = null;
        vm.locationMapReminder = false;

        vm.addSubject = addSubject;
        vm.addOption = addOption;
        vm.removeOption = removeOption;

        // Initialize model using form data
        init(null, $scope.vm.formData);
        $scope.$on('formDataReinitialized', init);

        function init(event, formData) {
            sectionData = formData.comparableRentData;

            // Initialize custom options
            var numOptions;
            if(!sectionData.$started) {
                // Initialize to 12 rows the first time this form is accessed
                numOptions = 2;
                $timeout(function() {
                    while(sectionData.customOptions.length < numOptions) {
                        // Mark the name as dirty for the option to force it to persist
                        sectionData.customOptions.push({ name: '' });
                        $scope.vm.setDirty('comparableRentData.customOptions['+(sectionData.customOptions.length-1)+'].name');
                    }
                });
            } else {
                // Find the total number of custom options
                // NOTE: since we are forcing custom options to persist when created, we don't really
                // need some of this logic; leaving it here anyway just to make this less brittle
                numOptions = sectionData.comparableSubjects
                    .map(function(subject) { return subject.customOptions.length; })
                    .concat([sectionData.customOptions.length])
                    .reduce(function(a,b) { return Math.max(a,b); }, 0);

                // Initialize the custom options to match the numOptions length
                while(sectionData.customOptions.length < numOptions) {
                    sectionData.customOptions.push({});
                }
            }

            // Initalize each comparable
            sectionData.comparableSubjects.forEach(function(subject) {
                initSubject(subject, numOptions);
            });


        }

        /**
         * Add a new custom option and mark the name as dirty to force it to persist
         */
        function addOption() {
            var newIndex = sectionData.customOptions.length;
            sectionData.customOptions.push({ name: '' });
            $scope.vm.setDirty('comparableRentData.customOptions['+newIndex+'].name');

            sectionData.comparableSubjects.forEach(function(subject) {
                subject.customOptions.push({});
            });
        }

        function removeOption(index) {
            $scope.vm.removeFromArray(sectionData.customOptions, index).then(function() {
                sectionData.comparableSubjects.forEach(function(subject) {
                    $scope.vm.removeItem(subject.customOptions, index);
                });
            });
        }

        function addSubject() {
            var numOptions = sectionData.customOptions.length;
            sectionData.comparableSubjects.push(initSubject({}, numOptions));
        }

        /**
         * Initialize the provided comparable object
         * @param  {Object} subject    The comparable to initalize
         * @param  {Number} numOptions The number of custom options
         * @return {Object}            The initialized comparable
         */
        function initSubject(subject, numOptions) {
            // Make sure arrays are initialized
            if(!subject.customOptions) subject.customOptions = [];
            if(!subject.images || subject.images.length === 0) subject.images = [{}];

            // Initalize custom options to match the numOptions length
            while(subject.customOptions.length < numOptions) {
                subject.customOptions.push({});
            }

            // Init calculated values
            subject.liveableFloorAreaAdjustment = function() {
                if(!$scope.vm.formData.comparableRentData.liveableFloorArea || !subject.liveableFloorArea) return null;
                var adjustment = ($scope.vm.formData.comparableRentData.liveableFloorArea - subject.liveableFloorArea) * (sectionData.pricePerArea || 0);
                return Math.round(adjustment/100)*100;
            };

            return subject;

            /**
             * Get a list of all adjustment values for the provided subject
             */
            function getAdjustments(subject) {
                return adjustmentFields.map(function(field) {
                    var fieldValue = subject[field + 'Adjustment'];
                    return angular.isFunction(fieldValue) ? fieldValue() : (fieldValue || 0);
                }).concat(subject.customOptions.map(function(option) {
                    return option.adjustment || 0;
                }));
            }

            function sum(a,b) {
                return a + b;
            }
        }
    }
})();
