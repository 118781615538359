(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraisalReportHeaderController', AppraisalReportHeaderController);

    AppraisalReportHeaderController.$inject = ['$rootScope', 'report', 'order', 'account', 'reportEditable', 'reportSubmittable'];

    function AppraisalReportHeaderController($rootScope, report, order, account, reportEditable, reportSubmittable) {
        var vm = this;
        vm.report = report;
        vm.order = order;
        vm.reportEditable = reportEditable;
        vm.reportSubmittable = reportSubmittable;
        vm.isCosigner = account.appraiserUser && report.cosignerId === account.appraiserUser.id;
        var address = order.address;
        vm.orderAddress = [address.address1, address.cityName, address.provinceName].filter(Boolean).join(', ');
    }
})();
