(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('originator-appraiser-direct-note-messageboard', {
            parent: 'entity',
            url: '/originator-appraiser-direct-messages-for-appraisal/{id}',
            data: {
                authorities: [/^ROLE_(ADMIN|VC|CUSTOMER_CARE|ORIGINATOR|APPRAISER|APPRAISAL).*/],
                pageTitle: 'valueconnectApp.originatorAppraiserDirectNote.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/originator-appraiser-direct-note/originator-appraiser-direct-note-messageboard.html',
                    controller: 'OriginatorAppraiserDirectNoteMessageboardController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('originatorAppraiserDirectNote');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                order: ['AppraisalOrder', '$stateParams', function(AppraisalOrder, $stateParams) {
                    return AppraisalOrder.getMinimal({id: $stateParams.id}).$promise; // <--- new code
                }],
                dashboard: ['OriginatorAppraiserDirectNote', 'order', function(OriginatorAppraiserDirectNote, order) {
                    return OriginatorAppraiserDirectNote.getMessageBoardData({appraisalOrderId : order.id}).$promise;
                }],
                account: ['Principal', function(Principal) {
                    return Principal.identity();
                }],
                hasInsertRole: ['Principal', 'authorize', function(Principal, authorize) {
                    return Principal.hasAnyRole(['ORIGINATOR_PRINCIPAL','ORIGINATOR_ADMINISTRATOR','ORIGINATOR_AGENT','APPRAISAL_FIRM_PRINCIPAL','APPRAISER', 'APPRAISER_CANDIDATE', 'APPRAISAL_FIRM_ADMINISTRATOR','ORIGINATOR_REGION_MANAGER','ROLE_ORIGINATOR_LOCATION_MANAGER']);
                }],
            }
        });
    }
})();
