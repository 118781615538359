(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('RiskRule', RiskRule);

    RiskRule.$inject = ['$resource'];

    function RiskRule ($resource) {
        var resourceUrl =  'api/risk-rules/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getRiskHistory': { method: 'GET', url:'api/risk-rules/history'},
            'getRiskHistoryExport': {
                method: 'GET',
                url: 'api/risk-rules/history/export',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    if (data) return { file: new Blob([data], { type: 'application/vnd.ms-excel' }) };
                    else return data;
                }
            }

        });
    }
})();
