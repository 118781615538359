(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('BrokerageMerge', BrokerageMerge);
    
    function BrokerageMerge() {
        var brokeragesToMerge = new Map();
        
        var service = {
            brokeragesToMerge: brokeragesToMerge
        };
        
        return service;
    }
})();
