(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AuditsController', AuditsController);

    AuditsController.$inject = ['$state', '$stateParams', '$filter', 'AuditsService', 'ParseLinks', 'moment', 'Enum', 'User', 'pagingParams'];

    function AuditsController ($state, $stateParams, $filter, AuditsService, ParseLinks, moment, Enum, User, pagingParams) {
        var vm = this;
        var defaultSort = 'auditEventDate,desc';
        vm.pagingParams = pagingParams;
        vm.audits = null;
        vm.links = null;
        vm.totalItems = null;
        vm.fromDate = moment().subtract(1, 'month').toDate();
        vm.toDate = moment().toDate();
        vm.eventType = null;
        vm.principal = null;
        Enum.$promise.then(function() {
            vm.eventTypes = Enum.AppraisalEvent.concat([
                'AUTHENTICATION_SUCCESS',
                'AUTHENTICATION_FAILURE',
                'SIGN_REPORT',
                'COSIGN_REPORT',
                'Disable Mortgage',
                'DYNAMIC_FORM_SAVE_ERROR',
                'DISABLED_CHANGED',
                'UPDATED',
                'TERMS_ACCEPTED',
                'PRIVACY_POLICY_ACCEPTED',
                'AUTHORIZATION_FAILURE_CUSTOM',
                'EMAIL',
                'EMAIL_BOUNCE'
            ]);
        });

        vm.loadData = loadData;
        vm.queryUsers = queryUsers;
        vm.transition = transition;

        loadData();

        function loadData() {
            var dateFormat = 'yyyy-MM-dd';

            var queryParams = {
                page: vm.pagingParams.page - 1,
                size: 20,
                sort: getSort(),
                fromDate: $filter('date')(vm.fromDate, dateFormat),
                toDate: $filter('date')(vm.toDate, dateFormat),
                eventType: vm.eventType,
                principal: vm.principal ? vm.principal.login : undefined,
                orderId: vm.appraisalOrder ?  vm.appraisalOrder.id : undefined
            };

            return AuditsService.query(queryParams, function(result, headers){
                vm.audits = result;
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
            }).$promise;
        }

        /**
         * Generate the 'sort' parameter to be sent to the server or used as a query parameter
         * for the state
         * @return {String}
         */
        function getSort() {
            return vm.pagingParams.predicate + ',' + (vm.pagingParams.ascending ? 'asc' : 'desc');
        }

        /**
         * Transition state then load new data. Squash state params that are equal to their defaults.
         * @return {Promise} Promise resolved when the new data is loaded
         */
        function transition() {
            $state.go($state.$current, {
                page: vm.pagingParams.page === 1 ? null : vm.pagingParams.page,
                sort: getSort() === defaultSort ? null : getSort()
            }).then(loadData);
        }

        /**
         * Get a list of users matching the supplied query string
         * @param  {String} queryString Optional. The string to use for the search query
         * @return {Promise}             A promise that will be resolved with the query results
         */
        function queryUsers(queryString) {
            return User.query({query: queryString}).$promise;
        }
    }
})();
