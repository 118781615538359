(function() {
    'use strict';
     angular
        .module('valueconnectApp')
        .factory('AppraisalFirmLocation', AppraisalFirmLocation);

    AppraisalFirmLocation.$inject = ['$resource'];

    function AppraisalFirmLocation ($resource) {
        var resourceUrl =  'api/appraisal-firm-locations/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            
            'update': { method:'PUT' },
            'findByAppraisalFirm' : { method:'GET', url: 'api/appraisal-firm-locations/by-firm/:id', isArray: true}
           
          });
    }
})();
