(function() {
    'use strict';
     angular
        .module('valueconnectApp')
        .factory('File', File);

    File.$inject = ['$resource'];

    function File ($resource) {
        var resourceUrl =  'api/files/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            
            'update': { method:'PUT' },
            
            'rotate': { method:'POST', url: 'api/files/:id/rotate-image/:direction' },
            
            'exportCancelledReport' : { 
                method:'POST', 
                url: 'api/files/export-cancelled-order/',
                responseType: 'arraybuffer',
                transformResponse: function(data, headersGetter, status) {
                return { data: data };
              }
            },
            
            'download': {
                method: 'GET',
                url: 'api/files/:id/download',
                responseType: 'arraybuffer',
                transformResponse: function(data, headersGetter, status) {
                    return { data: data };
                }
            },      
            
            'exportReport' : { 
                method:'POST', 
                url: 'api/files/export-report/:filetype',
                responseType: 'arraybuffer',
                transformResponse: function(data, headersGetter, status) {
                    return { data: data };
                }           	
            },      
           
            'upload': {
                method:'POST',
                url: 'api/files/upload',
                headers: {'Content-Type': undefined },
                transformResponse: function (data) {
                    console.log(data)
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        
         });
    }
})();
