(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AuthorityEditorController', AuthorityEditorController);

    AuthorityEditorController.$inject = ['$scope', 'Principal', 'Brokerage', 'BrokerageRegion', 'BrokerageLocation', 'AppraisalFirm', 'Lender', '$filter'];

    function AuthorityEditorController($scope, Principal, Brokerage, BrokerageRegion, BrokerageLocation, AppraisalFirm, Lender, $filter) {

        var brokerageAuthorities = [
            'ROLE_ORIGINATOR_PRINCIPAL',
            'ROLE_ORIGINATOR_ADMINISTRATOR'
        ];

        var brokerageRegionAuthorities = [
            'ROLE_ORIGINATOR_REGION_MANAGER'
        ];

        var brokerageLocationAuthorities = [
            'ROLE_ORIGINATOR_LOCATION_MANAGER',
            'ROLE_ORIGINATOR_AGENT',
            'ROLE_ORIGINATOR_AGENT_ASSISTANT'
        ];

        var appraisalFirmAuthorities = [
            'ROLE_APPRAISER',
            'ROLE_APPRAISER_CANDIDATE',
            'ROLE_APPRAISAL_FIRM_PRINCIPAL',
            'ROLE_APPRAISAL_FIRM_ADMINISTRATOR',
            'ROLE_APPRAISAL_REVIEWER'
        ];

        var lendingFirmAuthorities = [
            'ROLE_LENDER_ADMINISTRATOR'
        ];

        var vm = this;

        function init() {
            vm.translatedAuthority = translatedAuthority;
            vm.entityAuthority = {authorityType: "USER"};

            vm.isVC = Principal.hasAnyAuthority([/^ROLE_(ADMIN|VC|CUSTOMER_CARE).*/i]);
            vm.isAppraisalFirm = Principal.hasAnyAuthority([/^ROLE_(APPRAISAL).*/i]);
            vm.isOriginator = Principal.hasAnyAuthority([/^ROLE_(ORIGINATOR).*/i]);

            // Get the list of authorities that this user has permission to manage
            Principal.identity().then(function(account) {
                vm.authorities = account.authorities.filter(function(auth) {
                    return auth.startsWith("MANAGE_ROLE_");
                }).map(function(auth) {
                    return auth.substring("MANAGE_".length);
                });
                vm.authorities = $filter('orderBy')(vm.authorities, translatedAuthority);
            });

            if (vm.isVC || vm.isOriginator) {
                vm.brokerages = Brokerage.getDropDownBrokerages({size: 2000});
                vm.brokerageRegions = BrokerageRegion.getDropDownBrokerageRegions({size: 2000});
                vm.brokerageLocations = BrokerageLocation.getDropDownBrokerageLocations({size: 2000});
            }

            if (vm.isVC || vm.isAppraisalFirm) {
                vm.appraisalFirms = AppraisalFirm.getDropDownAppraisalFirms({size: 2000});
            }

            if (vm.isVC) {
                vm.lendingFirms = Lender.querySimplePublic({size: 2000});
            }

            vm.brokerageSearchText = "";
            vm.brokerageRegionSearchText = "";
            vm.brokerageLocationSearchText = "";
            vm.appraisalFirmSearchText = "";
            vm.lendingFirmSearchText = "";
        }

        init();

        vm.matchAuthorities = matchAuthorities;
        vm.matchBrokerage = matchBrokerage;
        vm.matchBrokerageRegion = matchBrokerageRegion;
        vm.matchBrokerageLocation = matchBrokerageLocation;
        vm.matchAppraisalFirm = matchAppraisalFirm;
        vm.matchLendingFirm = matchLendingFirm;

        vm.authorityUpdated = authorityUpdated;
        vm.entityUpdated = entityUpdated;

        vm.save = save;

        function matchAuthorities(searchText) {
            var searchRegex = new RegExp(searchText, 'i');
            return vm.authorities.filter(function(auth) {
                if(auth !== "ROLE_ANONYMOUS")
                    return searchRegex.test(vm.translatedAuthority(auth));
            });
        }

        function matchBrokerage(searchText) {
            var searchRegex = new RegExp(searchText, 'i');
            return vm.brokerages.filter(function(brokerage) {
                return searchRegex.test(brokerage.name);
            });
        }

        function matchBrokerageRegion(searchText) {
            var searchRegex = new RegExp(searchText, 'i');
            return vm.brokerageRegions.filter(function(brokerageRegion) {
                return searchRegex.test(brokerageRegion.name);
            });
        }

        function matchBrokerageLocation(searchText) {
            var searchRegex = new RegExp(searchText, 'i');
            return vm.brokerageLocations.filter(function(brokerageLocation) {
                return searchRegex.test(brokerageLocation.brokerageId+" " +brokerageLocation.brokerageName+ ", " + brokerageLocation.officeName+ ", " + brokerageLocation.brokerageRegionName);
            });
        }

        function matchAppraisalFirm(searchText) {
            var searchRegex = new RegExp(searchText, 'i');
            return vm.appraisalFirms.filter(function (appraisalFirm) {
                return searchRegex.test(appraisalFirm.name);
            });
        }

        function matchLendingFirm(searchText) {
            var searchRegex = new RegExp(searchText, 'i');
            return vm.lendingFirms.filter(function (lendingFirm) {
                return searchRegex.test(lendingFirm.lenderName);
            });
        }

        function cleanEditor() {
            if (vm.entityAuthority.authorityType !== "BROKERAGE") vm.entityAuthority.brokerageId = null;
            if (vm.entityAuthority.authorityType !== "BROKERAGE_REGION") vm.entityAuthority.brokerageRegionId = null;
            if (vm.entityAuthority.authorityType !== "BROKERAGE_LOCATION") vm.entityAuthority.brokerageLocationId = null;
            if (vm.entityAuthority.authorityType !== "APPRAISAL_FIRM") vm.entityAuthority.appraisalFirmId = null;
            if (vm.entityAuthority.authorityType !== "LENDING_FIRM") vm.entityAuthority.lenderId = null;

            vm.brokerage = null;
            vm.brokerageRegion = null;
            vm.brokerageLocation = null;
            vm.appraisalFirm = null;
            vm.lendingFirm = null;

            vm.brokerageSearchText = "";
            vm.brokerageRegionSearchText = "";
            vm.brokerageLocationSearchText = "";
            vm.appraisalFirmSearchText = "";
            vm.lendingFirmSearchText = "";
        }

        function save() {
            if (!$scope.$parent.vm.addAuthority) {
                return;
            }
            cleanEditor();
            $scope.$parent.vm.addAuthority(vm.entityAuthority);
            vm.entityAuthority = {};
        }

        function translatedAuthority(auth) {
            return $filter('translate')("valueconnectApp.authority." + auth);
        }

        // Update watchers
        function authorityUpdated(authority) {
            var auth = vm.entityAuthority.authority;
            if (brokerageAuthorities.indexOf(auth) !== -1)
                vm.entityAuthority.authorityType = "BROKERAGE";
            else if (brokerageRegionAuthorities.indexOf(auth) !== -1)
                vm.entityAuthority.authorityType = "BROKERAGE_REGION";
            else if(brokerageLocationAuthorities.indexOf(auth) !== -1)
                vm.entityAuthority.authorityType = "BROKERAGE_LOCATION";
            else if (appraisalFirmAuthorities.indexOf(auth) !== -1)
                vm.entityAuthority.authorityType = "APPRAISAL_FIRM";
            else if (lendingFirmAuthorities.indexOf(auth) !== -1)
                vm.entityAuthority.authorityType = "LENDING_FIRM";
            else
                vm.entityAuthority.authorityType = "USER";
        }

        function entityUpdated(obj, key) {
            vm.entityAuthority[key] = obj ? obj.id : null;
        }
    }
})();
