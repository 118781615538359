(function () {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraisalOrderDetailController', AppraisalOrderDetailController);

    AppraisalOrderDetailController.$inject = ['$q', '$sce', '$scope', '$rootScope', '$state', '$mdDialog', '$filter', '$window', 'entity', 'account', 'Principal',
        'AppraisalOrder', 'AppraisalReport', 'File', 'AppraisalOrderContact', 'QuoteSplit', 'AlertService', 'Quote', 'AppraisalOrderChangeRequest',
        'QuoteSplitModalService', 'AppraisalOrderModal', 'Blob', 'FileSaver', 'moment', 'AppraisalState', 'Coupon', 'AppraisalNote', 'VCNote',
        'LenderIntegrationBlacklist', 'User', 'daysAfterPayment', 'Invoice', 'InvoiceUtils'];

    function AppraisalOrderDetailController($q, $sce, $scope, $rootScope, $state, $mdDialog, $filter, $window, entity, account, Principal,
                                            AppraisalOrder, AppraisalReport, File, AppraisalOrderContact, QuoteSplit, AlertService, Quote, AppraisalOrderChangeRequest,
                                            QuoteSplitModalService, AppraisalOrderModal, Blob, FileSaver, moment, AppraisalState, Coupon, AppraisalNote, VCNote,
                                            LenderIntegrationBlacklist, User, daysAfterPayment, Invoice, InvoiceUtils) {

        var vm = this;
        vm.appraisalOrder = entity;
        vm.daysAfterPayment = daysAfterPayment;
        vm.activeReport = vm.appraisalOrder.appraisalReports.find(function (report) {
            return report.id === vm.appraisalOrder.activeReport.id;
        });
        vm.checkAssigned = checkAssigned;
        vm.isAssigned = checkAssigned();
        vm.isAppraiser = Principal.hasAnyAuthority([/^ROLE_(APPRAISER|APPRAISAL).*/]);
        vm.isVC = Principal.hasAnyAuthority([/^ROLE_(ADMIN|VC|CUSTOMER_CARE).*/]);
        vm.isAdmin = Principal.hasAnyAuthority([/^ROLE_(ADMIN).*/]);
        vm.isCosigner = vm.activeReport && account.appraiserUser && vm.activeReport.cosignerId === account.appraiserUser.id;
        // vm.isThirdParty = vm.activeReport.forms.length === 1 && vm.activeReport.forms[0].fieldName === 'thirdParty';
        // Reports retain other forms if you ever switch off of third party and back. Changed to a function
        vm.isThirdParty = isThirdParty();
        vm.canSwitchToThirdParty = vm.isAssigned && inState('editable') && !vm.isThirdParty;
        vm.canClone = vm.isAssigned && inState('editable') && vm.activeReport.started === false && !vm.isThirdParty &&
            !vm.appraisalOrder.inspectionFollowUp && !vm.appraisalOrder.narrative && vm.appraisalOrder.appraisalType !== 'RELOCATION' && vm.appraisalOrder.appraisalTypeOptions != 'AVM';
        vm.showDetails = false;
        vm.entitiesLoaded = false;
        vm.printLogData = printLogData;
        vm.showLogDetails = showLogDetails;
        //Used for toggling email log entries details
        vm.logToggle = [];
        vm.detailedLogTypes = ['EMAIL'];
        vm.isIntegrationOrder = null;
        vm.purchaseClosingDate = vm.appraisalOrder.purchaseClosingDate != null ? vm.appraisalOrder.purchaseClosingDate.split('T')[0] : vm.appraisalOrder.purchaseClosingDate;
        vm.navigateToEditOrder = navigateToEditOrder;
        vm.placeholderLenderOptions = getPlaceholderLenderOptions();

        vm.displayLenderPrompt;

        Principal.identity().then(function(identity) {
            vm.identity = identity;
            User.getIntegrationId({ id: identity.id }).$promise.then(function (value) {
                Promise.resolve(value).then(function (resolved) {
                    vm.isIntegrationOrder = resolved.data;

                    if (resolved.data > 0) {
                        LenderIntegrationBlacklist.isBlackListedByName({
                            integrationID: resolved.data,
                            lenderName: vm.appraisalOrder.lender ? vm.appraisalOrder.lender.lenderName : null
                        }).$promise.then(function (value) {
                            Promise.resolve(value).then(function (resolved) {
                                vm.displayLenderPrompt = resolved.data;
                            });
                        });
                    }
                });
            });
        });

        // Returns true if the only active form is thirdparty
        function isThirdParty() {
            var onlyThirdParty = false;
            for (var formIndex in vm.activeReport.forms) {
                if (vm.activeReport.forms[formIndex].formType === "THIRD_PARTY" && vm.activeReport.forms[formIndex].active === true) {
                    onlyThirdParty = true;
                } else {
                    onlyThirdParty = false;
                }
            }
            return onlyThirdParty;
        }


        $scope.$watch('vm.displayLenderPrompt', function (displayLenderPrompt) {
            if (displayLenderPrompt === "true") {
                //display modal
                $mdDialog.show(
                    $mdDialog.alert()
                        .clickOutsideToClose(true)
                        .title('Attention')
                        .textContent('Thank you for choosing Value Connect. Please be advised, due to the lender requirements Value Connect is unable to assist ')
                        .ok('Okay')
                ).then(function () {
                    $state.go('home');
                });
            }
        });

        // Initialize appraisal notes for the order
        vm.AppraisalNotes = [], vm.vcNotes = [];
        if (vm.isAppraiser || vm.isVC) {
            AppraisalNote.getNotesForAppraisal({ id: vm.appraisalOrder.id }, function (data) {
                vm.AppraisalNotes = data;
            });
        }
        if (vm.isVC) {
            VCNote.getVCNotesForAppraisalOrder({ id: vm.appraisalOrder.id }, function (data) {
                vm.vcNotes = data;
            });
        }

        if (vm.isVC && vm.appraisalOrder.lender && vm.appraisalOrder.lender.id && hasAnyState('REPORT_COMPLETE', 'REPORT_ACCEPTED')) {
            AppraisalOrder.getAppraisalReportAccessKeyURL({ id: vm.appraisalOrder.id }).$promise.then(function (data) {
                vm.appraisalReportAccessKeyURL = data.url;
            });
        }

        // Appraisal order editable
        vm.orderEditable = AppraisalState.inAnyState(vm.appraisalOrder, AppraisalState.orderEditable)
            && !vm.appraisalOrder.appraisalType.includes("AVM");

        // Reload each quote with additional details
        // TODO: Return this data in the first request
        var quoteRequests = vm.appraisalOrder.appraisalReports.map(function (report) {
            if (!report.quote) return $q.when(report.quote);

            else return Quote.get({ id: report.quote.id }, function (quote) {
                // Identify the active quote
                if (vm.appraisalOrder.appraisalReports.length === 1 ||
                    report.id === vm.appraisalOrder.activeReport.id) {
                    vm.quote = quote;
                }

                report.quote = quote;
            });
        });

        // invoices
        vm.invoices = [];
        vm.getInvoicePdf = getInvoicePdf;
        if (vm.isVC || vm.isAdmin) {
            Invoice.getByFilter({ appraisalOrderId: vm.appraisalOrder.id }, function (data) {
                vm.invoices = data;
            });
        }

        function getInvoicePdf(id) {
            return InvoiceUtils.getInvoicePdf(id);
        }

        $q.all(quoteRequests).then(function () { vm.entitiesLoaded = true; });

        // Get the appraisal order by id from the quote appraisal order id.
        var appraisalOrderId = vm.appraisalOrder.id;

        vm.appraisalOrder.$promise.then(function () {
            vm.appraisalOrder.appraisalOrderContacts = AppraisalOrderContact.getcontactbyappraisalorderid({ id: vm.appraisalOrder.id });
        });

        // Define methods exposed to view
        vm.requestQuote = requestQuote;

        vm.hasMadeAnyPayment = hasMadeAnyPayment;
        vm.hasPendingClientPayment = hasPendingClientPayment;
        vm.acceptQuote = acceptQuote;
        vm.regenerateReport = regenerateReport;
        vm.setupSplit = setupSplit;
        vm.resendPaymentRequest = resendPaymentRequest;

        vm.triggerPaymentDialog = triggerPaymentDialog;
        vm.viewPayment = viewPayment;
        vm.isPendingChangeRequest = isPendingChangeRequest;
        vm.isNoAppraiserOptions = isNoAppraiserOptions;
        vm.updateAppraiserAppraisalId = updateAppraiserAppraisalId;
        vm.cancelOrder = cancelOrder;
        vm.appraiserAction = appraiserAction;
        vm.ccrAction = ccrAction;
        vm.downloadFile = downloadFile;
        vm.createAdditionalInspectionReport = createAdditionalInspectionReport;
        vm.generateICal = generateICal;
        vm.inState = inState;
        vm.hasAnyState = hasAnyState;
        vm.openAppraisalOrderChangeRequestDialog = openAppraisalOrderChangeRequestDialog;
        vm.getLogEvent = getLogEvent;
        vm.applyCoupon = applyCoupon;
        vm.removeCoupon = removeCoupon;
        vm.hasOrderOption = hasOrderOption;
        vm.printScreen = printScreen;
        vm.allowChangeRequests = hasAnyState('PENDING_APPRAISER_ACCEPTANCE', 'PENDING_SCHEDULE_VISIT', 'PENDING_VISIT', 'PENDING_SUBMISSION', 'RESUBMISSION_REQUIRED')
            && (checkAssigned() || Principal.hasAnyAuthority([/^ROLE_APPRAISAL.*/]) || vm.isVC);
        vm.changeOrderState = changeOrderState;
        vm.getNumUnitsRequiringMarketRent = getNumUnitsRequiringMarketRent;
        vm.getWhichUnitsRequireMarketRent = getWhichUnitsRequireMarketRent;

        // Calculate LTV
        if (vm.appraisalOrder.loanAmount) {
            var totalLoanAmount = vm.appraisalOrder.loanAmount;
            if (vm.appraisalOrder.firstMortgageLoanAmount) totalLoanAmount += vm.appraisalOrder.firstMortgageLoanAmount;
            if (vm.appraisalOrder.secondMortgageLoanAmount) totalLoanAmount += vm.appraisalOrder.secondMortgageLoanAmount;
            vm.estimatedLTV = totalLoanAmount / vm.appraisalOrder.lendersEstimatedPropertyValue;
        }

        Principal.matchAnyAuthority(/^ROLE_(ADMIN|CUSTOMER_CARE|APPRAISER|APPRAISAL|VC).*/gi).then(function (result) {
            if (result) {
                vm.showDetails = true;

                AppraisalOrderChangeRequest.getForAppraisalOrderId({ id: vm.appraisalOrder.id }, function (data) {
                    vm.changeRequests = data;
                });
            }
        });

        /**
         * Show a confirmation dialog then send a request to request a quote for the order. State will
         * be reloaded on success.
         * @param  {Event} ev Optional. The javascript click event that opened the dialog
         * @return {Promise}  A promise that will be resolved with the result of the request, or
         *                    cancelled with any failure that occurs
         */
        function requestQuote(ev) {
            return AppraisalOrderModal.requestQuote(vm.appraisalOrder, ev).then(function (result) {
                // Reload state
                return $state.reload().then(function () {
                    // Show success message
                    AlertService.success('valueconnectApp.appraisalOrder.quoteRequested', { id: vm.appraisalOrder.id });
                    return result;
                });
            });
        }

        function printScreen() {
            window.print();
        }

        function hasMadeAnyPayment() {
            if (!vm.quote) return false;
            return vm.quote.quoteSplits && vm.quote.quoteSplits.some(function (split) { return split.paymentSplits.length > 0; });
        }

        function hasPendingClientPayment() {
            if (!vm.quote) return false;
            return vm.quote.quoteSplits && vm.quote.quoteSplits.some(function(split) {
                return split.role === 'CLIENT' && split.paymentSplits.length === 0 && split.splitAmount > 0;
            });
        }



        function resendPaymentRequest() {
            QuoteSplitModalService.openResendPaymentRequestDialog(vm.quote, vm.appraisalOrder.clientContact).result.then(
                function(data, headers) { $state.reload(); },
                function(data, headers) {
                    if(data === 'cancel' || data === 'backdrop click') return;
                    AlertService.error("An error occurred while resending the payment request. Please contact Value Connect support.");
                });
        }

        function acceptQuote() {
            QuoteSplit.update({ 'quoteId': vm.quote.id, 'originatorAmount': vm.quote.total },
                function (data, headers) { $state.reload(); },
                function (data, headers) {
                    if (data === 'cancel' || data === 'backdrop click') return;
                    AlertService.error("An error occurred while accepting the quote. Please contact Value Connect support.");
                });
        }

        function regenerateReport() {
            AppraisalReport
                .regenerateReport(
                    {'id': vm.appraisalOrder.activeReport.id},
                    {},
                    function() {
                        AlertService.success("Report created");
                    },
                    function(error) {
                        AlertService.error(error.data.description);
                    }
                );
        }

        function setupSplit() {
            QuoteSplitModalService.openCreateDialog(vm.quote, vm.appraisalOrder.clientContact).result.then(
                function (data, headers) { $state.reload(); },
                function (data, headers) {
                    if (data === 'cancel' || data === 'backdrop click') return;
                    AlertService.error("An error occurred while accepting the quote. Please contact Value Connect support.");
                });
        }

        function triggerPaymentDialog(split) {
            AppraisalOrderModal.openPaymentDialog(split);
        }

        function viewPayment(s) {
            if (s.role !== "VALUE_CONNECT" && s.splitAmount > 0 && s.paymentSplits.length > 0) {
                $state.go("payment-detail", { id: s.paymentSplits[0].paymentId });
            }
        }

        function isPendingChangeRequest(ev) {
            return /^ACCEPTANCE_PENDING_.*/.test(vm.appraisalOrder.state);
        }

        function isNoAppraiserOptions(ev) {
            return /^(ORDER_DRAFTED|PENDING_PAYMENT|PAYMENT_COMPLETE|PENDING_ASSIGNMENT|ADDITIONAL_PAYMENT_REQUIRED|ORDER_CANCELLED)/.test(vm.appraisalOrder.state);
        }

        function updateAppraiserAppraisalId() {
            AppraisalOrder.setInternalId(vm.appraisalOrder, function (data) {

            });
        }

        function cancelOrder(ev) {
            AppraisalOrderModal.openCancelAppraisalOrderDialog(vm.appraisalOrder).then(function (data) {

            });
        }

        function checkAssigned() {
            return account.appraiserUser && vm.appraisalOrder.assignedAppraiserId === account.appraiserUser.id;
        }

        function openAppraisalOrderChangeRequestDialog(changeRequest) {
            Principal.matchAnyAuthority(/^ROLE_(CUSTOMER_CARE|VC|ADMIN).*/).then(function (resp) {
                if (resp) {
                    AppraisalOrderModal.openCcrAppraisalOrderChangeRequestDialog(vm.appraisalOrder, changeRequest);
                } else {
                    AppraisalOrderModal.openAppraisalOrderChangeRequestDialog(null, vm.appraisalOrder, changeRequest,
                        vm.hasAnyState('PAYMENT_COMPLETE', 'PENDING_ASSIGNMENT', 'PENDING_APPRAISER_ACCEPTANCE'));
                }
            });
        }

        function appraiserAction(actionType, ev) {
            // Open a dialog then handle the result
            var modal = openDialog(actionType);
            var promise = modal && modal.result ? modal.result : modal;  // uibModal and mdModal return promises differently
            promise.then(handleSuccess, handleClose);

            function openDialog(actionType) {
                switch (actionType) {
                    case 'ACCEPT':
                        return AppraisalOrderModal.openAcceptDialog(vm.appraisalOrder);
                    case 'DECLINE':
                        return AppraisalOrderModal.openDeclinedDialog(vm.appraisalOrder);
                    case 'CLARIFY':
                    case 'FEE_CHANGE':
                    case 'DUE_DATE_CHANGE':
                        return AppraisalOrderModal.openAppraisalOrderChangeRequestDialog(actionType, vm.appraisalOrder);
                    case 'SCHEDULE_VISIT':
                        return AppraisalOrderModal.scheduleVisit(vm.appraisalOrder);
                    case 'COMPLETE_VISIT':
                        return AppraisalOrderModal.completeVisit(vm.appraisalOrder, ev);
                    case 'ASSIGN_COSIGNER':
                        return AppraisalOrderModal.assignCosigner(vm.appraisalOrder);
                    case 'CLONE_REPORT':
                        return AppraisalOrderModal.cloneReport(vm.appraisalOrder, ev);
                    case 'THIRD_PARTY_UPLOAD':
                        return AppraisalOrderModal.thirdPartyUpload(vm.activeReport, ev);
                }
            }

            function handleSuccess(result) {
                if (actionType === 'CLONE_REPORT' || actionType === 'THIRD_PARTY_UPLOAD') {
                    $state.go('form.builder', { id: vm.activeReport.id });
                } else if (actionType === 'DECLINE') {
                    $state.go('home');
                } else {
                    $state.reload();
                }
            }

            function handleClose(error) {
                if (error !== undefined) {
                    AlertService.error("Oops! An error has occurred. Please contact info@valueconnect.ca if the issue persists.")
                }
            }
        }

        function ccrAction(actionType, ev) {
            if (actionType === 'REDIRECT_ORDER') {

                AppraisalOrderModal.redirectOrder(vm.appraisalOrder).then(function (result) {
                    vm.appraisalOrder = AppraisalOrder.get({id: result.id});
                    AlertService.success("Lender updated successfully");
                }, function (result) {
                    if (result && result.error) AlertService.error(result.error);
                });
            } else if (actionType === 'RESTORE_CANCELLED_ORDER') {

                AppraisalOrderModal.restoreCancelledOrder(vm.appraisalOrder).then(function (result) {
                    if (result !== undefined) {
                        vm.appraisalOrder = AppraisalOrder.get({id: result.id});
                    }
                    AlertService.success("Appraisal order restored successfully");
                }, function (result) {
                    if (result && result.error) AlertService.error(result.error);
                });

            } else if (actionType === 'CHANGE_DUE_DATE') {

                AppraisalOrderModal.changeDueDate(vm.appraisalOrder).then(function (result) {
                    vm.appraisalOrder = AppraisalOrder.get({id: result.id});
                    AlertService.success("Due Date updated successfully");
                }, function (result) {
                    if (result && result.error) AlertService.error(result.error);
                });
            } else if (actionType === 'SEND_REPORT_BACK') {

                AppraisalOrderModal.sendReportBack(vm.appraisalOrder.activeReport).then(function (result) {
                    vm.appraisalOrder = AppraisalOrder.get({id: result.appraisal_order_id});
                    AlertService.success("The order has been sent back to the appraiser");
                }, function (result) {
                    if (result && result.error) AlertService.error(result.error);
                });
            } else if (actionType === 'ADD_VC_NOTE') {

                AppraisalOrderModal.addVCNote(vm.appraisalOrder).then(function (result) {
                    VCNote.getVCNotesForAppraisalOrder({ id: vm.appraisalOrder.id }, function (data) {
                        vm.vcNotes = data;
                    });
                }, function (result) {
                    if (result && result.error) AlertService.error(result.error);
                });
            } else {
                AppraisalOrderModal.assignAppraiser(vm.appraisalOrder, actionType).then(function (result) {
                    $state.reload();
                    AlertService.success("valueconnectApp.appraisalOrder.assignAppraiser.success." + actionType, {});
                }, function (result) {
                    if (result && result.error) AlertService.error(result.error);
                });
            }

        }

        function createAdditionalInspectionReport(ev) {
            $mdDialog.show({
                templateUrl: 'app/entities/appraisal-report/appraisal-report-new-dialog.html',
                controller: 'AppraisalReportNewDialogController',
                controllerAs: 'vm',
                fullscreen: false,
                locals: {
                    entity: {
                        id: null,
                        appraisalOrderId: appraisalOrderId
                    }
                }
            }).then(function () {
                // TODO: implement
            });
        }

        function downloadFile(file) {
            File.download({ id: file.id, appraisalOrderId: vm.appraisalOrder.id }, function (response) {
                var fileData = new Blob([response.data], { type: file.contentType });
                FileSaver.saveAs(fileData, file.fileName);
            });
        }

        function generateICal() {
            AppraisalReport.generateICal({ id: vm.appraisalOrder.appraisalReports[0].id }, {}, function (data) {
                var fileData = new Blob([data.data], { type: 'text/calendar' });
                FileSaver.saveAs(fileData, 'appraisal-visit-' + vm.appraisalOrder.id + '.ics');
            }, function (data) {
                alert("Unable to generate iCal");
            });
        }

        function inState(state) {
            return AppraisalState.inState(vm.appraisalOrder, state);
        }

        /**
         * Check if the current appraisal order has any of the specified states.
         * Each states to check should be passed as a seperate string argument.
         *
         * @return {Boolean} True if the appraisal order has any of the provided states, otherwise false
         */
        function hasAnyState() {
            var states = Array.prototype.slice.call(arguments);
            return AppraisalState.inAnyState(vm.appraisalOrder, states);
        }

        /**
         * Retrieve the latest event of the provided type
         * @param  {String} logType The type of event to retrieve
         * @return {Object}         The latest event of the provided type, or null if none exists
         */
        function getLogEvent(logType) {
            if (!vm.appraisalOrder.orderLog) {
                return null;
            }

            return vm.appraisalOrder.orderLog.filter(function (event) {
                return event.auditEventType === logType;
            })[0] || null;
        }

        function applyCoupon(couponCode, quote) {
            Coupon.apply({ couponCode: couponCode, quoteId: quote.id }, function (resp) {

                //Update the necessary parts of the quote so the frontend refreshes
                quote.lineItems = resp.lineItems;
                quote.subTotal = resp.subTotal;
                quote.total = resp.total;

            });
        }

        function removeCoupon(quote) {
            Coupon.removeFromQuote({ quoteId: quote.id }, {}, function (resp) {

                //Update the necessary parts of the quote so the frontend refreshes
                quote.lineItems = resp.lineItems;
                quote.subTotal = resp.subTotal;
                quote.total = resp.total;

            });
        }

        function hasOrderOption(option) {
            return vm.appraisalOrder.options
                && $filter('filter')(vm.appraisalOrder.options, { name: option }, true).length;
        }

        function printLogData(log, key, data) {
            if (log.auditEventType === "EMAIL" && key === "content") {
                return $sce.trustAsHtml(data);
            } else {
                return data;
            }

        }

        function showLogDetails(log) {
            vm.logToggle[log.id] = !(vm.logToggle[log.id] | false);
        }

        var unsubscribe = $rootScope.$on('valueconnectApp:appraisalOrderUpdate', function (event, result) {
            vm.appraisalOrder = result;
        });
        $scope.$on('$destroy', function () {
            unsubscribe();
        });

        function changeOrderState() {
            return AppraisalOrderModal.changeOrderState(vm.appraisalOrder);
        }

        function getNumUnitsRequiringMarketRent() {
            const marketRentsOption = vm.appraisalOrder.options.find(function (option) { return option.name === 'MARKET_RENTS' } )
            if (marketRentsOption != null && marketRentsOption.value != null) {
                const marketRentsOptionValue = JSON.parse(marketRentsOption.value)
                if (marketRentsOptionValue.numUnitsRequiringMarketRent != null) {
                    return marketRentsOptionValue.numUnitsRequiringMarketRent
                } else {
                    return 1
                }
            }
        }

        function getWhichUnitsRequireMarketRent() {
            const marketRentsOption = vm.appraisalOrder.options.find(function (option) { return option.name === 'MARKET_RENTS' } )
            if (marketRentsOption != null && marketRentsOption.value != null) {
                const marketRentsOptionValue = JSON.parse(marketRentsOption.value)
                if (marketRentsOptionValue.unitsRequiringMarketRentDescription != null) {
                    return marketRentsOptionValue.unitsRequiringMarketRentDescription
                }
            }
        }

        function getPlaceholderLenderOptions() {
            const placeholderLenderOption = vm.appraisalOrder.options.find(function (option) { return option.name === 'PLACEHOLDER_LENDER' })
            if (placeholderLenderOption != null && placeholderLenderOption.value != null) {
                const placeholderLenderOptionValue = JSON.parse(placeholderLenderOption.value)
                if (placeholderLenderOptionValue.potentialLenders != null) {
                    return placeholderLenderOptionValue.potentialLenders.map(function (potentialLender) {
                        return { id: potentialLender.id, name: potentialLender.lenderName }
                    })
                }
            }
        }

        function navigateToEditOrder(appraisalId) {
            $window.location.href = $window.location.protocol + "//" + $window.location.host + "/v2/appraisal-order/" + appraisalId + '/edit';
        }
    }

})();
