(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('payment', {
            parent: 'entity',
            url: '/payment?page&sort&search',
            data: {
                authorities: [/^ROLE_(ADMIN|VC|CUSTOMER_CARE|ORIGINATOR).*/gi],
                pageTitle: 'valueconnectApp.payment.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/payment/payments.html',
                    controller: 'PaymentController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('payment');
                    $translatePartialLoader.addPart('enumPaymentMethod');
                    $translatePartialLoader.addPart('enumPaymentStatus');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('error');
                    return $translate.refresh();
                }]
            }
        })

        .state('payment-detail', {
            parent: 'entity',
            url: '/payment/{id}',
            data: {
                authorities: [/^ROLE_(ADMIN|VC|CUSTOMER_CARE|ORIGINATOR).*/gi],
                pageTitle: 'valueconnectApp.payment.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/payment/payment-detail.html',
                    controller: 'PaymentDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('payment');
                    $translatePartialLoader.addPart('appraisalOrder');
                    $translatePartialLoader.addPart('enumPaymentMethod');
                    $translatePartialLoader.addPart('enumPaymentStatus');
                    $translatePartialLoader.addPart('enumAppraisalType');
                    $translatePartialLoader.addPart('enumProductType');
                    $translatePartialLoader.addPart('error');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Payment', function ($stateParams, Payment) {
                    return Payment.get({id: $stateParams.id}).$promise;
                }],
                daysAfterPayment: ['Setting', function (Setting) {
                    return Setting.getDaysAfterPayment().$promise;
                }]
            }
        }).state('payment-detail-client', {
            parent: 'entity',
            url: '/payment-receipt/{paymentKey}',
            data: {
                authorities: [],
                pageTitle: 'valueconnectApp.payment.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/payment/payment-detail.html',
                    controller: 'PaymentDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('payment');
                    $translatePartialLoader.addPart('appraisalOrder');
                    $translatePartialLoader.addPart('enumPaymentMethod');
                    $translatePartialLoader.addPart('enumPaymentStatus');
                    $translatePartialLoader.addPart('enumAppraisalType');
                    $translatePartialLoader.addPart('enumProductType');
                    $translatePartialLoader.addPart('error');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Payment', function($stateParams, Payment) {
                    return Payment.getByPaymentKey({paymentKey : $stateParams.paymentKey}, {}).$promise;
                }]
            }
        })

        .state('payment.client', {
            parent: 'payment',
            url: '/client/{token}',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/payment/payment-client-dialog.html',
                    controller: 'PaymentClientController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('payment');
                    $translatePartialLoader.addPart('paymentProfile');
                    $translatePartialLoader.addPart('enumPaymentMethod');
                    $translatePartialLoader.addPart('enumPaymentStatus');
                    $translatePartialLoader.addPart('enumAppraisalType');
                    $translatePartialLoader.addPart('enumAppraisalState');
                    $translatePartialLoader.addPart('enumProductType');
                    $translatePartialLoader.addPart('error');
                    return $translate.refresh();
                }],
                entity: function () {
                    return {
                        method: null,
                        amount: null,
                        transactionId: null,
                        transactionDate: null,
                        paymentStatus: null,
                        id: null,
                        quoteSplitId: null
                    };
                }
            }
        })
        .state('payment.edit', {
            parent: 'payment',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/payment/payment-dialog.html',
                    controller: 'PaymentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Payment', function(Payment) {
                            return Payment.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('payment', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('payment.delete', {
            parent: 'payment',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/payment/payment-delete-dialog.html',
                    controller: 'PaymentDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Payment', function(Payment) {
                            return Payment.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('payment', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
