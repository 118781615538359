(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('QuoteSplitModalService', QuoteSplitModalService);

    QuoteSplitModalService.$inject = ['$uibModal', '$state'];

    function QuoteSplitModalService ($uibModal, $state) {
        var service = {
            openCreateDialog: openCreateDialog,
            openResendPaymentRequestDialog: openResendPaymentRequestDialog
        };

        var modalInstance = null;

        var resetModal = function () {
            modalInstance = null;
            // $state.go("home");
        };

        return service;

        function openCreateDialog (quote, contact) {
            if (modalInstance !== null) return;

            var originatorQuoteSplit = quote.quoteSplits.find(function(qs) { return qs.role === "ORIGINATOR"; });

            modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/entities/quote-split/quote-split-dialog.html',
                controller: 'QuoteSplitDialogController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('quoteSplit');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            quoteId: quote.id,
                            totalAmount: quote.total,
                            originatorAmount: originatorQuoteSplit ? originatorQuoteSplit.splitAmount : 0.0,
                            clientContact: contact,
                            adjustmentReason: quote.adjustmentReason
                        };
                    }
                }
            });
            modalInstance.result.then(
                resetModal,
                resetModal
            );

            gtag("event", "modal_open", {title: "Quote Split Dialog"});

            return modalInstance;
        }

        function openResendPaymentRequestDialog (quote, contact) {
            if (modalInstance !== null) return;

            var clientQuoteSplit = quote.quoteSplits.find(function(qs) { return qs.role === "CLIENT"; });

            modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/entities/quote-split/resend-payment-request-dialog.html',
                controller: 'ResendPaymentRequestDialogController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('quoteSplit');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            quoteId: quote.id,
                            totalAmount: quote.total,
                            clientAmount: clientQuoteSplit ? clientQuoteSplit.splitAmount : 0.0,
                            clientContact: contact
                        };
                    }
                }
            });
            modalInstance.result.then(
                resetModal,
                resetModal
            );

            return modalInstance;
        }
    }
})();
