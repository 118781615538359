(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('CityDetailController', CityDetailController);

    CityDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'City', 'Region'];

    function CityDetailController($scope, $rootScope, $stateParams, entity, City, Region) {
        var vm = this;

        vm.city = entity;

        var unsubscribe = $rootScope.$on('valueconnectApp:cityUpdate', function(event, result) {
            vm.city = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
