(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .factory('LoginService', LoginService);

    LoginService.$inject = ['$rootScope','$state'];

    function LoginService ($rootScope, $state) {
        var service = {
            open: open
        };

        return service;

        function open () {
            if($rootScope.fromState.name !== 'login') {
                $state.go('login');
            }
        }
    }
})();
