(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('valueconnectApp')
        .constant('VERSION', "0.0.1-SNAPSHOT")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('SENTRY_ENABLED', true)
        .constant('GOOGLE_MAP_API_KEY', "AIzaSyDLYIWSSnbIfzLGT259PFbccfZMdImyOuc")
        .constant('GA4_MEASUREMENT_ID', "G-TSQX1PJVC7")
        .constant('FIREBASE_CONFIG', {
	"apiKey": "AIzaSyABznU_DYoWtj0JNJeDxWnVw0xF9aZN4cs",
	"authDomain": "value-connect-prod.firebaseapp.com",
	"projectId": "value-connect-prod",
	"storageBucket": "value-connect-prod.appspot.com",
	"messagingSenderId": "563349531038",
	"appId": "1:563349531038:web:0104db927688b65ac84e8f"
})
;
})();
