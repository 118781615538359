(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('InvoiceController', InvoiceController);

    InvoiceController.$inject = ['$scope', '$state', 'isAdmin', 'isCCR', 'Invoice', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'AppraisalFirmSelection', 'moment', 'filterDataSources', 'filterOriginators', 'InvoiceUtils'];

    function InvoiceController ($scope, $state, isAdmin, isCCR, Invoice, ParseLinks, AlertService, pagingParams, paginationConstants, appraisalFirmSelection, moment, filterDataSources, filterOriginators, InvoiceUtils) {
        var vm = this;
        vm.invoices = [];
        vm.isAdmin = isAdmin;
        vm.isCCR = isCCR;        
        vm.isAdminOrCCR = isAdmin || isCCR;

        vm.loadFilterData = loadFilterData;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.reset = reset;
        vm.search = search;
        vm.loadAll = loadAll;

        vm.filterBrokerageChange = filterBrokerageChange;
        vm.filterData = {};
        vm.isDataSorted = {};
        vm.invoiceSearch = {};
        vm.getInvoicePdf = getInvoicePdf;
        vm.markInvoicePaid = markInvoicePaid;
        invoiceSearchInit();
        
        function invoiceSearchInit() {
            vm.invoiceSearch.minDate = null;
            vm.invoiceSearch.maxDate = null;
            vm.invoiceSearch.appraisalOrderId = null;
            vm.invoiceSearch.brokerageId = null;
            vm.invoiceSearch.showAll = null;
            vm.invoiceSearch.query = null;
        }
        
        function getInvoicePdf(invoiceId) {
            return InvoiceUtils.getInvoicePdf(invoiceId);
        }
        
        function markInvoicePaid(invoiceId) {
            return Invoice.updateMarkPaid({id:invoiceId}).$promise.then(function(response) {
                if (response) {
                    for (let i=0; i < vm.invoices.length; i++) {
                        if (vm.invoices[i].id == invoiceId) {
                            vm.invoices[i].paidDate = response.paidDate;
                        }    
                    }
                }
            });
        }
        
        fetchRequiredData(['originators', 'lenders', 'appraisers', 'brokerages']);

        function fetchRequiredData(requiredApis) {
            requiredApis.forEach(
                function(requiredApi) {
                    loadFilterData(requiredApi);
                }
            );
        }

        loadAll();

        function loadAll () {
            
            Invoice.getByFilter({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, vm.invoiceSearch, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.invoices = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function reset() {
            // TODO: we could do this without reloading the state
            $state.go($state.current, {}, {reload: true});
        }
        
        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            });
        }

        function search () {
            vm.links = null;
            vm.page = 1;
            vm.reverse = false;
            vm.loadAll();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            invoiceSearchInit();
            vm.transition();
        }
        
        function filterBrokerageChange() {
            var key = 'originators';
            filterOriginators[key](vm.params.brokerageId).$promise.then(function(results) {
                vm.filterData[key] = results;
                sortLoadedData(key);
            });
        }
        
        
        /**
         * Re-load (or initialize) the filter data for the specified key
         * @param  {String} key The property name for the filter
         */
        function loadFilterData(key) {
            if (vm.filterData[key] === undefined) {
                if (angular.isFunction(filterDataSources[key])){
                    filterDataSources[key]().$promise.then(function(results) {
                        vm.filterData[key] = results;
                        sortLoadedData(key);
                    });
                } else {
                    vm.filterData[key] = filterDataSources[key];
                }
            }
        }
                        
        function sortLoadedData(key) {
            //check if the data has not already been sorted
            if (vm.isDataSorted[key] === undefined) {
                //call methods to sort data on the basis of the key
                switch (key) {
                    case 'originators':
                        sortData(vm.filterData[key], ['firstName', 'lastName', 'id']);
                        sanitizeData(vm.filterData[key], ['firstName', 'lastName']);
                        break;
                    // Note: Both 'allLenders' and 'lenders' use the exact same sorting logic.
                    case 'allLenders':
                    case 'lenders':
                        var publicLenders = [];
                        var privateLenders = [];
                        //separate public and private lenders using brokerageName
                        vm.filterData[key].forEach(
                            function (item) {
                                item['brokerageName'] === null ? publicLenders.push(item) : privateLenders.push(item);
                            }
                        );
                        sortData(publicLenders, ['lenderName', 'id']);
                    sortData(privateLenders, ['brokerageName', 'lenderName', 'id']);
                    vm.filterData[key] = publicLenders.concat(privateLenders);
                    //no need to sanitize: lender.lender_name is NOT NULL in database
                    //sanitizeData(vm.filterData[key], ['lenderName'])
                    break;
                case 'appraisers':
                    sortData(vm.filterData[key], ['userFullName', 'userId']);
                    //no need to sanitize: server concatenates firstName with lastName to give us userFullName
                    //sanitizeData(vm.filterData[key], ['userFullName'])
                    break;
                case 'ccrs':
                    sortData(vm.filterData[key], ['userFullName', 'userEmail']);
                    //Note #1: server concatenates firstName with lastName to give us userFullName
                    //Note #2: vc_user.user_email can be NULL in the database
                    sanitizeData(vm.filterData[key], ['userEmail']);
                    break;
                case 'appraisalFirms':
                    sortData(vm.filterData[key], ['name', 'id']);
                    //no need to sanitize: appraisal_firm.name is NOT NULL in database
                    //sanitizeData(vm.filterData[key], ['name']);
                    break;
                case 'brokerages':
                    sortData(vm.filterData[key], ['name', 'id']);
                    //no need to sanitize: brokerage.name is NOT NULL in database
                    //sanitizeData(vm.filterData[key], ['name']);
                    break;
                default:
                    return;
                }

                vm.isDataSorted[key] = true;
            }
        }

        //method to sort an array of objects based on object's properties
        //array should be clean and filtered to not encounter inconsistencies
        function sortData(array, properties) {
            if (array) {
                array.sort(customComparator(properties));
            }
        }

        //method to implement multi level sorting based on properties
        function customComparator(properties) {
            return function(a, b) {
                var value = 0;

                //go through properties amongst which we want to achieve multi level sorting
                //keep looping through array of properties until either of the conditions are not met
                // 1. value === 0 -> means both values are same for a and b objects
                // 2. index < properties.length -> means all properties have been checked
                for(var  index = 0; value === 0 && index < properties.length; index++) {
                    //check if the values are numeric or not
                    value = typeof a[properties[index]] !== 'number' || typeof b[properties[index]] !== 'number' ?
                                customCompareString(properties[index])(a, b):
                                customCompareNumeric(properties[index])(a, b);
                }
                return value;
            };
        }

        //custom comparator to compare based on the string values
        function customCompareString(property) {
            return function(a, b) {
                //map null to empty string for comparison
                var left = ( a[property] ) ? a[property].toLowerCase() : "";
                var right = ( b[property] ) ? b[property].toLowerCase() : "";
                if (left.localeCompare(right) > 0) {
                    return 1;
                } else if (left.localeCompare(right) < 0) {
                    return -1;
                } else {
                    return  0;
                }
            };
        }

        //custom comparator to compare based on the numeric values
        function customCompareNumeric(property) {
            return function (a,b){
                var left = a[property];
                var right = b[property];
                if (left - right > 0) {
                    return 1;
                } else if (left - right < 0) {
                    return -1;
                } else {
                    return 0;
                }
            };
        }

        //explicitly replace actual null with String "NULL" for caller supplied members of the object
        function sanitizeData(arr, keys) {
            if (arr) {
                for (var i = 0; i < arr.length; i++) {
                    var obj = arr[i];
                    if (obj) {
                        for (var j = 0; j < keys.length ; j++) {
                            var key = keys[j];
                            if (obj[key] == null) {
                                obj[key] = "NULL";
                            }
                        }
                    }
                }
            }
        }
    }
})();
