(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('MarketRentController', MarketRentController);

    MarketRentController.$inject = ['$scope', '$translate', '$mdDialog'];

    function MarketRentController($scope, $translate, $mdDialog) {
        var adjustmentFields = ['dataSource','rentRate','location','design','liveableFloorArea'];
        var additionalFields = ['address','city','postCode'];

        // Initialize model
        var vm = this;
        var formData = null;

        vm.addOption = addOption;
        vm.removeOption = removeOption;
        vm.addSubject = addSubject;

        vm.importComparable = importComparable;

        $scope.vm.reportData.standardResidential.$promise.then(function(reportData) {
            vm.reportData = reportData;

            if (!$scope.vm.formData.propertyAddress) {
                $scope.vm.formData.propertyAddress = vm.reportData.subject.address2 ?
                    (vm.reportData.subject.address2 + " - " + vm.reportData.subject.address1)
                        : vm.reportData.subject.address1;
                $scope.vm.setDirty('propertyAddress');
            }
        });

        // Initialize model using form data
        init(null, $scope.vm.formData);
        $scope.$on('formDataReinitialized', init);

        function init(event, newData) {
            formData = newData;

            // Initalize each comparable
            formData.comparableSubjects.forEach(function(subject) {
                initSubject(subject, formData.customOptions.length);
            });

        }

        /**
         * Add a new custom option and mark the name as dirty to force it to persist
         */
        function addOption() {
            var newIndex = formData.customOptions.length;
            formData.customOptions.push({ name: '' });
            $scope.vm.setDirty('customOptions['+newIndex+'].name');

            formData.comparableSubjects.forEach(function(subject) {
                subject.customOptions.push({});
            });
        }

        function removeOption(index) {
            $scope.vm.removeFromArray(formData.customOptions, index).then(function() {
                formData.comparableSubjects.forEach(function(subject) {
                    $scope.vm.removeItem(subject.customOptions, index);
                });
            });
        }

        function addSubject(comp) {
            formData.comparableSubjects.push(initSubject(comp, formData.customOptions.length));
        }

        function importComparable() {
            var templateStr =   '<md-dialog aria-label="List dialog">' +
                                '  <md-toolbar><div class="md-toolbar-tools"><h2>Import Comparable</h2></div></md-toolbar>' +
                                '  <md-dialog-content>' +
                                '    <md-list>' +
                                '      <md-list-item ng-repeat="comp in comparables track by $index" ng-if="comp.address" ng-click="import(comp)">' +
                                '        <div class="md-list-item-text" layout="column">' +
                                '          <p>#{{$index + 1}} - {{comp.address}}</p>' +
                                '        </div>' +
                                '      </md-list-item>' +
                                '      <md-list-item ng-if="!comparables.length">No Comparables</md-list-item>' +
                                '    </md-list>'+
                                '  </md-dialog-content>' +
                                '</md-dialog>';

            $scope.vm.reportData.standardResidential.$promise.then(function(reportData) {
                var comparables = reportData.directComparison.comparableSubjects;

                $mdDialog.show({
                    clickOutsideToClose: true,
                    template: templateStr,
                    locals: { comparables: comparables },
                    controller: ['$scope', 'comparables', '$mdDialog', function($scope, comparables, $mdDialog) {
                        $scope.comparables = comparables;
                        $scope.import = function(comp) {
                            // Remove invalid properties from comparable
                            var temp = comp;
                            for (var key in temp) {
                                if (temp.hasOwnProperty(key) && !adjustmentFields.includes(key) && !additionalFields.includes(key)) {
                                    delete temp[key];
                                }
                            }
                            addSubject(temp);
                            $mdDialog.hide();
                        };
                    }]
                });
            });
        }

        /**
         * Initialize the provided comparable object
         * @param  {Object} subject    The comparable to initalize
         * @param  {Number} numOptions The number of custom options
         * @return {Object}            The initialized comparable
         */
        function initSubject(subject, numOptions) {
            // Make sure arrays are initialized
            if(!subject.customOptions) subject.customOptions = [];

            // Initalize custom options to match the numOptions length
            while(subject.customOptions.length < numOptions) {
                subject.customOptions.push({});
            }

            // Init calculated values
            subject.liveableFloorAreaAdjustment = function() {
                if(!$scope.vm.formData.liveableFloorArea || !subject.liveableFloorArea) return null;
                var adjustment = ($scope.vm.formData.liveableFloorArea - subject.liveableFloorArea) * ($scope.vm.formData.pricePerArea || 0);
                return Math.round(adjustment/100)*100;
            };

            return subject;

            /**
             * Get a list of all adjustment values for the provided subject
             */
            function getAdjustments(subject) {
                return adjustmentFields.map(function(field) {
                    var fieldValue = subject[field + 'Adjustment'];
                    return angular.isFunction(fieldValue) ? fieldValue() : (fieldValue || 0);
                });
            }

            function sum(a,b) {
                return a + b;
            }
        }
    }
})();
