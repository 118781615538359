(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('SavedFormValue', SavedFormValue);

    SavedFormValue.$inject = ['$resource'];

    function SavedFormValue ($resource) {
        var resourceUrl =  'api/saved-form-values/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'queryPersonal': {
                method: 'GET',
                isArray: true,
                url: 'api/saved-form-values/personal'
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) { data = angular.fromJson(data); }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
