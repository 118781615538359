(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('StandardResidentialDirectComparisonController',StandardResidentialDirectComparisonController);

    StandardResidentialDirectComparisonController.$inject = ['$timeout', '$scope', 'moment'];

    function StandardResidentialDirectComparisonController($timeout, $scope, moment) {
        var adjustmentFields = ['dateOfSale','daysOnMarket','location','siteSize','buildingType','design','ageAndCondition','liveableFloorArea','parking','room','basement','bathroom'];

        // Initialize model
        var vm = this;
        var sectionData = null;
        vm.locationMapReminder = false;

        vm.addSubject = addSubject;
        vm.addOption = addOption;

        vm.removeOption = removeOption;
        vm.removeSubject = removeSubject;

        vm.compsLength = compsLength;
        vm.moveComparable = moveComparable;

        // Initialize model using form data
        init(null, $scope.vm.formData);
        $scope.$on('formDataReinitialized', init);

        function init(event, formData) {
            sectionData = formData.directComparison;

            // Ensure calculated values are set
            vm.subjectAge = angular.isFunction(formData.improvements.ageInYears) ?
                formData.improvements.ageInYears() : formData.improvements.ageInYears;


            vm.agAreaTotal = angular.isFunction(formData.improvementsDetailed.agAreaTotal) ?
                formData.improvementsDetailed.agAreaTotal() : formData.improvementsDetailed.agAreaTotal;

            // Migrate custom options to new data format
            // TODO: create a database migration for this
            sectionData.customOptions = sectionData.customOptions.map(function(option, index) {
                if(angular.isString(option)) {
                    $scope.vm.setDirty('directComparison.customOptions['+index+'].name');
                    return { name: option };
                } else {
                    return option;
                }
            });

            // Initialize custom options
            var numOptions;
            if(!sectionData.$started) {
                // Initialize to 12 rows the first time this form is accessed
                numOptions = 2;
                $timeout(function() {
                    while(sectionData.customOptions.length < numOptions) {
                        // Mark the name as dirty for the option to force it to persist
                        sectionData.customOptions.push({ name: '' });
                        $scope.vm.setDirty('directComparison.customOptions['+(sectionData.customOptions.length-1)+'].name');
                    }
                });
            } else {
                // Find the total number of custom options
                // NOTE: since we are forcing custom options to persist when created, we don't really
                // need some of this logic; leaving it here anyway just to make this less brittle
                numOptions = sectionData.comparableSubjects
                    .map(function(subject) { return subject.customOptions.length; })
                    .concat([sectionData.customOptions.length])
                    .reduce(function(a,b) { return Math.max(a,b); }, 0);

                // Initialize the custom options to match the numOptions length
                while(sectionData.customOptions.length < numOptions) {
                    sectionData.customOptions.push({});
                }
            }

            // Initalize each comparable
            sectionData.comparableSubjects.forEach(function(subject) {
                initSubject(subject, numOptions);
            });


        }

        /**
         * Add a new custom option and mark the name as dirty to force it to persist
         */
        function addOption() {
            var newIndex = sectionData.customOptions.length;
            sectionData.customOptions.push({ name: '' });
            $scope.vm.setDirty('directComparison.customOptions['+newIndex+'].name');

            sectionData.comparableSubjects.forEach(function(subject) {
                subject.customOptions.push({});
            });
        }

        function removeOption(index) {
            $scope.vm.removeFromArray(sectionData.customOptions, index).then(function() {
                sectionData.comparableSubjects.forEach(function(subject) {
                    $scope.vm.removeItem(subject.customOptions, index);
                });
            });
        }

        function addSubject() {
            var numOptions = sectionData.customOptions.length;
            sectionData.comparableSubjects.push(initSubject({}, numOptions));
        }

        function removeSubject(index) {
            $scope.vm.removeFromArray($scope.vm.formData.directComparison.comparableSubjects, index);
        }

        function compsLength() {
            return $scope.vm.formData.directComparison.comparableSubjects.filter($scope.vm.isNotDeleted).length;
        }

        function moveComparable(from, to) {
            $timeout(function() {
                $scope.vm.formData.directComparison.comparableSubjects.splice(to, 0, $scope.vm.formData.directComparison.comparableSubjects.splice(from, 1)[0]);
            }, 300)
        };

        /**
         * Initialize the provided comparable object
         * @param  {Object} subject    The comparable to initalize
         * @param  {Number} numOptions The number of custom options
         * @return {Object}            The initialized comparable
         */
        function initSubject(subject, numOptions) {
            // Make sure arrays are initialized
            if(!subject.customOptions) subject.customOptions = [];
            if(!subject.images || subject.images.length === 0) subject.images = [{}];

            // Initalize custom options to match the numOptions length
            while(subject.customOptions.length < numOptions) {
                subject.customOptions.push({});
            }

            // Init calculated values
            subject.liveableFloorAreaAdjustment = function() {
                if(!subject.liveableFloorArea) return null;
                var subjectArea = angular.isDefined($scope.vm.formData.improvementsDetailed)
                    ? ((angular.isFunction($scope.vm.formData.improvementsDetailed.agAreaTotal) ? $scope.vm.formData.improvementsDetailed.agAreaTotal() : $scope.vm.formData.improvementsDetailed.agAreaTotal) || 0) : 0;
                var adjustment = (subjectArea - subject.liveableFloorArea) * (sectionData.pricePerArea || 0);
                return Math.round(adjustment/100)*100;
            };
            subject.dollarAdjustment = function() {
                return getAdjustments(subject).reduce(sum, 0);
            };
            subject.grossAdjustment = function() {
                return subject.salePrice ? getAdjustments(subject).map(Math.abs).reduce(sum, 0)/subject.salePrice : null;
            };
            subject.netAdjustment = function() {
                return subject.salePrice ? subject.dollarAdjustment()/subject.salePrice : null;
            };
            subject.adjustedValue = function() {
                return (subject.salePrice || 0) + subject.dollarAdjustment();
            };

            return subject;

            /**
             * Get a list of all adjustment values for the provided subject
             */
            function getAdjustments(subject) {
                return adjustmentFields.map(function(field) {
                    var fieldValue = subject[field + 'Adjustment'];
                    return angular.isFunction(fieldValue) ? fieldValue() : (fieldValue || 0);
                }).concat(subject.customOptions.map(function(option) {
                    return option.adjustment || 0;
                }));
            }

            function sum(a,b) {
                return a + b;
            }
        }
    }
})();
