/*global geocoder */
(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('GoogleVC', GoogleVC);

    GoogleVC.$inject = ['$rootScope', '$state', '$q'];

    function GoogleVC ($rootScope, $state, $q) {

        var service = {
            lookupAddress: lookupAddress,
            setAddress: setAddress
        };

        return service;

        function lookupAddress(addressEntity, countryCode) {
            var dataPromise = $q.defer();
            geocoder.geocode({'componentRestrictions': { 'postalCode': addressEntity.postCode }, region: countryCode }, function(results, status) {
                if (status === 'OK') {
                    return dataPromise.resolve(results);
                } else {
                    return dataPromise.reject(null);
                }
            });
            return dataPromise.promise;
        }

        function setAddress(addressEntity, googleLookupResults, provinces, cities) {
            if (angular.isDefined(googleLookupResults[0].address_components)) {
                var addressComponents = googleLookupResults[0].address_components;

                // Currently, everywhere this is used; city uses object & province uses id

                // PROVINCE
                if (!addressEntity.provinceId) {
                    var provinceComponent = addressComponents.filter(function(comp) { return (comp.types.indexOf("administrative_area_level_1") !== -1); } )[0];
                    var province  = provinces.filter(function(p) { return (p.abbrev === provinceComponent.short_name); } )[0];

                    if (province) {
                        addressEntity.provinceId = province.id;
                    }
                }

                // CITY
                var cityComponent = null;
                if (!addressEntity.city) {
                    cityComponent = addressComponents.filter(function(comp) { return (comp.types.indexOf("locality") !== -1); } )[0];
                    var city  = cities.filter(function(c) { return (c.name === cityComponent.short_name || c.name === cityComponent.long_name); } )[0];

                    if (city) {
                        addressEntity.city = city;
                    }
                }
                return cityComponent;
            }
            return null;
        }

    }

})();
