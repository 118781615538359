(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .directive('validateAddress', ['Country', validateAddress]);

    function validateAddress (Country) {
        // Store a static map of country ids to abbreviations
        var countryPromise = Country.query().$promise.then(function(countries) {
            return countries.reduce(function(object, country, index) {
                object[country.id] = country.abbrev;
                return object;
            }, {});
        });

        var directive = {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, elm, attrs, ctrl) {
                var countries;
                var postCodeCtrl = elm.find("[postcode-input], [ng-model$='.postCode']").controller('ngModel');
                var countryCtrl = elm.find("[country-input], [ng-model$='.country']").controller('ngModel');

                // Wait for countries to resolve before doing anything
                countryPromise.then(function(result) {
                    countries = result;

                    // Add validators and parsers to postCode
                    postCodeCtrl.$validators.postalCode = validatePostalCode;
                    postCodeCtrl.$validators.zipCode = validateZipCode;
                    postCodeCtrl.$parsers.push(formatPostCode);

                    // Validate and format postCode when country changes
                    if(countryCtrl) {
                        scope.$watch(function() { return countryCtrl.$modelValue; }, function(oldVal, newVal) {
                            postCodeCtrl.$validate();
                            // TODO: trigger formatters
                        });
                    }
                });

                /**
                 * Get the current country selected on the form.
                 * If there is no country selector on the form, assume CA
                 */
                function currentCountry() {
                    if(!countryCtrl) return 'CA';
                    if(!countryCtrl.$modelValue) return null;
                    if(angular.isNumber(countryCtrl.$modelValue)) return countries[countryCtrl.$modelValue];
                    return countryCtrl.$modelValue.abbrev;
                }

                /**
                 * Validate a postal code. Will automatically return true if postCode or country is undefined,
                 * or if country is not set to Canada.
                 */
                function validatePostalCode(postalCode) {
                    var country = currentCountry();
                    return !postalCode || !country || country !== 'CA' ||
                        postalCode.search(/^([A-Za-z]\d[A-Za-z] *\d[A-Za-z]\d)$/) !== -1;
                }

                /**
                 * Validate a zip Code. Will automatically return true if zipCode or country is undefined,
                 * or if country is not set to US.
                 */
                function validateZipCode(zipCode) {
                    var country = currentCountry();
                    return !zipCode || !country || country !== 'US' ||
                        zipCode.search(/^\d{5}(?:[-\s]\d{4})?$/) !== -1;
                }

                function formatPostCode(postCode) {
                    if(!postCode) return postCode;
                    var country = currentCountry();

                    // Convert to uppercase and remove whitespace
                    postCode = postCode.toUpperCase().replace(/\s/g, '');

                    // Set max length
                    var maxLength = country && country === 'CA' ? 6 : 10;
                    postCode = postCode.substr(0, maxLength);

                    if(country) {
                        // Add a space for Canadian postal codes
                        if(country === 'CA' && postCode.length > 3) {
                            postCode = postCode.substr(0, 3) + ' ' + postCode.substr(3);
                        }

                        // Add a hyphen for American postal codes
                        if(country === 'US' && postCode.length > 5 && postCode.slice(-1) !== "-") {
                            postCode = postCode.replace(/-/g, '');
                            postCode = postCode.substr(0, 5) + '-' + postCode.substr(5);
                        }
                    }

                    // Update the view value to match the model
                    postCodeCtrl.$viewValue = postCode;
                    postCodeCtrl.$render();
                    return postCode;
                }
            }
        };

        return directive;
    }
})();
