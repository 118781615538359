angular.module('valueconnectApp').
	directive('collapsible', ['$timeout', function($timeout) {
		
		function link(scope, element, attrs) {
			$timeout( function(){
				$('.expandable').eq(0).find('button').click();
			}, 1000);
		}

		return {
			restrict: 'A',
			link: link
		}
	}]);