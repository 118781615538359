(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .factory('AcceptTerms', AcceptTerms);

    AcceptTerms.$inject = ['$resource'];

    function AcceptTerms($resource) {
        var service = $resource('api/account/accept_terms', {}, {});

        return service;
    }
})();
