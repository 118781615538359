'use strict';

angular.
    module('valueconnectApp').
    component('validationToolbar', {
        templateUrl: 'app/report-builder/validation-toolbar.template.html',
        bindings: {
            navSections: '<',
            currentForm: '<',
            currentSection: '<',
            navigateField: '&'
        },
        controllerAs: 'vm',
        controller: ['$scope', '$log', 'AlertService', function ValidationToolbarController($scope, $log, AlertService) {
            /**
             * Object with all errors on the report, updated by the 'validationsUpdated' event
             * @type {Object}
             */
            var reportErrors = {};

            /**
             * Tracks the index of the currently section of the report builder
             * @type {Integer}
             */
            var navIndex = null;

            /**
             * Tracks the index of the currently focused error in the current section. Is null if no error is currently selected.
             * @type {Integer}
             */
            var errorIndex = null;

            /**
             * An array with one element for each navigation section (in the same order).
             * Each element is a list of errors for that section
             * @type {Array}
             */
            var sectionErrors = [];

            /**
             * Boolean flag to detect if currentForm or currentSection was updated as a
             * result of a navigation operation initiated by this component.
             * @type {Boolean}
             */
            var navigatingSection = false;

            // Bind properties
            var vm = this;
            vm.initialized = false;
            vm.valid = null;
            vm.numErrors = null;

            // Bind Methods
            vm.navPrev = navPrev;
            vm.navNext = navNext;

            /**
             * Initialize the component
             */
            vm.$onInit = function() {
                $scope.$on("validationsUpdated", updateValidationErrors);
                $scope.$on("formDataReinitialized", formDataReinitialized);
            };

            /**
             * Watch bound properties and respond to changes
             * @param  {Object} changedBindings Object that describes the proprty changes
             */
            vm.$onChanges = function(changedBindings) {
                var changedProperties = Object.keys(changedBindings);

                // Watch currentForm and currentSection for changes
                if(changedProperties.includes('currentForm') || changedProperties.includes('currentSection')) {
                    if(navigatingSection) {
                        // If the current form/section was updated by the validation toolbar we don't need to update anything
                        navigatingSection = false;
                    } else {
                        // Otherwise, navigation performced by something else, so update the navIndex to match and reset errorIndex
                        navIndex = vm.navSections.findIndex(function(section) {
                            return section.form === vm.currentForm && section.section === vm.currentSection;
                        });
                        errorIndex = null;
                    }
                }
            };

            /**
             * Reinitialize internal variables when the validation errors on the form are updated
             */
            function updateValidationErrors(event, errors, sectionData, reportData) {
                // Get a list of all field with errors
                var errorFields = Object.keys(errors).filter(function(key) { return !key.startsWith('$'); });
                vm.numErrors = errorFields.length;
                vm.valid = vm.numErrors === 0;

                // Group errors by navigation section
                sectionErrors = vm.navSections.map(function(navSection, index) {
                    var sectionPrefix = [navSection.form, navSection.section].filter(Boolean).join('.');
                    return errorFields.filter(function(fieldKey) {
                        return fieldKey.startsWith(sectionPrefix);
                    });
                });

                reportErrors = errors;
                vm.initialized = true;
            }

            /**
             * Called when the form data is saved without navigating to a new section. Reset the error index.
             * TODO: We could be more intelligent about this and attempt to maintain position in the error list
             */
            function formDataReinitialized(event, formData) {
                errorIndex = null;
            }

            /**
             * Navigate to the next error, switching sections if necessary. If this current errorIndex is null,
             * navigate to the first error in the current section.
             */
            function navNext($event) {

                // If the next error is in the current section, increment errorIndex and navigate to the new field
                if(sectionErrors[navIndex].length && (errorIndex === null || errorIndex+1 < sectionErrors[navIndex].length)) {
                    errorIndex = errorIndex === null ? 0 : errorIndex + 1;
                    return navField(sectionErrors[navIndex][errorIndex]);
                }

                // Otherwise, switch to the next section and recurse
                else {
                    navigatingSection = true;
                    errorIndex = null;
                    navIndex = (navIndex + 1) % vm.navSections.length;
                    return navNext();
                }
            }

            /**
             * Navigate to the previous error, switching sections if necessary. If this current errorIndex is null,
             * navigate to the last error in the previous section.
             */
            function navPrev($event) {

                // If the prev error is in the current section, decrement errorIndex and navigate to the new field
                if(sectionErrors[navIndex].length && (errorIndex > 0 || errorIndex === -1)) {
                    errorIndex = errorIndex === -1 ? sectionErrors[navIndex].length-1 : errorIndex - 1;
                    return navField(sectionErrors[navIndex][errorIndex]);
                }

                // Otherwise, switch to the prev section and recurse
                else {
                    navigatingSection = true;
                    errorIndex = -1;
                    navIndex = (navIndex - 1 + vm.navSections.length) % vm.navSections.length;
                    return navPrev(true);
                }
            }

            /**
             * Navigate to the specified field using the bound 'navigateField' callback method.
             * @param  {String} fieldKey The field to navigate to
             */
            function navField(fieldKey) {
                $log.info('navigating to error on field: ' + fieldKey + ' (sectionErrors['+navIndex+']['+errorIndex+'])');
                vm.navigateField({fieldKey: fieldKey});
                var navSection = vm.navSections[navIndex];

                // If we are navigating to a section, display an alert for each error on the section
                if (fieldKey === [navSection.form,navSection.section].filter(Boolean).join('.')) {
                    reportErrors[fieldKey].errors.forEach(function(error) {
                        AlertService.error(['valueconnectApp.form.' + fieldKey, 'error.validation.component.' + error.errorType]);
                    });
                }
            }
        }]
    });
