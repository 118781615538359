(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(recaptchaConfig);

    recaptchaConfig.$inject = ['vcRecaptchaServiceProvider', 'ProfileServiceProvider'];

    function recaptchaConfig(vcRecaptchaServiceProvider, ProfileServiceProvider) {
        ProfileServiceProvider.$get().getProfileInfo().then(function(profile) {
            vcRecaptchaServiceProvider.setSiteKey(profile.recaptchaSiteKey);
            // vcRecaptchaServiceProvider.setTheme('---- light or dark ----');
            // vcRecaptchaServiceProvider.setStoken('--- YOUR GENERATED SECURE TOKEN ---');
            // vcRecaptchaServiceProvider.setSize('---- compact or normal ----');
            // vcRecaptchaServiceProvider.setType('---- audio or image ----');
        });

    }
})();
