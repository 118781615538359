(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('LenderDialogController', LenderDialogController);

    LenderDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$mdDialog', 'operation', 'Principal', 'entity', 'Lender', 'Province', 'Country', 'Brokerage'];

    function LenderDialogController ($timeout, $scope, $stateParams, $mdDialog, operation, Principal, entity, Lender, Province, Country, Brokerage) {
        var vm = this;

        vm.lender = entity;
        vm.operation = operation;
        vm.clear = clear;
        vm.save = save;
        vm.loadBrokerages = loadBrokerages;
        vm.provinces = Province.query({size:2000});
        vm.countries = Country.query({size:2000});
        vm.matchBrokerageName = matchBrokerageName;
        vm.matchBrokerageNameFromID = matchBrokerageNameFromID;
        Principal.identity().then(function(id){
            vm.isBrokerage = Principal.hasAnyAuthority([/^ROLE_(ORIGINATOR).*$/]);
        });


        vm.loadBrokerages();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function matchBrokerageName(searchText) {
        	var searchRegex = new RegExp(searchText, 'i');
        	var filterVal;
        	if(vm.brokerages.length > 0) {
                 	filterVal = vm.brokerages.filter(function(brokerage){
        			return searchRegex.test(brokerage.name);
                });
                return filterVal.map(function(brokerage){
                	return brokerage.id;
                });
            }
        	return [];
        }

        function matchBrokerageNameFromID(brokerageID) {
            if (brokerageID != null && vm.brokerages.length > 0) {
                 var disabledText = '';
                 var brokerageName = vm.brokerages.find(function(brokerage) {
                    if(brokerage.id == brokerageID) {
                        brokerage.disabled == 1 ? disabledText = "(disabled)" : null;
                        return true;
                    }
                    return false;
                 }).name;
                //concatenate brokerage id and broker name for usability
                return "" + brokerageName + " (id: " + brokerageID + ") " + disabledText;
            }
        }

        function clear () {
            $mdDialog.hide('cancel');
        }

        function loadBrokerages() {
            return vm.brokerages || Brokerage.getDropDownBrokerages({size:2000}, function(result) {
                vm.brokerages = result.sort(customCompareByBrokerageName);
            });
        };

        // custom comparison function to sort first by brokerageName ascending, then by id ascending
        function customCompareByBrokerageName(a, b) {
            var left = a.name.toLowerCase();
            var right = b.name.toLowerCase();
            var value = left.localeCompare(right);
            if (value > 0) {
                return 1;
            } else if (value < 0) {
                return -1;
            } else {
                return customCompareByLenderId(a, b);
            }
        }

        //custom comparison function to sort by lenderId ascending
        function customCompareByLenderId(a, b) {
            if (a.id < b.id) {
                return -1;
            } else if (a.id > b.id) {
                return 1;
            } else {
                return 0;
            }
        }

        function save () {
            vm.isSaving = true;
            if (vm.lender.id !== null) {
                Lender.update(vm.lender, onSaveSuccess, onSaveError);
            } else {
                Lender.save(vm.lender, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('valueconnectApp:lenderUpdate', result);
            $mdDialog.hide(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
