(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('BrokerageDetailController', BrokerageDetailController);

    BrokerageDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Brokerage', 'NationalBrokerage', 'BrokerageLocation'];

    function BrokerageDetailController($scope, $rootScope, $stateParams, entity, Brokerage, NationalBrokerage, BrokerageLocation) {
        var vm = this;

        vm.brokerage = entity;

        var unsubscribe = $rootScope.$on('valueconnectApp:brokerageUpdate', function(event, result) {
            vm.brokerage = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
