(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('BrokerageLocationDialogController', BrokerageLocationDialogController);

    BrokerageLocationDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'BrokerageLocation', 'BrokerageRegion', 'Region', 'Principal'];

    function BrokerageLocationDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, BrokerageLocation, BrokerageRegion, Region, Principal) {
        var vm = this;

        vm.brokerageLocation = entity;
        vm.clear = clear;
        vm.save = save;
        vm.brokerageregions = BrokerageRegion.query({ size: 2000 });
        vm.regions = Region.query({ size: 2000, sort: 'name' });
        vm.formatBrokerageRegion = function(brokerageRegion) {
            return (vm.isAdmin ? brokerageRegion.brokerageName + "(" + brokerageRegion.brokerageId + "): " : "") + brokerageRegion.name;
        }

        Principal.matchAnyAuthority(/^ROLE_(ADMIN).*/i).then(function(result) {
            vm.isAdmin = result;
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            vm.brokerageLocation.officeAddress = vm.brokerageLocation.officeAddress || { };
            vm.brokerageLocation.officeAddress.city = vm.brokerageLocation.officeAddress.cityName || { };
            if (vm.brokerageLocation.id !== null) {
                BrokerageLocation.update(vm.brokerageLocation, onSaveSuccess, onSaveError);
            } else {
                BrokerageLocation.save(vm.brokerageLocation, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('valueconnectApp:brokerageLocationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
