(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraisalOrderChangeStateController', AppraisalOrderChangeStateController);

    AppraisalOrderChangeStateController.$inject = ['$scope', '$mdDialog', 'appraisalOrder', 'AppraisalState', 'AppraisalOrder', 'AlertService'];

    function AppraisalOrderChangeStateController($scope, $mdDialog, appraisalOrder, AppraisalState, AppraisalOrder, AlertService) {
        var vm = this;
        
        vm.currentState = appraisalOrder.state;
        vm.appraisalOrder = appraisalOrder;
        vm.AllAppraisalStates = AppraisalState.all;
        
        vm.save = save;
        vm.cancel = cancel;
        
        function save() {
            vm.isSaving = true;
            
            AppraisalOrder.updateState({id: vm.appraisalOrder.id, newState: vm.newStatus}, {});
            
            $mdDialog.hide();
            vm.isSaving = false;
        }
        
        function cancel() {
            $mdDialog.cancel();
        }
    }
})();