(function() {
    'use strict';

    angular.
        module('valueconnectApp.auditEntry', [])
        .component('auditEntry', {
            templateUrl: 'app/components/audit/audit-entry.template.html',
            bindings: {
                audit: '<',
                hideHeadings: '<'
            },
            controllerAs: 'vm',
            controller: ['$log', '$scope', '$state', '$sce',
            function auditEntry($log, $scope, $state, $sce) {
                var vm = this;
                vm.printLogData = printLogData;
                vm.showLogDetails = showLogDetails;
                //Used for toggling email log entries details
                vm.logToggle = [];
                vm.detailedLogTypes = ['EMAIL', 'BOUNCE_EMAIL_NOTIFY'];

                if (!vm.audit.auditEventType && vm.audit.type) {
                    vm.audit.auditEventType = vm.audit.type;
                }

                function printLogData(log, key, data) {
                    if (log.auditEventType === "EMAIL" && key === "content") {
                        return $sce.trustAsHtml(data);
                    } else {
                        return data;
                    }

                }

                function showLogDetails(log) {
                    vm.logToggle[log.id] = !(vm.logToggle[log.id] | false);
                }

            }]
        });
})();
