(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('VCUserDialogController', VCUserDialogController);

    VCUserDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'VCUser', 'User'];

    function VCUserDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, VCUser, User) {
        var vm = this;

        vm.vCUser = entity;
        vm.clear = clear;
        vm.save = save;
        vm.usersLoaded = false;
        vm.users = [];
        vm.isUserAccountRep = isUserAccountRep;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        User.queryManaged().$promise.then(function(data){
            vm.usersLoaded = true;
            vm.users = data;
        })

        function selectedUser() {
            if(!vm.usersLoaded || vm.vCUser.userId === null) {
                return null;
            }

            return vm.users.find(x => x.id === vm.vCUser.userId);
        }

        function isUserAccountRep() {
            var user = selectedUser();

            return user !== null && user.authorities !== null && user.authorities.includes('ROLE_CUSTOMER_CARE_REPRESENTATIVE')
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function save () {
            vm.isSaving = true;
            if (vm.vCUser.id !== null) {
                VCUser.update(vm.vCUser, onSaveSuccess, onSaveError);
            } else {
                VCUser.save(vm.vCUser, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('valueconnectApp:vCUserUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
