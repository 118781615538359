(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('TaxCodeDetailController', TaxCodeDetailController);

    TaxCodeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'TaxCode', 'Country', 'Province'];

    function TaxCodeDetailController($scope, $rootScope, $stateParams, entity, TaxCode, Country, Province) {
        var vm = this;

        vm.taxCode = entity;

        var unsubscribe = $rootScope.$on('valueconnectApp:taxCodeUpdate', function(event, result) {
            vm.taxCode = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
