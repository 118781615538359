(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraisalOrderContactController', AppraisalOrderContactController);

    AppraisalOrderContactController.$inject = ['$scope', '$state', 'AppraisalOrderContact', 'AppraisalOrderContactSearch'];

    function AppraisalOrderContactController ($scope, $state, AppraisalOrderContact, AppraisalOrderContactSearch) {
        var vm = this;
        
        vm.appraisalOrderContacts = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            AppraisalOrderContact.query(function(result) {
                vm.appraisalOrderContacts = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            AppraisalOrderContactSearch.query({query: vm.searchQuery}, function(result) {
                vm.appraisalOrderContacts = result;
            });
        }    }
})();
