(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraisalFirmDialogController', AppraisalFirmDialogController);

    AppraisalFirmDialogController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'entity', 'AppraisalFirm', 'AppraisalFirmRegion', 'AppraisalFirmLocation', 'AppraiserUser'];

    function AppraisalFirmDialogController ($timeout, $scope, $state, $stateParams, entity, AppraisalFirm, AppraisalFirmRegion, AppraisalFirmLocation, AppraiserUser) {
        var vm = this;

        vm.appraisalFirm = entity;
        vm.isNew = entity.id == null
        vm.save = save;
        vm.appraisalfirmregions = AppraisalFirmRegion.query();
        vm.appraisalfirmlocations = AppraisalFirmLocation.query();
        vm.firmAppraisers = AppraiserUser.getAvailableAssistants({appraisalFirmId: entity.id});

        vm.headOfficeDisplayLine1 = 'Office name: ' + vm.appraisalFirm.headOfficeOfficeName;
        vm.headOfficeDisplayLine2 = 'Office address: ' + vm.appraisalFirm.headOfficeAddress.displayStringLong;
        vm.headOfficeDisplayLine3 = 'Appraisal Firm Location ID: ' + vm.appraisalFirm.headOfficeId;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function save() {
            vm.isSaving = true;
            if (vm.appraisalFirm.independent) {
                vm.appraisalFirm.assistantId = null;
            }
            if (vm.appraisalFirm.id !== null) {
                AppraisalFirm.update(vm.appraisalFirm, onSaveSuccess, onSaveError);
            } else {
                AppraisalFirm.save(vm.appraisalFirm, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $scope.$emit('valueconnectApp:appraisalFirmUpdate', result);

            if(vm.isNew) {
                $state.go('appraisal-firm', null, { reload: true });
            }
            else {
                $state.go('.', {id: result.id}, {reload: true})
            }
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
