(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('appraisal-note-messageboard', {
            parent: 'entity',
            url: '/appraisal-note-messageboard/{id}',
            data: {
                authorities: [/^ROLE_(ADMIN|VC|CUSTOMER_CARE|APPRAISER|APPRAISAL).*/],
                pageTitle: 'valueconnectApp.appraisalNote.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/appraisal-note/appraisal-note-messageboard.html',
                    controller: 'AppraisalNoteMessageboardController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('appraisalNote');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                order: ['AppraisalOrder', '$stateParams', function(AppraisalOrder, $stateParams) {
                    return AppraisalOrder.getMinimal({id: $stateParams.id}).$promise; // <--- new code
                }],
                messages: ['AppraisalNote', 'order', function(AppraisalNote, order) {
                    return AppraisalNote.getMessagesForAppraisal({id : order.id}).$promise;
                }],
                notes: ['AppraisalNote', 'order', function(AppraisalNote, order) {
                    return AppraisalNote.getNotesForAppraisal({id : order.id}).$promise;
                }],
                account: ['Principal', function(Principal) {
                    return Principal.identity();
                }],
                report: ['AppraisalReport', 'order', function(AppraisalReport, order) {
                    return AppraisalReport.getMinimal({id: order.activeReport.id}).$promise; // <--- new code
                }],
                reportEditable: ['order', 'report', 'account', 'Principal', function(order, report, account, Principal) {
                    if (Principal.hasAnyAuthority(['ROLE_CUSTOMER_CARE_REPRESENTATIVE'])) {
                        return true;
                    }
                    var editStates = ['PENDING_VISIT','PENDING_SCHEDULE_VISIT','PENDING_COSIGNER_ASSIGNMENT','PENDING_COSIGN','PENDING_SUBMISSION','RESUBMISSION_REQUIRED'];
                    return editStates.indexOf(order.state) !== -1 &&            // Make sure order is in the correct state
                        order.activeReport.id === report.id &&                  // Make sure this is the current active report
                        account.appraiserUser &&                                // Make sure the current account has appraiser information
                        order.assignedAppraiserId === account.appraiserUser.id; // Make sure this is the assigned appraiser
                }],
                // Determines if the user can access the report submit page
                reportSubmittable: ['order', 'report', 'account', 'Principal', function(order, report, account, Principal) {
                    if (Principal.hasAnyAuthority(['ROLE_CUSTOMER_CARE_REPRESENTATIVE'])) {
                        return false;
                    } else if(account.appraiserUser && report.cosignerId === account.appraiserUser.id) {
                        return ['PENDING_COSIGN','PENDING_SUBMISSION','RESUBMISSION_REQUIRED'].indexOf(order.state) !== -1;
                    } else if(['PENDING_SCHEDULE_VISIT','PENDING_VISIT','PENDING_COSIGNER_ASSIGNMENT','PENDING_COSIGN','PENDING_SUBMISSION','RESUBMISSION_REQUIRED'].indexOf(order.state) !== -1) {
                        return Principal.matchAnyAuthority(/^ROLE_(APPRAISER|APPRAISAL).*/);
                    } else if(order.state === 'REPORT_SUBMITTED') {
                        return Principal.matchAnyAuthority(/^ROLE_(ADMIN|VC_REVIEWER).*/);
                    } else if (order.state === 'REPORT_COMPLETE') {
                        return Principal.matchAnyAuthority(/^ROLE_(ORIGINATOR).*/);
                    } else {
                        return false;
                    }
                }]
            }
        });
    }
})();
