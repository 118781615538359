(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('BrokerageMergeDialogController', BrokerageMergeDialogController);

    BrokerageMergeDialogController.$inject = ['$scope', '$state', '$mdDialog', 'brokeragesToMerge', 'brokerageToMergeInto'];

    function BrokerageMergeDialogController($scope, $state, $mdDialog, brokeragesToMerge, brokerageToMergeInto) {
        var vm = this;

        vm.brokeragesToMerge = Array
            .from(brokeragesToMerge, function (brokerageMapping) {
                return brokerageMapping[1];
            });
        vm.brokerageToMergeInto = brokerageToMergeInto;

        vm.save = save;
        vm.cancel = cancel;

        function save() {
            $mdDialog.hide();
        }

        function cancel() {
            $mdDialog.cancel();
        }
    }

})();
