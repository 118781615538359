(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .factory('ProfileService', ProfileService);

    ProfileService.$inject = ['$q'];

    function ProfileService($q) {

        var dataPromise;

        var service = {
            getProfileInfo : getProfileInfo
        };

        return service;

        function getProfileInfo() {
            if (angular.isUndefined(dataPromise)) {
                dataPromise = httpRequest().then(function(result) {
                    if (result.activeProfiles) {
                        return {
                            activeProfiles: result.activeProfiles,
                            ribbonEnv: result.ribbonEnv,
                            revision: result.revision,
                            paypalActionUrl: result.paypalActionUrl,
                            paypalProcessIpnUrl: result.paypalProcessIpnUrl,
                            paypalBusinessEmail: result.paypalBusinessEmail,
                            paypalReturnUrl: result.paypalReturnUrl,
                            paypalCancelUrl: result.paypalCancelUrl,
                            googleApiKey: result.googleApiKey,
                            recaptchaSiteKey: result.recaptchaSiteKey,
                            inProduction: result.activeProfiles.indexOf("prod") !== -1,
                            swaggerDisabled: result.activeProfiles.indexOf("no-swagger") !== -1,
                            enableBambora: result.enableBambora
                        };
                    }
                });
            }
            return dataPromise;
        }

        /**
         * Send an HTTP GET request for the profile info. Does not use the $http service so that
         * this service can be used in config blocks
         * @return {Promise} A promise that is resolved with a JSON object parsed from a success
         *                   response, or rejected with the failed XHR object.
         */
        function httpRequest() {
            return $q(function(resolve, reject) {
                var xhr = new XMLHttpRequest();
                xhr.open('GET', 'api/profile-info');
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.onload = function() {
                    if (xhr.status === 200) {
                        resolve(angular.fromJson(xhr.responseText));
                    } else {
                        reject(xhr);
                    }
                };
                xhr.send();
            });
        }
    }
})();
