(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('StandaloneMarketRentGeneralImprovementsController',StandaloneMarketRentGeneralImprovementsController);

    StandaloneMarketRentGeneralImprovementsController.$inject = ['$log', '$scope', '$filter', '$translate', 'moment'];

    function StandaloneMarketRentGeneralImprovementsController($log, $scope, $filter, $translate, moment) {

        // Initialize model
        var vm = this;
        vm.capitalize = capitalize;
        var sectionData = null;

        // Initialize model using form data
        init(null, $scope.vm.formData);
        $scope.$on('formDataReinitialized', init);

        function init(event, formData) {
            sectionData = formData.improvements;
        }

        /**
         * @example
         * capitalize('javaSCrIPT');        // -> 'JavaSCrIPT
         * capitalize('javaSCrIPT', true);  // -> 'Javascript
         * @see Original post on {@link http://stackoverflow.com/questions/2332811/capitalize-words-in-string|Stack Overflow}
         */
        function capitalize(string, lower) {
            if(angular.isUndefined(lower)) lower = false;
            return (lower ? string.toLowerCase() : string).replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
        }
    }
})();
