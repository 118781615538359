(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('QuoteSplitDetailController', QuoteSplitDetailController);

    QuoteSplitDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'QuoteSplit', 'Quote'];

    function QuoteSplitDetailController($scope, $rootScope, $stateParams, entity, QuoteSplit, Quote) {
        var vm = this;

        vm.quoteSplit = entity;

        var unsubscribe = $rootScope.$on('valueconnectApp:quoteSplitUpdate', function(event, result) {
            vm.quoteSplit = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
