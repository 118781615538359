(function () {
    'use strict';
    angular
        .module('valueconnectApp')
        .controller('AppraisalOrderRestoreDialogController', AppraisalOrderRestoreDialogController);

    AppraisalOrderRestoreDialogController.$inject = ['$scope', '$state', '$mdDialog', 'appraisalOrder', 'AppraisalOrder'];

    function AppraisalOrderRestoreDialogController($scope, $state, $mdDialog, appraisalOrder, AppraisalOrder) {
        var vm = this;
        vm.appraisalOrder = appraisalOrder;

        vm.save = save;
        vm.cancel = cancel;

        function save() {
            vm.isSaving = true;
            AppraisalOrder.restoreCancelledOrder({id: vm.appraisalOrder.id}, {}, function (data) {
                $mdDialog.hide();
                vm.isSaving = false;
            });
        }

        function cancel() {
            $mdDialog.cancel();
        }
    }
})();
