'use strict';

angular.
    module('valueConnectPendingConcerns', []).
    component('valueConnectPendingConcerns', {
        templateUrl: 'app/components/pendingmessages/concerns/pending-concerns.template.html',
        controller: ['$scope', '$state', 'Concern', '$uibModal',
        function ValueConnectPendingConcernsController($scope, $state, Concern, $uibModal) {
            $scope.isOpen = false;
            $scope.collapsible = false;

            Concern.getInformationRequests().$promise.then(function(concerns) {
                $scope.pendingConcerns = concerns;
                $scope.collapsible = ($scope.collapsible || concerns.length > 0);
                $scope.isOpen = $scope.collapsible ? $scope.isOpen : false;
            });

            $scope.toggleView = function() {
                $scope.isOpen = !$scope.isOpen;
            };

            $scope.reply = function(id, name, email) {
                $uibModal.open({
                    templateUrl: 'app/entities/concern/concern-dialog-reply.html',
                    controller: 'ConcernDialogReplyController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                concern :null,
                                id: id,
                                name: name,
                                email: email
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('home', null, {reload: true});
                }, function() {
                    $state.go('home', {}, {reload: true});
                });
            };

        }]
    });
