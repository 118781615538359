(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('Invoice', Invoice);

    Invoice.$inject = ['$resource'];

    function Invoice ($resource) {
        var resourceUrl =  'api/invoices/:id';

        return $resource(resourceUrl, {}, {
            'save' : { method: 'POST', url: 'api/invoices', isArray: true},
            'getInvoiceableOrdersByFilter': {method: 'GET', url: 'api/invoices/invoiceable-orders-by-filter'},
            'query': { method: 'GET', url: 'api/invoices', isArray: true},
            'getByFilter': {method: 'POST', url: 'api/invoices/by-filter', isArray: true},
            'generatePdf': { method:'GET', url: 'api/invoices/generate-pdf/:id',
                responseType:'arraybuffer',
                transformResponse: function(data, headersGetter) { return { data : data, headers: headersGetter() }; }
            },
            'updateMarkPaid': {
                method: 'GET',
                url: 'api/invoices/:id/mark-paid'
            },
        });
    }
})();
