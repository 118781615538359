(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('MediaController', MediaController);

    MediaController.$inject = ['$scope', '$timeout'];

    function MediaController($scope, $timeout) {
        var vm = this;

        vm.selectedMediaTab = 0;

        vm.addMediaTab = addMediaTab;
        vm.removeMediaTab = removeMediaTab;
        vm.getNonDeletedLength = getNonDeletedLength;
        vm.updateTab = updateTab;

        function addMediaTab() {
            $scope.vm.formData.media.images.push({});
            $timeout(function() { vm.selectedMediaTab = $scope.vm.formData.media.images.length - 1; }, 0);
        }

        function removeMediaTab(index) {
            $scope.vm.removeFromTabs($scope.vm.formData.media.images, index).then(function() {
                var temp = 0;
                for (var i = 0; i < $scope.vm.formData.media.images.length; i++) {
                    if ($scope.vm.isDeleted($scope.vm.formData.media.images[i])) {
                        temp++;
                    } else {
                        break
                    }
                }
                vm.selectedMediaTab = temp;
            });
        }

        function getNonDeletedLength() {
            var count = 0;
            for (var i = 0; i < $scope.vm.formData.media.images.length; i ++) {
                if ($scope.vm.isNotDeleted($scope.vm.formData.media.images[i]))
                    count++;
            }
            return count;
        }

        function updateTab(index) {
            vm.selectedMediaTab = index;
        }

    }
})();
