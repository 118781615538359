'use strict';

// Register `heroReference` component, along with its associated controller and template
angular.
    module('valueConnectPendingRegistrations', []).
    component('valueConnectPendingRegistrations', {
        templateUrl: 'app/components/pendingregistrations/pendingregistrations.template.html',
        controller: ['$scope', '$timeout', 'AlertService', 'ParseLinks', 'Brokerage', 'Principal', 'PaginationUtil', 'moment',
        function ValueConnectPendingRegistrationsController($scope, $timeout, AlertService, ParseLinks, Brokerage, Principal, PaginationUtil, moment) {
            var vm = this;

            $scope.isOpen = false;
            $scope.collapsible = false;
            $scope.sortType = angular.isDefined($scope.sortField) ? $scope.sortField : 'id';
            $scope.sortReverse = angular.isDefined($scope.sortReverse) ? $scope.sortReverse : true;
            $scope.heading = angular.isDefined(vm.heading) ? vm.heading : '';

            vm.loadPage = loadPage;
            vm.transition = transition;
            vm.clear = clear;
            vm.loadAll = loadAll;

            $scope.totalItems = 0;
            $scope.page = 1;
            $scope.itemsPerPage = angular.isDefined(vm.itemsPerPage) ? vm.itemsPerPage : 10;
            $scope.transition = vm.transition;
            $scope.sortParams = {
                reverse: $scope.sortReverse,
                predicate: $scope.sortType
            };

            loadAll();

            function loadAll () {
                loadPage(1);
            }

            $scope.toggleView = function() {
                $scope.isOpen = !$scope.isOpen;
            };

            $scope.getDays = function (dt) {
                var one_day= 24*60*60*1000;
                var currentTime = new Date();
                var date1_ms = currentTime.getTime();
                var date2_ms = Date.parse(dt);
                var difference = date1_ms - date2_ms;
                return Math.round(difference/one_day);
            };

            function sort() {
                var result = [$scope.sortType + ',' + ($scope.sortReverse ? 'asc' : 'desc')];
                return result;
            }

            function onSuccess(data, headers) {
                $scope.links = ParseLinks.parseCommas(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
                $scope.idleaccounts = data;
                if ($scope.idleaccounts.length > 0) {
                    $scope.collapsible = true;
                }
            }

            function onError(error) {
                if (error && error.data) {
                    AlertService.error(error.data.message);
                }
            }

            function loadPage (page) {
                $scope.page = page;
                vm.transition();
            }

            function transition () {
                $scope.sortParams.reverse = $scope.sortReverse;
                $scope.sortParams.predicate = $scope.sortType;

                $scope.pendingregistrations = Brokerage.pendingRegistrations({
                    page: $scope.page - 1,
                    size: $scope.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }

            function clear () {
                vm.links = null;
                vm.page = 1;
                vm.reverse = true;
                vm.transition();
            }

        }],
        bindings: {
            searchPhrase: '=?',
            sortField: '=?',
            sortReverse: '=?',
            itemsPerPage: '=?',
            heading: '<?'
        }
    });
