(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('SavedFormValueDeleteController',SavedFormValueDeleteController);

    SavedFormValueDeleteController.$inject = ['$mdDialog', 'entity', 'SavedFormValue'];

    function SavedFormValueDeleteController($mdDialog, entity, SavedFormValue) {
        var vm = this;

        vm.savedFormValue = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $mdDialog.cancel('cancel');
        }

        function confirmDelete (id) {
            SavedFormValue.delete({id: id},
                function () {
                    $mdDialog.hide(true);
                });
        }
    }
})();
