(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('Refund', Refund);

    Refund.$inject = ['$resource'];

    function Refund ($resource) {
        var resourceUrl =  'api/refunds/:id';

        return $resource(resourceUrl, {}, {
            'refundPayment': { method: 'POST', url:'api/refund-payment' }
        });
    }
})();
