(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraiserUserDetailController', AppraiserUserDetailController);

    AppraiserUserDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'AppraiserUser', 'Region', 'User', 'VCUser'];

    function AppraiserUserDetailController($scope, $rootScope, $stateParams, entity, AppraiserUser, Region, User, VCUser) {
        var vm = this;

        vm.appraiserUser = entity;

        var unsubscribe = $rootScope.$on('valueconnectApp:appraiserUserUpdate', function(event, result) {
            vm.appraiserUser = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
