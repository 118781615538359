(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];    

    var getPagingParams = [
        '$log', '$state', '$stateParams', 'PaginationUtil', 'paginationConstants',
        function ($log, $state, $stateParams, PaginationUtil, paginationConstants) {
            // Get sort from query parameter, and fall back to a default
            var sortString = $stateParams.sort || $state.next.data.sort;
            if(!sortString) {
                $log.error("Report state must define a default sort string", $state.next);
                return undefined;
            }
            return {
                page: PaginationUtil.parsePage($stateParams.page || 1),
                itemsPerPage: paginationConstants.itemsPerPage,
                sort: sortString,
                predicate: PaginationUtil.parsePredicate(sortString),
                ascending: PaginationUtil.parseAscending(sortString),
                search: $stateParams.search
            };
        }
    ];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('invoice', {
            parent: 'entity',
            url: '/invoice?page&sort',
            data: {
                authorities: [/^ROLE_(CUSTOMER_CARE|ADMIN).*/gi],
                pageTitle: 'valueconnectApp.invoice.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/invoice/invoices.html',
                    controller: 'InvoiceController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                }
            },
            resolve: {
                isAdmin: ['Principal', 'authorize', function(Principal, authorize) {
                    return Principal.matchAnyAuthority(/^ROLE_(ADMIN|VC_DATA_ANALYST|VC_FINANCIAL_TEAM)$/);
                }],
                isCCR: ['Principal', 'authorize', function(Principal, authorize) {
                    return Principal.hasAnyRole(['CUSTOMER_CARE_REPRESENTATIVE']);
                }],
                pagingParams: getPagingParams,
                params: ['AppraisalState', 'moment', function(AppraisalState, moment) {
                    return {
                        minDate: moment().startOf('month').toDate(),
                        maxDate: moment().endOf('month').toDate(),
                        states: AppraisalState.all
                    };
                }],
                filterDataSources: ['AppraisalState', 'Lender', 'User', 'AppraiserUser', 'VCUser', 'Brokerage', 'AppraisalFirm', 'isAdmin', 'isCCR', 'Province', 'Region',
                    function(AppraisalState, Lender, User, AppraiserUser, VCUser, Brokerage, AppraisalFirm, isAdmin, isCCR, Province, Region) {
                        return {
                            states: AppraisalState.all,
                            allLenders:
                                function () {
                                    return Lender.querySimpleAll();
                                },
                            lenders:
                                function () {
                                    return Lender.querySimple();
                                },
                            ccrs: isAdmin ?
                                function () {
                                    return VCUser.queryCCRs();
                                }
                                : undefined,
                            brokerages: (isAdmin || isCCR) ?
                                function () {
                                    return Brokerage.getDropDownBrokerages({size: 2000, sort: 'name,asc'});
                                }
                                : undefined,
                            appraisalFirms: (isAdmin || isCCR) ?
                                function() {
                                    return AppraisalFirm.getDropDownAppraisalFirms({size: 2000, sort: 'name,asc'});
                                }
                                : undefined,
                            originators: (isAdmin || isCCR) ?
                                function() {
                                    return User.getOriginators();
                                }
                                : undefined,
                            appraisers: (isAdmin || isCCR) ?
                                function() {
                                    return AppraiserUser.getMinimal({size:2000, sort:'user.lastName,asc'});
                                }
                                : undefined,
                            provinces:
                                function() {
                                    return Province.query({size:2000});
                                },
                            regions:
                                function() {
                                    return Region.query({size: 2000, sort: 'name,asc'});
                                }
                        };
                    }
                ],
                filterOriginators: ['User', 'isAdmin', 'isCCR', 
                    function(User, isAdmin, isCCR) {
                        return {
                            originators: (isAdmin || isCCR) ?
                                function(brokerageId) {
                                    if (brokerageId != null) {
                                        return User.getOriginators({brokerageId: brokerageId});
                                    } else {
                                        return User.getOriginators();
                                    }
                                }
                                : undefined                            
                        };
                    }
                ],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('invoice');
                    $translatePartialLoader.addPart('report');
                    $translatePartialLoader.addPart('enumProductType');
                    return $translate.refresh();
                }]
            }
        })
        
        .state('invoice.new', {
            parent: 'invoice',
            url: '/new',
            data: {
                authorities: [/^ROLE_(CUSTOMER_CARE|ADMIN).*/gi],
                pageTitle: 'valueconnectApp.invoice.home.createLabel'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/invoice/invoice-add.html',
                    controller: 'InvoiceAddController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                isAdmin: ['Principal', 'authorize', function(Principal, authorize) {
                    return Principal.matchAnyAuthority(/^ROLE_(ADMIN|VC_DATA_ANALYST|VC_FINANCIAL_TEAM)$/);
                }],
                isCCR: ['Principal', 'authorize', function(Principal, authorize) {
                    return Principal.hasAnyRole(['CUSTOMER_CARE_REPRESENTATIVE']);
                }],
                pagingParams: getPagingParams,
                params: ['AppraisalState', 'moment', function(AppraisalState, moment) {
                    return {
                        minDate: moment().startOf('month').toDate(),
                        maxDate: moment().endOf('month').toDate(),
                        states: AppraisalState.all
                    };
                }],
                filterDataSources: ['AppraisalState', 'Lender', 'User', 'AppraiserUser', 'VCUser', 'Brokerage', 'AppraisalFirm', 'isAdmin', 'isCCR', 'Province', 'Region', 
                    function(AppraisalState, Lender, User, AppraiserUser, VCUser, Brokerage, AppraisalFirm, isAdmin, isCCR, Province, Region) {
                        return {
                            states: AppraisalState.all,
                            allLenders:
                                function () {
                                    return Lender.querySimpleAll();
                                },
                            lenders:
                                function () {
                                    return Lender.querySimple();
                                },
                            ccrs: isAdmin ?
                                function () {
                                    return VCUser.queryCCRs();
                                }
                                : undefined,
                            brokerages: (isAdmin || isCCR) ?
                                function () {
                                    return Brokerage.getDropDownBrokerages({size: 2000, sort: 'name,asc'});
                                }
                                : undefined,
                            appraisalFirms: (isAdmin || isCCR) ?
                                function() {
                                    return AppraisalFirm.getDropDownAppraisalFirms({size: 2000, sort: 'name,asc'});
                                }
                                : undefined,
                            originators: (isAdmin || isCCR) ?
                                function() {
                                    return User.getOriginators();
                                }
                                : undefined,
                            appraisers: (isAdmin || isCCR) ?
                                function() {
                                    return AppraiserUser.getMinimal({size:2000, sort:'user.lastName,asc'});
                                }
                                : undefined,
                            provinces:
                                function() {
                                    return Province.query({size:2000});
                                },
                            regions:
                                function() {
                                    return Region.query({size: 2000, sort: 'name,asc'});
                                }
                        };
                    }
                ],
                filterOriginators: ['User', 'isAdmin', 'isCCR', 
                    function(User, isAdmin, isCCR) {
                        return {
                            originators: (isAdmin || isCCR) ?
                                function(brokerageId) {
                                    if (brokerageId != null) {
                                        return User.getOriginators({brokerageId: brokerageId});
                                    } else {
                                        return User.getOriginators();
                                    }
                                }
                                : undefined                            
                        };
                    }
                ],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('invoice');
                    $translatePartialLoader.addPart('report');
                    $translatePartialLoader.addPart('appraisalOrder');
                    $translatePartialLoader.addPart('quote');
                    $translatePartialLoader.addPart('appraisalReport');
                    $translatePartialLoader.addPart('enumAppraisalState');
                    return $translate.refresh();
                }],
                entity: function () {
                    return {
                        id: null,
                        dueDate: null,
                        sentDate: null,
                        paidDate: null,
                        appraisalOrderIds: [],
                        sentToEmail: null
                    };
                }
            }
        })
        
    }
})();
