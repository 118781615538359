(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('ResendPaymentRequestDialogController', ResendPaymentRequestDialogController);

    ResendPaymentRequestDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'QuoteSplit', 'Quote'];

    function ResendPaymentRequestDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, QuoteSplit, Quote) {
        var vm = this;

        vm.quoteSplit = entity;
   
        vm.clear = clear;
        vm.save = save;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;

            QuoteSplit.resendPaymentRequest({
                quoteId: vm.quoteSplit.quoteId
            }, vm.quoteSplit.clientContact, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('valueconnectApp:quoteSplitUpdate', result.clientSplit);
            $uibModalInstance.close(result.clientSplit);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
