(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('PaymentDetailController', PaymentDetailController);

    PaymentDetailController.$inject = ['$scope', '$rootScope', '$stateParams', '$mdDialog', 'entity', 'Payment', 'Principal',
        'daysAfterPayment', 'AlertService', '$state', 'Refund'];

    function PaymentDetailController($scope, $rootScope, $stateParams, $mdDialog, entity, Payment, Principal,
                                     daysAfterPayment, AlertService, $state, Refund) {

        var vm = this;

        vm.payment = entity;
        vm.daysAfterPayment = daysAfterPayment;

        // Payment Info
        vm.quoteTotal = vm.payment.appraisalOrder.activeReport.feeProposed;
        vm.paymentSubTotal = vm.payment.amount / vm.payment.taxRate;
        vm.paymentTax = vm.payment.amount - vm.paymentSubTotal;
        vm.paymentAmount = vm.payment.amount;

        vm.isAuthenticated = Principal.isAuthenticated;
        vm.isAdmin = Principal.hasAnyAuthority([/^ROLE_(ADMIN).*/]);
        vm.canRefundOrder = canRefundOrder;
        vm.refundOrder = refundOrder;
        vm.startRefund = startRefund;
        vm.isRefunding = false;

        var unsubscribe = $rootScope.$on('valueconnectApp:paymentUpdate', function(event, result) {
            vm.payment = result;
        });
        $scope.$on('$destroy', unsubscribe);

        function canRefundOrder() {
            return vm.payment.method === "STRIPE"
                && vm.payment.paymentStatus === "PAID"
                && vm.payment.transactionId !== null
                && vm.payment.transactionId.length > 0;
        }

        function startRefund() {
            $mdDialog.show(
                $mdDialog.confirm()
                    .clickOutsideToClose(true)
                    .title('Attention')
                    .textContent('You are about to execute a full refund for this order, are you sure you want to continue?')
                    .ok('Yes')
                    .cancel('Cancel')
            ).then(function () {
                refundOrder();
            });
        }

        function refundOrder() {
            vm.isRefunding = true;
            Refund.refundPayment({ id: vm.payment.id }, onRefundSuccess, onRefundError);
        }

        function onRefundSuccess(result) {
            AlertService.success("valueconnectApp.payment.refund.success", {});
            vm.payment = Payment.get({id: $stateParams.id});
            vm.isRefunding = false;
        }

        function onRefundError (data) {
            vm.isRefunding = false;
            AlertService.error("error.refund.processing", {});
        }
    }
})();
