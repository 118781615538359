(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('originator-note-messageboard', {
            parent: 'entity',
            url: '/originator-messages-for-appraisal/{id}',
            data: {
                authorities: [/^ROLE_(ADMIN|VC|CUSTOMER_CARE|ORIGINATOR).*/],
                pageTitle: 'valueconnectApp.originatorNote.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/originator-note/originator-note-messageboard.html',
                    controller: 'OriginatorNoteMessageboardController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('originatorNote');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                order: ['AppraisalOrder', '$stateParams', function(AppraisalOrder, $stateParams) {
                    return AppraisalOrder.getMinimal({id: $stateParams.id}).$promise; // <--- new code
                }],
                notes: ['OriginatorNote', 'order', function(OriginatorNote, order) {
                    return OriginatorNote.getNotesForAppraisal({appraisalOrderId : order.id}).$promise;
                }],
                account: ['Principal', function(Principal) {
                    return Principal.identity();
                }]
            }
        });
    }
})();
