(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('Opta', Opta);

    Opta.$inject = ['$resource'];

    function Opta ($resource) {
        var resourceUrl =  'api/opta';

        return $resource(resourceUrl, {}, {
            'query': { method: 'POST', isArray: true},
            'getAutomatedValuationConfidenceRating': {
                method: 'POST',
                url: resourceUrl + '/automated-valuation-confidence-rating',
                transformResponse: function(data) { return { data : data }; },
                responseType: 'json'
            }
        });
    }
})();
