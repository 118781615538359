(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('blackout-period', {
            parent: 'entity',
            url: '/blackout-period',
            data: {
                authorities: [/^ROLE_(APPRAISAL|APPRAISER).*/gi],
                pageTitle: 'valueconnectApp.blackoutPeriod.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/blackout-period/blackout-periods.html',
                    controller: 'BlackoutPeriodController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('blackoutPeriod');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('blackout-period-detail', {
            parent: 'entity',
            url: '/blackout-period/{id}',
            data: {
                authorities: [/^ROLE_(ADMIN|VC|APPRAISAL|APPRAISER).*/gi],
                pageTitle: 'valueconnectApp.blackoutPeriod.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/blackout-period/blackout-period-detail.html',
                    controller: 'BlackoutPeriodDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('blackoutPeriod');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'BlackoutPeriod', function($stateParams, BlackoutPeriod) {
                    return BlackoutPeriod.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        .state('blackout-period.new', {
            parent: 'blackout-period',
            url: '/new',
            data: {
                authorities: [/^ROLE_(APPRAISAL|APPRAISER).*/gi]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/blackout-period/blackout-period-dialog.html',
                    controller: 'BlackoutPeriodDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                startDate: null,
                                endDate: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('blackout-period', null, { reload: true });
                }, function() {
                    $state.go('blackout-period');
                });
            }]
        })
        .state('blackout-period.edit', {
            parent: 'blackout-period',
            url: '/{id}/edit',
            data: {
                authorities: [/^ROLE_(ADMIN|VC|APPRAISAL|APPRAISER).*/gi]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/blackout-period/blackout-period-dialog.html',
                    controller: 'BlackoutPeriodDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['BlackoutPeriod', function(BlackoutPeriod) {
                            return BlackoutPeriod.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('blackout-period', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('blackout-period.delete', {
            parent: 'blackout-period',
            url: '/{id}/delete',
            data: {
                authorities: [/^ROLE_(ADMIN|VC|APPRAISAL|APPRAISER).*/gi]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/blackout-period/blackout-period-delete-dialog.html',
                    controller: 'BlackoutPeriodDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['BlackoutPeriod', function(BlackoutPeriod) {
                            return BlackoutPeriod.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('blackout-period', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
