(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('HubspotDealDetailController', HubspotDealDetailController);

    HubspotDealDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'HubspotDeal', 'AppraisalOrder'];

    function HubspotDealDetailController($scope, $rootScope, $stateParams, entity, HubspotDeal, AppraisalOrder) {
        var vm = this;

        vm.hubspotDeal = entity;

        var unsubscribe = $rootScope.$on('valueconnectApp:hubspotDealUpdate', function(event, result) {
            vm.hubspotDeal = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
