(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .directive('vcAddressForm', ['$q', 'Country', 'Province', 'CitySearch', 'GoogleVC', vcAddressForm]);

    function vcAddressForm ($q, Country, Province, CitySearch, GoogleVC) {
        var directive = {
            restrict: 'E',
            link:  postLink,
            transclude: true,
            templateUrl: "app/components/form/address-form/vc-address-form.template.html",
            scope: {
                address: "=addressEntity",
                quoteFieldsDisabled: "<quoteFieldsDisabled"
            }
        };

        return directive;

        function postLink(scope, elm, attrs) {
            scope.lookupAddress = lookupAddress;

            scope.provinces = Province.query();
            scope.countries = Country.query();

            scope.getCityName = getCityName;
            scope.citiesPromise = $q.when([]);
            scope.refreshCities = refreshCities;

            // rebuild city object from model for autocomplete
            if (scope.address && scope.address.cityId) {
                scope.address.city = {id: scope.address.cityId, name: scope.address.cityName};
            }

            /**
             * Validate that city has been selected
             */
            function checkSelected() {
                if (scope.addressForm.city) {
                    if (scope.address.city) {
                        scope.addressForm.city.$setValidity("nonechosen", true);
                    } else {
                        scope.addressForm.city.$setValidity("nonechosen", false);
                    }
                }
            }

            /**
             * Google lookup address from postal code
             */
            function lookupAddress() {
                if (!scope.quoteFieldsDisabled) {
                    GoogleVC.lookupAddress(scope.address, "CA").then( function(googleLookupResults) {
                        scope.citiesPromise.then(function(cities) {
                            var googleLookupCity = GoogleVC.setAddress(scope.address, googleLookupResults, scope.provinces, cities);
                            if (googleLookupCity && !scope.address.city) { // Perform more specific query using google results to get city if not in current list
                                refreshCities(googleLookupCity.long_name).then(function () {
                                    lookupAddress();
                                });
                            }
                        });
                    });
                }
            }

            function getCityName(city) {
                return city.name;
            }

            /**
             * City autocomplete list query method
             */
            function refreshCities(searchText) {
                checkSelected();

                var query = buildCityQuery(searchText);
                scope.citiesPromise = CitySearch.query(query).$promise;
                return scope.citiesPromise;
            }

            /**
             * Build city query based on search text and province
             */
            function buildCityQuery(searchText) {
                var query = { size: 500, sort: "name,asc" };
                if (searchText) {
                    query['query'] = searchText;
                }
                if (scope.address && scope.address.provinceId) {
                    query['provinceId'] = scope.address.provinceId;
                    query['size'] = 2000;
                }
                return query;
            }

            /**
             * Watch for province selection and clear city/reset list if needed
             */
            scope.$watch('address.provinceId', function(newVal, oldVal) {
                if (newVal !== oldVal && oldVal) {
                    scope.address.city = null;
                }

                if (scope.address && !scope.address.city) {
                    refreshCities();
                }
            });

            /**
             * Watch for city selection
             */
            scope.$watch('address.city', function() {
                if (scope.address) {
                    if (scope.address.city && !scope.address.provinceId) {
                        scope.address.provinceId = scope.address.city.provinceId;
                    }

                    scope.address.cityId = scope.address.city ? scope.address.city.id : null;
                    scope.address.cityName = scope.address.city ? scope.address.city.name : null;
                }

                checkSelected();
            });

        }
    }
})();
