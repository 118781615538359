/*global Case */
(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('StandardResidentialSubjectController', StandardResidentialSubjectController);

    StandardResidentialSubjectController.$inject = ['$scope'];

    function StandardResidentialSubjectController($scope) {
        var vm = this;

        // Initialize model using form data
        init(null, $scope.vm.formData);
        $scope.$on('formDataReinitialized', init);

        vm.addressChanged = addressChanged;

        function init(event, formData) {
            var propertyAddressMismatch = !compareAddresses();
            if (angular.isUndefined($scope.vm.formData.subject.propertyAddressMismatch) || $scope.vm.formData.subject.propertyAddressMismatch !== propertyAddressMismatch) {
                $scope.vm.formData.subject.propertyAddressMismatch = propertyAddressMismatch;
                $scope.vm.setDirty("subject.propertyAddressMismatch");
            }
        }

        function addressChanged() {
            $scope.vm.addressUpdatedType = 1;
        }

        function compareAddresses() {
            return (
                $scope.vm.appraisalOrder.address.address1 === $scope.vm.formData.subject.address1 &&
                ($scope.vm.appraisalOrder.address.address2 === $scope.vm.formData.subject.address2
                     || (!$scope.vm.appraisalOrder.address.address2 && !$scope.vm.formData.subject.address2)) &&
                $scope.vm.appraisalOrder.address.cityName === $scope.vm.formData.subject.city &&
                $scope.vm.appraisalOrder.address.postCode === $scope.vm.formData.subject.postCode &&
                $scope.vm.appraisalOrder.address.provinceName === $scope.vm.formData.subject.province
            );
        }
    }
})();
