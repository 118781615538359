(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('SavedFormValueDialogController', SavedFormValueDialogController);

    SavedFormValueDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$mdDialog', 'Principal', 'entity', 'SavedFormValue'];

    function SavedFormValueDialogController ($timeout, $scope, $stateParams, $mdDialog, Principal, entity, SavedFormValue) {
        var vm = this;
        vm.savedFormValue = entity;
        vm.clear = clear;
        vm.save = save;

        // TODO: pass this as an argument to the controller
        vm.showDetailed = false;

        vm.supportedFields = [
            'standardResidential.letter.summary',
            'standardResidential.appraisalScope.scope',
            'standardResidential.neighbourhood.comments',
            'standardResidential.site.curbAppealComments',
            'standardResidential.improvements.garageDetails',
            'standardResidential.improvements.improvementDetails'
        ].map(function(path) {
            var pathSegments = path.split('.');
            return {
                field: path,
                form: pathSegments[0] + '.title',
                section: pathSegments[0] + '.' + pathSegments[1] + '.title'
            };
        });

        vm.tinyMceOptions = {
            plugins: ['autoresize', 'paste'],
            menubar: false,
            toolbar: "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat"
            // width: 700
        };

        Principal.hasRole('ADMIN').then(function(result) {
            vm.isAdmin = result;
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $mdDialog.cancel('cancel');
        }

        function save () {
            vm.isSaving = true;
            vm.savedFormValue.value.fieldName = vm.savedFormValue.value.fieldPath.split('.').pop();
            if (vm.savedFormValue.id !== null) {
                SavedFormValue.update(vm.savedFormValue, onSaveSuccess, onSaveError);
            } else {
                SavedFormValue.save(vm.savedFormValue, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('valueconnectApp:savedFormValueUpdate', result);
            $mdDialog.hide(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
