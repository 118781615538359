(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .factory('stateHandler', stateHandler);

    stateHandler.$inject = ['$log', '$rootScope', '$state', '$sessionStorage', '$translate', 'JhiLanguageService', 'translationHandler', '$window',
        'Auth', 'Principal', 'VERSION', 'ProfileService', '$mdDialog'];

    function stateHandler($log, $rootScope, $state, $sessionStorage, $translate, JhiLanguageService, translationHandler, $window,
        Auth, Principal, VERSION, ProfileService, $mdDialog) {
        return {
            initialize: initialize
        };

        function initialize() {
            $rootScope.VERSION = VERSION;

            var stateChangeStart = $rootScope.$on('$stateChangeStart', function (event, toState, toStateParams, fromState) {
                $rootScope.toState = toState;
                $rootScope.toStateParams = toStateParams;
                $rootScope.fromState = fromState;

                // Redirect to a state with an external URL (http://stackoverflow.com/a/30221248/1098564)
                if (toState.external) {
                    event.preventDefault();
                    $window.open(toState.url, '_self');
                }

                if (Principal.isIdentityResolved()) {
                    Auth.authorize();
                }

                // Update the language
                JhiLanguageService.getCurrent().then(function (language) {
                    JhiLanguageService.setLang(language);
                });

            });

            var stateChangeSuccess = $rootScope.$on('$stateChangeSuccess',  function(event, toState, toParams, fromState, fromParams) {
                var titleKey = 'global.title' ;

                // Set the page title key to the one configured in state or use default one
                if (toState.data.pageTitle) {
                    titleKey = toState.data.pageTitle;
                }
                translationHandler.updateTitle(titleKey);

                $rootScope.currentState = $state.current.name;
            });

            // Unsubscribe when destroyed
            $rootScope.$on('$destroy', function () {
                if(angular.isDefined(stateChangeStart) && stateChangeStart !== null){
                    stateChangeStart();
                }
                if(angular.isDefined(stateChangeSuccess) && stateChangeSuccess !== null){
                    stateChangeSuccess();
                }
            });

            // Add debugging statements for ui-router events
            ProfileService.getProfileInfo().then(function(profile) {
                // Do nothing if we are in production
                if(profile.inProduction) return;

                var debugStateChangeStart = $rootScope.$on('$stateChangeStart',function(event, toState, toParams, fromState, fromParams){
                    $log.debug('$stateChangeStart to ' + toState.name + "\ntoState:", toState, "\ntoParams:", toParams); // fired when the transition begins
                });
                var debugStateChangeError = $rootScope.$on('$stateChangeError',function(event, toState, toParams, fromState, fromParams, error){
                    $log.debug("$stateChangeError", "event:", event, "toState:", toState, "toParams:", toParams, "fromState:", fromState, "fromParams:", fromParams, "error:", error);
                });
                var debugStateChangeSuccess = $rootScope.$on('$stateChangeSuccess',function(event, toState, toParams, fromState, fromParams){
                    $log.debug('$stateChangeSuccess to ' + toState.name);   //fired when the state transition is complete
                });
                var debugViewContentLoading = $rootScope.$on('$viewContentLoading',function(event, viewConfig){
                    $log.debug('$viewContentLoading', viewConfig);  // fired when view begins loading; dom not rendered
                });
                // This runs on individual scopes, so putting it in "run" doesn't work.
                // $rootScope.$on('$viewContentLoaded',function(event){
                //   $log.debug('$viewContentLoaded - fired after dom rendered',event);
                // });
                var debugStateNotFound = $rootScope.$on('$stateNotFound',function(event, unfoundState, fromState, fromParams){
                    $log.debug('$stateNotFound ' + unfoundState.to); // fired when a state cannot be found by its name
                    $log.debug("unfoundState:",unfoundState,"\nfromState:", fromState,"\nfromParams:", fromParams);
                });

                // Unsubscribe when destroyed
                $rootScope.$on('$destroy', function () {
                    if(debugStateChangeStart) debugStateChangeStart();
                    if(debugStateChangeError) debugStateChangeError();
                    if(debugStateChangeSuccess) debugStateChangeSuccess();
                    if(debugViewContentLoading) debugViewContentLoading();
                    if(debugStateNotFound) debugStateNotFound();
                });
            });
        }
    }
})();
