(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('ConcernDialogReplyController', ConcernDialogReplyController);

    ConcernDialogReplyController.$inject = ['$timeout', '$scope', '$stateParams', 'Principal', '$uibModalInstance', 'entity', 'Concern'];

    function ConcernDialogReplyController ($timeout, $scope, $stateParams, Principal, $uibModalInstance, entity,  Concern) {
        var vm = this;

        vm.concern = entity;
        vm.clear = clear;
        vm.save = save;  
        
        //alert($stateParams.name);
       
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
                vm.isSaving = true;                
                vm.concern.parentId = entity.id; 
            	vm.concern.name = entity.name;
            	vm.concern.email = entity.email;    
            	vm.concern.id = null;
                Concern.save(vm.concern, onSaveSuccess, onSaveError);            
        }

        function onSaveSuccess (result) {
            $scope.$emit('valueconnectApp:concernUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

    }
})();
