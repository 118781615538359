(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('LenderContact', LenderContact);

    LenderContact.$inject = ['$resource'];

    function LenderContact ($resource) {
        var resourceUrl =  'api/lender-contacts/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            /*
                queryMinimal ->  fetches only required fields from the database which are
                required to view a contact using the ID
            */
            'queryMinimal': {method: 'GET', url: 'api/lender-contacts-minimal/:id'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'getbylender': {method: 'GET', url: 'api/lender-contacts-fromlender/:id', isArray: true},
            /*
                getEnabledByLenders ->  fetches only required fields from the database which are
                required to view a contact using the ID
            */
            'getEnabledByLender': {method: 'GET', url: 'api/lender-contacts-enabled/:id', isArray: true}
        });
    }
})();
