(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('SavedFormValueSearchDialogController', SavedFormValueSearchDialogController);

    SavedFormValueSearchDialogController.$inject = ['$mdDialog', '$scope', 'fieldPath', 'SavedFormValue'];

    function SavedFormValueSearchDialogController ($mdDialog, $scope, fieldPath, SavedFormValue) {
        var vm = this;
        vm.fieldPath = fieldPath;
        vm.selectedValue = null;
        vm.values = SavedFormValue.query({fieldPath: fieldPath});

        vm.cancel = cancel;
        vm.finish = finish;

        function cancel($event) {
            $mdDialog.cancel();
        }

        function finish($event) {
            $mdDialog.hide(vm.selectedValue);
        }
    }
})();
