(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraisalOrderContactDetailController', AppraisalOrderContactDetailController);

    AppraisalOrderContactDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'AppraisalOrderContact', 'AppraisalOrder', 'Contact'];

    function AppraisalOrderContactDetailController($scope, $rootScope, $stateParams, entity, AppraisalOrderContact, AppraisalOrder, Contact) {
        var vm = this;

        vm.appraisalOrderContact = entity;

        var unsubscribe = $rootScope.$on('valueconnectApp:appraisalOrderContactUpdate', function(event, result) {
            vm.appraisalOrderContact = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
