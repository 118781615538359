(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('appraisal-order', {
            parent: 'entity',
            url: '/appraisal-order?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'valueconnectApp.appraisalOrder.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/appraisal-order/appraisal-orders.html',
                    controller: 'AppraisalOrderController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null,
                isSSO: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('appraisalOrder');
                    $translatePartialLoader.addPart('enum');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('enumAppraisalPurpose');
                    $translatePartialLoader.addPart('enumAppraisalFirmSelection');
                    $translatePartialLoader.addPart('enumAppraisalType');
                    $translatePartialLoader.addPart('financingPurpose');
                    $translatePartialLoader.addPart('legalProceedingType');
                    $translatePartialLoader.addPart('enumOccupiedBy');
                    $translatePartialLoader.addPart('address');
                    $translatePartialLoader.addPart('appraisalOrderContact');
                    $translatePartialLoader.addPart('contact');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('appraisalOrderContact');
                    $translatePartialLoader.addPart('appraisalContactRole');
                    $translatePartialLoader.addPart('propertyUse');
                    $translatePartialLoader.addPart('file');
                    return $translate.refresh();
                }]
            }
        })
        .state('appraisal-order-detail', {
            parent: 'entity',
            url: '/appraisal-order/{id}',
            data: {
                authorities: ['ROLE_CUSTOMER_CARE_REPRESENTATIVE', /^ROLE_(ADMIN|VC|CUSTOMER_CARE|ORIGINATOR|APPRAISER|APPRAISAL).*/gi],
                pageTitle: 'valueconnectApp.appraisalOrder.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/appraisal-order/appraisal-order-detail.html',
                    controller: 'AppraisalOrderDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('quote');
                    $translatePartialLoader.addPart('enumQuoteState');
                    $translatePartialLoader.addPart('appraisalOrder');
                    $translatePartialLoader.addPart('quoteSplit');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('address');
                    $translatePartialLoader.addPart('appraisalOrderContact');
                    $translatePartialLoader.addPart('contact');
                    $translatePartialLoader.addPart('declined');
                    $translatePartialLoader.addPart('enum');
                    $translatePartialLoader.addPart('enumDeclined');
                    $translatePartialLoader.addPart('enumProductType');
                    $translatePartialLoader.addPart('enumAppraisalType');
                    $translatePartialLoader.addPart('financingPurpose');
                    $translatePartialLoader.addPart('legalProceedingType');
                    $translatePartialLoader.addPart('propertyUse');
                    $translatePartialLoader.addPart('enumOccupiedBy');
                    $translatePartialLoader.addPart('appraisalOrderClarification');
                    $translatePartialLoader.addPart('appraisalOrderChangeRequest');
                    $translatePartialLoader.addPart('appraisalOrderChangeReason');
                    $translatePartialLoader.addPart('appraisalContactRole');
                    $translatePartialLoader.addPart('enumAppraisalPurpose');
                    $translatePartialLoader.addPart('enumAppraisalFirmSelection');
                    $translatePartialLoader.addPart('enumAppraisalState');
                    $translatePartialLoader.addPart('appraisalReport');
                    $translatePartialLoader.addPart('payment');
                    $translatePartialLoader.addPart('appraisalNote');
                    $translatePartialLoader.addPart('originatorNote');
                    $translatePartialLoader.addPart('originatorAppraiserDirectNote');
                    $translatePartialLoader.addPart('vcNote');
                    $translatePartialLoader.addPart('formThirdParty');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'AppraisalOrder', function ($stateParams, AppraisalOrder) {
                    return AppraisalOrder.get({id: $stateParams.id}).$promise;
                }],
                account: ['Principal', function (Principal) {
                    return Principal.identity();
                }],
                daysAfterPayment: ['Setting', function (Setting) {
                    return Setting.getDaysAfterPayment().$promise;
                }]
            }
        })
        .state('appraisal-order.new', {
            parent: 'appraisal-order',
            url: '/new',
            data: {
                authorities: [/^ROLE_(ORIGINATOR|CUSTOMER_CARE|ADMIN).*/gi],
                pageTitle: 'valueconnectApp.appraisalOrder.new.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/appraisal-order/appraisal-order-dialog.html',
                    controller: 'AppraisalOrderDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: function () {
                    return {
                        appraisalType: null,
                        financingPurpose: null,
                        legalProceedingType: null,
                        purposeOther: null,
                        appraisalTypeOptions: null,
                        submittingAgent: null,
                        lendersEstimatedPropertyValue: null,
                        loanAmount: null,
                        legalDescription: null,
                        existingUse: null,
                        occupiedBy: null,
                        appraisalDueDate: null,
                        conditionFinancingDate: null,
                        loanReferenceNumber: null,
                        specialInstructions: null,
                        customForms: [],
                        supportingDocumentation: [],
                        agreementPurchase: [],
                        mlsPropertySheet: [],
                        id: null
                    };
                }
            }
        })

        .state('appraisal-order.edit', {
            parent: 'appraisal-order',
            url: '/{id}/edit',
            data: {
                authorities: [/^ROLE_(ADMIN|CUSTOMER_CARE|VC|ORIGINATOR).*/gi],
                pageTitle: 'valueconnectApp.appraisalOrder.edit.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/appraisal-order/appraisal-order-dialog.html',
                    controller: 'AppraisalOrderDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'AppraisalOrder', function($stateParams, AppraisalOrder) {
                    return AppraisalOrder.get({id : $stateParams.id}).$promise;
                }],
                canEdit: ['$q', '$state', 'AlertService', 'AppraisalState', 'Principal', 'entity', function($q, $state, AlertService, AppraisalState, Principal, order) {
                    var orderEditable = AppraisalState.inAnyState(order, AppraisalState.orderEditable) || order.state === 'MANUAL_INTERVENTION_REQUIRED'
                        && Principal.hasAnyRole(['ORIGINATOR_PRINCIPAL', 'ORIGINATOR_ADMINISTRATOR', 'ORIGINATOR_REGION_MANAGER', 'ORIGINATOR_LOCATION_MANAGER', 'ORIGINATOR_AGENT', 'ADMIN', 'CUSTOMER_CARE_REPRESENTATIVE']);

                    if (orderEditable) return $q.when(true);
                    else {
                        AlertService.error('valueconnectApp.appraisalOrder.edit.invalidState');
                        $state.go('appraisal-order-detail', { id: order.id });
                        return $q.reject(false);
                    }
                }]
            }
        })

        .state('appraisal-order-open', {
            parent: 'appraisal-order',
            url: '/openorders',
            data: {
                authorities: [],
                pageTitle: 'valueconnectApp.appraisalOrder.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/appraisal-order/appraisal-order-open.html',
                    controller: 'AppraisalOrderOpenController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('address');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('appraisalOrderContact');
                    $translatePartialLoader.addPart('enumAppraisalFirmSelection');
                    $translatePartialLoader.addPart('appraisalContactRole');
                    $translatePartialLoader.addPart('enumOccupiedBy');
                    $translatePartialLoader.addPart('contact');
                    $translatePartialLoader.addPart('file');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'AppraisalOrder', function($stateParams, AppraisalOrder) {
                    return AppraisalOrder.getByState({id : $stateParams.id}).$promise;
                }]
            }
        })

        .state('appraisal-order-dashboard', {
            parent: 'home',
            url: '/dashboard',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/appraisal-order/appraisal-order-dashboard.html'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('address');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('appraisalOrderContact');
                    $translatePartialLoader.addPart('enumAppraisalFirmSelection');
                    $translatePartialLoader.addPart('appraisalContactRole');
                    $translatePartialLoader.addPart('enumOccupiedBy');
                    $translatePartialLoader.addPart('contact');
                    $translatePartialLoader.addPart('file');
                    return $translate.refresh();
                }]
            }
        })


        .state('appraisal-order-open-dashboard', {
            parent: 'home',
            url: '/dashboard-open',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/appraisal-order/appraisal-order-open-dashboard.html'
                }
            },
            params: {
                allorders: true
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('address');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('appraisalOrderContact');
                    $translatePartialLoader.addPart('appraisalContactRole');
                    $translatePartialLoader.addPart('enumAppraisalFirmSelection');
                    $translatePartialLoader.addPart('enumOccupiedBy');
                    $translatePartialLoader.addPart('contact');
                    $translatePartialLoader.addPart('file');
                    return $translate.refresh();
                }]
            }
        })

        .state('appraisal-order-outstanding', {
            parent: 'home',
            url: 'order-outstanding',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/appraisal-order/appraisal-order-outstanding.html'
                }
            },
            params: {
                allorders: true
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('address');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('appraisalOrderContact');
                    $translatePartialLoader.addPart('enumAppraisalFirmSelection');
                    $translatePartialLoader.addPart('appraisalContactRole');
                    $translatePartialLoader.addPart('enumOccupiedBy');
                    $translatePartialLoader.addPart('contact');
                    return $translate.refresh();
                }]
            }
        })

        .state('appraisal-order.delete', {
            parent: 'appraisal-order',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/appraisal-order/appraisal-order-delete-dialog.html',
                    controller: 'AppraisalOrderDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['AppraisalOrder', function(AppraisalOrder) {
                            return AppraisalOrder.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('appraisal-order', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
