(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('concern', {
            parent: 'entity',
            url: '/concern?page&sort&search',
            data: {
                authorities: [],
                pageTitle: 'valueconnectApp.concern.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/concern/concerns.html',
                    controller: 'ConcernController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('concern');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        
        .state('concern-detail', {
            parent: 'entity',
            url: '/concern/{id}',
            data: {
                authorities: [],
                pageTitle: 'valueconnectApp.concern.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/concern/concern-detail.html',
                    controller: 'ConcernDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('concern');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Concern', function($stateParams, Concern) {
                    return Concern.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        
        
        .state('concern.new', {
            parent: 'home',
            url: 'home',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/concern/concern-dialog.html',
                    controller: 'ConcernDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',                    
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                email: null,
                                note: null,
                                id: null,
                                currentUrl: window.location.href
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('home', null, { reload: true });
                }, function() {
                    $state.go('home');
                });
            }]
        })
        
        
        .state('concern.reply', {
            parent: 'concern',
            url: '/{id}/reply',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/concern/concern-dialog-reply.html',
                    controller: 'ConcernDialogReplyController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Concern', function(Concern) {
                            return Concern.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('concern', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        
        
        
        .state('concern.delete', {
            parent: 'concern',
            url: '/{id}/delete',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/concern/concern-delete-dialog.html',
                    controller: 'ConcernDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Concern', function(Concern) {
                            return Concern.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('concern', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
