'use strict';

angular.
    module('valueConnectPendingOriginatorMessages', []).
    component('valueConnectPendingOriginatorMessages', {
        templateUrl: 'app/components/pendingmessages/originator/pending-originator-messages.template.html',
        controller: ['$scope', 'OriginatorNote', 'AlertService',
        function ValueConnectPendingOriginatorMessagesController($scope, OriginatorNote, AlertService) {
            $scope.isOpen = false;
            $scope.collapsible = false;

            $scope.loadPage = loadPage;
            $scope.transition = transition;
            $scope.loadAll = loadAll;

            $scope.totalItems = 0;
            $scope.page = 1;
            $scope.itemsPerPage = 10;
            $scope.transition = $scope.transition;

            $scope.markAsRead = markAsRead;

            loadAll();

            function loadAll () {
                loadPage(1);
            }

            $scope.toggleView = function() {
                $scope.isOpen = !$scope.isOpen;
            };

            function onSuccess(data, headers) {
                $scope.totalItems = headers('X-Total-Count');
                $scope.collapsible = (data.length > 0);
                $scope.isOpen = $scope.collapsible ? $scope.isOpen : false;
            }

            function onError(error) {
                if (error && error.data) {
                    AlertService.error(error.data.message, {});
                }
            }

            function loadPage (page) {
                $scope.page = page;
                $scope.transition();
            }

            function transition () {
                $scope.pendingMessages = OriginatorNote.getNotesForCCR({
                    page: $scope.page - 1,
                    size: $scope.itemsPerPage,
                    sort: ['createdDate,desc']
                }, onSuccess, onError);
            }

            function markAsRead(note, ev) {
                ev.stopPropagation();
                var noteId = note.id;
                OriginatorNote.markAsRead({id: noteId}).$promise
                .then(function() {
                    $scope.pendingMessages = $scope.pendingMessages.filter(function(note) { return note.id !== noteId; });
                    AlertService.success('Successfully marked note as read');
                }, function () {
                    AlertService.error('Unable to mark note as read');
                });
            };
        }]
    });
