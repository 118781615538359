(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .factory('AppraisalFirmLocationSearch', AppraisalFirmLocationSearch);

    AppraisalFirmLocationSearch.$inject = ['$resource'];

    function AppraisalFirmLocationSearch($resource) {
        var resourceUrl =  'api/_search/appraisal-firm-locations/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
