(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('UserManagementAppraiserController', UserManagementAppraiserController);

    UserManagementAppraiserController.$inject = ['$scope', '$state', '$rootScope', '$mdDialog', 'User', 'Principal', 'canEdit'];

    function UserManagementAppraiserController($scope, $state, $rootScope, $mdDialog, User, Principal, canEdit) {
        var vm = this;
        vm.canEdit = canEdit;
        vm.appraisers = User.getAppraisers();
    }
})();
