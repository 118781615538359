(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraisalFirmDetailController', AppraisalFirmDetailController);

    AppraisalFirmDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'AppraisalFirm', 'AppraisalFirmRegion', 'AppraisalFirmLocation'];

    function AppraisalFirmDetailController($scope, $rootScope, $stateParams, entity, AppraisalFirm, AppraisalFirmRegion, AppraisalFirmLocation) {
        var vm = this;

        vm.appraisalFirm = entity;

        var unsubscribe = $rootScope.$on('valueconnectApp:appraisalFirmUpdate', function(event, result) {
            vm.appraisalFirm = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
