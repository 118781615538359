(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('TaxCodeDialogController', TaxCodeDialogController);

    TaxCodeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'TaxCode', 'Country', 'Province'];

    function TaxCodeDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, TaxCode, Country, Province) {
        var vm = this;

        vm.taxCode = entity;
        vm.clear = clear;
        vm.save = save;
        vm.countries = Country.query();
        vm.provinces = Province.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.taxCode.id !== null) {
                TaxCode.update(vm.taxCode, onSaveSuccess, onSaveError);
            } else {
                TaxCode.save(vm.taxCode, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('valueconnectApp:taxCodeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
