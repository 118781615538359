(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraisalReportDialogController', AppraisalReportDialogController);

    AppraisalReportDialogController.$inject = ['$log', '$state', '$mdDialog', 'report', 'hasForm', 'addForm', 'removeForm', 'AppraisalReport'];

    function AppraisalReportDialogController ($log, $state, $mdDialog, report, hasForm, addForm, removeForm, AppraisalReport) {
        var vm = this;
        vm.report = report;
        vm.formList = getFormList(report);
        vm.hasForm = hasForm;
        vm.captureSelection = captureSelection;
        vm.isSelected = isSelected;
        vm.dictCheckboxes = {};

        initDictCheckboxes()

        function initDictCheckboxes() {
            var currentFormName;
            for (var i = 0; i < vm.formList.length; i++) {
                currentFormName = vm.formList[i];
                vm.dictCheckboxes[currentFormName] = vm.hasForm(currentFormName);
            }
        }

        function isSelected(formName) {
            return vm.dictCheckboxes[formName];
        }

        function captureSelection(formName, $event) {
            var boolValue;
            // Fetch and coerce to boolean.
            boolValue = !!vm.dictCheckboxes[formName];
            // Store the negation.
            vm.dictCheckboxes[formName] = !boolValue;
        }

        vm.applyAndClose = function ($event) {
            var currentFormName;
            var isEverythingDeselected = true;
            var isSelected;
            var isDefined;
            var i;
            var arrayPromises;
            for (i = 0; i < vm.formList.length; i++) {
                currentFormName = vm.formList[i];
                // fetch and coerce to boolean
                isSelected = !!vm.dictCheckboxes[currentFormName];
                if (isSelected) {
                    isEverythingDeselected = false;
                    break;
                }
            }
            if (isEverythingDeselected && report.forms.length === 0) {
                $mdDialog.cancel();
                $state.go('appraisal-order-detail', {id: report.appraisalOrderId});
            } else {
                for (i = 0; i < vm.formList.length; i++) {
                    currentFormName = vm.formList[i];
                    isSelected = !!vm.dictCheckboxes[currentFormName];
                    isDefined = vm.hasForm(currentFormName);
                    if (isSelected && !isDefined) {
                        addForm(currentFormName);
                    } else if (!isSelected && isDefined) {
                        removeForm(currentFormName, $event);
                    }
                }
                $mdDialog.cancel();
            }
        };

        vm.cancel = function ($event) {
            $mdDialog.cancel();
        };

        /**
         * Get a list of forms to display in the checkbox list.
         * Returns the union of the eligible and existing forms on the report.
         */
        function getFormList(report) {
            return report.eligibleForms
                .concat(report.forms.map(function (form) {
                    return form.fieldName;
                }))
                .filter(function (value, index, self) {
                    return self.indexOf(value) === index;
                });
        }
    }
})();
