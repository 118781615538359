(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraisalReportLenderReviewController', AppraisalReportLenderReviewController);

    AppraisalReportLenderReviewController.$inject = ['$stateParams', '$sce', 'report', 'AppraisalReport'];

    function AppraisalReportLenderReviewController($stateParams, $sce, report, AppraisalReport) {
        var vm = this;

        // Model //
        vm.appraisalReport = report;
        vm.appraisalReportHtml = $sce.trustAsHtml("<p style='text-align: center'>Loading Web Report...</p>");

        initReport();

        /**
         * Set the PDF URL and retrieve the HTML version from the server
         * @return {Promise} Promise that is resolved when the HTML report is done generating
         */
        function initReport() {
            // Set the generated pdf url
            vm.appraisalReportPdfUrl = $sce.trustAsResourceUrl('/api/appraisal-reports/generate-pdf/by-access-key/' + $stateParams.accessKey);

            // Generate the report HTML if the report is not manual
            if (!vm.appraisalReport.manual) {
                AppraisalReport.generateHtmlByAccessKey({accessKey : $stateParams.accessKey}, {}, function(data) {
                    vm.appraisalReportHtml = $sce.trustAsHtml(data.response);
                });
            }
        }

    }
})();
