(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .factory('ValidDate', ValidDate);

    ValidDate.$inject = ['moment', 'Holiday'];

    function ValidDate (moment, Holiday) {

        var upcomingHolidays = Holiday.getUpcoming();

        var service = {
            upcomingHolidays: upcomingHolidays,
            defaultDueDateTime: defaultDueDateTime,
            earliestDueDateFromNow: earliestDueDateFromNow,
            earliestDueDateOffset: earliestDueDateOffset,
            isWeekDay: isWeekDay,
            isDuringHoliday: isDuringHoliday
        };

        // Default due date time as date (4:30 pm)
        function defaultDueDateTime() {
            return moment().hour(16).minute(30).toDate();
        }

        // Earliest due date from now as date
        function earliestDueDateFromNow() {
            return moment().add(earliestDueDateOffset(), 'days').toDate();
        }

        // Earliest due date offset (in three days, not including weekends)
        function earliestDueDateOffset() {
            switch (moment().day()) {
            case 0: return 4; // Sunday, add 4 days
            case 3: return 5; // Wednesday, add 5 days
            case 4: return 5; // Thursday, add 5 days
            case 5: return 5; // Friday, add 5 days
            case 6: return 5; // Saturday, add 4 days
            default: return 3; // Weekdays, add 3 days
            }
        }

        function isWeekDay(date) {
            return ([1,2,3,4,5].indexOf(date.getDay()) !== -1);
        }

        function isDuringHoliday(holiday, date) {

            var testDate = new Date(moment(date).startOf('day')); 
            var startDate = new Date(moment.utc(holiday.startDate).format('LL'));
            var endDate = new Date(moment.utc(holiday.endDate).format('LL'));

            return startDate <= testDate && testDate <= endDate;
        }

        return service;
    }
})();
