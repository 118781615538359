(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraisalFirmRegionDetailController', AppraisalFirmRegionDetailController);

    AppraisalFirmRegionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'AppraisalFirmRegion', 'AppraisalFirm', 'AppraisalFirmLocation'];

    function AppraisalFirmRegionDetailController($scope, $rootScope, $stateParams, entity, AppraisalFirmRegion, AppraisalFirm, AppraisalFirmLocation) {
        var vm = this;

        vm.appraisalFirmRegion = entity;

        var unsubscribe = $rootScope.$on('valueconnectApp:appraisalFirmRegionUpdate', function(event, result) {
            vm.appraisalFirmRegion = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
