(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('HubspotLeadDeleteController',HubspotLeadDeleteController);

    HubspotLeadDeleteController.$inject = ['$uibModalInstance', 'entity', 'HubspotLead'];

    function HubspotLeadDeleteController($uibModalInstance, entity, HubspotLead) {
        var vm = this;

        vm.hubspotLead = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            HubspotLead.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
