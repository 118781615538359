(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('AppraisalNote', AppraisalNote);

    AppraisalNote.$inject = ['$resource'];

    function AppraisalNote ($resource) {
        var resourceUrl =  'api/appraisal-notes/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },

            'getNotesForAppraisal' : {method: 'GET', url:'api/notes-for-appraisal/:id', isArray: true },

            'getMessagesForAppraisal' : {method: 'GET', url:'api/messages-for-appraisal/:id', isArray: true },

            'getCCRMessages': { method:'GET', url: 'api/messages-for-ccr', isArray: true },

            'markAsRead': { method:'POST', url: 'api/appraisal-notes/:id/mark-as-read', params:{ id:'@id' } }

        });
    }
})();
