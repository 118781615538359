(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller("CalendarController", CalendarController);

    CalendarController.$inject = ['$state', '$rootScope', '$scope', '$timeout', 'Principal', 'BlackoutPeriod', 'AppraisalOrder', 'DateUtils'];

    function CalendarController($state, $rootScope, $scope, $timeout, Principal, BlackoutPeriod, AppraisalOrder, DateUtils) {

        var vm = this;
        vm.eventSources = [];
        vm.uiConfig = {
            calendar:{
                header:{
                    left: 'title',
                    right: 'today prev,next'
                }
            }
        };

        // TODO: this is so wrong
        $timeout(function() {
            angular.element('.calendar').fullCalendar('render');
        });

        Principal.identity().then(init);
        function init(account) {
            // Add blackout periods to the calendar
            BlackoutPeriod.query().$promise.then(
                function(data){
                    vm.eventSources.push(data.map(function(blackoutPeriod) {
                        return  {
                            title: 'away',
                            start: blackoutPeriod.startDate,
                            end: blackoutPeriod.endDate,
                            allDay: true
                        };
                    }));
                }
            );

            // Add appraisal orders to the calendar
            // TODO: This should only retrieve orders for the current month selected in the calendar
            // or we are going to see big performance hits as more orders enter the system
            AppraisalOrder.getByState({states: ["PENDING_SCHEDULE_VISIT", "PENDING_VISIT", "PENDING_COSIGN", "PENDING_SUBMISSION", "REPORT_SUBMITTED", "RESUBMISSION_REQUIRED"]}).$promise.then(function(data) {
                // Create an event for each due date and scheduled
                // visit on each report on the order
                var events = [];
                data.forEach(function(order) {
                    var orderUrl = $state.href('appraisal-order-detail', {id:order.id});

                    events.push({
                        title: 'Appraisal Due (id #' + order.id + ')',
                        start: DateUtils.convertUtcDateToLocalDate(order.appraisalDueDate),
                        color: '#067C2C', // TODO: use a class instead
                        url: orderUrl
                    });

                    if (order.visitScheduleDate) {
                        events.push({
                            title: 'Visit Scheduled (id #' + order.id + ')',
                            start: DateUtils.convertUtcDateToLocalDate(order.visitScheduleDate),
                            color: '#067C2C', // TODO: use a class instead
                            url: orderUrl
                        });
                    }
                });

                vm.eventSources.push(events);
            });
        }
        $scope.isOpen = false;
        $scope.toggleView = function() {
            $scope.isOpen = !$scope.isOpen;
        }
        // var unsubscribe = $rootScope.$on('authenticationSuccess', init);
        // $scope.$on('$destroy', unsubscribe());
    }
})();
