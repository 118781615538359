(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('QuoteDialogController', QuoteDialogController);

    QuoteDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$mdDialog', '$q', 'order', 'quote', 'Quote', 'RegionalProduct'];

    function QuoteDialogController ($timeout, $scope, $stateParams, $mdDialog, $q, order, quote, Quote, RegionalProduct) {
        // Initialize model
        var vm = this;
        init(quote);
        vm.order = order;
        vm.regionalProducts = RegionalProduct.query({regionId: quote.regionId, size: 9999, sort: 'productType'});

        // Define controller methods
        vm.save = save;
        vm.finalize = finalize;
        vm.clear = close;
        vm.addLineItem = addLineItem;
        vm.subTotal = subTotal;
        vm.taxTotal = taxTotal;
        vm.grandTotal = grandTotal;

        // Initialize the model with a new quote
        function init(quote) {
            vm.quote = quote;
            vm.quote.lineItems.map(initLineItem);
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        /**
         * Save the quote, and optionally finalize it. On success, reinitialzie the form with the
         * updated quote.
         * @param  {boolean} finalize Optional. If true, the quote will be finalized.
         * @return {promise}          Promise that is resolved with the result of the service request
         */
        function save (finalize) {

            // Set line item price as unit cost (since total is recalculated on backend)
            var tempQuote = vm.quote;
            tempQuote.lineItems.map(function(item) {
                item.price = item.$unitCost();
            });

            vm.isSaving = true;
            return Quote.update({finalize: finalize}, tempQuote).$promise.then(function(result) {
                vm.quoteUpdated = true;
                init(result);
                $scope.$emit('valueconnectApp:quoteUpdate', result);
                return result;
            }).finally(function() {
                vm.isSaving = false;
            });
        }

        /**
         * Save and finalize the quote, then close the dialog on success
         * @return {Promise} Promise that is resolved with the result of the service request
         */
        function finalize() {
            return save(true).then(function(result) {
                $mdDialog.hide(result);
            });
        }

        /**
         * If the quote has been updated, resolve the dialog with the updated quote,
         * otherwise cancel the dialog
         */
        function close() {
            if(vm.quoteUpdated) $mdDialog.hide(vm.quote);
            else $mdDialog.cancel();
        }

        function initLineItem(lineItem) {
            lineItem.isManualMarkup = false;

            lineItem.setIsManualMarkup = function(isManualMarkup) {
                lineItem.isManualMarkup = isManualMarkup;
            };

            lineItem.$unitCost = function() {
                return lineItem.$price() / lineItem.quantity;
            };

            lineItem.$markupMultiplier = function() {
                return (parseFloat(lineItem.markupPercent) + 100) / 100;
            };

            // calc initial markup percent, this number won't be auto-calculated after init
            if(lineItem.appraiserPrice !== null && lineItem.price !== null && lineItem.appraiserPrice > 0 && lineItem.price > 0) {
                lineItem.markupPercent = Math.max(parseFloat(((lineItem.price * 100) / lineItem.appraiserPrice - 100).toFixed(3)), 0);
            } else {
                lineItem.markupPercent = 0;
            }

            lineItem.$price = function() {
                if(!lineItem.isManualMarkup) {
                    if(!angular.isNumber(lineItem.price)) return 0;
                    return lineItem.price;
                } else {
                    // Make sure the price is calculated individually, then multiplied by the quantity. That way when you're figuing
                    // out the price per unit you'll end up with a valid $ amount (not something like $XX.XX345)
                    return parseFloat((lineItem.appraiserPrice * lineItem.$markupMultiplier()).toFixed(2)) * lineItem.quantity;
                }
            };

            lineItem.$tax = function() {
                if(!lineItem.regionalProduct) return null;
                return parseFloat((lineItem.$price() * (lineItem.regionalProduct.taxRate - 1)).toFixed(2));
            };

            lineItem.$total = function() {
                return lineItem.$price() + lineItem.$tax();
            };

            return lineItem;
        }

        function addLineItem() {
            vm.quote.lineItems.push(initLineItem({
                quantity: 1
            }));
        }

        function subTotal() {
            return vm.quote.lineItems
                .map(function(item) { return item.$price(); })
                .reduce(sum, 0);
        }

        function taxTotal() {
            return vm.quote.lineItems
                .map(function(item) { return item.$tax(); })
                .reduce(sum, 0);
        }

        function grandTotal() {
            return vm.quote.lineItems
                .map(function(item) { return item.$total(); })
                .reduce(sum, 0);
        }

        function sum(a,b) {
            var aIsNum = angular.isNumber(a), bIsNum = angular.isNumber(b);
            if(!aIsNum && !bIsNum) return null;
            else if(!aIsNum) return b;
            else if(!bIsNum) return a;
            else return a + b;
        }
    }
})();
