(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('form', {
            abstract: true,
            parent: 'appraisal-report.edit-forms',
            params: {
                formSection: {
                    value: null,
                    squash: true
                }
            },
            resolve: {
                formDefinitions: ['$stateParams', 'AppraisalReport', function($stateParams, AppraisalReport) {
                    return AppraisalReport.getFormDefinitions({id: $stateParams.id}).$promise;
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', 'report', function ($translate, $translatePartialLoader, report) {
                    // Load the translation file for each existing and eligible form in the report
                    report.eligibleForms
                        .concat(report.forms.map(function(form) { return form.fieldName; }))
                        .filter(function(value, index, self) { return self.indexOf(value) === index; })
                        .map(function(formName) { return formName.charAt(0).toUpperCase() + formName.slice(1); })
                        .forEach(function(formNameCapitalized) {
                            $translatePartialLoader.addPart('form' + formNameCapitalized);
                        });

                    $translatePartialLoader.addPart('appraisalOrder');
                    $translatePartialLoader.addPart('appraisalReportForm');
                    $translatePartialLoader.addPart('formType');
                    $translatePartialLoader.addPart('file');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('address');
                    $translatePartialLoader.addPart('contact');
                    $translatePartialLoader.addPart('enum');
                    $translatePartialLoader.addPart('enumReportSource');
                    $translatePartialLoader.addPart('enumOccupiedBy');
                    $translatePartialLoader.addPart('reportValue');
                    $translatePartialLoader.addPart('valueApproach');
                    $translatePartialLoader.addPart('template');
                    return $translate.refresh();
                }]
            }
        })
        .state('form.builder', {
            parent: 'form',
            url: '/builder?form&section&field',
            reloadOnSearch: false,
            views: {
                'reportContent@appraisal-report': {
                    templateUrl: 'app/report-builder/dynamic-form.html',
                    controller: 'DynamicFormController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                order: ['report', 'AppraisalOrder', function(report, AppraisalOrder) {
                    return AppraisalOrder.getDetailed({id: report.appraisalOrderId}).$promise;
                }],
                // If no formName parameter is specified, use the first form on the report
                currentForm: ['$stateParams', 'report', function($stateParams, report) {
                    if (!$stateParams.form && report.forms.length) {
                        angular.forEach(report.forms, function(value) {
                            if (value.active && !$stateParams.form) {
                                $stateParams.form = value.fieldName;
                            }
                        });
                    }
                    return $stateParams.form || null;
                }],
                formData: ['$q', 'currentForm', 'report', 'DynamicForm', function($q, currentForm, report, DynamicForm) {
                    return currentForm ?
                        DynamicForm.get({ reportId: report.id, formName: currentForm}).$promise :
                        $q.when(undefined);
                }]
            }
        });
    }
})();
