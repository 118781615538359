(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraisalOrderChangeRequestAppraiserDialogController', AppraisalOrderChangeRequestAppraiserDialogController);

    AppraisalOrderChangeRequestAppraiserDialogController.$inject = ['$timeout', '$scope', '$state', '$stateParams', '$mdDialog', 'entity', 'AppraisalOrder'
        , 'AppraisalOrderChangeRequest'];

    function AppraisalOrderChangeRequestAppraiserDialogController ($timeout, $scope, $state, $stateParams, $mdDialog, entity, AppraisalOrder
        , AppraisalOrderChangeRequest) {
        var vm = this;


        if (entity.appraisalOrderChangeRequest) {
            AppraisalOrderChangeRequest.get({id: entity.appraisalOrderChangeRequest.id}, function(data) {
                vm.appraisalOrderChangeRequest = data;

                vm.appraisalDueDate = new Date(entity.appraisalOrder.activeReport.appraisalDueDate);

                vm.dueDateOpen = false;
                vm.conditionFinancingDateOpen = false;

                vm.dueDatePickerOptions = {
                    showWeeks: false,
                    minDate: new Date() < vm.appraisalDueDate ? entity.appraisalOrder.activeReport.appraisalDueDate : new Date(),
                    dateDisabled: function(args) {
                        var day = args.date.getDay();
                        return day == 0 || day == 6;
                    }
                };

                vm.minDate = new Date() < vm.appraisalDueDate ? vm.appraisalDueDate : new Date();
                vm.readOnly = vm.appraisalOrderChangeRequest && vm.appraisalOrderChangeRequest.id > 0;
                if (vm.appraisalOrderChangeRequest && vm.appraisalOrderChangeRequest.proposedDueDate) {
                    vm.appraisalOrderChangeRequest.proposedDueDate = new Date(vm.appraisalOrderChangeRequest.proposedDueDate);
                }

                vm.loaded = true;
            });
        } else {
            vm.loaded = true;
        }
        vm.changeDialog = entity;
        vm.clear = clear;

        vm.save = save;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;





        vm.dateEnabled = function(date) {
            var day = date.getDay();
            return day != 0 && day != 6;
        }

        vm.activeReport = null;
        $(entity.appraisalOrder.appraisalReports).each(function(i, rep) {
           if (entity.appraisalOrder.activeReport.id == rep.id) {
               vm.activeReport = rep;
               return;
           } 
        });


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $mdDialog.cancel('cancel');
        }

        function save () {
            vm.isSaving = true;
            vm.appraisalOrderChangeRequest.appraisalOrderId = vm.changeDialog.appraisalOrder.id;

            AppraisalOrderChangeRequest.update(vm.appraisalOrderChangeRequest, function(data) {
                $mdDialog.hide(data);

                var alert = $mdDialog.alert()
                      .title('Change request sent')
                      .textContent('Your changes have been sent for review.')
                      .targetEvent(null)
                      .ok('OK');
                $mdDialog.show(alert).then(function() {
                    $state.reload();
                });
               
            }, function(data) {
                console.log(data);
            });
        }

        function onSaveSuccess (result) {
           // $scope.$emit('valueconnectApp:addressUpdate', result);
            $mdDialog.hide(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.proposedDueDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }


    }
})();
