(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('QuoteSplit', QuoteSplit);

    QuoteSplit.$inject = ['$resource'];

    function QuoteSplit ($resource) {
        var resourceUrl =  'api/quote-splits/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method: 'PUT' },
            'getByPaymentKey': { method: 'GET', url: 'api/quote-splits/by-payment-key/:paymentKey' },
            'resendPaymentRequest': { method: 'PUT', url: 'api/quote-splits/:quoteId/resend-payment-request' },
            'generateQuoteSplitPaymentLink': { method: 'POST', url:'api/quote-splits/:id/generate-stripe-payment-link' }
        });
    }
})();
