(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('CityDialogController', CityDialogController);

    CityDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'City', 'CitySearch', 'Region'];

    function CityDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, City, CitySearch, Region) {
        var vm = this;

        vm.city = entity;
        vm.clear = clear;
        vm.save = save;
        vm.regions = Region.query({ size: 1000, sort: 'name' });

        vm.citiesPromise = $q.when([]);
        vm.searchCities = searchCities;
        vm.matchCities = matchCities;


        console.log(vm.city);

        // rebuild city object from model for autocomplete
        if (vm.city && vm.city.metropolisId) {
            vm.city.metropolis = {id: vm.city.metropolisId, name: vm.city.metropolisName};
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.city.id !== null) {
                City.update(vm.city, onSaveSuccess, onSaveError);
            } else {
                City.save(vm.city, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('valueconnectApp:cityUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


// Metropolis Search Methods

        /**
        * City autocomplete filter method
        */
        function matchCities(searchText) {
            return vm.citiesPromise.then(function(cities) {
                var searchRegex = new RegExp(searchText, 'i');
                return cities.filter(function(city) {
                    return searchRegex.test(city.name);
                });
            });
        }

        /**
        * City autocomplete list query method
        */
        function searchCities(searchText) {
            var query = buildCityQuery(searchText);
            vm.citiesPromise = CitySearch.query(query).$promise;
        }

        /**
        * Build city query based on search text and province
        */
        function buildCityQuery(searchText) {
            var query = { size: 500, sort: "name,asc" };
            if (searchText) {
                query['query'] = searchText;
            }
            if (vm.city && vm.city.regionId) {
                var region = vm.regions.find(function(region) { return region.id === vm.city.regionId; });
                query['provinceId'] = region.provinceId;
                query['size'] = 2000;
            }
            return query;
        }

        /**
        * Watch for city selection
        */
        $scope.$watch('vm.city.metropolis',function (newVal,oldVal) {
            vm.city.metropolisId = vm.city.metropolis ? vm.city.metropolis.id : null;
            vm.city.metropolisName = vm.city.metropolis ? vm.city.metropolis.name : null;
        });


        $scope.$watch('vm.city.regionId',function(newVal,oldVal) {
            if (newVal) {
                if (vm.regions) {
                    var region = vm.regions.find(function (region) {
                        return region.id === vm.city.regionId;
                    });
                    vm.city.regionName = region.name;
                }
            }
            if (newVal !== oldVal) {
                vm.city.metropolis = null;
            }
        });

    }
})();
