(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('NewFeatureDetailController', NewFeatureDetailController);

    NewFeatureDetailController.$inject = ['entity'];

    function NewFeatureDetailController(entity) {
        var vm = this;

        vm.feature = entity;
    }
})();
