(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('BrokerageRegionDialogController', BrokerageRegionDialogController);

    BrokerageRegionDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'BrokerageRegion', 'Brokerage', 'BrokerageLocation'];

    function BrokerageRegionDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, BrokerageRegion, Brokerage, BrokerageLocation) {
        var vm = this;

        vm.brokerageRegion = entity;
        vm.clear = clear;
        vm.save = save;

        // Automatically set the brokerage if there is only one option
        vm.brokerages = Brokerage.query({size:2000}, function(data){
            vm.singleBrokerage = data.length == 1;
            if (vm.singleBrokerage) {
                vm.brokerageRegion.brokerageId = vm.brokerageRegion.brokerageId || data[0].id;
            }
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.brokerageRegion.id !== null) {
                BrokerageRegion.update(vm.brokerageRegion, onSaveSuccess, onSaveError);
            } else {
                BrokerageRegion.save(vm.brokerageRegion, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('valueconnectApp:brokerageRegionUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
