(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .controller('AppraisalOrderRedirectDialogController', AppraisalOrderRedirectDialogController);

    AppraisalOrderRedirectDialogController.$inject = ['$mdDialog', '$scope', 'appraisalOrder', 'AppraisalOrder', 'Lender', 'LenderContact', 'AppraisalOrderContact', 'AppraisalOrderModal', '$filter'];

    function AppraisalOrderRedirectDialogController ($mdDialog, $scope, appraisalOrder, AppraisalOrder, Lender, LenderContact, AppraisalOrderContact, AppraisalOrderModal, $filter) {
        var vm = this;
        vm.appraisalOrder = appraisalOrder;
        vm.hasLenderContacts = false;
        if(vm.appraisalOrder.lender) {
            LenderContact.getbylender({id: vm.appraisalOrder.lender.id}).$promise.then(function(contacts) {
                vm.hasLenderContacts = true;
                contacts.forEach(function(contact) {
                    vm.lenderContacts.push(contact);
                });
            });
        }
        vm.lenders = Lender.query({size: 2000});

        vm.changeLender = changeLender;
        vm.save = save;
        vm.cancel = cancel;
        vm.matchLender = matchLender;
        vm.matchLenderContact = matchLenderContact;
        vm.newContact = false;
        vm.lenderContacts = [];
        vm.appraisalOrder.appraisalOrderContacts = AppraisalOrderContact.getcontactbyappraisalorderid({id: vm.appraisalOrder.id});
        vm.appraisalOrderManage = AppraisalOrder.getManage({id:vm.appraisalOrder.id}).$promise.then( function(result) {
            // Assign coAgentId to appraisal order after we get it from the manageDTO.
            vm.appraisalOrder.coAgentId = result.coAgentId;
            vm.appraisalOrder.lenderUnderwriterContactId = result.lenderUnderwriterContactId;
        });
        vm.removeNewContact = removeNewContact;
        vm.createNewContact = createNewContact;

        function removeNewContact() {
            vm.newContact = false;
            vm.appraisalOrder.lenderUnderwriterContact = null;
        }

        function createNewContact() {
            vm.newContact = true;
            vm.appraisalOrder.lenderUnderwriterContact = null;
            vm.lenderUnderwriterContactSearchText = "";
        }


        /**
         * Called when the selected lender is changed. Update the lenderContacts
         * lists with the contacts for the current lender. If no lender is selected, both lists
         * are cleared.
         */
        function changeLender() {
            if (!vm.appraisalOrder.lender || !vm.appraisalOrder.lender.id) return;
            LenderContact.getbylender({id: vm.appraisalOrder.lender.id}).$promise.then(function(contacts) {
                vm.hasLenderContacts = true;
                contacts.$promise.then(function(contacts){
                    contacts.forEach(function(contact) {
                        vm.lenderContacts.push(contact);
                    });
                });
            });
        }

        vm.triggerModalOpenLenderContact = function() {
            AppraisalOrderModal.openLenderContactDialog(vm.appraisalOrder.lender.id, true).result.then(function(contact){
                vm.lenderContacts.push(contact);
                vm.appraisalOrder.lenderUnderwriterContactId = contact.id;
            });
        };

        function matchLender(searchText) {
        	var searchRegex = new RegExp(searchText, 'i');
          return vm.lenders.$promise.then(function(lenders) {
              return lenders.filter(function(lenders) {
                  return searchRegex.test(lenders.lenderName);
              });
          });
        }

        function matchLenderContact(searchText){
        	var searchRegex = new RegExp(searchText, 'i');
        	if(vm.lenderContacts.length > 0) {
        		return vm.lenderContacts.filter(function(lenderContact){
        			return searchRegex.test(lenderContact.contact.firstName + " " + lenderContact.contact.lastName);
        		});
        	}
        	return [];

        }

        function save() {
            AppraisalOrder.redirectOrder({
                appraisalOrderId: vm.appraisalOrder.id,
                lenderId: vm.appraisalOrder.lender.id,
                loanReferenceNumber: vm.appraisalOrder.loanReferenceNumber,
                loanAmount: vm.appraisalOrder.loanAmount,
                lenderUnderwriterContactId: vm.appraisalOrder.lenderUnderwriterContact.id,
                lenderUnderwriterContact: vm.appraisalOrder.lenderUnderwriterContact,
                reason: vm.reason
            }, function(response) {
                $mdDialog.hide(response);
            }, function(response) {
                $mdDialog.cancel({ error: "An error occurred while redirecting the order."});
            });
        }

        function cancel() {
            $mdDialog.cancel();
        }

        $scope.$watch('vm.appraisalOrder.lender' , function(lender) {
            if(lender == null) {
                vm.appraisalOrder.lenderUnderwriterContact = null;
                vm.lenderUnderwriterContactSearchText = "";
                vm.newContact = false;
            }
        });
    }
})();
