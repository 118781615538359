(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .directive('vcAddressFormV2', ['$q', 'Country', 'Province', 'CitySearch', 'GoogleVC', vcAddressFormV2]);

    function vcAddressFormV2 ($q, Country, Province, CitySearch, GoogleVC) {
        var directive = {
            restrict: 'E',
            link:  postLink,
            transclude: true,
            templateUrl: "app/components/form/address-form/vc-address-form-v2.template.html",
            scope: {
                address: "=addressEntity",
                quoteFieldsDisabled: "<quoteFieldsDisabled"
            }
        };

        return directive;

        function postLink(scope, elm, attrs) {
            scope.lookupAddress = lookupAddress;

            scope.provinces = Province.query();
            scope.countries = Country.query();

            scope.citiesPromise = $q.when([]);
            scope.searchCities = searchCities;
            scope.matchCities = matchCities;

            // rebuild city object from model for autocomplete
            if (scope.address && scope.address.cityId) {
                scope.address.city = {id: scope.address.cityId, name: scope.address.cityName};
            }

            if (scope.address && scope.address.address1) {
                streetSplit();
            }

            function streetMerge() {
                if (scope.address.number && scope.address.name && scope.address.type){
                    scope.address.address1 = scope.address.number + " " + scope.address.name + " " + scope.address.type;
                }

                if (scope.address.direction) scope.address.address1 += (" " + scope.address.direction);
            }

            function streetSplit() {
                var streetArray = scope.address.address1.split(" ");
                scope.address.number = streetArray[0];
                scope.address.name = streetArray[1];
                scope.address.type = streetArray[2];
                if (streetArray[3]) scope.address.direction = streetArray[3];
            }

            /**
             * Validate that city has been selected
             */
            function checkSelected() {
                if (scope.addressForm.city) {
                    if (scope.address.city) {
                        scope.addressForm.city.$setValidity("nonechosen", true);
                    } else {
                        scope.addressForm.city.$setValidity("nonechosen", false);
                    }
                }
            }

            /**
             * Google lookup address from postal code
             */
            function lookupAddress() {
                if (!scope.quoteFieldsDisabled) {
                    GoogleVC.lookupAddress(scope.address, "CA").then( function(googleLookupResults) {
                        return scope.citiesPromise.then(function(cities) {
                            var googleLookupCity = GoogleVC.setAddress(scope.address, googleLookupResults, scope.provinces, cities);
                            if (googleLookupCity && !scope.address.city) { // Perform more specific query using google results to get city if not found
                                searchCities(googleLookupCity.long_name).then(function () {
                                    lookupAddress();
                                });
                            }
                        });
                    });
                }
            }

            /**
             * City autocomplete filter method
             */
            function matchCities(searchText) {
                return scope.citiesPromise.then(function(cities) {
                    var searchRegex = new RegExp(searchText, 'i');
                    return cities.filter(function(city) {
                        return searchRegex.test(city.name);
                    });
                });
            }

            /**
             * City autocomplete list query method
             */
            function searchCities(searchText) {
                checkSelected();
                var query = buildCityQuery(searchText);
                scope.citiesPromise = CitySearch.query(query).$promise;
                return scope.citiesPromise;
            }

            /**
             * Build city query based on search text and province
             */
            function buildCityQuery(searchText) {
                var query = { size: 500, sort: "name,asc", hideDisabled: true };
                if (searchText) {
                    query['query'] = searchText;
                }
                if (scope.address && scope.address.provinceId) {
                    query['provinceId'] = scope.address.provinceId;
                    query['size'] = 2000;
                }
                return query;
            }

            /**
             * Watch for province selection and clear city/reset list if needed
             */
            scope.$watch(function() { return scope.address.provinceId; }, function(newVal, oldVal) {
                if (newVal !== oldVal && oldVal) {
                    scope.address.city = null;
                }

                if (!scope.address.city) {
                    searchCities();
                }
            });

            /**
             * Watch for city selection
             */
            scope.$watch(function() { return scope.address.city; }, function() {
                if (scope.address && scope.address.city && !scope.address.provinceId) {
                    scope.address.provinceId = scope.address.city.provinceId;
                }

                scope.address.cityId = scope.address.city ? scope.address.city.id : null;
                scope.address.cityName = scope.address.city ? scope.address.city.name : null;

                if (scope.address.provinceId > 0) {
                    scope.address.province = scope.provinces.filter(p => p.id === scope.address.provinceId);
                }

                checkSelected();
            });

            scope.$watch(function() {return scope.address.name;}, function() {
                streetMerge();
            });
            scope.$watch(function() {return scope.address.number;}, function() {
                streetMerge();
            });
            scope.$watch(function() {return scope.address.type;}, function() {
                streetMerge();
            });
            scope.$watch(function() {return scope.address.direction;}, function() {
                streetMerge();
            });

        }
    }
})();
