(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('Region', Region);

    Region.$inject = ['$resource'];

    function Region ($resource) {
        var resourceUrl =  'api/regions/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'queryForCCR' : {method: 'GET', url:'api/regions/ccr', isArray: true },
            'countForCCR' : {method: 'GET', url:'api/regions/ccr/count'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    return data ? angular.fromJson(data) : data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
