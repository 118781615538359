(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('HomePrivacyController', HomePrivacyController);

    HomePrivacyController.$inject = ['$log', '$rootScope', '$scope', 'Principal', 'JhiLanguageService'];

    function HomePrivacyController ($log, $rootScope, $scope, Principal, languageService) {

        var vm = this;

        languageService.getCurrent().then(
            function(language) {
                vm.language = language;
            });

        $rootScope.$on('$translateChangeSuccess', function(event, current, previous) {
            vm.language = current.language;
        });

    }
})();
