(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('BlackoutPeriodController', BlackoutPeriodController);

    BlackoutPeriodController.$inject = ['$scope', '$state', 'BlackoutPeriod', 'BlackoutPeriodSearch'];

    function BlackoutPeriodController ($scope, $state, BlackoutPeriod, BlackoutPeriodSearch) {
        var vm = this;
        
        vm.blackoutPeriods = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            BlackoutPeriod.query(function(result) {
                vm.blackoutPeriods = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            BlackoutPeriodSearch.query({query: vm.searchQuery}, function(result) {
                vm.blackoutPeriods = result;
            });
        }    }
})();
