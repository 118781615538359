(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('SearchbarController', SearchbarController);

    SearchbarController.$inject = ['$rootScope', '$scope', '$state', 'Auth', 'Principal', 'ProfileService', 'LoginService'];

    function SearchbarController ($rootScope, $scope, $state, Auth, Principal, ProfileService, LoginService) {
        var vm = this;
        vm.logout = logout;

        $scope.isAuthenticated = Principal.isAuthenticated;
        $scope.account = null;
        getUser();


        vm.globalSearch = function() {
            if (typeof(vm.searchPhrase) === "string" && vm.searchPhrase.length > 0) {
                gtag('event', "global_search", {searchPhrase: vm.searchPhrase});

                $state.go("home-search", {'phrase':vm.searchPhrase});
            }
        };

        $rootScope.$on('authenticationSuccess', function() {
           getUser();
        });

        function getUser() {
            Principal.identity().then(function(account) {
                $scope.account = account;
            });
        }

        function logout() {
            Auth.logout();
            $state.go('login');
        }
    }
})();
