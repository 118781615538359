(function() {
    'use strict';

    angular
    .module('valueconnectApp')
    .controller('ReportController', ReportController);

    ReportController.$inject = ['$scope', '$state', '$stateParams', 'ParseLinks', 'AlertService', 'FileSaver', 'moment', 'Integration', 'LenderIntegrationBlacklist',
        // Parameters below are injected via state resolve
        'isAdmin', 'isCCR', 'isReviewer', 'isPrincipal', 'isFinancialTeam', 'params', 'pagingParams', 'numCols', 'isCustomReport', 'queryFunction', 'exportFunction', 'filterDataSources', 'Region', 'Quote', '$mdDialog', 'requiredApis'];

    function ReportController($scope, $state, $stateParams, ParseLinks, AlertService, FileSaver, moment, Integration, LenderIntegrationBlacklist,
        isAdmin, isCCR, isReviewer, isPrincipal, isFinancialTeam, params, pagingParams, numCols, isCustomReport, queryFunction, exportFunction, filterDataSources, Region, Quote, $mdDialog, requiredApis) {
        var vm = this;
        var defaultSort = $state.current.data.sort;

        // Expose resolved variables to view
        vm.requiredApis = requiredApis;
        vm.isAdmin = isAdmin;
        vm.isCCR = isCCR;
        vm.isReviewer = isReviewer;
        vm.isPrincipal = isPrincipal;
        vm.isVC = isAdmin || isCCR || isReviewer;
        vm.isFinancialTeam = isFinancialTeam;
        vm.isAdminOrCCR = isAdmin || isCCR;
        vm.params = params;
        vm.pagingParams = pagingParams;
        vm.numCols = numCols;
        vm.filterData = {};
        vm.isDataSorted = {};
        vm.isCustomReport = isCustomReport;

        vm.updatePaidAppraiser = updatePaidAppraiser;
        vm.updateAccountsPayablePaidAppraiser = updateAccountsPayablePaidAppraiser;
        vm.updatePaidAppraiserAmount = updatePaidAppraiserAmount;

        // Define methods available to view
        vm.loadFilterData = loadFilterData;
        vm.search = search;
        vm.transition = transition;
        vm.exportData = exportData;
        vm.reset = reset;
        vm.sortLoadedData = sortLoadedData;

        vm.displayAppraisers = displayAppraisers;

        vm.params.year = new Date().getFullYear();

        vm.clearArray = clearArray;

        fetchRequiredData(vm.requiredApis);


        //loop through requiredApis array and fetch data from required endpoints
        function fetchRequiredData(requiredApis) {
            requiredApis.forEach(
                function(requiredApi) {
                    loadFilterData(requiredApi);
                }
            );
        }

        //only watch provinces when both provinces and regions are displayed on the page
        if (requiredApis.includes('provinces') && requiredApis.includes('regions')) {
            $scope.$watch('vm.params.provinceId', function() {
                if (vm.params.provinceId) {
                    vm.filterData['regions'] = Region.query({size:2000,provinceId:vm.params.provinceId, sort:'name,asc'});
                }
            });
        }

        // Load initial report data from server
        if (!vm.isCustomReport) search();

        function clearArray(arr) {
            arr.splice(0,arr.length);
        }

        /**
         * Load results using the current controller model
         * @return {Promise} A promise, resolved with the returned results
         */
        function search() {
            // Execute query; reset results object
            if (!vm.isCustomReport) {
                vm.report = queryFunction(getParams(), function(data, headers) {
                    vm.results = angular.isArray(data) ? data : data.results;

                    // Extract paging information from result
                    if (headers('link')) vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                });
            } else {
                vm.report = queryFunction(vm.params);
            }
            return vm.report.$promise;
        }

        /**
         * Send request to server to generate and download an export of this report using the
         * current filters, then open a dialog to save the downloaded file.
         * @return {Promise} A promise that is resolved when the result of FileSaver::saveAs
         */
        function exportData() {
            return exportFunction(getParams()).$promise.then(function(response) {
                return FileSaver.saveAs(response.file, $state.current.name.replace(/^report./,'') + '.xls');
            });
        }

        /**
         * Update params object to match filters/paging parameters then return it
         * @return {Object} The updated parameters
         */
        function getParams() {
            // Set maxDate to the end of the day, if specified
            if (vm.params.maxDate) {
                vm.params.maxDate = moment(vm.params.maxDate).endOf('day').toDate();
            }

            vm.params.showAll = vm.params.showAll ? true : false;
            vm.params.hidePaidInFull = vm.params.hidePaidInFull ? true : false;

            // Generate paging parameter values
            vm.params.page =  vm.pagingParams.page - 1;
            vm.params.size =  vm.pagingParams.itemsPerPage;
            vm.params.sort =  getSort();
            return vm.params;
        }

        /**
         * Generate the 'sort' parameter to be sent to the server or used as a query parameter
         * for the state
         * @return {String}
         */
        function getSort() {
            return vm.pagingParams.predicate + ',' + (vm.pagingParams.ascending ? 'asc' : 'desc');
        }

        /**
         * Perform search then transition state. Squash parameters that are equal to their defaults.
         * @return {Promise} Promise resolved when the state transition completes
         */
        function transition() {
            search().then(function() {
                $stateParams.page = vm.pagingParams.page === 1 ? null : vm.pagingParams.page;
                $stateParams.sort = getSort() === (defaultSort+',asc') ? null : getSort();
                return $state.transitionTo($state.$current, $stateParams);
            });
        }

        function reset() {
            // TODO: we could do this without reloading the state
            $state.go($state.current, {}, {reload: true});
        }

        /**
         * Re-load (or initialize) the filter data for the specified key
         * @param  {String} key The property name for the filter
         */
        function loadFilterData(key) {
            if (vm.filterData[key] === undefined) {
                if (angular.isFunction(filterDataSources[key])){
                    filterDataSources[key]().$promise.then(function(results) {
                        vm.filterData[key] = results;
                        sortLoadedData(key);
                    });
                } else {
                    vm.filterData[key] = filterDataSources[key];
                }
            }
        }

        function updatePaidAppraiser($event, result) {
            $event.stopPropagation();
            Quote.updatePaidAppraiser({id : result.quoteId, paidAppraiser: !result.paidAppraiser});
        }

        function updateAccountsPayablePaidAppraiser($event, result) {
            $event.stopPropagation();
            Quote.updateAccountsPayablePaidAppraiser({id : result.accountsPayableId, paidAppraiser: !result.paidAppraiser});
        }

        function updatePaidAppraiserAmount(entry, paidInFull) {
            var amount = paidInFull ? entry.feeOwedTotal : entry.feePaidTotal;
            Quote.updatePaidAppraiserAmount({id : entry.quoteId}, {paidAppraiserAmount: amount}).$promise
            .then(function (quote) {
                entry.feePaidTotal = amount;
                AlertService.success("valueconnectApp.quote.paidAppraiserAmount.success", {});
            }).catch(function (err) {
                AlertService.error("valueconnectApp.quote.paidAppraiserAmount.error", {});
            });
        }

        function displayAppraisers(ev, appraisers) {
            if (appraisers.length === 0) return;

            var templateStr =   '<md-dialog aria-label="List dialog">' +
                                '  <md-toolbar><div class="md-toolbar-tools"><h2>Appraisers</h2></div></md-toolbar>' +
                                '  <md-dialog-content>' +
                                '    <md-list>' +
                                '      <md-list-item ng-repeat="user in users" ng-click="closeDialog()" ui-sref="user-management-detail({login:user.login})">' +
                                '        <div class="md-list-item-text" layout="column">' +
                                '          <p><strong>{{user.firstName}} {{user.lastName}}</strong> ({{user.appraisalFirmName}})</p>' +
                                '        </div>' +
                                '      </md-list-item>' +
                                '    </md-list>'+
                                '  </md-dialog-content>' +
                                '</md-dialog>';

            $mdDialog.show({
                parent: ev.parent,
                targetEvent: ev,
                clickOutsideToClose: true,
                template: templateStr,
                locals: { users: appraisers },
                controller: ['$scope', 'users', '$mdDialog', function($scope, users, $mdDialog) {
                    $scope.users = users;
                    $scope.closeDialog = function() {
                        $mdDialog.hide();
                    };
                }]
            });
        }

        function sortLoadedData(key) {
            //check if the data has not already been sorted
            if (vm.isDataSorted[key] === undefined) {
                //call methods to sort data on the basis of the key
                switch (key) {
                    case 'originators':
                        sortData(vm.filterData[key], ['firstName', 'lastName', 'id']);
                        sanitizeData(vm.filterData[key], ['firstName', 'lastName']);
                        break;
                    // Note: Both 'allLenders' and 'lenders' use the exact same sorting logic.
                    case 'allLenders':
                    case 'lenders':
                        var publicLenders = [];
                        var privateLenders = [];
                        //separate public and private lenders using brokerageName
                        vm.filterData[key].forEach(
                            function (item) {
                                item['brokerageName'] === null ? publicLenders.push(item) : privateLenders.push(item);
                            }
                        );
                        sortData(publicLenders, ['lenderName', 'id']);
                    sortData(privateLenders, ['brokerageName', 'lenderName', 'id']);
                    vm.filterData[key] = publicLenders.concat(privateLenders);
                    //no need to sanitize: lender.lender_name is NOT NULL in database
                    //sanitizeData(vm.filterData[key], ['lenderName'])
                    break;
                case 'appraisers':
                    sortData(vm.filterData[key], ['userFullName', 'userId']);
                    //no need to sanitize: server concatenates firstName with lastName to give us userFullName
                    //sanitizeData(vm.filterData[key], ['userFullName'])
                    break;
                case 'ccrs':
                    sortData(vm.filterData[key], ['userFullName', 'userEmail']);
                    //Note #1: server concatenates firstName with lastName to give us userFullName
                    //Note #2: vc_user.user_email can be NULL in the database
                    sanitizeData(vm.filterData[key], ['userEmail']);
                    break;
                case 'appraisalFirms':
                    sortData(vm.filterData[key], ['name', 'id']);
                    //no need to sanitize: appraisal_firm.name is NOT NULL in database
                    //sanitizeData(vm.filterData[key], ['name']);
                    break;
                case 'brokerages':
                    sortData(vm.filterData[key], ['name', 'id']);
                    //no need to sanitize: brokerage.name is NOT NULL in database
                    //sanitizeData(vm.filterData[key], ['name']);
                    break;
                default:
                    return;
                }

                vm.isDataSorted[key] = true;
            }
        }

        //method to sort an array of objects based on object's properties
        //array should be clean and filtered to not encounter inconsistencies
        function sortData(array, properties) {
            if (array) {
                array.sort(customComparator(properties));
            }
        }

        //method to implement multi level sorting based on properties
        function customComparator(properties) {
            return function(a, b) {
                var value = 0;

                //go through properties amongst which we want to achieve multi level sorting
                //keep looping through array of properties until either of the conditions are not met
                // 1. value === 0 -> means both values are same for a and b objects
                // 2. index < properties.length -> means all properties have been checked
                for(var  index = 0; value === 0 && index < properties.length; index++) {
                    //check if the values are numeric or not
                    value = typeof a[properties[index]] !== 'number' || typeof b[properties[index]] !== 'number' ?
                                customCompareString(properties[index])(a, b):
                                customCompareNumeric(properties[index])(a, b);
                }
                return value;
            };
        }

        //custom comparator to compare based on the string values
        function customCompareString(property) {
            return function(a, b) {
                //map null to empty string for comparison
                var left = ( a[property] ) ? a[property].toLowerCase() : "";
                var right = ( b[property] ) ? b[property].toLowerCase() : "";
                if (left.localeCompare(right) > 0) {
                    return 1;
                } else if (left.localeCompare(right) < 0) {
                    return -1;
                } else {
                    return  0;
                }
            };
        }

        //custom comparator to compare based on the numeric values
        function customCompareNumeric(property) {
            return function (a,b){
                var left = a[property];
                var right = b[property];
                if (left - right > 0) {
                    return 1;
                } else if (left - right < 0) {
                    return -1;
                } else {
                    return 0;
                }
            };
        }

        //explicitly replace actual null with String "NULL" for caller supplied members of the object
        function sanitizeData(arr, keys) {
            if (arr) {
                for (var i = 0; i < arr.length; i++) {
                    var obj = arr[i];
                    if (obj) {
                        for (var j = 0; j < keys.length ; j++) {
                            var key = keys[j];
                            if (obj[key] == null) {
                                obj[key] = "NULL";
                            }
                        }
                    }
                }
            }
        }
    }

})();
