(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('AppraisalOrderChangeRequest', AppraisalOrderChangeRequest);

    AppraisalOrderChangeRequest.$inject = ['$resource', 'DateUtils'];

    function AppraisalOrderChangeRequest ($resource, DateUtils) {
        var resourceUrl =  'api/appraisal-order-change-requests/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.proposedDueDate = DateUtils.convertDateTimeFromServer(data.proposedDueDate);
                        data.respondedOn = DateUtils.convertDateTimeFromServer(data.respondedOn);
                    }
                    return data;
                }
            },
            'pending': { method: 'GET', isArray: true, url: 'api/appraisal-order-change-requests/pending'},
            'update': { method:'PUT' },
            'getForAppraisalOrderId': { method: 'GET', isArray: true, url: 'api/appraisal-order-change-requests/for-appraisal-order/:id'},
        });
    }
})();
