(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('DeclinedDetailController', DeclinedDetailController);

    DeclinedDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Declined', 'AppraiserUser', 'AppraisalOrder'];

    function DeclinedDetailController($scope, $rootScope, $stateParams, entity, Declined, AppraiserUser, AppraisalOrder) {
        var vm = this;

        vm.declined = entity;

        var unsubscribe = $rootScope.$on('valueconnectApp:declinedUpdate', function(event, result) {
            vm.declined = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
