(function () {
    'use strict';

    angular
        .module('valueconnectApp')
        .factory('JhiLanguageService', JhiLanguageService);

    JhiLanguageService.$inject = ['$q', '$http', '$translate', 'LANGUAGES', 'tmhDynamicLocale'];

    function JhiLanguageService ($q, $http, $translate, LANGUAGES, tmhDynamicLocale) {
        var service = {
            getAll: getAll,
            getCurrent: getCurrent,
            setLang: setLang
        };

        return service;

        function getAll () {
            var deferred = $q.defer();
            deferred.resolve(LANGUAGES);
            return deferred.promise;
        }

        function getCurrent () {
            var deferred = $q.defer();
            var language = $translate.storage().get('NG_TRANSLATE_LANG_KEY');

            deferred.resolve(language);

            return deferred.promise;
        }

        function setLang (langKey){
            $translate.use(langKey);
            tmhDynamicLocale.set(langKey);
        }
    }
})();
