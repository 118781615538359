(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$log', '$rootScope', '$scope', '$window', 'Principal', '$state', '$timeout', '$translate', 'identity', 'AppraisalOrder', 'AppraisalState', 'News', 'User', 'Region', '$uibModal', 'LocalStorage', 'JhiLanguageService', 'newFeatures'];

    function HomeController ($log, $rootScope, $scope, $window, Principal, $state, $timeout, $translate, identity, AppraisalOrder, AppraisalState, News, User, Region, $uibModal, LocalStorage, JhiLanguageService, newFeatures) {

        var vm = this;
        vm.newFeatures = newFeatures;
        vm.appraisalState = AppraisalState;
        vm.authenticationError = false;
        vm.credentials = {};
        vm.password = null;
        vm.register = register;
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;
        $timeout(function (){angular.element('#username').focus();});
        vm.phrase = $state.params.phrase;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = login;
        vm.openOrders = $state.params.openorders;
        vm.identity = identity;
        vm.isReviewer = Principal.hasAnyAuthority(["ROLE_VC_REVIEWER"]);
        vm.isAdmin = Principal.hasAnyAuthority(["ROLE_ADMIN"]);
        vm.isCCR = Principal.hasAnyAuthority(["ROLE_CUSTOMER_CARE_REPRESENTATIVE"]);
        vm.isAppraiser = Principal.hasAnyAuthority([/^ROLE_(APPRAISER|APPRAISAL).*/i]);
        vm.isOriginatorOrAppraiser = Principal.hasAnyAuthority([/^ROLE_(APPRAISER|APPRAISAL|ORIGINATOR).*/i]);
        vm.isLenderUser = Principal.hasAnyAuthority(["ROLE_LENDER_ADMINISTRATOR"]);
        vm.navigateToPlaceOrder = navigateToPlaceOrder;

        vm.showAll = false;
        vm.showAllChanged = showAllChanged;
        Principal.identity().then(function(identity) {
            if (identity && LocalStorage.HasModel('dashboard_show_all_' + identity.id))
                vm.showAll = LocalStorage.GetModel('dashboard_show_all_' + identity.id);
        });

        if(vm.newFeatures != null && vm.newFeatures.length > 0) {
            showNewFeatures()
        }

        function login () {
            $state.go('login');
        }

        function register () {
            $state.go('register');
        }

        function requestResetPassword () {
            $state.go('requestReset');
        }

        var authSuccessUnsubscribe = $rootScope.$on('authenticationSuccess', getAccount);
        $scope.$on('$destroy', authSuccessUnsubscribe);
        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                if(account === null) {
                    authSuccessUnsubscribe();
                    $state.go('login');
                    return;
                } else {
                    init(account);
                    redirectLenderAdminUser()
                }
            });
        }

        publishNotification();
        function publishNotification() {
            News.getUrgentNotifications(function(data) {
                if(data.length>0) {
                    $uibModal.open({
                        templateUrl: 'app/entities/news/news-notification.html',
                        controller: 'NewsNotificationController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('news');
                                return $translate.refresh();
                            }],
                            entity: function() { return data; }
                        }
                    }).result.then(function() {
                        $state.go('home', null, { reload: true });
                    }, function() {
                        $state.go('home');
                    });
                }
            });
        }

        function init(account) {

            // Perform these actions on every page load.
            vm.account = account;
            vm.isAuthenticated = Principal.isAuthenticated;
            vm.ccr = account.email;

            if (account.authorities.indexOf("ROLE_ADMIN") !== -1) {

                // Set the theme class on every page load.
                $rootScope.themeClass = "admin";

                // Perform these actions only for the dashboard page.
                if ($rootScope.toState.name === "home") {
                    vm.originatorsCount = User.countOriginators();
                    vm.appraisersCount = User.countAppraisers();
                    // vm.ordersCount = AppraisalOrder.getNumberOfAppraiserOrders();
                    vm.outstandingOrdersCount = AppraisalOrder.getNumberOfOutstandingOrders();
                    vm.ccrData = {
                        numRegions: Region.countForCCR(),
                        numOriginators: vm.originatorsCount,
                        numAppraisers: vm.appraisersCount
                    };
                }

            } else if (account.authorities.indexOf("ROLE_ORIGINATOR_PRINCIPAL") !== -1 ||
                account.authorities.indexOf("ROLE_ORIGINATOR_AGENT") !== -1) {

                // Set the theme class on every page load.
                $rootScope.themeClass = "originator";

                // Perform these actions only for the dashboard page.
                if ($rootScope.toState.name === "home") {
                    vm.newsArticles = News.getRecentNewsForOriginators();
                    vm.ccrInfo = User.getCurrentUserManager();
                }

            } else if (account.authorities.indexOf("ROLE_APPRAISER") !== -1 ||
                account.authorities.indexOf("ROLE_APPRAISER_CANDIDATE") !== -1 ||
                account.authorities.indexOf("ROLE_APPRAISAL_FIRM_PRINCIPAL") !== -1 ||
                account.authorities.indexOf("ROLE_APPRAISAL_FIRM_ADMINISTRATOR") !== -1) {

                // Set the theme class on every page load.
                $rootScope.themeClass = "appraiser";

                // Perform these actions only for the dashboard page.
                if ($rootScope.toState.name === "home") {
                    vm.ccrInfo = User.getCurrentUserManager();
                    vm.newsArticles = News.getRecentNewsForAppraisers();
                }

            } else if (account.authorities.indexOf("ROLE_VC_OWNERSHIP_TEAM") !== -1 ||
                account.authorities.indexOf("ROLE_VC_MANAGEMENT_TEAM") !== -1 ||
                account.authorities.indexOf("ROLE_VC_DATA_ANALYST") !== -1 ||
                account.authorities.indexOf("ROLE_VC_FINANCIAL_TEAM") !== -1) {

                // Set the theme class on every page load.
                $rootScope.themeClass = "admin";

                // Perform these actions only for the dashboard page.
                if ($rootScope.toState.name === "home") {
                    vm.originatorsCount = User.countOriginators();
                    vm.appraisersCount = User.countAppraisers();
                    // vm.ordersCount = AppraisalOrder.getNumberOfAppraiserOrders();
                    vm.outstandingOrdersCount = AppraisalOrder.getNumberOfOutstandingOrders();
                }

            } else if (account.authorities.indexOf("ROLE_CUSTOMER_CARE_REPRESENTATIVE") !== -1) {

                // Set the theme class on every page load.
                $rootScope.themeClass = "admin";

                // Perform these actions only for the dashboard page.
                if ($rootScope.toState.name === "home") {
                    // TODO: combine these into a single request
                    vm.ccrData = {
                        numRegions: Region.countForCCR(),
                        numOriginators: User.countOriginators(),
                        numAppraisers: User.countAppraisers()
                    };
                }
            } else if (account.authorities.indexOf("ROLE_LENDER_ADMINISTRATOR") !== -1) {

                // Set the theme class on every page load.
                $rootScope.themeClass = "admin";
            }
        }

        function redirectLenderAdminUser() {
            if (vm.isLenderUser && $rootScope.toState.name === "home") {
                $state.go('risk-rule');
            }
        }

        function showAllChanged() {
            Principal.identity().then(function (identity) {
                LocalStorage.SaveModel('dashboard_show_all_' + identity.id, vm.showAll);
            });
        }

        function showNewFeatures () {
            $uibModal.open({
                templateUrl: 'app/home/new-features.modal.html',
                controller: 'NewFeaturesModalController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    features: function() {
                        return vm.newFeatures;
                    }
                }
            });
        }

        function navigateToPlaceOrder() {
            $window.location.href = $window.location.protocol + "//" + $window.location.host + "/v2/appraisal-order/new"
        }
    }
})();
