(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('AppraisalReportNewDialogController', AppraisalReportNewDialogController);

    AppraisalReportNewDialogController.$inject = ['$state', '$timeout', '$scope', '$stateParams', 'moment', '$mdDialog', 'entity', 'AppraisalReport', 'DynamicForm'];

    function AppraisalReportNewDialogController ($state, $timeout, $scope, $stateParams, moment, $mdDialog, entity, AppraisalReport, DynamicForm) {
        var vm = this;
        vm.appraisalReport = entity;

        if (entity.forms) {
            vm.includedForms = entity.forms.map(function(form) { return form.formName; });
        }
        vm.optionalForms = entity.optionalForms;
        vm.clear = clear;
        vm.save = save;
        vm.getFormUrl = getFormUrl;
        vm.cancelNewAppraisalReportDialog = cancelNewAppraisalReportDialog;

        vm.minDate = moment().toDate();
        //vm.maxDate = moment.max(moment(), moment(vm.appraisalOrder.activeReport.appraisalDueDate)).toDate();


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            window.history.back();
        }

        function cancelNewAppraisalReportDialog() {
            $mdDialog.cancel('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.appraisalReport.id) {
                AppraisalReport.update(vm.appraisalReport, onSaveSuccess, onSaveError);
            } else {
                AppraisalReport.save(vm.appraisalReport, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('valueconnectApp:appraisalReportUpdate', result);
            $mdDialog.cancel('cancel');
            $state.reload();
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function getFormUrl(form, isNew) {
            return $state.href("form" + (isNew ? '.new' : '.update'), {id: vm.appraisalReport.id, formName: form});
        }
    }
})();
